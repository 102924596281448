import { Box } from "grommet";
import React from "react";
import { Column, Filters } from "react-table";
import { Storage } from "aws-amplify";
import formatDate from "date-fns/format";
import { toast } from "react-toastify";

import { CollectorLayout } from "src/components/shared/layout";
import { Card } from "src/components/shared/layout/Card";
import { CollectorPageTop } from "src/components/shared/layout/CollectorPageTop";
import { PageConfines } from "src/components/shared/layout/PageConfines";
import { Text } from "src/components/shared/text";
import { PageProps } from "src/pages/Router";
import { useAuthContext } from "src/utils/authentication";
import { UserDocument, DocumentStatus } from "src/utils/api/routes/users.api";
import { Button } from "src/components/shared/button/Button";
import { Table } from "src/components/shared/table";
import { useApiRequest } from "src/utils/api";
import { FiltersOptions } from "src/utils/api/api";

type CollectorDocumentsTable = Pick<UserDocument, "id" | "name" | "description" | "s3Key"> & { updatedAt: string };

const columns: Array<Column<CollectorDocumentsTable>> = [
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    disableFilters: true
  },
  {
    Header: "Download",
    accessor: "s3Key",
    disableFilters: true,
    // eslint-disable-next-line react/display-name
    Cell: row => {
      // download the file from s3 and trigger a download
      return (
        <div>
          <Button
            label="Download"
            onClick={() => {
              Storage.get(row.value, {
                level: "public",
                download: true,
                customPrefix: { public: "" }
              }).then(data => {
                const blob = new Blob([ (data as Record<string, any>).Body ], { type: (data as Record<string, any>).ContentType });
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(blob);
                link.download = row.row.original.name;
                link.click();
              });
            }}
          />
        </div>
      );
    }
  }
];

const defaultFilters: FiltersOptions<Partial<UserDocument>> = { status: DocumentStatus.uploaded };

export const CollectorDocuments: React.FC<PageProps<Record<string, unknown>>> = () => {
  const [ data, setData ] = React.useState<CollectorDocumentsTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [ userDocumentsRes, userDocumentsReq ] = useApiRequest("USERS:documents");
  const { authState } = useAuthContext();
  const { user } = authState;

  // initaial fetch
  React.useEffect(() => {
    if (authState.user?.id) {
      userDocumentsReq({
        pathParams: { userId: authState.user.id.toString() },
        params: {
          filters: { ...defaultFilters },
          offset: 0,
          limit: 10
        }
      });
    }
  }, [ authState.user, userDocumentsReq ]);

  // table fetch paginated data
  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters
  }: {
      pageSize: number;
      pageIndex: number;
      tableFilters?: Filters<CollectorDocumentsTable>;
    }) => {
    if (authState.user?.id) {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const filters: Partial<CollectorDocumentsTable> = {};

        tableFilters?.map(filter => {
          const id = filter.id as keyof CollectorDocumentsTable;

          filters[ id ] = filter.value;
        });
        // Set the loading state
        setLoading(true);

        userDocumentsReq({
          pathParams: { userId: authState.user.id.toString() },
          params: {
            filters: {
              ...defaultFilters,
              ...filters
            },
            offset: pageIndex * pageSize,
            limit: pageSize
          }
        });
      }
    }
  }, [ authState.user, userDocumentsReq ]);

  // handle incoming documents data
  React.useEffect(() => {
    if (userDocumentsRes.data && userDocumentsRes.data.items) {
      const tableData: CollectorDocumentsTable[] = userDocumentsRes.data.items.map(userDocument => {
        return {
          id: userDocument.id,
          name: userDocument.name,
          description: userDocument.description,
          updatedAt: formatDate(new Date(userDocument.updatedAt), "dd MMM yyyy HH:mm"),
          s3Key: userDocument.s3Key
        };
      });

      setData(tableData);
      setPageCount(Math.ceil(userDocumentsRes.data.total / userDocumentsRes.data.limit));
      setLoading(false);
    }

    if (userDocumentsRes.errorMessage) {
      toast.error(userDocumentsRes.errorMessage);
      setLoading(false);
    }
  }, [ userDocumentsRes ]);

  return (
    <CollectorLayout>
      <CollectorPageTop>
        <Box direction="row" justify="between">
          <Box justify="end">
            <Box pad={{ vertical: "small" }}>
              <Text color="white" as="h1">
                Your Documents
              </Text>
            </Box>
          </Box>
        </Box>
      </CollectorPageTop>
      <PageConfines>
        <Card margin={{ top: "medium" }}>
          {user && (
            <Table<CollectorDocumentsTable>
              name="clientCollectorDocumentsTable"
              columns={columns}
              data={data}
              loading={loading}
              featuredFilter="name"
              fetchData={fetchData}
              pageCount={pageCount}
              usePagination
              useResizeColumns
              useHideColumns
              useFilters
            />
          )}
        </Card>
      </PageConfines>

    </CollectorLayout>
  );
};
