import React from "react";
import { HeaderProps } from "react-table";
import { Button, Box } from "grommet";

import { Select } from "../forms/Select";

export function SelectRoleColumnFilter<T extends Record<string, unknown>>({ 
  column: {
    filterValue, setFilter, id
  } 
}: HeaderProps<T>): React.ReactElement {
  const options = [ "Admin", "Customer" ];

  return (
    <Box direction="row" align="center">
      <Select
        value={filterValue || options}
        name={id}
        placeholder="Select role"
        onChange={({ value }) => setFilter(value)}
        options={options}
        multiple
        closeOnChange={false}
        messages={{ multiple: "All roles" }}
      />
      <Box className="featured-filter-submit-btn" pad={{ left: "small" }}>
        <Button primary type="submit" label="Filter" />
      </Box>
    </Box>
  );
}