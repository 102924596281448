import React from "react";
import { Box } from "grommet";

import { Text } from "src/components/shared/text";

interface VariationPriceBoxProps {
  variationPercentage: number | null;
}

export const VariationPriceBox: React.FC<VariationPriceBoxProps> = ({ variationPercentage }) => {
  const variationSymbol = variationPercentage ? variationPercentage < 0 ? "-" : variationPercentage > 0 ? "+" : "" : "";

  return (
    variationPercentage ? (
      <Box>
        <Text family="primary" color={variationPercentage > 0 ? "success" : "warning"}>
          {variationSymbol} 
          {" "}
          {Math.abs(Number((variationPercentage).toFixed(2)))}
          %
        </Text>
      </Box>
    ) : <></>
  );
};