import formatDate from "date-fns/format";
import { Box, Menu } from "grommet";
import * as _ from "lodash";
import React from "react";
import { toast } from "react-toastify";

import {
  InfoBox, InfoColumn, InfoPanel 
} from "src/components/shared/info";
import { PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Card } from "src/components/shared/layout/Card";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import { Modal } from "src/components/shared/modal";
import { CurrentValueBox } from "src/components/shared/price-graph";
import { Table } from "src/components/shared/table";
import { TabPanel, TabTitle } from "src/components/shared/tabs";
import { Text } from "src/components/shared/text";
import {
  CashValueBox, userCasesColumns, UserCasesTable 
} from "src/components/user";
import { useTableFetch } from "src/hooks/useTableFetch";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { User } from "src/utils/api/routes/users.api";
import { theme } from "src/utils/theme";

import { ClientDocuments } from "./ClientDocuments";
import { ClientTransactions } from "./ClientTransactions";
import { PortfolioValueGraph } from "./PortfolioValueGraph";

interface UserPageParams {
  userId: string;
}

export const ViewUserPage: React.FC<PageProps<Record<string, unknown>, UserPageParams>> = props => {
  const [ getUserRes, getUserReq ] = useApiRequest("USERS:get");
  const [ deleteUserRes, deleteUserReq ] = useApiRequest("USERS:delete");
  const [ inviteUserRes, inviteUserReq ] = useApiRequest("USERS:invite");
  const [ user, setUser ] = React.useState<User>();
  const [ loading, setLoading ] = React.useState(true);
  const [ userCasesData, setUserCasesData ] = React.useState<UserCasesTable[]>([]);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ userCasesLoading, setUserCasesLoading ] = React.useState(false);
  const [ tabIndex, setTabIndex ] = React.useState(0);
  const [ deleteModalOpen, setDeleteModalOpen ] = React.useState(false);
  const userId = React.useMemo(() => props.match?.params.userId || "", [ props.match ]);

  const [ casesRes, casesReq ] = useTableFetch<"USERS:cases", UserCasesTable>("USERS:cases", { defaultSort: [ [ "name", "ASC" ] ] }, {
    pathParams: { userId },
    params: {}
  });

  // Handle the deletion of a user
  const handleDeleteUser = React.useCallback(() => {
    deleteUserReq({ pathParams: { userId } });
  }, [ deleteUserReq, userId ]);

  // Request the user by id based off path params
  React.useEffect(() => {
    if (!user && props.match?.params?.userId) {
      getUserReq({ pathParams: { userId: props.match.params.userId } });
    }
  }, [
    props,
    getUserReq,
    user
  ]);

  // Detect the response for get user by id
  React.useEffect(() => {
    if (getUserRes.data) {
      setUser(getUserRes.data);
      setLoading(false);
    }

    if (getUserRes.errorMessage) {
      toast.error(getUserRes.errorMessage);
    }
  }, [
    getUserRes,
    props.history,
    props.match
  ]);

  // Detect success or error in the delete user response
  React.useEffect(() => {
    if (deleteUserRes.data) {
      toast.success("The user was successfully deleted.");
      props.history.push("/admin/users");
    }

    if (deleteUserRes.errorMessage) {
      toast.error(deleteUserRes.errorMessage);
    }
  }, [ deleteUserRes, props.history ]);

  // Detect and handle the cases response
  React.useEffect(() => {
    if (casesRes.data && casesRes.data.items) {
      setUserCasesData(casesRes.data.items);
      setPageCount(Math.ceil(casesRes.data.total / casesRes.data.limit));
      setUserCasesLoading(false);
    }

    if (casesRes.errorMessage) {
      toast.error(casesRes.errorMessage);
    }
  }, [ casesRes ]);

  const inviteUser = React.useCallback(() => {
    if (user?.id) {
      inviteUserReq({ pathParams: { userId: user.id.toString() } });
    }
  }, [ user, inviteUserReq ]);

  // Detect and handle the cases response
  React.useEffect(() => {
    if (inviteUserRes.data) {
      toast.success("User was invited. Please prompt them to check their emails to reset their password and login for the first time.");
    }

    if (inviteUserRes.errorMessage) {
      toast.error(inviteUserRes.errorMessage);
    }
  }, [ inviteUserRes ]);

  return (
    <AdminLayout>
      <PageHeader backLink={() => props.history.push("/admin/users")} />
      {loading && <LoadingSpinner />}
      {user && (
        <Box justify="between" direction="row" align="end" pad={{ bottom: "medium" }}>
          <Box>
            <Box pad={{ vertical: "medium" }}>
              <Text as="h1" bold>
                {user.name}
              </Text>
            </Box>
          </Box>
          <Box>
            <Menu
              dropProps={{
                align: {
                  top: "bottom",
                  right: "right"
                }
              }}
              items={[
                {
                  label: "Edit user",
                  onClick: () => props.history.push(`/admin/user/${userId}/edit`)
                },
                {
                  label: "New transaction",
                  onClick: () => props.history.push(`/admin/user/${userId}/transactions/new`)
                },
                {
                  label: "Invite user",
                  onClick: () => inviteUser()
                },
                {
                  label: "Delete user",
                  onClick: () => setDeleteModalOpen(true)
                }
              ]}
              label="Actions"
            />
          </Box>

        </Box>
      )}
      <Box direction="row">
        <Box width="100%">
          {user && (
            <InfoPanel>
              <InfoColumn>
                <InfoBox label="Email" value={user.email} />
                <InfoBox label="Phone" value={user.phone} />
                <InfoBox label="External id" value={user.externalId} />
                <InfoBox label="Member Since" value={formatDate(new Date(user.createdAt), "dd MMM yyyy")} />
              </InfoColumn>
            </InfoPanel>

          )}
        </Box>
      </Box>
      {user && _.find(user.roles, role => role.name === "collector") && (
        <Card margin={{ top: "medium" }}>
          <Box
            border={{
              color: theme.colors.fadedLighter,
              side: "bottom",
              size: "2px"
            }}
            align="center"
            direction="row"
          >
            <TabTitle onClick={() => setTabIndex(0)} active={tabIndex === 0} title="Wine Portfolio" />
            <TabTitle onClick={() => setTabIndex(1)} active={tabIndex === 1} title="Performance" />
            <TabTitle onClick={() => setTabIndex(2)} active={tabIndex === 2} title="Cash Transactions" />
            <TabTitle onClick={() => setTabIndex(3)} active={tabIndex === 3} title="Documents" />
            <Box margin={{ left: "auto" }}>
              {(tabIndex === 0 || tabIndex === 1) && (
                <CurrentValueBox currentValue={user.portfolioValue} title="Portfolio Value" />
              )}
              {tabIndex === 2 && (
                <CashValueBox totalInvested={72000} readyToInvest={user.cashBalance} />
              )}
            </Box>
          </Box>
          <TabPanel active={tabIndex === 0}>
            <Table<UserCasesTable>
              title={`${casesRes.data?.total || 0} cases`}
              name="userCasesTable"
              columns={userCasesColumns}
              data={userCasesData}
              onClick={row => props.history.push(`/admin/case/${row.original.id}`)}
              loading={userCasesLoading}
              fetchData={casesReq}
              useResizeColumns
              pageCount={pageCount}
              featuredFilter="name"
              usePagination
              useFilters
              useHideColumns
              useSortBy
            />
          </TabPanel>
          <TabPanel active={tabIndex === 1}>
            {user && <PortfolioValueGraph user={user} />}
          </TabPanel>
          <TabPanel active={tabIndex === 2}>
            <ClientTransactions userId={userId} />
          </TabPanel>
          <TabPanel active={tabIndex === 3}>
            <ClientDocuments userId={userId} />
          </TabPanel>
        </Card>
      )}

      {deleteModalOpen && user && (
        <Modal
          isLoading={loading}
          onClose={() => setDeleteModalOpen(false)}
          title={`Delete ${user.name}?`}
          description={`Are you sure that you want to delete ${user.name}?`}
          actions={{
            confirm: {
              label: "Delete",
              onClick: () => {
                handleDeleteUser();
                setDeleteModalOpen(false);
              }
            },
            reject: {
              label: "Cancel",
              onClick: () => setDeleteModalOpen(false)
            }
          }}
        />
      )}
    </AdminLayout>
  );
};
