import { Box } from "grommet";
import React from "react";
import styled, { css } from "styled-components";

import { Text } from "../shared/text";

interface DrinkingWindowLineProps {
  start?: number;
  end?: number;
  rampStart?: number;
  peakStart?: number;
  peakEnd?: number;
  rampEnd?: number; 
}
export const DrinkingWindowLine: React.FC<DrinkingWindowLineProps> = ({ 
  start = 0, rampStart = 0, peakStart = 0, peakEnd = 0, rampEnd = 0, end = 0
}) => {
  const spannedYears = end - start;
  const divider = spannedYears / 10;
  const currentYear = new Date().getFullYear();
  const currentYearPosition = ((currentYear - start) * 100) / (end - start) + "%";

  const stops = React.useMemo(() => {
    return [
      ((rampStart - start) * 100) / (end - start),
      ((peakStart - start) * 100) / (end - start),
      ((peakEnd - start) * 100) / (end - start),
      ((rampEnd - start) * 100) / (end - start)
    ];
  }, [
    end,
    peakEnd,
    peakStart,
    rampEnd,
    rampStart,
    start
  ]);
  
  return (
    <Box width="100%" direction="column">
      <Box direction="row" justify="between">
        <Text as="small">
          {start}
        </Text>
        <Text as="small">
          {end}
        </Text>
      </Box>
      <GradientLine stops={stops}>
        <ToolTip style={{ left: currentYearPosition }}>
          <Text>
            {currentYear}
          </Text>
        </ToolTip>
        {new Array(9).fill("").map((_, index) => {
          const amount = (index + 1) * divider;

          return (
            <Divider key={index}>
              <Tick />
              <ToolTip>
                <Text>
                  {Math.ceil(start + amount)}
                </Text>
              </ToolTip>
              
            </Divider>
          );
        })}

      </GradientLine>
    </Box>
  );
};

const ToolTip = styled.div`
  ${({ theme }) => css`
    transition: opacity ease-in-out 0.2s;
    margin-top: 0.5rem;
    opacity: 0;
    position: absolute;
    transform: translate(-50%, 20px);
    background: ${theme.colors.fadedLight};
    border-radius: ${theme.borderRadius.small};
    padding: 0.3rem 0.5rem;
    left: 50%;
    pointer-events: none;
    :after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: ${theme.colors.fadedLight};
      border-width: 8px;
      margin-left: -8px;
    }
  `}
`;

const GradientLine = styled.div<{stops: number[]}>`
  ${({ theme, stops }) => css`
    position: relative;
    height: 1rem;
    width: 100%;
    border-radius: 20px;
    margin: 1rem 0;
    background: ${theme.colors.fadedLighter};
    background: linear-gradient(90deg, ${theme.colors.fadedLighter} ${stops[ 0 ]}%, ${theme.colors.success} ${stops[ 1 ]}%, ${theme.colors.success} ${stops[ 2 ]}%, ${theme.colors.fadedLighter} ${stops[ 3 ]}%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
  `}

  > ${ToolTip} {
      opacity: 1;
    }

  :hover {
    > ${ToolTip} {
      opacity: 0;
    }
  }

`;

const Tick = styled.div`
  height: 0.4rem;
  width: 1px;
  background: white;
`;

const Divider = styled.div`
  min-width: 10%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* Gives the mouse padding to pick up hover */
  padding-bottom: 30px;
  margin-bottom: -30px;
  padding-top: 20px;
  margin-top: -20px;
  :hover{ 
    ${ToolTip}{
      opacity: 1;
    }
  }
`;
