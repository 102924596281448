import { Box } from "grommet";
import React from "react";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";

import { bottleTableColumns, CaseBottlesTable } from "src/components/case/CaseBottlesTable";
import { Icons } from "src/components/shared/icon";
import { InfoBox, InfoColumn } from "src/components/shared/info";
import { Card } from "src/components/shared/layout/Card";
import { CollectorLayout } from "src/components/shared/layout/CollectorLayout";
import { CollectorPageTop } from "src/components/shared/layout/CollectorPageTop";
import { PageConfines } from "src/components/shared/layout/PageConfines";
import { Table } from "src/components/shared/table";
import { Text } from "src/components/shared/text";
import { DrinkingWindowLine } from "src/components/vintage";
import { WineIconBox } from "src/components/wine/WineIconBox";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { Case, statusLabels } from "src/utils/api/routes/cases.api";
import { Vintage } from "src/utils/api/routes/vintages.api";
import { Wine } from "src/utils/api/routes/wines.api";
import { formatCurrency } from "src/utils/currency/formatCurrency";

import { CollectorCaseHistory } from "./CollectorCaseHistory";

interface CasePageParams {
  caseId: string;
  vintageId?: string;
}

const hiddenColumns = [ "id" ];

export const CollectorViewCasePage: React.FC<PageProps<Record<string, unknown>, CasePageParams>> = props => {
  const [ singleCase, setCase ] = React.useState<Case>();
  const [ vintage, setVintage ] = React.useState<Vintage>();
  const [ wine, setWine ] = React.useState<Wine>();
  const [ bottleTableData, setBottleTableData ] = React.useState<CaseBottlesTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ getCaseRes, getCaseReq ] = useApiRequest("CASES:get");
  const [ getVintagesRes, getVintagesReq ] = useApiRequest("VINTAGES:get");

  // Request the case by id based off path params
  React.useEffect(() => {
    if (!singleCase && props.match?.params?.caseId) {
      getCaseReq({ pathParams: { caseId: props.match.params.caseId } });
    }
  }, [
    props,
    getCaseReq,
    singleCase
  ]);
  
  // Detect the response for get case by id
  React.useEffect(() => {
    if (getCaseRes.data) {
      setCase(getCaseRes.data);

      if (getCaseRes.data.bottles) {
        setBottleTableData(getCaseRes.data.bottles);
      }

      setLoading(false);
    }

    if (getCaseRes.errorMessage) {
      toast.error(getCaseRes.errorMessage);
    }
  }, [
    getCaseRes,
    props.history,
    props.match
  ]);

  // Request the vintage if the case is consistent
  React.useEffect(() => {
    if (singleCase?.bottles) {
      const consistentCase = singleCase.bottles.every(bottle => bottle.vintage.id);
      const vintageId = singleCase.bottles[ 0 ].vintage.id;

      if (consistentCase && vintageId) {
        getVintagesReq({ pathParams: { vintageId: vintageId.toString() } });
      }
    }
  }, [ getVintagesReq, singleCase ]);

  // Detect the response for get case by id
  React.useEffect(() => {
    if (getVintagesRes.data) {
      setVintage(getVintagesRes.data);
      setWine(getVintagesRes.data.wine);
    }
  
    if (getVintagesRes.errorMessage) {
      toast.error(getVintagesRes.errorMessage);
    }
  }, [ getVintagesRes ]);
  
  return (
    <CollectorLayout>
      <CollectorPageTop>
        {singleCase && (
          <Box direction="row" justify="between">
            <Box justify="end">
              <Box pad={{ vertical: "small" }}>
                <Text color="white" as="h1">
                  {singleCase.name}
                </Text>
              </Box>
            </Box>
            <Box width="66%" margin={{ bottom: "-10rem" }} direction="row">
              <Card direction="row">
                <CardLeft>
                  1275
                </CardLeft>
                <CardBody>
                  {wine && (
                    <Box wrap direction="row">
                      <WineIconBox width="50%" icon={Icons.droplet} label={wine.wineColour?.name} />
                      <WineIconBox width="50%" icon={Icons.globe} label={`${wine.region}, ${wine.country}`} />
                      <WineIconBox width="50%" icon={Icons.barrel} label={wine.displayWineLine1} />
                      <WineIconBox width="50%" icon={Icons.grapes} label={wine.displayWineLine2} />
                    </Box>
                  )}
                  {(vintage?.drinkingWindowStart && vintage?.drinkingWindowEnd && wine?.preDrinkingYears && wine.matureDrinkingYears) && (
                    <Box pad={{ vertical: "small" }}>
                      <Text as="h4">
                        Drinking window
                      </Text>
                      <DrinkingWindowLine 
                        start={vintage.year} 
                        rampStart={vintage.drinkingWindowStart - wine?.preDrinkingYears}
                        peakStart={vintage.drinkingWindowStart}
                        peakEnd={vintage.drinkingWindowEnd}
                        rampEnd={vintage.drinkingWindowEnd + wine?.matureDrinkingYears}
                        end={vintage.drinkingWindowEnd + wine?.matureDrinkingYears}
                      />
                    </Box>
                  )}
                </CardBody>
              </Card>
            </Box>
          </Box>
        )}
      </CollectorPageTop>
      <PageConfines>
        <Box direction="row">
          <Box>
            {singleCase && (
              <Box pad={{ top: "2rem" }} width="300px">
                <InfoColumn>
                  <InfoBox labelWidth="14rem" label="Case Code" value={singleCase?.label?.certificationReference} />
                  <InfoBox label="Status" value={statusLabels[ singleCase.status ]} />
                  <InfoBox label="Book Price" value={formatCurrency(singleCase.bookPrice)} />
                </InfoColumn>
              </Box>
            )}
          </Box>
        </Box>
        <Card margin={{ top: "medium" }}>
          <Table<CaseBottlesTable>
            title={`Contains ${bottleTableData.length} bottles`}
            name="collectorCaseBottlesTable"
            columns={bottleTableColumns}
            data={bottleTableData}
            loading={loading}
            pageCount={1}
            defaultHiddenColumns={hiddenColumns}
            useHideColumns
            useResizeColumns
          />
        </Card>
        <Box pad={{ top: "large" }}>
          <Text as="h3">
            Case history
          </Text>
        </Box>
        <Card
          margin={{
            bottom: "medium",
            top: "small" 
          }}
        >

          {(singleCase && props.match?.params.caseId) && (
            <CollectorCaseHistory
              currentData={{
                location: singleCase.location,
                createdAt: singleCase.createdAt,
                status: singleCase.status
              }}
              caseId={props.match.params.caseId}
            />
          )}
        </Card>
      </PageConfines>
      
    </CollectorLayout>
  );
};

const CardLeft = styled.div`
 ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    background: ${theme.colors.fadedLight};  
  `}
`;

const CardBody = styled.div`
  ${({ theme }) => css`
    width: 80%;
    padding: 2rem;
    background: ${theme.colors.white};
  `}
`;
