import React from "react";
import { toast } from "react-toastify";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { EstateForm } from "src/components/estate";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { CreateEstateInput } from "src/utils/api/routes/estates.api";

/**
 * New estate form
 */
const NewEstateForm: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ createEstateRes, createEstateReq ] = useApiRequest("ESTATES:create");

  // On submit handler
  const onSubmit = React.useCallback((estate: CreateEstateInput) => {
    // types as Partial which createEstateReq doesn't like - but we've
    // already validated wine to ensure it contains required fields
    createEstateReq({ data: pickBy(estate, identity) as CreateEstateInput });
  }, [ createEstateReq ]);

  // On createEstateRes change
  React.useEffect(() => {
    if (createEstateRes.data) {
      toast.success(`${createEstateRes.data.name} was created successfully. Click here to view.`, {
        autoClose: false,
        onClick: () => props.history.push(`/admin/estates/${createEstateRes.data?.id}`)
      });
    }

    if (createEstateRes.errorMessage) {
      toast.error(createEstateRes.errorMessage);
    }
  }, [ createEstateRes, props.history ]);

  return (
    <AdminLayout>
      <PageHeader title="New Estate" backLinkText="Back to all estates" backLink={() => props.history.push("/admin/estates")} />
      <Card pad="small" maxWidth="70rem">
        <EstateForm isLoading={createEstateRes.loading} onSubmit={onSubmit} />
      </Card>

    </AdminLayout>
  );
};

export default NewEstateForm;