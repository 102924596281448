import { Box } from "grommet";
import { FormNextLink, FormPreviousLink } from "grommet-icons";
import React, {
  PropsWithChildren, ReactElement, useCallback 
} from "react";
import { TableInstance } from "react-table";
import styled from "styled-components";

import { Select } from "../../forms/Select";
import { Button } from "../button";
import { Text } from "../text";

const rowsPerPageOptions = [
  5,
  10,
  25,
  50,
  100
];

export function TablePagination<T extends Record<string, unknown>>({ instance }: PropsWithChildren<{ instance: TableInstance<T> }>): ReactElement | null {
  const {
    state: {
      pageIndex, pageSize, rowCount = instance.rows.length 
    },
    canNextPage,
    pageCount,
    canPreviousPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage
  } = instance;

  const onChangeRowsPerPage = useCallback((option: number) => {
    setPageSize(option);
  },
  [ setPageSize ]);

  return rowCount ? (
    <>
      <Button plain display="flex" disabled={!canPreviousPage} onClick={() => previousPage()} icon={<FormPreviousLink />} label="" />
      {([
        -5,
        -4,
        -3,
        -2,
        -1,
        0,
        1,
        2,
        3,
        4,
        5
      ]).map(n => {
        const pageNumber = pageIndex + n;
        const pageNumberPlusOne = pageNumber + 1;

        if (pageNumberPlusOne > 0 && pageNumberPlusOne <= pageCount) {
          const handleClick = () => {
            gotoPage(pageNumber);
          };

          return (
            <Box 
              pad="small" 
              onClick={handleClick} 
              key={pageNumber}
            >
              <Text family="secondary" bold={pageNumber === pageIndex}>
                {pageNumberPlusOne}
              </Text>
            </Box>
          );
        }
      })}
      <Button plain display="flex" disabled={!canNextPage} onClick={() => nextPage()} icon={<FormNextLink />} label="" />
      <Box
        pad="small"
        direction="row"
        align="center"
      >
        <StyledRowsPerPageLabel family="secondary" as="small">
          Show
        </StyledRowsPerPageLabel>
        <StyledSelect
          options={rowsPerPageOptions}
          // @ts-expect-error - number not assignable to Select (although it is)
          value={pageSize}
          onChange={({ option }) => onChangeRowsPerPage(option)}
        />
      </Box>

    </>
  ) : null;
}

const StyledRowsPerPageLabel = styled(Text)`
  
  margin: 1rem;
`;

const StyledSelect = styled(Select)`
  width: 5rem;
`;