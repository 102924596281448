import React, { forwardRef } from "react";
import { FieldError, FormContextValues } from "react-hook-form";
import styled, { css } from "styled-components";

import { Text } from "src/components/shared/text";
import { TransactionMethod } from "src/utils/api/routes/transactions.api";
import {
  FormRow, FormError, TextInput
} from "src/components/forms";

interface TransactionCashInputProps {
  method: TransactionMethod | undefined | null;
  formError?: string | null | FieldError;
  setValue: FormContextValues["setValue"];
  disabled: boolean;
  cashValue: number;
}

export const TransactionCashInput = forwardRef<any, TransactionCashInputProps>(({
  method, formError, setValue, disabled, cashValue
}, ref) => {
  const localFieldError = React.useMemo(() => {
    if (method?.type === "debit" && cashValue > 0) {
      return `${method.label} requires the value to be a negative number`;
    }

    if (method?.type === "credit" && cashValue < 0) {
      return `${method.label} requires the value to be a positive number`;
    }

    return null;
  }, [ cashValue, method ]);

  return (
    <TransactionCashInputFormRow label={`Cash ${method ? method.type : ""}`}>
      <div className="input-wrapper">
        <Text className="currency">
          €
        </Text>
        <TextInput
          disabled={disabled}
          name="value"
          ref={ref}
          type="number"
          defaultValue="0.00"
          min={method?.type === "credit" ? "0" : ""}
          max={method?.type === "debit" ? "0" : ""}
          step=".01"
          onBlur={e => {
            let value = parseFloat(e.target.value);

            if (method?.type === "debit" && value > 0) {
              value = -value;
            }

            if (method?.type === "credit" && value < 0) {
              value = 0 - value;
            }

            setValue("value", value.toFixed(2));
          }}
        />
      </div>
      <FormError message={localFieldError || formError && "This field is required"} />
    </TransactionCashInputFormRow>
  );
});

const TransactionCashInputFormRow = styled(FormRow)`
${({ theme }) => css`
    .input-wrapper{
      position: relative;
    }
    .currency {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      font-size: ${theme.font.sizes.bodyRegular};
      font-weight: ${theme.font.weights.medium};
      line-height: 1;
    }

    input {
      text-align: right;
    }
  `}
`;

TransactionCashInput.displayName = "TransactionCashInput";