import React from "react";
import { toast } from "react-toastify";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { NewStorageFeeCalculationForm } from "src/components/shared/storage-fees/NewStorageFeeCalculationForm";
import { Text } from "src/components/shared/text";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { CreateStorageFeeCalculation } from "src/utils/api/routes/storage-fees.api";
import { useAuthContext } from "src/utils/authentication";

export const NewStorageFeesCalculation: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ createStorageFeeCalculationsRes, createStorageFeeCalculationsReq ] = useApiRequest("STORAGE-FEE:create-storage-fee-calculation");
  const { authState } = useAuthContext();

  const handleCreate = React.useCallback((data: CreateStorageFeeCalculation) => {
    if (data && authState.user) {
      const { endDate, startDate } = data;

      createStorageFeeCalculationsReq({
        data: {
          endDate,
          startDate
        }
      });
    }
  }, [ authState.user, createStorageFeeCalculationsReq ]);

  React.useEffect(() => {
    if (createStorageFeeCalculationsRes.data) {
      const { id } = createStorageFeeCalculationsRes.data;

      toast.success("Calculation was correctly created, user storage fees are being processed.");
      props.history.push(`/admin/storage-fee-calculation/${id}`);
    }

    if (createStorageFeeCalculationsRes.errorMessage) {
      toast.error(createStorageFeeCalculationsRes.errorMessage);
    }
  }, [ createStorageFeeCalculationsRes, props.history ]);

  return (
    <AdminLayout>
      <PageHeader 
        title="Storage Fee Calculator" 
        backLinkText="Back to storage fees"
        backLink={() => props.history.push("/admin/storage-fee-calculation")}
      />
      <Card pad="medium">
        <Text as="h3">
          Generate storage fees for a time frame.
        </Text>
        <NewStorageFeeCalculationForm
          isLoading={false}
          onSubmit={values => handleCreate(values)}
        />
      </Card>
    </AdminLayout>
  );
};
