import React from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";

import { Table } from "src/components/shared/table";
import { useTableFetch } from "src/hooks/useTableFetch";
import { Case } from "src/utils/api/routes/cases.api";
import { formatCurrency } from "src/utils/currency/formatCurrency";

interface UserCollectionParams {
  userId: number;
  onSelectCase: (caseId: number) => void;
}

type UserCasesTable = Pick<Case, "id" | "name" | "lwin" | "bookPrice" | "status" | "location" | "format" | "packSize" | "bottleSize">;

const userCasesColumns: Array<Column<UserCasesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Bottle Size",
    accessor: "bottleSize"
  },
  {
    Header: "Pack Size",
    accessor: "packSize"
  },
  {
    Header: "LWIN",
    accessor: "lwin",
    disableFilters: true
  },
  {
    Header: "Book Price",
    accessor: "bookPrice",
    Cell: cell => formatCurrency(cell.value)
  },
  {
    Header: "Location",
    accessor: "location"
  }

];

export const UserCollectionTable: React.FC<UserCollectionParams> = props => {
  const [ userCasesData, setUserCasesData ] = React.useState<UserCasesTable[]>([]);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ userCasesLoading, setUserCasesLoading ] = React.useState(false);
  const userId = props.userId;

  const [ casesRes, casesFetch ] = useTableFetch<"USERS:cases", UserCasesTable>(
    "USERS:cases", 
    { defaultSort: [ [ "name", "ASC" ] ] }, 
    {
      pathParams: { userId: userId.toString() },
      params: {} 
    });
  
  // Detect and handle the cases response
  React.useEffect(() => {
    if (casesRes.data && casesRes.data.items) {
      setUserCasesData(casesRes.data.items);
      setPageCount(Math.ceil(casesRes.data.total / casesRes.data.limit));
      setUserCasesLoading(false);
    }

    if (casesRes.errorMessage) {
      toast.error(casesRes.errorMessage);
    }
  }, [ casesRes.data, casesRes.errorMessage ]);

  return (
    <Table<UserCasesTable>
      title={`${casesRes.data?.total || 0} cases`}
      name="collectorCasesTable"
      columns={userCasesColumns}
      data={userCasesData}
      onClick={row => props.onSelectCase(row.original.id)}
      loading={userCasesLoading}
      fetchData={casesFetch}
      pageCount={pageCount}
      useResizeColumns
      featuredFilter="name"
      usePagination
      useSortBy
      useFilters
      useHideColumns
    />    
  );
};
