import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button } from "src/components/shared/button";
import {
  FormRow, TextInput, Select, FormError 
} from "src/components/forms";
import { AssignCaseToUserInput } from "src/utils/api/routes/cases.api";
import { useApiRequest } from "src/utils/api";
import { User } from "src/utils/api/routes/users.api";

interface CaseFormProps {
  onSubmit: (data: AssignCaseToUserInput) => void;
  isLoading: boolean;
}

interface SelectUserDropDown {
  id: number;
  name: string;
}

export const AssignCaseToUserForm: React.FC<CaseFormProps> = props => {
  const {
    register, handleSubmit, watch, setValue, errors
  } = useForm<AssignCaseToUserInput>();

  const [ usersRes, usersReq ] = useApiRequest("USERS:list");
  const [ userData, setUserData ] = React.useState<SelectUserDropDown[]>([]);

  React.useEffect(() => {
    usersReq({
      params: {
        filters: {},
        offset: 0,
        limit: 100
      }
    });
  }, [ usersReq ]);
  
  React.useEffect(() => {
    if (usersRes.data && usersRes.data.items) {
      const data = usersRes.data.items.map((user: User) => {
        return {
          id: user.id,
          name: user.name
        };
      });

      setUserData(data);
    }

    if (usersRes.errorMessage) {
      toast.error(usersRes.errorMessage);
    }
  }, [ usersRes ]);

  const onSubmit = (caseInput: AssignCaseToUserInput) => {
    props.onSubmit(caseInput);
  };

  React.useEffect(() => {
    register({ name: "userId" });
  }, [ register ]);
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRow required label="User">
        <Select
          options={userData}
          name="userId"
          labelKey="name"
          value={userData.find(user => user.id === watch("userId"))}
          valueKey="id"
          onChange={e => setValue(
            "userId", parseInt(e.option.id, 10), true
          )}
        />
        {errors.userId && <FormError message="This field is required" />}
      </FormRow>
      
      <FormRow required label="Book Price (€)">
        <TextInput name="bookPrice" type="number" ref={register()} step="0.01" />
        {errors.bookPrice && <FormError message="This field is required" />}

      </FormRow>
    
      <Button disabled={props.isLoading} label="Submit" type="submit" />
    </form>
  );
};
