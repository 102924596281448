import * as React from "react";
import { Button as GrommetButton, ButtonType } from "grommet";
import styled, { css, DefaultTheme } from "styled-components";

export interface ButtonProps extends ButtonType {
  className?: string;
  label: string;
  iconDirection?: "left" | "right";
  display?: "inline" | "flex";
  backgroundColor?: keyof DefaultTheme["colors"];
  textColor?: keyof DefaultTheme["colors"];
  loading?: boolean;
  form?: string;
  small?: boolean;
  isSelected?: boolean;
}

const Wrapper = styled.div<{display?: "inline" | "flex"}>`
  z-index: 1;
  display: ${props => props.display || "inline"};
  position: relative;
`;

const StyledButton = styled(GrommetButton)<ButtonProps>`
  flex-direction: ${props => props.reverse ? "row" : "row-reverse"};

  ${props =>
    !props.plain &&
    css`
      font-family: ${props.theme.font.family.secondary};
      font-weight: ${props.theme.font.weights.medium};
      font-size: ${props.theme.font.sizes.bodySmall};
      position: relative;
      border-radius: ${props.theme.borderRadius.medium};
      height: 4rem;
      background: ${props.backgroundColor ? props.theme.colors[ props.backgroundColor ] : props.theme.colors.dark};
      border-color: ${props.backgroundColor ? props.theme.colors[ props.backgroundColor ] : props.theme.colors.dark};
      color: ${props.textColor ? props.theme.colors[ props.textColor ] : "white"};
      transition: all ease-in-out 0.2s;

      &:hover, &:focus, &:active {
        border-color: ${props.backgroundColor ? props.theme.colors[ props.backgroundColor ] : props.theme.colors.dark};
        box-shadow: 0px 1px 6px -2px ${props.theme.colors.dark};
      }

      &.selected {
        background: ${props.theme.colors.dark};
        border-color: ${props.theme.colors.dark};
        color: white;

        &:hover {
          box-shadow: 0px 0px 5px ${props.theme.colors.dark};
        }
      }
      &.negative-action {
        background: ${props.theme.colors.error};
        border-color: ${props.theme.colors.error};
        color: white;

        &:hover {
          box-shadow: 0px 0px 5px ${props.theme.colors.error};
        }
      }
    `};
  ${props =>
    props.small &&
    css`
      height: auto;
      padding: 0.3rem 1.2rem;
      border-radius: ${props.theme.borderRadius.small};
      font-size: ${props.theme.font.sizes.small};
    `};

    ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `};
`;

export const Button: React.FC<ButtonProps> = props => {
  const { iconDirection, display } = props;
  const iconDir: boolean = iconDirection === "right" || false;

  return (
    <Wrapper display={display}>
      <StyledButton className={props.isSelected && "selected"} disabled={props.loading || props.disabled} reverse={iconDir} {...props} />
    </Wrapper>
  );
};
