import * as React from "react";
import styled, { css } from "styled-components";
import { FieldError, Message } from "react-hook-form";

import { Text } from "../shared/text";

export interface FormErrorProps extends React.HTMLAttributes<HTMLSpanElement> {
  message?: Message | FieldError | string | null;
}

/**
 * Form error message
 */
export const FormError: React.FC<FormErrorProps> = props => {
  const { message, ...rest } = props;

  // If there isn't a message provided, return.
  if (!message) {
    return null;
  }

  return (
    <FormErrorStyled role="alert" {...rest}>
      <Text small>
        {message}
      </Text>
    </FormErrorStyled>
  );
};

const FormErrorStyled = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    color: ${theme.actions.error};
    font-size: ${theme.font.sizes.small};
  `};
`;