// credits to https://usehooks.com/useLocalStorage/

import { useCallback, useState } from "react";

import { createLogger } from "src/utils/logger";

const logger = createLogger("LOCAL_STORAGE");

export function useLocalStorage<T = Record<string, unknown>>(key: string, initialValue: T): [
  T,
  (value: T) => void,
  () => void,
] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [ storedValue, setStoredValue ] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      logger.error(error);

      return initialValue;
    }
  });

  // Retrieve value from local storage 
  // Helpful when local storage updates after initial mount
  const getValue = useCallback(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      setStoredValue(item ? JSON.parse(item) : initialValue);
    } catch (error) {
      // If error also return initialValue
      logger.error(error);
      setStoredValue(initialValue);
    }
  }, [ key, initialValue ]);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback((value: T) => {
    try {
      // Save state
      setStoredValue(value);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // A more advanced implementation would handle the error case
      logger.error(error);
    }
  },
  [ key ]);

  return [
    storedValue,
    setValue,
    getValue 
  ];
}
