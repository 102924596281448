import { ApiRouteDefinition } from "../useApiRequest";

const WineColourRoutes = {
  /** list all roles */
  "WINE-COLOURS:list": {
    path: "/wines/colours",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<WineColour[], null, null, null>
};

export default WineColourRoutes;

export interface WineColour {
  id: number;
  name: string;
  label: string;
}
