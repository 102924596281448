import React from "react";
import { isNil, sortBy } from "lodash";
import styled from "styled-components";
import isNumber from "lodash/isNumber";

import { TextInput } from "src/components/forms";
import { Text } from "src/components/shared/text";
import { CreateUserStorageFeeRate } from "src/utils/api/routes/users.api";

export interface UserStorageFeeRatesFormProps {
  onValueChange: (value: CreateUserStorageFeeRateFormInput[]) => void;
  onErrorChange: (error: string | null) => void;
  defaultValues?: CreateUserStorageFeeRateFormInput[];
}

export interface CreateUserStorageFeeRateFormInput {
  percentage: string | null;
  rateStart: string | null;
  rateEnd: string | null;  
}

export const UserStorageFeeRatesForm: React.FC<UserStorageFeeRatesFormProps> = ({
  onValueChange, onErrorChange, defaultValues 
}) => {
  const [ currentValues, setCurrentValues ] = React.useState<CreateUserStorageFeeRateFormInput[]>(defaultValues || []);
  const [ currentError, setCurrentError ] = React.useState<string | null>(null);

  const handleOnChange = React.useCallback((
    name: keyof CreateUserStorageFeeRate, index: number, value: string
  ) => {
    let newValues = [ ...currentValues ];

    if (!newValues[ index ]) {
      newValues[ index ] = {
        percentage: null,
        rateStart: null,
        rateEnd: null
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - values from number inputs come back as strings
    newValues[ index ][ name ] = value === "" ? null : value;
    newValues = newValues.filter(newValue => !isNil(newValue.percentage) || !isNil(newValue.rateEnd) || !isNil(newValue.rateStart));
    setCurrentValues(newValues);
    onValueChange(newValues);

    const error = validateUserStorageFees(newValues);

    if (error !== currentError) {
      setCurrentError(error);
      onErrorChange(error);
    }
  }, [
    currentError,
    onValueChange,
    onErrorChange,
    currentValues
  ]);

  return (
    <UserStorageFeeRatesFormWrapper>
      {currentValues.map((value, index: number) =>
        <UserStorageFeeRatesFormRow defaultValues={value} index={index} key={index} onChange={handleOnChange} />)
        .concat(<UserStorageFeeRatesFormRow index={currentValues.length} key={currentValues.length} onChange={handleOnChange} />)}
    </UserStorageFeeRatesFormWrapper>
  );
};

interface UserStorageFeeRatesFormRowProps {
  index: number;
  onChange: (name: keyof CreateUserStorageFeeRateFormInput, index: number, value: string) => void;
  defaultValues?: CreateUserStorageFeeRateFormInput;
}

const UserStorageFeeRatesFormRow: React.FC<UserStorageFeeRatesFormRowProps> = props => {
  const {
    index, onChange, defaultValues 
  } = props;

  return (
    <UserStorageFeeRatesFormRowWrapper>
      <Text>
        From:
        {" "}
      </Text>
      <TextInput
        type="number"
        step="0.01"
        value={!isNil(defaultValues?.rateStart) ? defaultValues?.rateStart : undefined}
        onChange={value => onChange(
          "rateStart", index, value.target.value
        )} 
        name={`rateStart${index}`} 
      />
      <Text>
        To:
        {" "}
      </Text>
      <TextInput
        type="number"
        step="0.01"
        value={!isNil(defaultValues?.rateEnd) ? defaultValues?.rateEnd : undefined}
        onChange={value => onChange(
          "rateEnd", index, value.target.value
        )} 
        name={`rateEnd${index}`} 
      />
      <Text>
        Rate:
        {" "}
      </Text>
      <TextInput
        type="number"
        step=".01"
        value={!isNil(defaultValues?.percentage) ? defaultValues?.percentage : undefined}
        onChange={value => onChange(
          "percentage", index, value.target.value
        )} 
        name={`percentage${index}`} 
      />
      <Text>
        {" "}
        %
      </Text>
    </UserStorageFeeRatesFormRowWrapper>
  );
};

const UserStorageFeeRatesFormRowWrapper = styled.div`
  display: flex;
  margin-top: 1rem;

  span {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

const UserStorageFeeRatesFormWrapper = styled.div`
  button {
    margin-top: 1rem;
  }
`;

export const validateUserStorageFees = (storageFeeRateGroup: CreateUserStorageFeeRateFormInput[]): string | null => {
  // Check there is, at least one storageFeeRate.
  if (!storageFeeRateGroup || storageFeeRateGroup.length === 0) {
    return "The user storage fee rates must have at least one rate range.";
  }
  // Order the rates by rateStart. (We'll apply the storage based on this).
  const storageFeeRatesOrdered = sortBy(storageFeeRateGroup, "rateStart");

  // Check the individual storage fee rate entities pass validation.
  for (const storageFeeRate of storageFeeRatesOrdered) {
    if (Number.isNaN(storageFeeRate.rateStart)) {
      return "'From' rate must be a number";
    }
    
    if (storageFeeRate.percentage && !isNumber(parseFloat(storageFeeRate.percentage))) {
      return "'Percentage' must be a number";
    }

    if (storageFeeRate.rateEnd && Number.isNaN(storageFeeRate.rateEnd)) {
      return "'To' rate from must be a number";
    }

    // Check rateStart is less than rateEnd.
    if (storageFeeRate.rateEnd && storageFeeRate.rateStart && (parseInt(storageFeeRate.rateStart, 10) >= parseInt(storageFeeRate.rateEnd, 10))) {
      return `'From' rate must be less that 'To' rate: ${storageFeeRate.rateStart}-${storageFeeRate.rateStart}`;
    }
  }

  // Check the group of rates is consistent.
  // Check the bottom fee rate has rateStart = 0;
  if (storageFeeRateGroup[ 0 ].rateStart && parseInt(storageFeeRateGroup[ 0 ].rateStart, 10) !== 0) {
    return "Top fee rate must have 'From' rate equals to 0";
  }
  //
  // - Check there is one and only one fee rate that has rateEnd = null
  // and it is situated at the top.
  const nullRateEnd = storageFeeRateGroup.filter(storageFeeRate => storageFeeRate.rateEnd === null);

  if (nullRateEnd.length !== 1) {
    return "One and only one (bottom) fee rate must have empty 'To' rate";
  }

  if (storageFeeRateGroup[ storageFeeRateGroup.length - 1 ] === null) {
    return "Bottom fee rate must have empty 'To' rate";
  }

  // - Check there rateStart value is equals to previous rate rateEnd value.

  for (const [ index, storageFeeRate ] of Object.entries(storageFeeRateGroup)) {
    if (parseInt(index, 10) !== 0 && storageFeeRate.rateStart !== storageFeeRateGroup[ parseInt(index, 10) - 1 ].rateEnd) {
      return "'From' rate value must be equal to previous 'To' rate value";
    }
  }

  return null;
};
