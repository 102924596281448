import { Action } from "redux";

import { User } from "src/utils/api/routes/users.api";

export enum GLOBAL_ACTIONS {
  SET_USER = "@GLOBAL/SET_USER"
}

export type GlobalActions = SetUser;

interface SetUser extends Action<GLOBAL_ACTIONS> {
  type: GLOBAL_ACTIONS.SET_USER;
  user: User | null;
}

export const setGlobalUser = (user: User | null): SetUser => {
  return {
    type: GLOBAL_ACTIONS.SET_USER,
    user
  };
};
