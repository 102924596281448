import { ApiRouteDefinition } from "../useApiRequest";
import {
  PaginatedQueryRequest, PaginatedResponse, PickParams, StatusResponse
} from "../api";

const EstateRoutes = {
  /** Create an Estate */
  "ESTATES:create": {
    path: "/estates/",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    Estate,
    CreateEstateInput,
    null
  >,

  /** list estates */
  "ESTATES:list": {
    path: "/estates",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Estate>, null, null, PaginatedQueryRequest<Partial<Estate>>>,

  /** Get estate by id */
  "ESTATES:get": {
    path: "/estates/:estateId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Estate, null, PickParams<"estateId">>,

  /** Partial update an Estate */
  "ESTATES:update": {
    path: "/estates/:estateId",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<
    Estate,
    Partial<UpdateEstateInput>,
    PickParams<"estateId">
  >,

  /** delete an Estate */
  "ESTATES:delete": {
    path: "/estates/:estateId",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<
    StatusResponse,
    null,
    PickParams<"estateId">
  >
};

export default EstateRoutes;

export interface Estate {
  id: number;
  name: string;
  country: string;
  region: string;
  subRegion?: string;
  latitude: number;
  longitude: number;
}

export interface CreateEstateInput {
  name: string;
  country: string;
  region: string;
  subRegion?: string;
  latitude: number;
  longitude: number;
}

export type UpdateEstateInput = Partial<CreateEstateInput> & {id: number};