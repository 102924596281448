import { Form } from "grommet";
import React from "react";
import { useForm } from "react-hook-form";

// import useRouter from "use-react-router";
import { Button } from "src/components/shared/button";
import {
  FormRow, TextInput, FormError
} from "src/components/forms";
import useForgotPassword from "src/utils/authentication/hooks/useForgotPassword";
import { PublicLayout } from "src/components/shared/layout";

interface VerifyFormValues {
  username: string;
}

interface ResetPasswordFormValues {
  username: string;
  verificationCode: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const ForgotPassword: React.FunctionComponent = () => {
  const {
    forgotState, setNewPassword, requestPasswordChange
  } = useForgotPassword();

  const {
    handleSubmit: passwordResetHandleSubmit,
    register: passwordResetRegister,
    errors: passwordResetErrors,
    getValues: passwordResetGetValues,
    setValue: passwordResetSetValue,
    watch: passwordResetWatch
  } = useForm<ResetPasswordFormValues>();

  const handlePasswordReset = React.useCallback(() => {
    const values = passwordResetGetValues();

    setNewPassword(
      values.username, values.verificationCode, values.newPassword
    );
  }, [ passwordResetGetValues, setNewPassword ]);

  const {
    handleSubmit, register, errors
  } = useForm<VerifyFormValues>();

  return (
    <PublicLayout>

      {forgotState.action === "code" ?
        (
          <form onSubmit={passwordResetHandleSubmit(handlePasswordReset)}>
            <Form>
              {!!forgotState.error && <FormError message={forgotState.error} />}
              <FormRow required label="Email" error={passwordResetErrors.username?.message}>
                <TextInput disabled name="username" ref={passwordResetRegister({ required: "Email address is required" })} />
              </FormRow>

              <FormRow required label="Verification Code" error={passwordResetErrors.verificationCode?.message}>
                <TextInput name="verificationCode" ref={passwordResetRegister({ required: "Verification Code is required" })} />
              </FormRow>

              <FormRow required label="New Password" error={passwordResetErrors.newPassword?.message}>
                <TextInput
                  name="newPassword"
                  type="password"
                  ref={passwordResetRegister({
                    required: "Password is required",
                    pattern: {
                      value: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d+=^$*.[\]{}()?\-"!@#%&/,><':;|_~` ]{8,}$/),
                      message: "Password must be at least 8 characters, 1 upper case, 1 lowercase and 1 number"
                    }
                  })}
                />
              </FormRow>

              <FormRow required label="Confirm New Password" error={passwordResetErrors.newPasswordConfirm?.message}>
                <TextInput
                  name="newPasswordConfirm"
                  type="password"
                  ref={passwordResetRegister({
                    required: "Confirm New Password is required",
                    validate: value => value === passwordResetWatch("newPassword") ? true : "Passwords must match!"
                  })}
                />
              </FormRow>
              <Button
                onClick={() => {
                  const values = passwordResetGetValues();

                  requestPasswordChange(values.username);
                }}
                label="Resend code"
              />
              <Button type="submit" label="Reset password" />
            </Form>
          </form>
        ) : (
          <form
            onSubmit={handleSubmit(values => {
              passwordResetSetValue("username", values.username);
              requestPasswordChange(values.username);
            })}
          >
            <Form>
              {!!forgotState.error && <FormError message={forgotState.error} />}
              <FormRow required label="Email" error={errors.username?.message}>
                <TextInput name="username" ref={register({ required: "Email address is required" })} />
              </FormRow>
              <Button type="submit" label="Send Verification Code" />
            </Form>
          </form>
        )}
    </PublicLayout>
  );
};

export default ForgotPassword;
