import React from "react";
import { useForm } from "react-hook-form";
import { Box } from "grommet";

import { Button } from "src/components/shared/button";
import {
  FormError, FormRow, TextInput
} from "src/components/forms";
import { CreateVintageInput, Vintage } from "src/utils/api/routes/vintages.api";
import { Text } from "src/components/shared/text";

interface VintageFormProps {
  isLoading: boolean;
  defaultValues?: Partial<CreateVintageInput>;
  onSubmit: (data: CreateVintageInput) => void;
  lwin7?: string;
}

export type VintageLwinLookupValues = Pick<Vintage, "name" | "year">;

/**
 * Vintage form
 */
export const VintageForm: React.FC<VintageFormProps> = props => {
  const {
    register, handleSubmit, errors, watch
  } = useForm<CreateVintageInput>({ defaultValues: { ...props.defaultValues } });

  const onSubmit = (vintage: CreateVintageInput) => {
    props.onSubmit(vintage);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text>
        Enter a year to add the vintage to this wine.
      </Text>
      
      <FormRow label="Year">
        <TextInput
          type="number"
          name="year"
          ref={register({
            required: {
              value: true,
              message: "This field is required"
            },
            minLength: {
              value: 4,
              message: "The year must be exactly 4 digits long."
            },
            maxLength: {
              value: 4,
              message: "The year must be exactly 4 digits long."
            }
          })}
        />
        <FormError message={errors.year?.message} />
      </FormRow>
      {watch("year") && (
        <Box pad={{ vertical: "small" }} direction="row"> 
          <Text bold>
            LWIN11:&nbsp;
          </Text>
          <Text>
            {props.lwin7}
            {watch("year")}
          </Text>
        </Box>
      )}
      <Button
        type="submit"
        label="Submit"
        disabled={props.isLoading}
      />
    </form>
  );
};
