import { Box } from "grommet";
import React from "react";
import { toast } from "react-toastify";
import {
  find, omit, reduce
} from "lodash";

import {
  AssignedLabelsTable, LabelAssignmentForm, LabelContainer
} from "src/components/label";
import { PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { PageProps } from "src/pages/Router";
import { Label } from "src/utils/api/routes/labels.api";
import useApiRequest from "src/utils/api/useApiRequest";
import { AssignedCaseLabel } from "src/components/label/LabelAssignmentForm";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { SensorGroupAssignmentForm, SensorGroupAssignmentFormValues } from "src/components/label/SensorGroupAssignmentForm";

interface LabelAssignmentPageProps {
  scannerId: string;
}

/**
 * Get a list of labels from an array of assigned labels.
 */
const getAssignedLabelIds = (assignnedCaseLabels: AssignedCaseLabel[]): number[] => {
  const reduceLabelIds = (labelIds: number[], assignnedCaseLabel: AssignedCaseLabel): number[] => [
    ...labelIds,
    assignnedCaseLabel.labelId,
    ...assignnedCaseLabel.bottles.map(bottle => bottle.labelId)
  ];

  return reduce(
    assignnedCaseLabels, reduceLabelIds, []
  );
};

/**
 * Label Assignment Page
 *
 * This page will poll for unassigned labels for the given scannerID, and
 * allow the user to bulk assign labels, creating the appropriate cases
 * and bottles in the system.
 */
export const LabelAssignmentPage: React.FC<PageProps<Record<string, unknown>, LabelAssignmentPageProps>> = props => {
  // Setup local storage hook to save assignedCases
  const [ initialAssignedCases, setInitialAssignedCases ] = useLocalStorage<AssignedCaseLabel[]>("labelAssignment:", []);
  // Setup API calls
  const [ labelRes, labelReq ] = useApiRequest("LABELS:list-scanner-unassigned");
  const [ removeAllLabelsRes, removeAllLabelsReq ] = useApiRequest("LABELS:remove-scanner-unassigned");
  const [ labelRemoveRes, labelRemoveReq ] = useApiRequest("LABELS:remove-by-id");
  const [ createBulkCaseRes, createBulkCaseReq ] = useApiRequest("CASES:bulk-create");
  // Setup variables to store API responses
  const [ labels, setLabels ] = React.useState<Label[]>([]);
  const [ labelsTotal, setLabelsTotal ] = React.useState<number>(0);
  const [ lastResponse, setLastResponse ] = React.useState<Date>();
  const [ scanError, setScanError ] = React.useState<string>();
  // Setup layout state and variables to store form submissions
  const [ assignedCases, setAssignedCases ] = React.useState<AssignedCaseLabel[]>(initialAssignedCases);
  const [ assignmentActive, setAssignmentActive ] = React.useState<boolean>(false);
  // Keep track of the labels that already assigned
  const [ alreadyAssignedLabelIds, setAlreadyAssignedLabelIds ] = React.useState<number[]>(getAssignedLabelIds(initialAssignedCases));

  // Make initial request to get labels by scannerID
  React.useEffect(() => {
    if (props.match?.params?.scannerId) {
      labelReq({ pathParams: { scannerId: props.match.params.scannerId } });
    }
  }, [ props, labelReq ]);

  // Detect reponse for unassigned labels, and store reponse to state
  React.useEffect(() => {
    if (labelRes.data?.items) {
      let labelItems = labelRes.data.items;
      const usedLabelIds = alreadyAssignedLabelIds;

      // Filter labels that are already assigned.
      labelItems = labelItems.filter(label => !find(usedLabelIds, alreadyAssignedLabelId => alreadyAssignedLabelId === label.id));
      const caseLabels = labelItems.filter(l => l.isCase).length;
      const labelErrors = labelItems.some(l => l.status !== "valid");

      setScanError(undefined);

      // If we detect more than one case label at a time
      if (caseLabels > 1) {
        setScanError("Too many case labels");
      }

      // If any labels aren't valid
      if (labelErrors) {
        setScanError("All labels must be valid");
      }

      // If labels detected but no cases
      if (caseLabels === 0 && labelItems.length > 0) {
        setScanError("Add a case to begin assignment.");
      }

      setLastResponse(new Date());
      setLabels(labelItems);
      setLabelsTotal(labelItems.length);
    }

    if (labelRes.errorMessage) {
      toast.error(labelRes.errorMessage);
    }
  }, [
    alreadyAssignedLabelIds,
    labelRes,
    assignedCases
  ]);

  // Listen for a response to clear all labels request
  React.useEffect(() => {
    if (removeAllLabelsRes.data) {
      setLabels([]);
      setScanError(undefined);
    }

    if (removeAllLabelsRes.errorMessage) {
      toast.error(removeAllLabelsRes.errorMessage);
    }
  }, [ removeAllLabelsRes ]);

  // Remove a single label by id
  const removeLabelById = React.useCallback((labelId: string) => {
    labelRemoveReq({ pathParams: { labelId } });
    setLabels(labels.filter(l => l.id.toString() !== labelId));
  }, [ labelRemoveReq, labels ]);

  // Listen for a response to remove a label by id
  React.useEffect(() => {
    if (labelRemoveRes.errorMessage) {
      toast.error(labelRemoveRes.errorMessage);
    }
  }, [ labelRemoveRes ]);

  // Keep track of the case and bottle count
  const unassignedCaseLabelCount = React.useCallback((labels: Label[]) => labels.filter(l => l.isCase).length, []);
  const unassignedBottleLabelCount = React.useCallback((labels: Label[]) => labels.filter(l => !l.isCase).length, []);

  // Handle saving the assignment and clearing current labels
  const handleAssign = React.useCallback((parentCase: AssignedCaseLabel) => {
    if (props.match) {
      setAlreadyAssignedLabelIds([ ...alreadyAssignedLabelIds, ...getAssignedLabelIds([ parentCase ]) ]);
      // Set the assigned bottles and clear all unassigned labels from the scanner. Then close.
      setAssignedCases([ ...assignedCases, parentCase ]);
      setInitialAssignedCases([ ...assignedCases, parentCase ]);
      setAssignmentActive(false);
      setLabels([]);
    }
  }, [
    alreadyAssignedLabelIds,
    setInitialAssignedCases,
    assignedCases,
    props.match
  ]);

  // Listen to the createBulkCases response and handle success/error
  React.useEffect(() => {
    if (createBulkCaseRes.data) {
      setLabels([]);
      setAssignedCases([]);
      setInitialAssignedCases([]);
      setLabelsTotal(0);
      setScanError(undefined);
      toast.success(createBulkCaseRes.data.message);
    }

    if (createBulkCaseRes.errorMessage) {
      toast.error(createBulkCaseRes.errorMessage);
    }
  }, [ createBulkCaseRes, setInitialAssignedCases ]);

  // Handle finish and perform the request to create cases and bottles!
  const handleFinish = React.useCallback(({
    orderReference, sensorGroupId, sensorFromDate, sensorFromTime
  }: SensorGroupAssignmentFormValues) => {
    createBulkCaseReq({
      data: {
        cases: assignedCases.map((c => {
          return {
            name: c.name,
            lwin: c.lwin,
            bottleSize: c.bottleSize,
            packSize: c.packSize,
            labelId: c.labelId,
            bottles: c.bottles.map(bottle => omit(bottle, "verifiedCode", "certificationReference")),
            sensorGroupId,
            sensorFromDateTime: `${sensorFromDate}T${sensorFromTime}:00`,
            orderReference
          };
        }))
      }
    });
  }, [ createBulkCaseReq, assignedCases ]);

  // Handle clearing of assigned cases so far
  const handleDeleteAssignedSoFar = React.useCallback(() => {
    setAlreadyAssignedLabelIds([]);
    setAssignedCases([]);
    setInitialAssignedCases([]);
  }, [ setAssignedCases, setInitialAssignedCases ]);
  
  // Handle clearing of assigned cases so far
  const handleDeleteAssignedCase = React.useCallback((labelId: number) => {
    // Remove the case from labels:
    const labelsToRemove = [ labelId ];

    // Find any nested bottle labels to remove, too:
    assignedCases.find(l => l.labelId)?.bottles.map(b => {
      labelsToRemove.push(b.labelId);
    });

    const newlyAssignedCases = assignedCases.filter(c => c.labelId !== labelId);

    // Remove from assigned cases:
    setAssignedCases(newlyAssignedCases);
    setInitialAssignedCases(newlyAssignedCases);
    // Remove from alreadyAssignedLabelIds:
    setAlreadyAssignedLabelIds(alreadyAssignedLabelIds.filter(id => !labelsToRemove.includes(id)));
  }, [
    alreadyAssignedLabelIds,
    assignedCases,
    setInitialAssignedCases 
  ]);

  // Handle cancel the current assigment.
  const handleCancelCurrentAssignmnent = React.useCallback(() => {
    setAssignmentActive(false);
  }, []);

  return (
    <AdminLayout>
      <PageHeader title="Label Assignment" backLinkText="Back to all scanners" backLink={() => props.history.push("/admin/labels")} />
      <Box direction="row" justify="between">
        <Box width="35%">
          <LabelContainer
            lastResponse={lastResponse}
            labels={labels}
            fetchLabels={() => props.match && labelReq({ pathParams: { scannerId: props.match.params.scannerId } })}
            labelsTotal={labelsTotal}
            unassignedCaseLabelCount={unassignedCaseLabelCount(labels)}
            unassignedBottleLabelCount={unassignedBottleLabelCount(labels)}
            assignmentActive={assignmentActive}
            setAssignmentActive={setAssignmentActive}
            scanError={scanError}
            removeAllLabels={() => props.match && removeAllLabelsReq({ pathParams: { scannerId: props.match.params.scannerId } })}
            removeSingleLabel={labelId => removeLabelById(labelId)}
          />
        </Box>
        <Box width="64%">
          {assignmentActive ? (
            <LabelAssignmentForm
              labels={labels}
              handleCancel={handleCancelCurrentAssignmnent}
              handleCreate={handleAssign}
            />
          ) : (
            <>
              <AssignedLabelsTable
                assignedCases={assignedCases}
                removeAssignedCase={handleDeleteAssignedCase}
                loading={false}
                deleteCases={handleDeleteAssignedSoFar}
              />
              {!!assignedCases.length &&
                <SensorGroupAssignmentForm onSubmit={handleFinish} />}
            </>
          )}
        </Box>
      </Box>
    </AdminLayout>
  );
};
