import React from "react";
import styled, { css } from "styled-components";

import { Text } from "../text";

interface TabProps {
  onClick: () => void;
  active: boolean;
  title: string;
}
export const TabTitle: React.FC<TabProps> = props => {
  return (
    <Tab onClick={props.onClick} className={props.active ? "active" : "inactive"}>
      <Text as="h4">
        {props.title}
      </Text>
    </Tab>
  );
};

const Tab = styled.header`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    padding: 2.5rem 0;
    margin: 0 2.5rem;
    border-bottom: 2px solid transparent;
    transition: all ease-in-out 0.2s;
    color: ${theme.colors.fadedDarker};
    &.active {
      color: ${theme.colors.darkest};
      border-bottom: 2px solid ${theme.colors.darkest};
    }

  `};



`;