import { useCombobox } from "downshift";
import { Box } from "grommet";
import { Search } from "grommet-icons";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { theme } from "src/utils/theme";
import { TextInput } from "../../forms/TextInput";
import { Card } from "../layout";
import { LoadingSpinner } from "../loading-spinner";
import { Text } from "../text";
import { useLocalStorage } from "src/hooks/useLocalStorage";

export interface SearchAheadItem<T = string | number, D = Record<string, unknown>> {
  label: string;
  value: T;
  data?: D;
}

interface SearchAheadProps<T = string | number, D = Record<string, unknown>> {
  name: string;
  items: Array<SearchAheadItem<T, D>>;
  label?: string;
  totalItems?: number;
  renderItem?: (item: SearchAheadItem<T, D>) => React.ReactChild;
  loading: boolean;
  placeholder?: string;
  onSelect: (selection?: SearchAheadItem<T, D> | null) => void;
  onSearch?: (search: string) => void;
  maxWidth?: string;
  saveState?: boolean;
}

interface StoredState<T = string | number, D = Record<string, unknown>> {
  items: Array<SearchAheadItem<T, D>>
}

export function SearchAhead<T = string | number, D = Record<string, unknown>>(props: SearchAheadProps<T, D>): ReactElement {
  // Use local storage to save recently searched items
  const [
    // State saved in local storage
    initialState,
    // Update local storage
    setInitialState,
    // Force re-fetch from local storage
    refresh 
  ] = useLocalStorage<StoredState<T, D>>(`searchAheadState:${name}`, { items: [] });

  // Setup useCombobox from downshift
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    openMenu
  } = useCombobox<SearchAheadItem<T, D>>({
    items: [ ...initialState.items, ...props.items ],
    itemToString: item => (item ? item.label : ""),
    onStateChange: ({
      inputValue, type, selectedItem
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          
          if (selectedItem?.label !== inputValue) {
            props.onSearch && props.onSearch(inputValue || "");
          }
          
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:

          if (props.saveState && selectedItem) {
            const savedItems = initialState.items;

            if (initialState.items.findIndex(i => i.label === selectedItem.label) === -1) {
              savedItems.unshift(selectedItem);
            }
            
            if (savedItems.length > 10) {
              savedItems.pop();
            }

            setInitialState({ items: savedItems });
          }

          props.onSelect(selectedItem);
          break;
        default:
          break;
      }
    }

  });

  return (
    <Box style={{ maxWidth: props.maxWidth || "350px" }}>
      {props.label && (
        <Box pad={{ vertical: "small" }}>
          <Text as="label" bold family="secondary" {...getLabelProps()}>
            {props.label}
          </Text>
        </Box>
      )}
      <Box
        style={{ position: "relative" }}
        width="100%"
        
        {...getComboboxProps({
          onClick: () => {
            refresh(); openMenu(); 
          },
          tabIndex: -1
        })}
      >
        <SearchIcon size="medium" {...getToggleButtonProps()} ref={null} />
        <TextInput style={{ paddingLeft: "5rem" }} placeholder={props.placeholder} {...getInputProps()} />
      </Box>
      <div style={{ position: "relative" }} {...getMenuProps()}>
    
        {isOpen ? (
          <ResultsCard>
            {!props.items.length && initialState?.items.length > 0 && (
              <Box>
                <Box
                  direction="row"
                  pad={{
                    top: "small",
                    horizontal: "small" 
                  }}
                  justify="between"
                  align="center"
                >
                  <Text bold small uppercase>
                    Recent
                  </Text>
                  <ClearBtn onClick={() => setInitialState({ items: [] })} bold small uppercase>
                    Clear
                  </ClearBtn>
                </Box>
                {initialState.items.map((item, index) => (
                  <Box
                    pad="small"
                    key={`recent-item-${index}`}
                    {...getItemProps({
                      key: `recent-item-${index}`,
                      index,
                      item,
                      style: {
                        color:
                      highlightedIndex === index ? "white" : theme.colors.darkest,
                        backgroundColor:
                      highlightedIndex === index ? theme.colors.dark : "white"
                      }
                    })}
                  >
                    {props.renderItem ? props.renderItem(item) : (
                      <Text as="p">
                        {item.label}
                      </Text>
                    )}
                  </Box>
                ))}
              </Box>
            )}
            <ResultsBody>
              {props.loading && <LoadingSpinner />}
              {!props.loading && props.items.length > 0 && (
                <Box
                  direction="row"
                  pad={{
                    top: "small",
                    horizontal: "small" 
                  }}
                  justify="between"
                  align="center"
                >
                  <Text bold small uppercase>
                    {`Results for "${inputValue}"`}
                  </Text>
                </Box>
              )}
              {!props.loading && props.items
                .map((item, i) => {
                  const index = (initialState?.items?.length || 0) + i;

                  return (
                    <Box
                      pad="small"
                      key={`search-item-${index}`}
                      {...getItemProps({
                        key: `search-item-${index}`,
                        index,
                        item,
                        style: {
                          color:
                          highlightedIndex === index ? "white" : theme.colors.darkest,
                          backgroundColor:
                          highlightedIndex === index ? theme.colors.dark : "white"
                        }
                      })}
                    >
                      {props.renderItem ? props.renderItem(item) : (
                        <Text as="p">
                          {item.label}
                        </Text>
                      )}
                    </Box>
                  );
                })}
            </ResultsBody>
            <ResultsFooter pad="small">
              {props.loading ? (
                <Text>
                  Loading...
                </Text>
              ) : (
                (props.items.length > 0) ? (
                  props.totalItems && props.items.length !== props.totalItems ? (
                    <Text>
                      {`Displaying ${props.items.length} of ${props.totalItems} results. Refine your search to reduce unwanted results.`}
                    </Text>
                  ) : (
                    <Text>
                      {`Displaying ${props.items.length} result(s).`}
                    </Text>
                  )
                ) : inputValue.length > 0 ? (
                  <Text>
                    No results found.
                  </Text>
                ) : (
                  <Text>
                    Start typing to search
                  </Text>
                )
              )}
            </ResultsFooter>
          </ResultsCard>
        ) : null}
      
      </div>

    </Box>
  );
}

const ResultsCard = styled(Card)`
  position: absolute;
  overflow: hidden;
  width: 600px;
  z-index: 10;
`;

const ClearBtn = styled(Text)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.fadedDark};
  }
`;

const ResultsBody = styled.div`
  max-height: 400px;
  overflow: scroll;
  display: block;
`;

const SearchIcon = styled(Search)`
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
`;

const ResultsFooter = styled(Box)`
  background: ${({ theme }) => theme.colors.fadedLight}
`;
