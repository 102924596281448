import { Box } from "grommet";
import React from "react";

import { CollectorLayout } from "src/components/shared/layout";
import { Card } from "src/components/shared/layout/Card";
import { CollectorPageTop } from "src/components/shared/layout/CollectorPageTop";
import { PageConfines } from "src/components/shared/layout/PageConfines";
import { Text } from "src/components/shared/text";
import { CashValueBox } from "src/components/user";
import { PageProps } from "src/pages/Router";
import { useAuthContext } from "src/utils/authentication";

import { CollectorTransactions } from "./user-transactions";

export const CashTransactions: React.FC<PageProps<Record<string, unknown>>> = () => {
  const { authState } = useAuthContext();
  const { user } = authState;

  return (
    <CollectorLayout>
      <CollectorPageTop>
        <Box direction="row" justify="between">
          <Box justify="end">
            <Box pad={{ vertical: "small" }}>
              <Text color="white" as="h1">
                Transactions
              </Text>
            </Box>
          </Box>
          <Box direction="row">
            <Card>
              <CashValueBox totalInvested={72000} readyToInvest={user?.cashBalance} />
            </Card>
          </Box>
        </Box>
      </CollectorPageTop>
      <PageConfines>
        <Card margin={{ top: "medium" }}>
          {user && (
            <CollectorTransactions userId={user.id} />
          )}
        </Card>
      </PageConfines>
      
    </CollectorLayout>
  );
};
