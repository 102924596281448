import React from "react";

import { User } from "src/utils/api/routes/users.api";
import { PriceGraph } from "src/components/shared/price-graph";
import { useApiRequest } from "src/utils/api";

interface PortfolioValueGraphProps {
  user: User;
}

interface HistoricalPrice {
  price: number;
  date: string;
}

export const PortfolioValueGraph: React.FC<PortfolioValueGraphProps> = ({ user }) => {
  const defaultStartDate = user.createdAt;
  const [ prices, setPrices ] = React.useState<HistoricalPrice[]>([]);
  const [ currentGraphStartDate, setStartGraphDate ] = React.useState<Date>(defaultStartDate);
  const [ historicalStartDate, setHistoricalStartDate ] = React.useState<Date>(defaultStartDate);
  const [ getPortfolioHistoricalResponse, getPortfolioHistoricalRequest ] = useApiRequest("PRICES:getPortfolioHistorical");
  const [ getPortfolioFirstValueResponse, getPortfolioFirstValueRequest ] = useApiRequest("PRICES:getPortfolioHistorical");

  // Get the first stored data data point.
  // This should be loaded when the component is mounted.
  React.useEffect(() => {
    getPortfolioFirstValueRequest({
      pathParams: { userId: user.id.toString() },
      params: {
        limit: 1,
        offset: 0,
        order: [ [ "valueDate", "ASC" ] ]
      }
    });
  }, [ getPortfolioFirstValueRequest, user.id ]);

  React.useEffect(() => {
    if (getPortfolioFirstValueResponse.data && getPortfolioFirstValueResponse.data.items.length) {
      const firstValue = getPortfolioFirstValueResponse.data.items[ 0 ];

      setHistoricalStartDate(new Date(firstValue.valueDate));
      setStartGraphDate(new Date(firstValue.valueDate));
    }
  }, [ getPortfolioFirstValueResponse ]);

  // Get the graph data points.
  React.useEffect(() => {
    getPortfolioHistoricalRequest({
      pathParams: { userId: user.id.toString() },
      params: { filters: { valueDate: { $gte: currentGraphStartDate } } }
    });
  }, [
    getPortfolioHistoricalRequest,
    user.id,
    currentGraphStartDate
  ]);

  React.useEffect(() => {
    if (getPortfolioHistoricalResponse.data) {
      const singlePortfolioValues = getPortfolioHistoricalResponse.data.items;

      setPrices(singlePortfolioValues.map(dataPoint => {
        return { 
          date: dataPoint.valueDate.toString(),
          price: dataPoint.value
        };
      }));
    }
  }, [ getPortfolioHistoricalResponse ]);

  return (
    <PriceGraph
      isLoading={getPortfolioFirstValueResponse.loading || getPortfolioHistoricalResponse.loading}
      onSelectedDateChange={date => setStartGraphDate(date)}
      pricePoints={prices}
      historicalStartDate={historicalStartDate}
    />
  );
};
