/* eslint-disable react-hooks/exhaustive-deps */
import { Auth } from "aws-amplify";
import * as React from "react";
import { History } from "history";

import { User } from "src/utils/api/routes/users.api";
import { useApiRequest } from "src/utils/api";
import { isPrivateRoute } from "src/utils/navigation/is-private-route";

interface AuthState {
  user: User | null;
  loggedIn: boolean;
  error: string | null;
  redirectTo: string | null;
  authenticating: boolean;
}

const initialContext: AuthState = {
  error: null,
  loggedIn: false,
  user: null,
  redirectTo: null,
  authenticating: true
};

interface AuthContextAttributes {
  authState: AuthState;
  setAuthState: (newState: Partial<AuthState>) => void;
  logout: () => void;
  checkLoggedIn: () => void;
  handleLoggedIn: (c: User) => void;
}

export const AuthContext = React.createContext<AuthContextAttributes>({
  authState: initialContext,
  setAuthState: () => null,
  logout: () => null,
  checkLoggedIn: () => null,
  handleLoggedIn: () => null
});

export const AuthProvider: React.FunctionComponent<{history: History}> = props => {
  const [ getMeResponse, getMeRequest ] = useApiRequest("USERS:getMe");
  // load initial state for auth
  const [ authState, setAuthState ] = React.useState(initialContext);

  const handleLoggedIn = React.useCallback((user: User | null) => {
    const isAdmin = !!user?.roles.find(role => role.name === "admin");
    const isCollector = !!user?.roles.find(role => role.name === "collector");
    
    setAuthState(currentState => {
      if (currentState.redirectTo) {
        props.history.push(currentState.redirectTo);
      } else if (isAdmin) {
        props.history.push("/admin/dashboard");
      } else if (isCollector) {
        props.history.push("/collector/wine-portfolio");
      }

      return ({
        ...currentState,
        authenticating: false,
        loggedIn: true,
        redirectTo: location.pathname,
        user: user || currentState.user
      });
    });
  },
  [ props.history ]);

  const logout = React.useCallback(async () => {
    await Auth.signOut();

    // If logging out, reset the authState
    setAuthState(currentState => ({
      ...currentState,
      loggedIn: false,
      authenticating: false,
      user: null,
      redirectTo: null
    }));
    props.history.push("/?redirected=true");
  }, [ props.history ]);

  /**
   * Checks the user is logged in
   **/
  const checkLoggedIn = React.useCallback(async () => {
    try {
      if (isPrivateRoute(location.pathname)) {
        const redirectTo = `${location.pathname}`;

        setAuthState(currentState => ({
          ...currentState,
          redirectTo
        })); 
      }
      const session = await Auth.currentSession();

      if (!authState.user || authState.user.id !== session.getIdToken().payload.userId) {
        getMeRequest({});
      } else {
        handleLoggedIn(null);
      }

      return;

      logout();
    } catch (error) {
      logout();
    }
    // dont want authstate.user in here or it recursively loops
  }, [
    getMeRequest,
    handleLoggedIn,
    logout
  ]);

  React.useEffect(() => {
    checkLoggedIn();
    // dont want authstate.user in here or it recursively loops
  }, []);

  const setAuthContext = React.useCallback((newState: Partial<AuthState>) => {
    setAuthState(currentState => ({
      ...currentState,
      ...newState
    }));
  },
  []);

  /**
   * handle the api response for the user
   */
  React.useEffect(() => {
    if (getMeResponse.errorMessage) {
      setAuthContext({
        loggedIn: false,
        error: getMeResponse.errorMessage
      });
      logout();
    } else if (getMeResponse.data) {
      handleLoggedIn(getMeResponse.data);
    }
  }, [
    getMeResponse,
    handleLoggedIn,
    logout,
    setAuthContext
  ]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState: setAuthContext,
        logout,
        handleLoggedIn,
        checkLoggedIn
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext<AuthContextAttributes>(AuthContext);
