import React from "react";
import styled from "styled-components";

/**
 * Info column
 *
 * Usage: InfoPanel > InfoColumn / InfoRow > InfoBox
 */
export const InfoColumn: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <InfoColumnStyled {...rest}>
      {children}
    </InfoColumnStyled>
  );
};

const InfoColumnStyled = styled.div`
  transition: padding ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 1.2rem;
  width: 100%;
  
  &:first-child {
    padding-left: 0;
  }

 
`;
