import React, { useState } from "react";
import { toast } from "react-toastify";
import { Box } from "grommet";
import styled, { css } from "styled-components";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import {
  CreateTransactionInput, TransactionMethodType, TransactionMethod 
} from "src/utils/api/routes/transactions.api";
import { TransactionForm } from "src/components/transactions/TransactionForm";
import { User } from "src/utils/api/routes/users.api";
import { Button } from "src/components/shared/button";
import { Text } from "src/components/shared/text";
import { useAuthContext } from "src/utils/authentication/AuthContext";
import { formatCurrency } from "src/utils/currency/formatCurrency";

interface TransactionsPageParams {
  userId: string; // client!
}

interface LocationState {
  client: User; // client
}

export const NewTransaction: React.FC<PageProps<Record<string, unknown>, TransactionsPageParams, LocationState>> = props => {
  const [ createTransactionRes, createTransactionReq ] = useApiRequest("TRANSACTIONS:createTransaction");
  const [ getClientRes, getClientReq ] = useApiRequest("USERS:get");
  const { authState: { user } } = useAuthContext();
  const [ client, setClient ] = useState<User | null>(null);
  const [ cashValue, setCashState ] = useState<{value: number; type?: TransactionMethodType}>({ value: 0 });

  React.useEffect(() => {
    if (props.match?.params.userId) {
      if (props.location?.state?.client) {
        if (props.location.state.client.id === parseInt(props.match.params.userId, 10)) {
          return setClient(props.location.state.client);
        }
      }

      getClientReq({ pathParams: { userId: props.match.params.userId } });
    }
  }, [
    getClientReq,
    props.location,
    props.match
  ]);

  React.useEffect(() => {
    if (getClientRes.data) {
      setClient(getClientRes.data);
    }

    if (getClientRes.errorMessage) {
      toast.error("Could not load the clients informations");
      props.history.goBack();
    }
  }, [
    getClientRes.data,
    getClientRes.errorMessage,
    props.history
  ]);

  const onSubmit = React.useCallback((transaction: CreateTransactionInput) => {
    if (client && user) {
      createTransactionReq({
        data: {
          ...transaction,
          facilitatorId: user.id
        },
        pathParams: { userId: client.id.toString() }
      });
    }
  }, [
    client,
    user,
    createTransactionReq
  ]);

  React.useEffect(() => {
    if (createTransactionRes.data) {
      toast.success("Transaction was created successfully!");

      if (props.match) {
        props.history.push(`/admin/user/${props.match?.params.userId}`);
      }
    }

    if (createTransactionRes.errorMessage) {
      toast.error(createTransactionRes.errorMessage);
    }
  }, [
    createTransactionRes,
    props.history,
    props.match
  ]);

  return (
    <AdminLayout>
      <PageHeader title={`New Transaction ${client?.name}`} backLink={() => props.history.goBack()} />
      <Box direction="row" justify="between">
        <Box basis="1/2">
          <Card pad="medium">

            <TransactionForm
              setCashState={setCashState}
              onSubmit={onSubmit}
            />

          </Card>
        </Box>

        <Box basis="1/3">
          <Card pad="medium">
            <Text as="h3">
              Transaction
            </Text>
            <Text as="h4">
              {client?.name}
            </Text>
            <Text as="p">
              <Text family="primary">
                {formatCurrency(client?.cashBalance)}
              </Text>
              <CashValue family="primary" type={cashValue.type}>
                {formatCurrency(cashValue?.value ? cashValue.value : 0.00)}
              </CashValue>
            </Text>

            <Button type="submit" form="transaction-form" label="Confirm" />
          </Card>
        </Box>
      </Box>

    </AdminLayout>
  );
};

const CashValue = styled(Text)<Pick<Partial<TransactionMethod>, "type">>`
  ${({ type, theme }) => css`
    margin-left: 2rem;
    color: ${type === "debit" ? theme.colors.error : type === "credit" ? theme.colors.success : ""};

    &::before {
      content: "${type === "credit" ? "+ " : ""}";
    }
  `}
`;