import React from "react";
import { useForm } from "react-hook-form";
import pick from "lodash/pick";
import { Box } from "grommet";
import styled, { css } from "styled-components";

import { Button } from "src/components/shared/button";
import { Text } from "src/components/shared/text";
import {
  FormError, FormRow, TextInput
} from "src/components/forms";
import { Estate, CreateEstateInput } from "src/utils/api/routes/estates.api";
import { Map } from "src/components/shared/map";

interface EstateFormProps {
  isLoading: boolean;
  defaultValues?: Partial<Estate>;
  onSubmit: (data: CreateEstateInput) => void;
}

export const EstateForm: React.FC<EstateFormProps> = props => {
  const {
    register, handleSubmit, errors, reset
  } = useForm<CreateEstateInput>({ defaultValues: { ...props.defaultValues } as CreateEstateInput });

  const [ latitude, setLatitude ] = React.useState<google.maps.LatLngLiteral["lat"]>();
  const [ longitude, setLongitude ] = React.useState<google.maps.LatLngLiteral["lng"]>();
  const [ markers, setMarkers ] = React.useState<google.maps.MarkerOptions[]>();

  const validationTemplates = {
    required: "This field is required.",
    minLength: {
      value: 2,
      message: "The value is to short."
    }
  };

  // On defaultValues change
  React.useEffect(() => {
    if (props.defaultValues && props.defaultValues.latitude && props.defaultValues.longitude) {
      setMarkers([
        {
          position: {
            lat: props.defaultValues.latitude,
            lng: props.defaultValues.longitude
          }
        }
      ]);
    }
  }, [ props.defaultValues ]);

  // On latitude / longitude coordinate change
  React.useEffect(() => {
    // If latitude and longitude is set, set the markers for the map
    if (latitude && longitude) {
      setMarkers([
        {
          position: {
            lat: latitude,
            lng: longitude
          }
        }
      ]);
    }
  }, [ latitude, longitude ]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <FormRow required label="Name">
        <TextInput
          name="name"
          ref={register({ ...pick(validationTemplates, [ "required", "minLength" ]) })}
          isInvalid={errors.name ? true : false}
        />
        {errors.name && <FormError message={errors.name.message} />}
      </FormRow>

      <FormRow required label="Country">
        <TextInput
          name="country"
          ref={register({ ...pick(validationTemplates, [ "required", "minLength" ]) })}
          isInvalid={errors.country ? true : false}
        />
        {errors.country && <FormError message={errors.country.message} />}
      </FormRow>

      <FormRow required label="Region">
        <TextInput
          name="region"
          ref={register({ ...pick(validationTemplates, [ "required", "minLength" ]) })}
          isInvalid={errors.region ? true : false}
        />
        {errors.region && <FormError message={errors.region.message} />}
      </FormRow>

      <FormRow label="Sub-region">
        <TextInput
          name="subRegion"
          ref={register({ ...pick(validationTemplates, [ "minLength" ]) })}
          isInvalid={errors.subRegion ? true : false}
        />
        {errors.subRegion && <FormError message={errors.subRegion.message} />}
      </FormRow>

      <FormRow required label="Map coordinates">
        <Text as="small">
          To set accurate coordinates, please follow the steps below:
        </Text>
        <StyledOrderedList>
          <li>
            On your computer, open
            {" "}
            <a href="https://www.google.com/maps" target="_blank" rel="noreferrer">
              Google Maps
            </a>
            . If you&apos;re using Maps in
            {" "}
            <a href="https://support.google.com/maps/answer/3031966" target="_blank" rel="noreferrer">
              Lite mode
            </a>
            , you’ll see a lightning bolt at the bottom and you won&apos;t be able to get the coordinates of a place.
          </li>
          <li>
            Right-click the place or area on the map.
          </li>
          <li>
            Select
            {" "}
            <strong>
              What&apos;s here?
            </strong>
          </li>
          <li>
            At the bottom, you’ll see a card with the coordinates.
          </li>
        </StyledOrderedList>

        <Box direction="row">
          <Box pad="small">
            <FormRow label="Latitude">
              <TextInput
                name="latitude"
                ref={register({ ...pick(validationTemplates, [ "required", "minLength" ]) })}
                onChange={event => setLatitude(parseFloat(event.target.value))}
                isInvalid={errors.latitude ? true : false}
              />
              {errors.latitude && <FormError message={errors.latitude.message} />}
            </FormRow>
          </Box>

          <Box pad="small">
            <FormRow required label="Longitude">
              <TextInput
                name="longitude"
                ref={register({ ...pick(validationTemplates, [ "required", "minLength" ]) })}
                onChange={event => setLongitude(parseFloat(event.target.value))}
                isInvalid={errors.longitude ? true : false}
              />
              {errors.longitude && <FormError message={errors.longitude.message} />}
            </FormRow>
          </Box>
        </Box>

        <StyledMap markers={markers} />
      </FormRow>

      <Box direction="row">
        <Button 
          disabled={props.isLoading || Object.keys(errors).length > 0} 
          label="Submit" 
          type="submit" 
        />
        <Box pad={{ left: "small" }}>
          <Button
            type="reset"
            secondary
            label="Reset"
            onClick={() => reset()}
          />
        </Box>
      </Box>

    </form>
  );
};

const StyledOrderedList = styled.ol`
  ${({ theme }) => css`
    padding-top: 1.4em;
    padding-left: 3.8rem;
    list-style: decimal;
    font-size: ${theme.font.sizes.bodySmall};
    line-height: 1.4em;

    strong {
      font-weight: ${theme.font.weights.medium};
    }

    li {
      padding-top: 0.4rem;

      &:first-child {
        padding-top: 0;
      }
    }
  `};
`;

const StyledMap = styled(Map)`
  position: relative;
  padding-top: 25rem;
  width: 100%;
`;