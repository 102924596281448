import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/components/shared/button";
import {
  FormRow, TextInput, Select
} from "src/components/forms";
import { BulkEditCaseInput } from "src/utils/api/routes/cases.api";
import { SensorGroupSelect } from "src/components/label/SensorGroupSelect";

interface CaseFormProps {
  onSubmit: (data: BulkEditCaseInput) => void;
  isLoading: boolean;
}

export const BulkEditCaseForm: React.FC<CaseFormProps> = props => {
  const {
    register, handleSubmit, watch, setValue
  } = useForm<BulkEditCaseInput>();

  const onSubmit = (caseInput: BulkEditCaseInput) => {
    props.onSubmit(caseInput);
  };

  React.useEffect(() => {
    register({ name: "status" });
    register({ name: "sensorGroupId" });
  }, [ register ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRow label="Status">
        <Select
          options={[
            "allocated",
            "in_storage",
            "in_transit",
            "sold",
            "delivered"
          ]}
          name="name"
          value={watch("status")}
          onChange={e => setValue(
            "status", e.option, true
          )}
        />
      </FormRow>

      <FormRow label="Location">
        <TextInput name="location" ref={register()} />
      </FormRow>

      <FormRow>
        <SensorGroupSelect
          setSensorGroupId={id => setValue("sensorGroupId", id)}
        />
      </FormRow>

      <Button disabled={props.isLoading} label="Submit" type="submit" />
    </form>
  );
};
