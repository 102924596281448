import React from "react";
import { Box } from "grommet";
import { toast } from "react-toastify";
import { Storage } from "aws-amplify";

import { Card } from "src/components/shared/layout";
import { Text } from "src/components/shared/text";
import { Button } from "src/components/shared/button";
import { TextInput } from "src/components/forms";
import { useApiRequest } from "src/utils/api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

export const InitialiseLabelsContainer: React.FC = () => {
  const [ initialiseLabelsRes, initialiseLabelsReq ] = useApiRequest("LABELS:initialise");
  const [ labelsQty, setLabelsQty ] = React.useState("100");
  const [ loading, setLoading ] = React.useState(false);

  const initialiseLabels = React.useCallback(() => {
    const quantity = parseInt(labelsQty.replace(/,/g, ""), 10);

    setLoading(true);
    initialiseLabelsReq({ data: { quantity } });
  }, [ labelsQty, initialiseLabelsReq ]);

  const onLabelsQtyChange = React.useCallback((value: string) => {
    const number = parseInt(value.replace(/,/g, ""), 10) || 0;

    if (number > 100000) {
      toast.warn("Batches cannot be initialised with over 100,000 labels at a time.");
    } else {
      setLabelsQty(`${number.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0 
      })}`);
    }
  }, [ setLabelsQty ]);

  React.useEffect(() => {
    if (initialiseLabelsRes.data) {
      setLoading(false);
      toast.success(initialiseLabelsRes.data.message);
    }

    if (initialiseLabelsRes.errorMessage) {
      setLoading(false);
      toast.error(initialiseLabelsRes.errorMessage);
    }
  }, [ initialiseLabelsRes ]);

  const downloadFile = React.useCallback(() => {
    if (initialiseLabelsRes.data?.s3Key) {
      Storage.get(initialiseLabelsRes.data.s3Key, {
        level: "admin",
        customPrefix: { public: "" } 
      }).then(result => {
        window.open(result as string, "blank");
      }).catch(error => {
        toast.error(error);
      });
    }
  }, [ initialiseLabelsRes ]);

  return (
    <Card direction="row" justify="between" align="center" pad="small">
      <Box pad="small">
        <Text as="h4" bold>
          Initialise labels
        </Text>
        <Text as="p">
          Generate a batch of initialised labels to export.
        </Text>
      </Box>
      {loading ? (
        <Box pad="small">
          <LoadingSpinner inline />
        </Box>
      ) : ( 
        <Box>
          {initialiseLabelsRes.data && initialiseLabelsRes.data.s3Key ? (
            <Box>
              <Box pad="small">
                <Button backgroundColor="success" label="Download" onClick={() => downloadFile()} />
              </Box>
            </Box>
          ) : (
            <Box pad="small" direction="column" justify="center" align="end">
              <Box pad={{ bottom: "xsmall" }}>
                <TextInput
                  value={labelsQty}
                  onChange={e => onLabelsQtyChange(e.target.value)}
                />
              </Box>
              <Box>
                <Button label="Generate" onClick={() => initialiseLabels()} />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Card>
  );
};
