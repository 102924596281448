/**
 * Format Currency as string
 * Given a number this function formats with a Euro sign, rounds to 2 fraction digits 
 * and makes the two fraction digits smaller (relative to significant digits).
 * Moves the negative sign before the currency symbol.
 * @param value [number]
 */
export const formatCurrencyString: (value?: number) => string = value => {
  // Separates thousands with a comma. Ensures 2 fraction digits (& rounds to nearest)
  if (!value) {
    return "€0";
  }

  let number = "€";

  if (Math.sign(value) < 0) {
    // Number is negative.
    number = "- €";
    value = -value;
  }

  number += `${value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0 
  })}`;

  return number;
};
