import { useCombobox } from "downshift";
import { Box } from "grommet";
import { FormDown } from "grommet-icons";
import React, { useState } from "react";
import styled from "styled-components";
import { input } from "aws-amplify";

import { theme } from "src/utils/theme";
import { Card } from "../shared/layout";
import { Text } from "../shared/text";

import { TextInput } from "./TextInput";

export interface SelectItem<T = string | number> {
  label: string;
  value: T;
}

interface DropdownComboboxProps<T = string | number> {
  items: Array<SelectItem<T>>;
  placeholder?: string;
  defaultItem?: SelectItem<T>;
  onSelect: (selection: SelectItem<T> | null) => void;
  maxWidth?: string;
  displayType?: "box" | "input"
}

export function SelectInput<T = string | number>({
  items, defaultItem, onSelect, placeholder = "Start typing or select...", maxWidth = "300px", displayType = "box"
}: DropdownComboboxProps<T>): React.ReactElement {
  const [ inputValue, setInputValue ] = useState<string>("");
  const [ inputItems, setInputItems ] = useState<Array<SelectItem<T>>>(items);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    closeMenu,
    reset,
    openMenu
  } = useCombobox<SelectItem<T> | null>({
    inputValue,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      inputValue ? setInputItems(
        items.filter(item => item.label.toLowerCase().startsWith(inputValue.toLowerCase())
        ),
      ) : setInputItems(items);
    },
    initialSelectedItem: defaultItem,
    onStateChange: ({
      inputValue, type, selectedItem
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || "");
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:

          if (selectedItem) {
            setInputValue("");
            setInputItems(items);
            onSelect(selectedItem);
          }

          break;
        default:
          break;
      }
    }
  });

  React.useEffect(() => {
    if (items.toString() !== inputItems.toString()) {
      reset();
      setInputItems(items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ items, reset ]);

  return (
    <div style={{ maxWidth }}>
      <Box
        pad={{ bottom: "xsmall" }}
        width="100%"
        {...getComboboxProps({
          onClick: () => openMenu(),
          tabIndex: -1,
          style: {
            position: "relative",
            opacity:
            items.length === 0 ? 0.6 : 1
          }
        })}
      >
        <TextInput
          {...getInputProps({
            placeholder,
            disabled: items.length === 0,
            value: inputValue || displayType === "input" && selectedItem?.label || "",
            onBlur: () => {
              if (inputItems.length === 0) {
                setInputItems(items);
                setInputValue("");
                closeMenu();
              }
            }
          })}
        />
        <DropdownIcon size="medium" {...getToggleButtonProps({ disabled: items.length === 0 })} aria-label="toggle menu" ref={null} />
      </Box>
      {displayType === "box" && (
        <Box direction="row">
          {selectedItem && (
            <SelectedItem
              direction="row"
              justify="center"
              align="center"
            >
              <Box
                pad={{
                  vertical: "xsmall",
                  horizontal: "small" 
                }}
                style={{ textTransform: "capitalize" }}
              >
                {selectedItem?.label}
              </Box>
          
            </SelectedItem>
          )}
        </Box>
      )}
      <div style={{ position: "relative" }} {...getMenuProps()}>
        {isOpen ? (
          <ResultsCard>
            <ResultsBody>
              {inputItems.map((item, index) => (
                <Box
                  pad="small"
                  key={`${item.label}${index}`}
                  {...getItemProps({
                    item,
                    index,
                    style: {
                      color:
                          highlightedIndex === index ? theme.colors.appBackground : theme.colors.darkest,
                      backgroundColor:
                          highlightedIndex === index ? theme.colors.dark : theme.colors.appBackground
                    }
                  })}
                >
                  <Text as="p" style={{ textTransform: "capitalize" }}>
                    {item.label}
                  </Text>
                </Box>
              ))}
            </ResultsBody>
          </ResultsCard>
        ) : null}        
      </div>
    </div>
  );
}

const ResultsBody = styled.div`
  max-height: 300px;
  overflow: scroll;
  display: block;
`;

const ResultsCard = styled(Card)`
  position: absolute;
  overflow: hidden;
  width: 400px;
  z-index: 10;
`;

const DropdownIcon = styled(FormDown)`
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
`;

const SelectedItem = styled(Box)`
  background: ${({ theme }) => theme.colors.fadedLighter};
  border-radius: 3px;
  margin: 2px;
`;
