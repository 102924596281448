import React from "react";

import { Case } from "src/utils/api/routes/cases.api";
import { PriceGraph } from "src/components/shared/price-graph";
import { useApiRequest } from "src/utils/api";

interface CasePriceGraphProps {
  singleCase: Case;
}

interface HistoricalPrice {
  price: number;
  date: string;
}

export const CaseValueGraph: React.FC<CasePriceGraphProps> = ({ singleCase }) => {
  const defaultStartDate = singleCase.createdAt;
  const [ prices, setPrices ] = React.useState<HistoricalPrice[]>([]);
  const [ currentGraphStartDate, setStartGraphDate ] = React.useState<Date>(defaultStartDate);
  const [ historicalStartDate, setHistoricalStartDate ] = React.useState<Date>(defaultStartDate);
  const [ getCaseHistoricalResponse, getCaseHistoricalRequest ] = useApiRequest("PRICES:getCaseHistorical");
  const [ getCaseFirstValueResponse, getCaseFirstValueRequest ] = useApiRequest("PRICES:getCaseHistorical");

  // Get the first stored data data point.
  // This should be loaded when the component is mounted.
  React.useEffect(() => {
    getCaseFirstValueRequest({
      pathParams: { caseId: singleCase.id.toString() },
      params: {
        limit: 1,
        offset: 0,
        order: [ [ "valueDate", "ASC" ] ]
      }
    });
  }, [ getCaseFirstValueRequest, singleCase.id ]);

  React.useEffect(() => {
    if (getCaseFirstValueResponse.data && getCaseFirstValueResponse.data.items.length) {
      const firstValue = getCaseFirstValueResponse.data.items[ 0 ];

      setHistoricalStartDate(new Date(firstValue.valueDate));
      setStartGraphDate(new Date(firstValue.valueDate));
    }
  }, [ getCaseFirstValueResponse ]);

  // Get the graph data points.
  React.useEffect(() => {
    getCaseHistoricalRequest({
      pathParams: { caseId: singleCase.id.toString() },
      params: { filters: { valueDate: { $gte: currentGraphStartDate } } }
    });
  }, [
    getCaseHistoricalRequest,
    singleCase.id,
    currentGraphStartDate
  ]);

  React.useEffect(() => {
    if (getCaseHistoricalResponse.data) {
      const singleCasePrices = getCaseHistoricalResponse.data.items;

      setPrices(singleCasePrices.map(dataPoint => {
        return { 
          date: dataPoint.valueDate.toString(),
          price: dataPoint.value
        };
      }));
    }
  }, [ getCaseHistoricalResponse ]);

  return (
    <PriceGraph
      isLoading={getCaseHistoricalResponse.loading || getCaseFirstValueResponse.loading}
      onSelectedDateChange={date => setStartGraphDate(date)}
      pricePoints={prices}
      historicalStartDate={historicalStartDate}
    />
  );
};
