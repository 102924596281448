import React from "react";
import { Box } from "grommet";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { Card } from "src/components/shared/layout";
import { Text } from "src/components/shared/text";
import { Button } from "src/components/shared/button";
import { TextInput } from "src/components/forms";
import { useApiRequest } from "src/utils/api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import {
  InfoPanel, InfoColumn, InfoBox 
} from "src/components/shared/info";

export const CheckLabelCodeContainer: React.FC = () => {
  const [ getLabelByCertificationRes, getLabelByCertificationReq ] = useApiRequest("LABELS:check");
  const [ certificationReference, setCertificationReference ] = React.useState<string>("");
  const [ loading, setLoading ] = React.useState(false);

  React.useEffect(() => {
    if (getLabelByCertificationRes.data) {
      setLoading(false);
      toast.success(getLabelByCertificationRes.data);
    }

    if (getLabelByCertificationRes.errorMessage) {
      setLoading(false);
      toast.error(getLabelByCertificationRes.errorMessage);
    }
  }, [ getLabelByCertificationRes ]);

  const handleCheck = React.useCallback(() => {
    const code = certificationReference.replace(/[^\w-]/gi, "");

    if (code) {
      getLabelByCertificationReq({ pathParams: { certificationReference: code } });
    } else {
      toast.warn("Your code must only contain letters, numbers or the hyphen (-) symbol.");
    }
  }, [ certificationReference, getLabelByCertificationReq ]);

  return (
    <Card pad="small">
      <Box direction="row" justify="between" align="center">
        <Box pad="small">
          <Text as="h4" bold>
            Check code
          </Text>
          <Text as="p">
            Enter a 9 digit label code to check its status.
          </Text>
        </Box>
        {loading ? (
          <Box pad="small">
            <LoadingSpinner inline />
          </Box>
        ) : ( 
          <Box>
            <Box pad="small" direction="column" justify="center" align="end">
              <Box pad={{ bottom: "xsmall" }}>
                <TextInput
                  value={certificationReference}
                  type="text"
                  onBlur={e => setCertificationReference(e.target.value)} 
                  onChange={e => setCertificationReference(e.target.value)}
                />
              </Box>
              <Box>
                <Button label="Check" onClick={handleCheck} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {getLabelByCertificationRes.data && (
        <Box pad="small">
          <InfoPanel>
            <InfoColumn>
              <InfoBox label="Certification Reference" value={getLabelByCertificationRes.data.certificationReference} />
              <InfoBox label="Status" value={getLabelByCertificationRes.data.status} />
              <InfoBox label="Batch Reference" value={getLabelByCertificationRes.data.batchRef} />
              <InfoBox label="NFC Code" value={getLabelByCertificationRes.data.nfcCode} />
              <InfoBox label="Verified Code" value={getLabelByCertificationRes.data.verifiedCode} />
              <InfoBox label="Scanner ID" value={getLabelByCertificationRes.data.scannerId} />
              <InfoBox label="Entity Type" value={getLabelByCertificationRes.data.entityType} />
              <InfoBox label="Entity ID" value={getLabelByCertificationRes.data.entityId} />
              {getLabelByCertificationRes.data.caseId && (
                <InfoBox
                  label={getLabelByCertificationRes.data.entityType === "bottle" ? "Contained in case" : "Case"}
                  value={(
                    <Link to={`/admin/case/${getLabelByCertificationRes.data.caseId}`}>
                      {`${getLabelByCertificationRes.data.caseName || "-"}`}
                    </Link>
                  )}
                />
              )}
            </InfoColumn>
          </InfoPanel>
        </Box>
      )}
    </Card>
  );
};
