import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "src/components/shared/button";
import {
  FormError, FormRow, TextInput, Select 
} from "src/components/forms";
import { CreateCaseInput } from "src/utils/api/routes/cases.api";
import { SearchAhead, SearchAheadItem } from "src/components/shared/search-ahead/SearchAhead";

interface CaseFormProps {
  onSubmit: (data: CreateCaseInput) => void;
  isLoading: boolean;
  defaultValues?: Partial<CreateCaseInput>;
  userSearchResults: SearchAheadItem[];
  userSearchTotal: number;
  userSearchLoading: boolean;
  searchForUserName: (search: string) => void;
}

export const CaseForm: React.FC<CaseFormProps> = props => {
  const {
    register, handleSubmit, errors, watch, setValue
  } = useForm<CreateCaseInput>({ defaultValues: { ...props.defaultValues } });

  const onSubmit = (caseInput: CreateCaseInput) => {
    props.onSubmit(caseInput);
  };

  React.useEffect(() => {
    register({ name: "status" });
    register({ name: "userId" });
  }, [ register ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <FormRow required label="Case Name">
        <TextInput name="name" ref={register({ required: true })} />
        {errors.name && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="LWIN18">
        <TextInput
          name="lwin"
          ref={register({
            required: {
              value: true,
              message: "This field is required"
            },
            minLength: {
              value: 18,
              message: "LWIN18 must be exactly 18 characters. "
            },
            maxLength: {
              value: 18,
              message: "LWIN18 must be exactly 18 characters. "
            }
          })}
        />
        <FormError message={errors.lwin?.message} />
      </FormRow>

      <FormRow required label="Pack Size">
        <TextInput name="packSize" ref={register({ required: true })} />
        {errors.packSize && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Bottle Size">
        <TextInput name="bottleSize" ref={register({ required: true })} />
        {errors.bottleSize && <FormError message="This field is required" />}
      </FormRow>
    
      <FormRow required label="Book Price (€)">
        <TextInput name="bookPrice" type="number" ref={register()} step="0.01" />

      </FormRow>

      <FormRow required label="Status">
        <Select
          options={[
            "allocated",
            "in_storage",
            "in_transit",
            "sold",
            "delivered"
          ]}
          name="name"
          value={watch("status")}
          onChange={e => setValue(
            "status", e.option, true
          )}
        />
        {errors.status && <FormError message="This field is required" />}
      </FormRow>
      
      <SearchAhead 
        name="caseForm"
        label="Search for a user" 
        loading={props.userSearchLoading} 
        items={props.userSearchResults} 
        totalItems={props.userSearchTotal}
        placeholder="Search name"
        onSelect={selected => selected && setValue(
          "userId", parseInt(selected.value.toString(), 10), true
        )} 
        onSearch={search => props.searchForUserName(search)} 
      />
      
      <FormRow required label="Location">
        <TextInput name="location" ref={register({ required: true })} />
        {errors.location && <FormError message="This field is required" />}
      </FormRow>
    
      <Button disabled={props.isLoading} label="Submit" type="submit" />
    </form>
  );
};
