import React from "react";
import formatDate from "date-fns/format";
import { Box } from "grommet";
import { toast } from "react-toastify";
import { sortBy } from "lodash";

import {
  Graph, AxesProps, GraphHeader
} from "src/components/shared/graph";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { PageHeader, Card } from "src/components/shared/layout";
import { PageProps } from "src/pages/Router";
import Text from "src/components/shared/text/Text";
import { SensorMeasure, Sensor } from "src/utils/api/routes/sensors.api";
import { useApiRequest } from "src/utils/api";

type SensorMeasureGraphData = Pick<SensorMeasure, "value"> & {
  date: number;
};

const getAxes = (sensor: Sensor, minMax: [number, number]): AxesProps<SensorMeasureGraphData> => {
  return {
    yAxis: {
      dataKey: "value",
      unit: sensor.sensorMeasureUnit ? sensor.sensorMeasureUnit.unit : "-",
      name: sensor.sensorMeasureUnit ? sensor.sensorMeasureUnit.name : "",
      interval: "preserveStartEnd",
      domain: minMax,
      allowDataOverflow: false,
      type: "number",
      tick: {
        fontSize: 14,
        width: 800
      }
    },
    xAxis: {
      dataKey: "date",
      domain: [ "dataMin", "dataMax" ],
      interval: "preserveStartEnd",
      name: "Time",
      tickFormatter: time => {
        if (isFinite(time)) {
          const date = new Date();

          date.setTime(time);

          return formatDate(date, "dd MMM HH:mm");
        }

        return "";
      },
      type: "number",
      tick: { fontSize: 14 }
    }
  };
};

interface SensorMeasurementsGraphProps {
  sensorId: string;
}

/**
 * Sensor graph table
 */
const SensorMeasurementsGraph: React.FC<PageProps<Record<string, unknown>, SensorMeasurementsGraphProps>> = props => {
  const [ measurements, setMeasurements ] = React.useState<SensorMeasure[]>([]);
  const [ sensor, setSensor ] = React.useState<Sensor>();
  const [ currentGraphStartDate, setStartGraphDate ] = React.useState<Date | null>(null);
  const [ getSensorMeasurementsRes, getSensorMeasurementsReq ] = useApiRequest("SENSORS:get-sensor-measurements");
  const [ getSensorRes, getSensorReq ] = useApiRequest("SENSORS:get-by-id");

  // Get the sensor
  React.useEffect(() => {
    if (props.match?.params.sensorId) {
      getSensorReq({ pathParams: { sensorId: props.match.params.sensorId } });
    }
  }, [ getSensorReq, props.match ]);

  // set the deafult start date
  React.useEffect(() => {
    if (getSensorRes.data) {
      setSensor(getSensorRes.data);

      const latest = getSensorRes.data.sensorMeasurements?.reduce((a, b) => (a.date > b.date ? a : b));

      if (latest) {
        setStartGraphDate(new Date(latest?.date));
      }
    }

    if (getSensorRes.errorMessage) {
      toast.error(getSensorRes.errorMessage);
    }
  }, [ getSensorRes.data, getSensorRes.errorMessage ]);

  // set the deafult start date
  React.useEffect(() => {
    if (currentGraphStartDate && props.match) {
      const startDate = new Date(currentGraphStartDate);

      // Get 24 hours up to the latest reading
      startDate.setDate(startDate.getDate() - 1);

      getSensorMeasurementsReq({
        pathParams: { sensorId: props.match.params.sensorId },
        params: { filters: { startDate } }
      });
    }
  }, [
    currentGraphStartDate,
    getSensorMeasurementsReq,
    props.match
  ]);

  React.useEffect(() => {
    if (getSensorMeasurementsRes.data) {
      setMeasurements(getSensorMeasurementsRes.data.items);
    }

    if (getSensorMeasurementsRes.errorMessage) {
      toast.error(getSensorMeasurementsRes.errorMessage);
    }
  }, [ getSensorMeasurementsRes.data, getSensorMeasurementsRes.errorMessage ]);

  const minMax = React.useMemo<[number, number]>(() => {
    const sortedByValue = sortBy(measurements, "value");

    if (!sortedByValue.length) {
      return [ 0, 0 ];
    }

    return [ Math.floor(sortedByValue[ 0 ].value - 1), Math.ceil(sortedByValue[ measurements.length - 1 ].value + 1) ];
  }, [ measurements ]);

  const graphHeading = React.useMemo(() => {
    const sortedByDates = sortBy(measurements, "date");

    if (sortedByDates[ sortedByDates.length - 1 ] && currentGraphStartDate) {
      return `Daily Readings: ${formatDate(new Date(currentGraphStartDate), "dd MMM HH:mm")} -> ${formatDate(new Date(sortedByDates[ sortedByDates.length - 1 ].date), "dd MMM HH:mm")}`;
    }

    return "";
  }, [ measurements, currentGraphStartDate ]);

  return (
    <AdminLayout>
      <PageHeader 
        title={`Sensor: ${sensor?.name || "loading..."}`}
        backLink={() => props.history.push("/admin/sensors")} 
        backLinkText="Back to all sensors"
      />
      <Card>
        {(getSensorMeasurementsRes.data && sensor) && (
          <Graph<SensorMeasureGraphData>
            isLoading={getSensorMeasurementsRes.loading}
            GraphHeader={<GraphHeader text={graphHeading} />}
            axes={getAxes(sensor, minMax)}
            height={200}
            data={measurements.map(measure => ({
              value: measure.value,
              date: new Date(measure.date).getTime()
            }))}
            toolTipLabelFormatter={v => formatDate(new Date(v), "dd MMM yyyy HH:mm")}
          />
        )}
      </Card>
    </AdminLayout>
  );
};

export const SensorMeasurementsGraphHeader: React.FC = () => {
  return (
    <Box pad={{ bottom: "medium" }}>
      <Text as="h4" family="primary">
        Market Value
      </Text>
    </Box>
  );
};
export default SensorMeasurementsGraph;
