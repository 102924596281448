import React from "react";
import { Column, Row } from "react-table";
import { toast } from "react-toastify";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Table } from "src/components/shared/table";
import { useTableFetch } from "src/hooks/useTableFetch";
import { PageProps } from "src/pages/Router";
import { Sensor } from "src/utils/api/routes/sensors.api";

type SensorTable = Pick<Sensor, "id" | "sensorGroup" | "externalReference" | "sensorMeasureUnit" | "status" | "name" >;

const columns: Array<Column<SensorTable>> = [
  {
    Header: "#",
    accessor: "id",
    width: 80,
    disableFilters: true
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Loaction",
    accessor: "sensorGroup",
    Cell: ({ row }) => row.original.sensorGroup ? `${row.original.sensorGroup.name} (${row.original.sensorGroup.externalReference} )` : "-",
    disableFilters: true
  },
  {
    Header: "Sensor Id",
    accessor: "externalReference"
  },
  {
    Header: "Measuring",
    accessor: "sensorMeasureUnit",
    Cell: ({ row }) => row.original.sensorMeasureUnit ? `${row.original.sensorMeasureUnit.name}` : "-",
    disableFilters: true
  }
];

/**
 * Estates table
 */
const SensorTable: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ data, setData ] = React.useState<SensorTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ sensorsRes, fetch ] = useTableFetch<"SENSORS:list", SensorTable>("SENSORS:list", { defaultSort: [ [ "name", "ASC" ] ] });

  // Table item click handler
  const handleOnClick = React.useCallback((row: Row<SensorTable>) => {
    props.history.push(`/admin/sensors/${row.original.id}`);
  }, [ props.history ]);

  // On sensorsRes
  React.useEffect(() => {
    if (sensorsRes.data && sensorsRes.data.items) {
      setData(sensorsRes.data.items);
      setPageCount(Math.ceil(sensorsRes.data.total / sensorsRes.data.limit));
      setLoading(false);
    }

    if (sensorsRes.errorMessage) {
      toast.error(sensorsRes.errorMessage);
    }
  }, [ sensorsRes ]);

  return (
    <AdminLayout>
      <PageHeader
        title="List of sensors" 
        backLinkText="Back to dashboard"
        backLink={() => props.history.push("/admin/dashboard")}
      />
      <Card>
        <Table<SensorTable>
          title={`${sensorsRes.data?.total || 0} sensors`}
          name="SensorTable"
          columns={columns}
          data={data}
          onClick={handleOnClick}
          loading={loading}
          fetchData={fetch}
          pageCount={pageCount}
          featuredFilter="name"
          useResizeColumns
          usePagination
          useFilters
          useHideColumns
        />
      </Card>
    </AdminLayout>
  );
};

export default SensorTable;