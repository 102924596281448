import { useCombobox, useMultipleSelection } from "downshift";
import { Box } from "grommet";
import { FormDown } from "grommet-icons";
import React, { useState } from "react";
import styled from "styled-components";

import { theme } from "src/utils/theme";
import { Card } from "../shared/layout";
import { Text } from "../shared/text";

import { TextInput } from "./TextInput";

export interface MultiSelectItem<T = string | number> {
  label: string;
  value: T;
}

interface DropdownMultipleComboboxProps<T = string | number> {
  items: Array<MultiSelectItem<T>>;
  placeholder?: string;
  defaultItems?: Array<MultiSelectItem<T>>;
  onSelect: (selection?: Array<MultiSelectItem<T>>) => void;
  maxWidth?: "300px"
}

export function MultiSelectInput<T = string | number>({
  items, defaultItems = [], onSelect, placeholder = "Start typing or select multiple...", maxWidth = "300px"
}: DropdownMultipleComboboxProps<T>): React.ReactElement {
  const [ inputValue, setInputValue ] = useState<string | undefined>("");
  const [ inputItems, setInputItems ] = useState<Array<MultiSelectItem<T>>>(items);

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems
  } = useMultipleSelection<MultiSelectItem<T>>({
    initialSelectedItems: defaultItems,
    onSelectedItemsChange: changes => onSelect(changes.selectedItems) 
  });
  
  const isDisabled = React.useMemo(() => items.length === selectedItems.length, [ items, selectedItems ]);

  React.useEffect(() => {
    setInputItems(items.filter(
      item =>
        selectedItems.findIndex(selItem => selItem?.label === item.label) === -1 &&
        (!inputValue ||
        item.label.toLowerCase().startsWith(inputValue.toLowerCase())),
    ));
  }, [
    inputValue,
    items,
    selectedItems,
    setInputItems 
  ]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem
  } = useCombobox<MultiSelectItem<T> | null>({
    inputValue,
    items: inputItems,
    onStateChange: ({
      inputValue, type, selectedItem
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:

          if (selectedItem) {
            setInputValue("");
            addSelectedItem(selectedItem);
            selectItem(null);
          }

          break;
        default:
          break;
      }
    }
  });

  return (
    <div style={{ maxWidth }}>
      <Box
        pad={{ bottom: "xsmall" }}
        style={{
          opacity: isDisabled ? 0.6 : 1,
          pointerEvents: isDisabled ? "none" : "all",
          position: "relative" 
        }}
        width="100%"
        {...getComboboxProps()}
      >
        <TextInput
          {...getInputProps(getDropdownProps({
            preventKeyAction: isOpen,
            disabled: isDisabled,
            placeholder
          }))}
        />
        <DropdownIcon size="medium" {...getToggleButtonProps()} aria-label="toggle menu" ref={null} />
      </Box>
      <Box direction="row">
        {selectedItems.map((selectedItem, index) => (
          <SelectedItem
            direction="row"
            justify="center"
            align="center"
            key={`selected-item-${index}`}
            {...getSelectedItemProps({
              selectedItem,
              index 
            })}
          >
            <Box
              pad={{
                vertical: "xsmall",
                horizontal: "small" 
              }}
              style={{ textTransform: "capitalize" }}
            >
              {selectedItem?.label}
            </Box>
            <Box
              pad={{
                vertical: "xsmall",
                horizontal: "small" 
              }}
              onClick={() => removeSelectedItem(selectedItem)}
            >
              &#10005;
            </Box>
          </SelectedItem>
        ))}
      </Box>
      <div style={{ position: "relative" }} {...getMenuProps()}>
        {isOpen ? (
          <Card>
            <ResultsBody>
              {inputItems.map((item, index) => (
                <Box
                  pad="small"
                  key={`${item.label}${index}`}
                  {...getItemProps({
                    item,
                    index,
                    style: {
                      color:
                          highlightedIndex === index ? theme.colors.appBackground : theme.colors.darkest,
                      backgroundColor:
                          highlightedIndex === index ? theme.colors.dark : theme.colors.appBackground
                    }
                  })}
                >
                  <Text as="p" style={{ textTransform: "capitalize" }}>
                    {item.label}
                  </Text>
                </Box>
              ))}
            </ResultsBody>
          </Card>
        ) : null}        
      </div>
    </div>
  );
}

const ResultsBody = styled.div`
  max-height: 400px;
  overflow: scroll;
  display: block;
`;

const SelectedItem = styled(Box)`
  background: ${({ theme }) => theme.colors.fadedLighter};
  border-radius: 3px;
  margin: 2px;
`;

const DropdownIcon = styled(FormDown)`
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
`;
