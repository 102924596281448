import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow 
} from "grommet";
import React from "react";
import { toast } from "react-toastify";

import { Label } from "src/utils/api/routes/labels.api";
import { formatUid } from "../../utils/formatLabelCode";
import { FormRow, TextInput } from "../forms";
import { Button } from "../shared/button";
import { Text } from "../shared/text";

import { AssignedBottleLabel, AssignedCaseLabel } from "./LabelAssignmentForm";
import { AssignedBottle, MixedCaseRow } from "./MixedCaseRow";

interface LabelAssignmentFormProps {
  // Labels to assign
  labels: Label[];
  // Pass back the assigned bottles
  handleCreate: (caseWithBottles: AssignedCaseLabel) => void;
  // Cancel the assignment
  handleCancel: () => void;
}

type BottleLabel = Label & AssignedBottle;

/**
 * Label Assignment Form
 * When a user is ready to assign unassigned labels to a case.
 *
 * MIXED CASE:
 * If it is a mixed case, then they will need to set:
 *  - a case name
 * They will add
 *  - a wine
 *  - a vintage
 *  - a format
 * For each bottle in the case.
 * 
 * Finally, once all bottles have been assigned, the whole case is passed
 * back using the handleCreate() prop.
 */
export const MixedCaseAssignment: React.FC<LabelAssignmentFormProps> = props => {
  // Setup variables to store form inputs
  const [ mixedCaseName, setMixedCaseName ] = React.useState<string>("");
  const [ caseBottles, setCaseBottles ] = React.useState<BottleLabel[]>(props.labels.filter(l => !l.isCase));

  // Function to update caseBottles state
  const updateBottles = React.useCallback((bottle: BottleLabel) => {
    const newCaseBottles = caseBottles.map(caseBottle => {
      if (bottle.id === caseBottle.id) {
        return bottle;
      }

      return caseBottle;
    });

    setCaseBottles(newCaseBottles);
  }, [ caseBottles ]); 

  // Handle creation of AssignedCaseLabel with bottles.   
  const handleAssignMixedCase = React.useCallback(() => {
    let fieldRequired: string | null = null;
    const caseLabel = props.labels.find(l => l.isCase);
    let valid = true;

    if (!mixedCaseName) {
      toast.error("Add a case name to continue.");
      valid = false;

      return;
    }

    for (const bottle of caseBottles) {
      // Validate the bottle
      if (!bottle.selectedWine) {
        fieldRequired = "a wine";
      } else if (!bottle.selectedVintage) {
        fieldRequired = "a vintage";
      } else if (!bottle.selectedFormat) {
        fieldRequired = "a format";
      } else if (!bottle.bottleName?.[ 1 ]) {
        fieldRequired = "a bottle name";
      } else if (!bottle.bottleLWIN?.[ 1 ]) {
        fieldRequired = "a bottle lwin";
      }
  
      if (fieldRequired) {
        toast.error(`Bottle "${formatUid(bottle.certificationReference)}" needs ${fieldRequired} to continue.`);
        valid = false;
        break;
      }
    }

    if (valid && caseLabel) {
      // Wine LWIN 
      // (First 7 numbers):
      const sameWine = caseBottles.every(bottle => bottle.selectedWine?.lwin === caseBottles[ 0 ].selectedWine?.lwin);
      const caseLwinName = sameWine && caseBottles[ 0 ].selectedWine?.lwin || "XXXXXXX";
      // Vintage LWIN 
      // (next 4 numbers e.g. 2020):
      const sameVintage = caseBottles.every(bottle => bottle.selectedVintage?.label === caseBottles[ 0 ].selectedVintage?.label);
      const caseLwinVintage = sameVintage && caseBottles[ 0 ].selectedVintage?.label || "XXXX";
      // Packsize 
      // (0 padded number e.g. 06)
      const packSize = caseBottles.length.toString().padStart(2, "0");   
      // Format 
      // (0 padded number in ml: e.g. 00750)
      const sameFormat = caseBottles.every(bottle => bottle.selectedFormat?.value === caseBottles[ 0 ].selectedFormat?.value);
      const caseFormat = caseBottles[ 0 ].selectedFormat?.value;
      const caseLwinFormat = sameFormat && caseFormat || "XXXXX";
      // Construct the Case LWIN:
      const caseLwin = `${caseLwinName}${caseLwinVintage}${packSize}${caseLwinFormat}`;
      // Setup bottle labels: 
      const bottleLabels: AssignedBottleLabel[] = [];
      
      caseBottles.map(label => {       
        bottleLabels.push({
          labelId: label.id,
          // Casting type because we've previously validated
          bottleSize: label.selectedFormat?.value as string,
          // Casting type because we've previously validated
          vintageId: label.selectedVintage?.value as number,
          // Casting type because we've previously validated
          name: label.bottleName?.[ 0 ] as string,
          // Casting type because we've previously validated
          lwin: label.bottleLWIN?.[ 0 ] as string,
          verifiedCode: label.verifiedCode,
          certificationReference: formatUid(label.certificationReference)
        });
      });
  
      // Setup the case label
      const assignedCaseLabel: AssignedCaseLabel = {
        labelId: caseLabel.id,
        bottleSize: caseFormat || "XXXXX",
        packSize,
        name: mixedCaseName,
        lwin: caseLwin,
        bottles: bottleLabels,
        certificationReference: formatUid((caseLabel as Label).certificationReference),
        verifiedCode: (caseLabel as Label).verifiedCode
      };
      
      // Pass case & bottle labels back for creation!
      props.handleCreate(assignedCaseLabel);
    }
  }, [
    caseBottles,
    mixedCaseName,
    props 
  ]);

  return (
    <>
      <Box pad={{ top: "medium" }}>
        <FormRow label="Case name">
          <TextInput
            placeholder="Case name"
            value={mixedCaseName}
            onChange={event => setMixedCaseName(event.target.value)}
          />
        </FormRow>
        <Box pad={{ top: "medium" }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" plain>
                  <Text uppercase bold small>
                    Code
                  </Text>
                </TableCell>
                <TableCell scope="col" plain>
                  <Text uppercase bold small>
                    Wine
                  </Text>
                </TableCell>
                <TableCell scope="col" plain>
                  <Text uppercase bold small>
                    Vintage
                  </Text>
                </TableCell>
                <TableCell scope="col" plain>
                  <Text uppercase bold small>
                    Format
                  </Text>
                </TableCell>
                <TableCell scope="col" plain align="center">
                  <Text uppercase bold small>
                    Valid
                  </Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {props.labels.filter(l => !l.isCase).map((label, i) => {
                return (
                  <TableRow key={i}>
                    <MixedCaseRow
                      label={label}
                      onChange={bottle => updateBottles({
                        ...bottle,
                        ...label 
                      })}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box
        direction="row"
        pad={{
          top: "large",
          bottom: "small" 
        }}
      >
        <Box>
          <Button
            primary
            label="Assign"
            onClick={handleAssignMixedCase}
          />
        </Box>
        <Box pad={{ horizontal: "xsmall" }}>
          <Button
            secondary
            backgroundColor="fadedDark"
            label="Cancel"
            onClick={props.handleCancel}
          />
        </Box>
      </Box>
    </>
  );
};
