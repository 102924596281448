import React, { useCallback, useRef } from "react";
import { Filters, SortingRule } from "react-table";
import { map } from "lodash";

import { PaginatedQueryRequest, useApiRequest } from "src/utils/api";
import {
  ConditionalRequestData, RoutesType, UseApiRequestType 
} from "src/utils/api/useApiRequest";

interface Options<T extends Record<string, unknown>> {
  defaultSort?: PaginatedQueryRequest<T>["order"];
  fetchRef?: React.MutableRefObject<FetchInfo<T>>;
  persistFilters?: Partial<T>;
}

export interface FetchInfo<T extends Record<string, unknown> = Record<string, unknown>> {
  id: number;
  pageSize: number;
  pageIndex: number;
  tableFilters?: Filters<T>;
  sortBy?: Array<SortingRule<T>>;
}

interface FetchParams<T extends Record<string, unknown>> {
  pageSize: number;
  pageIndex: number;
  tableFilters?: Filters<T>;
  sortBy?: Array<SortingRule<T>>
}

type AllowedRequest = keyof Pick<RoutesType, "CASES:list" | "USERS:list" | "WINES:list" | "ESTATES:list" | "SENSORS:list" | "USERS:cases" | "VINTAGES:listCases" | "STORAGE-FEE:list-storage-fee-calculations">;

/**
 * Use Table Fetch
 * 
 * Wraps useApiRequest to format filters, pagination etc in requests from the Table component
 * 
 * @param requestName The API endpoint
 * @param options Config for this hook
 * @param request Any additional headers for the request (pathParams etc)
 */
export const useTableFetch = <D extends AllowedRequest, T extends Record<string, unknown>>(
  requestName: D, 
  initialOptions?: Options<T>, 
  initialRequest?: ConditionalRequestData<RoutesType[D]>
): [UseApiRequestType<D>[0], (params: FetchParams<T>) => void] => {
  const fetchIdRef = useRef(0);
  const [ res, req ] = useApiRequest<D>(requestName);
  const [ options ] = React.useState(initialOptions);
  const [ request ] = React.useState(initialRequest);
    
  const fetch = useCallback(({
    pageSize, pageIndex, tableFilters, sortBy
  }: FetchParams<T>) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    
    if (fetchId === fetchIdRef.current) {
      // Initialise filters, we always include any persistent filters:
      const filters: Partial<T> = { ...options?.persistFilters };

      // Add filters to the request:
      map(tableFilters, filter => {
        const id = filter.id;
  
        filters[ id ] = filter.value;
      });
      
      // Setup order with default, if included:
      let order: PaginatedQueryRequest<T>["order"] = options?.defaultSort;

      // Override with sortBy from the table, if included:
      if (sortBy && sortBy[ 0 ]) {
        order = [ [ sortBy[ 0 ].id, sortBy[ 0 ].desc ? "DESC" : "ASC" ] ];
      }

      // Update and expose the filters, pagination and sort as a mutable ref:
      if (options?.fetchRef) {
        options.fetchRef.current = {
          id: fetchId,
          pageIndex,
          tableFilters,
          pageSize,
          sortBy
        };
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - useApiRequest typings
      req({
        ...request,
        params: {
          order,
          filters,
          offset: pageIndex * pageSize,
          limit: pageSize
        }
      });
    }
  }, [
    req,
    request,
    options
  ]);

  return [ res, fetch ];
};
