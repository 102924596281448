import React from "react";
import styled, { css } from "styled-components";

import { PublicLayout } from "src/components/shared/layout/PublicLayout";
import { Text } from "src/components/shared/text";

const NotAllowed: React.FunctionComponent = () => {
  return (
    <PublicLayout>
      <NotAllowedWrapper>
        <Text as="p" className="not-found-text">
          403 - Not allowed
        </Text>
        <Text as="a" className="go-back-link" href="/">
          Go back
        </Text>
      </NotAllowedWrapper>
    </PublicLayout>
  );
};

const NotAllowedWrapper = styled.div`
  ${({ theme }) => css`
    text-align: center;

    .not-found-text {
      margin-bottom: 2rem;
    }
    .go-back-link {
      color: ${theme.colors.dark};
      text-decoration: none;
      font-weight: ${theme.font.weights.medium};
    }
  `}
`;

export default NotAllowed;
