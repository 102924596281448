import React from "react";
import { toast } from "react-toastify";
import {
  Column, Filters, Row 
} from "react-table";
import { Storage } from "aws-amplify";
import { Box } from "grommet";
import formatDate from "date-fns/format";

import { Modal } from "src/components/shared/modal";
import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { PageProps } from "src/pages/Router";
import { Table } from "src/components/shared/table";
import { BulkActionLayer } from "src/components/shared/bulk-action-layer";
import {
  UserStorageFee, ProcessJobStatus, StorageFeeCalculation, UserStorageFeeBulkAction
} from "src/utils/api/routes/storage-fees.api";
import {
  User, UserDocument, UserStorageFeeCollectionBasis 
} from "src/utils/api/routes/users.api";
import { useApiRequest } from "src/utils/api";
import {
  InfoBox, InfoColumn, InfoPanel 
} from "src/components/shared/info";
import { Text } from "src/components/shared/text";
import { useInterval } from "src/hooks/useInterval";
import { Button } from "src/components/shared/button";
import { formatCurrency } from "src/utils/currency/formatCurrency";
import { FetchInfo } from "src/hooks/useTableFetch";

interface ViewStorageFeesParams {
  storageFeeCalculationId: string;
}
type UserStorageFeesTable = Pick<UserStorageFee, "id" | "status"> &
  Pick<User, "name" | "externalId"> & {storageFee: React.ReactElement, averagePortfolioValue: React.ReactElement, collectionBasis: UserStorageFeeCollectionBasis | string};

const columns: Array<Column<UserStorageFeesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true,
    width: 50
  },
  {
    Header: "Client Id",
    accessor: "externalId"
  },
  {
    Header: "Client",
    accessor: "name"
  },
  {
    Header: "Avg. Portfolio Value",
    accessor: "averagePortfolioValue"
  },
  {
    Header: "Storage fee",
    accessor: "storageFee"
  },
  {
    Header: "Collection basis",
    accessor: "collectionBasis"
  },
  {
    Header: "Status",
    accessor: "status"
  }
];

export const ViewStorageFeesCalculation: React.FC<PageProps<ViewStorageFeesParams, ViewStorageFeesParams>> = props => {
  const [ userStorageFeesData, setUserStorageFeesData ] = React.useState<UserStorageFeesTable[]>([]);
  const [ loadingUserStorageFeesData, setLoadingUserStorageFeesData ] = React.useState(false);
  const [ loadingBulkAction, setLoadingBulkAction ] = React.useState(false);
  const [ finishedStorageFeeCalculationIsLoaded, setFinishedStorageFeeCalculationIsLoaded ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ selected, setSelected ] = React.useState<UserStorageFeesTable[]>([]);
  const [ bulkActionModalOpen, setBulkActionModalOpen ] = React.useState(false);
  const [ getUserStorageFeeCalculationsRes, getUserStorageFeeCalculationsReq ] = useApiRequest("STORAGE-FEE:get-storage-fees-by-storage-fee-calculation");
  const [ getStorageFeeCalculationsRes, getStorageFeeCalculationsReq ] = useApiRequest("STORAGE-FEE:get-storage-fee-calculation");
  const [ bulkActionStorageFeesRes, bulkActionStorageFeesReq ] = useApiRequest("STORAGE-FEE:bulk-action-user-storage-fees");
  const fetchIdRef = React.useRef(0);

  const fetchInfo = React.useRef<FetchInfo<UserStorageFeesTable>>({
    id: 0,
    pageIndex: 0,
    pageSize: 10
  });

  const handleBulkAction = React.useCallback(() => {
    setLoadingBulkAction(true);

    bulkActionStorageFeesReq({
      data: {
        ids: selected.map(sel => sel.id),
        action: UserStorageFeeBulkAction.CREATE_TRANSACTIONS 
      } 
    });
  }, [ bulkActionStorageFeesReq, selected ]);

  React.useEffect(() => {
    if (props.match?.params?.storageFeeCalculationId) {
      // Get the storage fee calculation data.
      if (!finishedStorageFeeCalculationIsLoaded) {
        getStorageFeeCalculationsReq({ pathParams: { storageFeeCalculationId: props.match?.params?.storageFeeCalculationId } });
      } else {
        getUserStorageFeeCalculationsReq({
          params: {},
          pathParams: { storageFeeCalculationId: props.match.params.storageFeeCalculationId } 
        });
      }
    }
  }, [
    getStorageFeeCalculationsReq,
    getUserStorageFeeCalculationsReq,
    props.match,
    finishedStorageFeeCalculationIsLoaded
  ]);

  // Make subsequent requests until the calculation fees information
  // is loaded (the process has finished).
  useInterval(() => {
    if (props.match?.params?.storageFeeCalculationId && !finishedStorageFeeCalculationIsLoaded) {
      getStorageFeeCalculationsReq({ pathParams: { storageFeeCalculationId: props.match?.params?.storageFeeCalculationId } });
    }
  }, !finishedStorageFeeCalculationIsLoaded ? 3000 : null);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<UserStorageFeesTable>;
  }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      const filters: Partial<UserStorageFeesTable> = {};

      tableFilters?.map(filter => {
        const id = filter.id as keyof UserStorageFeesTable;

        filters[ id ] = filter.value;
      });
      // Set the loading state
      setLoadingUserStorageFeesData(true);

      if (props.match?.params?.storageFeeCalculationId) {
        getUserStorageFeeCalculationsReq({
          params: {
            offset: pageIndex * pageSize,
            limit: pageSize
          },
          pathParams: { storageFeeCalculationId: props.match.params.storageFeeCalculationId } 
        });
      }
    }
  }, [ getUserStorageFeeCalculationsReq, props.match ]);

  React.useEffect(() => {
    if (getUserStorageFeeCalculationsRes.data && getUserStorageFeeCalculationsRes.data.items) {
      const tableData = getUserStorageFeeCalculationsRes.data.items.map(userStorageFee => {
        return {
          id: userStorageFee.id,
          storageFee: formatCurrency(userStorageFee.storageFee),
          name: (userStorageFee.user as User) ? (userStorageFee.user as User).name : "",
          externalId: (userStorageFee.user as User) ? (userStorageFee.user as User).externalId : "",
          averagePortfolioValue: formatCurrency(userStorageFee.averagePortfolioValue),
          status: userStorageFee.status,
          collectionBasis: userStorageFee.calculationData?.collectionBasis || ""
        };
      });

      setUserStorageFeesData(tableData);
      setPageCount(Math.ceil(getUserStorageFeeCalculationsRes.data.total / getUserStorageFeeCalculationsRes.data.limit));
      setLoadingUserStorageFeesData(false);

      if (getUserStorageFeeCalculationsRes.errorMessage) {
        toast.error(getUserStorageFeeCalculationsRes.errorMessage);
      }
    }

    if (getUserStorageFeeCalculationsRes.errorMessage) {
      toast.error(getUserStorageFeeCalculationsRes.errorMessage);
    }
  }, [ getUserStorageFeeCalculationsRes ]);

  React.useEffect(() => {
    if (getStorageFeeCalculationsRes.data) {
      const { processJob } = getStorageFeeCalculationsRes.data;

      if (processJob && (processJob.status === ProcessJobStatus.ERROR || processJob.status === ProcessJobStatus.SUCCESS)) {
        setFinishedStorageFeeCalculationIsLoaded(true);
      } 
    }

    if (getStorageFeeCalculationsRes.errorMessage) {
      toast.error(getStorageFeeCalculationsRes.errorMessage);
    }
  }, [ getStorageFeeCalculationsRes ]);

  React.useEffect(() => {
    if (bulkActionStorageFeesRes.data) {
      const { 
        total, actioned, notActioned 
      } = bulkActionStorageFeesRes.data;

      toast.success(`The action was successfully performed. Total storage fees: ${total}. Generated transaction: ${actioned}. Not actioned: ${notActioned}.`);
      setBulkActionModalOpen(false);
      setLoadingBulkAction(false);

      // Get last fetch information to refresh the table:
      const {
        pageIndex, tableFilters, pageSize
      } = fetchInfo.current;

      fetchData({
        pageIndex,
        pageSize,
        tableFilters
      });
    }

    if (bulkActionStorageFeesRes.errorMessage) {
      toast.error(bulkActionStorageFeesRes.errorMessage);
      setLoadingBulkAction(false);
      setBulkActionModalOpen(false);
    }
  }, [ bulkActionStorageFeesRes, fetchData ]);

  const handleOnRowClick = React.useCallback((row: Row<UserStorageFeesTable>) => {
    props.history.push(`/admin/user-storage-fee/${row.original.id}`);
  }, [ props.history ]);

  const handleSelectRows = React.useCallback((selected: UserStorageFeesTable[]) => {
    if (selected.length > 0) {
      setSelected(selected);
    } else {
      setSelected([]);
    }
  }, []);

  return (
    <AdminLayout>
      <PageHeader 
        title="Storage Fee Calculator" 
        backLinkText="Back to storage fees"
        backLink={() => props.history.push("/admin/storage-fee-calculation")} 
      />
 
      <Box width="100%">
        {getStorageFeeCalculationsRes.data && (
          <Box pad={{ vertical: "medium" }}>
            <Text as="h3">
              Storage Fee Calculation Information
            </Text>
            <InfoPanel>
              <InfoColumn>
                <InfoBox label="Generated at" value={formatDate(new Date(getStorageFeeCalculationsRes.data.createdAt), "dd/MM/yyyy HH:mm:ss")} />
                <InfoBox label="Start Date" value={formatDate(new Date(getStorageFeeCalculationsRes.data.startDate), "dd/MM/yyyy")} />
                <InfoBox label="End Date" value={formatDate(new Date(getStorageFeeCalculationsRes.data.endDate), "dd/MM/yyyy")} />
                {getStorageFeeCalculationsRes.data.csvFile && (
                  <InfoBox
                    label="CSV file"
                    value={(
                      <div>
                        <Button
                          label="Download"
                          onClick={e => {
                            e.stopPropagation();

                            Storage.get(((getStorageFeeCalculationsRes.data as StorageFeeCalculation).csvFile as UserDocument).s3Key, {
                              level: "admin",
                              download: true,
                              customPrefix: { public: "" }
                            }).then(data => {
                              const blob = new Blob([ (data as Record<string, any>).Body ], { type: (data as Record<string, any>).ContentType });
                              const link = document.createElement("a");

                              link.href = window.URL.createObjectURL(blob);
                              link.download = ((getStorageFeeCalculationsRes.data as StorageFeeCalculation).csvFile as UserDocument).name;
                              link.click();
                            });
                          }}
                        />
                      </div>
                    )}
                  />
                )}
                <InfoBox label="Process job id" value={getStorageFeeCalculationsRes.data.processJob?.id} />
                <InfoBox label="Process job status" value={getStorageFeeCalculationsRes.data.processJob?.status} />
                <InfoBox label="Started at" value={getStorageFeeCalculationsRes.data.processJob?.startedAt ? (formatDate(new Date(new Date(getStorageFeeCalculationsRes.data.processJob?.startedAt)), "dd/MM/yyyy HH:mm:ss")) : "-"} />
                <InfoBox label="Finished at" value={getStorageFeeCalculationsRes.data.processJob?.finishedAt ? (formatDate(new Date(new Date(getStorageFeeCalculationsRes.data.processJob?.finishedAt)), "dd/MM/yyyy HH:mm:ss")) : "-"} />
                <InfoBox label="Status message" value={getStorageFeeCalculationsRes.data.processJob?.statusMessage} />
              </InfoColumn>
              {getStorageFeeCalculationsRes.data.processJob?.errorData?.calculationErrors && (
                <>
                  {getStorageFeeCalculationsRes.data.processJob?.errorData?.calculationErrors.map((calculationError: {userId: number;errorMessage: string}, i: number) => (
                    <InfoBox key={i} label="Calculation error" value={`User id: ${calculationError.userId} -  Error message: ${calculationError.errorMessage}`} />
                  ))}
                </>
              )}
              {getStorageFeeCalculationsRes.data.processJob?.errorData?.uncaughtErrors && (
                <>
                  {getStorageFeeCalculationsRes.data.processJob?.errorData?.uncaughtErrors.map((uncaughtError: Error, i: number) => (
                    <InfoBox key={i} label="Uncaught error" value={uncaughtError.toString()} />
                  ))}
                </>
              )}
              
            </InfoPanel>
          </Box>
        )}
      </Box>

      <Card>
        <Table<UserStorageFeesTable>
          name="UserStorageFeesTable"
          title="User storage fees"
          columns={columns}
          data={userStorageFeesData}
          onClick={handleOnRowClick}
          loading={loadingUserStorageFeesData || loadingBulkAction}
          fetchData={fetchData}
          pageCount={pageCount}
          useResizeColumns
          usePagination
          useHideColumns
          handleSelectRows={handleSelectRows}
          useBulkSelect
        />
      </Card>

      {selected.length > 0 && (
        <BulkActionLayer>
          <Text>
            {selected.length}
            {" "}
            selected
          </Text>
          <Box direction="row">
            <Button label="Generate Trasanctions" onClick={() => setBulkActionModalOpen(true)} />
          </Box>
        </BulkActionLayer>
      )}

      {bulkActionModalOpen && (
        <Modal
          isLoading={loadingBulkAction}
          onClose={() => setBulkActionModalOpen(false)}
          title="Generate transactions"
          description={`This will generate a transaction for the selected storage fee/s (${selected.length}). This action cannot be undone, are you sure?`}
          actions={{
            confirm: {
              label: "Commit transactions",
              onClick: () => {
                handleBulkAction();
                setBulkActionModalOpen(false);
              }
            },
            reject: {
              label: "Cancel",
              onClick: () => setBulkActionModalOpen(false)
            }
          }}
        />
      )}
    </AdminLayout>
  );
};
