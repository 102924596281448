import React from "react";
import styled from "styled-components";
import { Box } from "grommet";
import { isNil } from "lodash";

import { Text } from "../text";

export interface InfoBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  labelWidth?: string;
  value?: React.ReactChild | null;
}

/**
 * Info box
 *
 * Usage: InfoPanel > InfoColumn > InfoBox
 */
export const InfoBox: React.FC<InfoBoxProps> = props => {
  const {
    label, value, ...rest
  } = props;

  return (
    <InfoBoxStyled {...rest}>
      <Label title={label} as="small" family="secondary" bold>
        {label}
      </Label>
      <Label as="small" family="secondary">
        {!isNil(value) ? value : "-"}
      </Label>
    </InfoBoxStyled>
  );
};

const InfoBoxStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.fadedLight};
  
  &:first-child {
    border: none;
    padding-top: 0;
  }
`;

const Label = styled(Text)`
  width: 50%;  
  line-height: 1rem;
`;
