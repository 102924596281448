import React from "react";
import { Box } from "grommet";

import { theme } from "src/utils/theme/index";
import { Text } from "../shared/text";
import { Button } from "../shared/button";

interface LabelAssignmentHeaderProps {
  onAssign: () => void;
  onClear: () => void;
  canAssign: boolean;
  canClear: boolean;
  labelCount: number;
}

export const LabelAssignmentHeader: React.FC<LabelAssignmentHeaderProps> = props => {
  return (
    <Box background={theme.colors.fadedDarker} direction="row" justify="between" align="center" pad="small">
      <Text bold as="h4" color="darkest">
        {`${props.labelCount} labels`}
      </Text>
      <Box direction="row" align="center">
        <Box pad={{ right: "small" }}>
          {props.canClear && <Button plain label="Clear all" onClick={props.onClear} />}
        </Box>
        
        {props.canAssign && <Button label="Assign" onClick={props.onAssign} />}
      </Box>
    </Box>
  );
};