import React from "react";
import { useForm } from "react-hook-form";
import { Box } from "grommet";

import { Button } from "src/components/shared/button";
import {
  FormError, FormRow, Select, TextInput
} from "src/components/forms";
import { CreateWineInput, Wine } from "src/utils/api/routes/wines.api";
import { WineColour } from "src/utils/api/routes/wine-colours.api";
import { Grape } from "src/utils/api/routes/grapes.api";

import { WineGrapeForm } from "./WineGrapeForm";

interface WineFormProps {
  isLoading: boolean;
  defaultValues?: Partial<CreateWineInput>;
  onLwinLookup?: (lwin: Wine["lwin"]) => void;
  lwinLookupValues?: WineLwinLookupValues;
  wineColours: WineColour[];
  grapes: Grape[];
  onSubmit: (data: CreateWineInput) => void;
}

interface GrapeInput {
  grapeId: number;
  blendPercent: number;
}

export interface WineLwinLookupValues extends Pick<Wine, "name" | "country" | "wineColourId"> {
  region: Wine["region"] | undefined;
  subRegion?: Wine["subRegion"] | undefined;
}

/**
 * Wine form
 */
export const WineForm: React.FC<WineFormProps> = props => {
  const {
    register, handleSubmit, errors, setValue, watch, reset
  } = useForm<CreateWineInput>({ defaultValues: { ...props.defaultValues } as CreateWineInput });

  const watchLwinField = watch("lwin");

  const onSubmit = (wine: CreateWineInput) => {
    props.onSubmit(wine);
  };

  const changeDropDown = (value: number) => {
    setValue(
      "wineColourId", value, true
    );
  };

  const changeWineGrapes = (values: GrapeInput[]) => {
    setValue(
      "grapes", values, true
    );
  };

  // On mount
  React.useEffect(() => {
    register({ name: "wineColourId" });
    register({ name: "grapes" });
  }, [ register ]);

  // On lwinLookupValues update
  React.useEffect(() => {
    if (props.lwinLookupValues) {
      // Go through all of the values and set the appropriate registered
      // field's value
      for (const prop in props.lwinLookupValues) {
        const typedProp = prop as keyof WineLwinLookupValues;

        setValue(prop, props.lwinLookupValues[ typedProp ]);
      }
    }
  }, [ props.lwinLookupValues, setValue ]);

  const lookupLwin = React.useCallback(() => {
    if (props.onLwinLookup) {
      reset();
      props.onLwinLookup(watchLwinField);
    }
  }, [
    props,
    reset,
    watchLwinField 
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRow required label="LWIN7">
        <TextInput
          name="lwin"
          ref={register({
            required: {
              value: true,
              message: "This field is required"
            },
            minLength: {
              value: 7,
              message: "LWIN7 must be exactly 7 characters. "
            },
            maxLength: {
              value: 7,
              message: "LWIN7 must be exactly 7 characters. "
            }
          })}
        />
        <FormError message={errors.lwin?.message} />

        {props.onLwinLookup && (
          <Box pad={{ top: "1.2rem" }}>
            <Button
              type="button"
              label="Fetch details"
              onClick={() => lookupLwin()}
              disabled={props.isLoading || !watchLwinField || typeof watchLwinField === "string" && watchLwinField.length !== 7}
            />
          </Box>
        )}
      </FormRow>

      <FormRow required label="Name">
        <TextInput
          name="name"
          ref={register({ required: true })}
        />
        {errors.name && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Wine colour">
        <Select
          options={props.wineColours}
          labelKey="name"
          value={props.wineColours.find(wineColour => wineColour.id === watch("wineColourId"))}
          onChange={e => changeDropDown(e.option.id)}
        />
        {errors.wineColourId && <FormError message="This field is required" />}
      </FormRow>
      
      <WineGrapeForm grapeOptions={props.grapes} onChange={values => changeWineGrapes(values)} defaultValue={props.defaultValues?.grapes} />

      <FormRow required label="Country">
        <TextInput name="country" ref={register({ required: true })} />
        {errors.country && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Region">
        <TextInput name="region" ref={register({ required: true })} />
        {errors.region && <FormError message="This field is required" />}
      </FormRow>

      <FormRow label="Sub Region">
        <TextInput name="subRegion" ref={register} />
      </FormRow>

      <FormRow label="Village">
        <TextInput name="village" ref={register} />
      </FormRow>

      <FormRow required label="Display Location">
        <TextInput name="displayLocation" ref={register({ required: true })} />
        {errors.displayLocation && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Display line 1">
        <TextInput name="displayWineLine1" ref={register({ required: true })} />
        {errors.displayWineLine1 && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Display line 2">
        <TextInput name="displayWineLine2" ref={register({ required: true })} />
        {errors.displayWineLine2 && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Pre Drinking Years">
        <TextInput name="preDrinkingYears" type="number" ref={register({ required: true })} />
        {errors.displayWineLine2 && <FormError message="This field is required" />}
      </FormRow>
      <FormRow required label="Mature Drinking Years">
        <TextInput name="matureDrinkingYears" type="number" ref={register({ required: true })} />
        {errors.displayWineLine2 && <FormError message="This field is required" />}
      </FormRow>

      <Box direction="row">
        <Button
          type="submit"
          label="Submit"
          disabled={props.isLoading ||
          !watchLwinField ||
          typeof watchLwinField === "string" && watchLwinField.length !== 7}
        />
        <Box pad={{ left: "small" }}>
          <Button
            type="reset"
            secondary
            label="Reset"
            onClick={() => reset()}
          />
        </Box>
      </Box>
    </form>
  );
};
