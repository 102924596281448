import styled, { css } from "styled-components";
import { FormField } from "grommet";

export const FormRow = styled(FormField)<{required?: boolean}>`
  ${({ theme, required }) => css`
    width: 100%;

    label {
      margin-left: 0;
      font-weight: ${theme.font.weights.medium};
      font-size: ${theme.font.sizes.bodyRegular};
      ${required && css`
        &:after{
          content: " *";
          color: ${theme.colors.warning};
        }
      `}

    }

    > div {
      border: none;
    }
    
  `}
`;

FormRow.displayName = "FormRow";