import { ApiRouteDefinition } from "../useApiRequest";
import {
  PickParams, PaginatedQueryRequest, PaginatedResponse
} from "../api";

import { User } from "./users.api";

const TransactionRoutes = {

  /** list all transaction methods */
  "TRANSACTIONS:listMethods": {
    path: "/transactions/methods/all",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<TransactionMethod[], null, null, null>,

  /** create a transaction */
  "TRANSACTIONS:createTransaction": {
    path: "/transactions/user/:userId",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<Transaction, CreateTransactionInput, PickParams<"userId">, null>,

  /** list user transactions */
  "TRANSACTIONS:listByUser": {
    path: "/transactions/user/:userId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Transaction>, null, PickParams<"userId">, PaginatedQueryRequest<Partial<Pick<Transaction, "note" | "invoice">>>>
};

export default TransactionRoutes;

export interface Transaction {
  id: number;
  transactionMethodId: number;
  userId: number;
  facilitatorId: number;
  note: string;
  value: number;
  invoice: string;
  user?: User;
  facilitator?: User;
  transactionMethod?: TransactionMethod;
  createdAt: Date;
  updatedAt: Date;
}

export interface TransactionMethod {
  id: number;
  name: string;
  label: string;
  type: TransactionMethodType;
}

export enum TransactionMethodType {
  "credit" = "credit",
   "debit" = "debit"
}

export type CreateTransactionInput = Pick<Transaction, "facilitatorId" | "invoice" | "note" | "value" | "transactionMethodId">;