import React from "react";
import { toast } from "react-toastify";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { EstateForm } from "src/components/estate";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { CreateEstateInput, Estate } from "src/utils/api/routes/estates.api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

interface EditEstateFormParams {
  estateId: string;
}

/**
 * Edit estate form
 */
const EditEstateForm: React.FC<PageProps<Record<string, unknown>, EditEstateFormParams>> = props => {
  const [ loading, setLoading ] = React.useState(false);
  const [ estate, setEstate ] = React.useState<Estate>();
  const [ getEstateRes, getEstateReq ] = useApiRequest("ESTATES:get");
  const [ updateEstateRes, updateEstateReq ] = useApiRequest("ESTATES:update");
  const estateId = React.useMemo(() => props.match?.params.estateId || "", [ props.match ]);

  // On submit handler
  const onSubmit = React.useCallback((estate: CreateEstateInput) => {
    // types as Partial which updateEstateReq doesn't like - but we've
    // already validated wine to ensure it contains required fields
    const valuesToUpdate = pickBy(estate, identity) as CreateEstateInput;

    updateEstateReq({
      data: {
        ...valuesToUpdate,
        id: parseInt(estateId, 10)
      },
      pathParams: { estateId }
    });
  }, [ estateId, updateEstateReq ]);

  // On mount get the requests estate
  React.useEffect(() => {
    if (!estate && estateId) {
      getEstateReq({ pathParams: { estateId } });
      setLoading(true);
    }
  }, [
    estate,
    estateId,
    getEstateReq
  ]);

  // On getEstateRes
  React.useEffect(() => {
    if (getEstateRes.data) {
      setEstate(getEstateRes.data);
      setLoading(false);
    }

    if (getEstateRes.errorMessage) {
      toast.error(getEstateRes.errorMessage);
    }
  }, [ getEstateRes ]);

  // On updateEstateRes change
  React.useEffect(() => {
    if (updateEstateRes.data) {
      toast.success(`${updateEstateRes.data.name} was updated successfully. Click here to view.`, {
        autoClose: false,
        onClick: () => props.history.push(`/admin/estates/${updateEstateRes.data?.id}`)
      });
    }

    if (updateEstateRes.errorMessage) {
      toast.error(updateEstateRes.errorMessage);
    }
  }, [ updateEstateRes, props.history ]);

  return (
    <AdminLayout>
      <PageHeader title="Edit Estate" backLink={() => props.history.goBack()} />
      <Card pad="small" maxWidth="70rem">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <EstateForm isLoading={updateEstateRes.loading} defaultValues={estate} onSubmit={onSubmit} />
        )}
      </Card>

    </AdminLayout>
  );
};

export default EditEstateForm;