import { SearchAheadItem } from "../shared/search-ahead/SearchAhead";
import { Wine } from "../../utils/api/routes/wines.api";

export const formatCaseName = (
  mixedCase: boolean,
  mixedCaseName: string,
  selectedVintage?: SearchAheadItem,
  selectedWine?: Wine
): [string, boolean] => {
  let name = "";
  let valid = true;

  if (mixedCase) {
    name = mixedCaseName;
  } else {
    if (selectedWine?.name) {
      name += selectedWine.name;
    } else {
      valid = false;
    }
    
    if (selectedVintage?.label) {
      name += " ";
      name += selectedVintage.label;
    } else {
      valid = false;
    }
  }

  return [ name, valid ];
};

export const formatCaseLWIN = (
  mixedCase: boolean,
  unassignedBottleLabelCount: number,
  selectedFormat?: SearchAheadItem,
  selectedVintage?: SearchAheadItem,
  selectedWine?: Wine
): [string | null, boolean] => {
  let lwin: string | null = "";
  let valid = true;

  if (mixedCase) {
    lwin = null;
  } else {
    if (selectedWine?.lwin) {
      lwin += selectedWine.lwin;
    } else {
      valid = false;
    }
    
    if (selectedVintage?.label) {
      lwin += selectedVintage.label;
    } else {
      valid = false;
    }
    
    if (unassignedBottleLabelCount) {
      lwin += unassignedBottleLabelCount.toString().padStart(2, "0");
    } else {
      valid = false;
    }
      
    if (selectedFormat?.value) {
      lwin += selectedFormat.value;
    } else {
      valid = false;
    }
  }

  return [ lwin, valid ];
};

export const formatBottleName = (selectedVintage?: SearchAheadItem, selectedWine?: Wine): [string, boolean] => {
  let name = "";
  let valid = true;

  if (selectedWine?.name) {
    name += selectedWine.name;
  } else {
    valid = false;
  }

  if (selectedVintage?.label) {
    name += " ";
    name += selectedVintage.label;
  } else {
    valid = false;
  }

  return [ name, valid ];
};

export const formatBottleLWIN = (
  selectedFormat?: SearchAheadItem,
  selectedVintage?: SearchAheadItem,
  selectedWine?: Wine
): [string, boolean] => {
  let lwin = "";
  let valid = true;

  if (selectedWine?.lwin) {
    lwin += selectedWine.lwin;
  } else {
    valid = false;
  }

  if (selectedVintage?.label) {
    lwin += selectedVintage.label;
  } else {
    valid = false;
  }

  if (selectedFormat?.value) {
    lwin += selectedFormat.value;
  } else {
    valid = false;
  }

  return [ lwin, valid ];
};
