import React from "react";
import { StoreContext } from "redux-react-hook";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/browser";
import Amplify from "aws-amplify";
import { Helmet } from "react-helmet";

import configureStore from "src/store";

import AppRouter from "./pages/Router";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://7eb4c3e85b0742238163e3114a7d3edc@o406647.ingest.sentry.io/5274508",
    environment: process.env.NODE_ENV
  });
}
const history = createBrowserHistory();
const { store, persistor } = configureStore(history);

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_ID_POOL,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_S3_REGION
    }
  }
});

export const App: React.FC = () => {
  return (
    <StoreContext.Provider value={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <link href="https://fonts.googleapis.com/css2?family=Vesper+Libre:wght@400;500;700;900&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap" rel="stylesheet" />
        </Helmet>
        <AppRouter history={createBrowserHistory} />
      </PersistGate>
    </StoreContext.Provider>
  );
};
