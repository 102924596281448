import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import pick from "lodash/pick";
import { toast } from "react-toastify";
import axios from "axios";
import styled, { css } from "styled-components";
import { Box } from "grommet";

import useApiRequest from "src/utils/api/useApiRequest";
import {
  CreateDocumentInput, DocumentStatus, S3Level
} from "src/utils/api/routes/users.api";
import { Button } from "src/components/shared/button";
import { Icon, Icons } from "src/components/shared/icon";
import { Text } from "src/components/shared/text";
import { TextInput, FormRow } from "src/components/forms";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import { theme } from "src/utils/theme/index";

interface UploadUserDocumentProps {
  userId: number;
  onSuccess: () => void;
}

const validationTemplates = {
  required: "This field is required.",
  minLength: {
    value: 2,
    message: "The value is to short."
  }
};

export const UploadUserDocument: React.FC<UploadUserDocumentProps> = ({ userId, onSuccess }) => {
  const [ uploadUserDocumentRes, uploadUserDocumentReq ] = useApiRequest("USERS:upload-user-document");
  const [ setUserDocumentStatusRes, setUserDocumentStatusReq ] = useApiRequest("USERS:update-document-status");

  const {
    getValues, register, handleSubmit
  } = useForm<CreateDocumentInput>();

  const [ file, setFile ] = React.useState<File | null>(null);
  const [ uploading, setUploading ] = React.useState<boolean>(false);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[ 0 ]) {
      setFile(acceptedFiles[ 0 ]);
    }
  }, []);

  const {
    getRootProps, getInputProps, isDragActive, acceptedFiles
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: !!file || uploading
  });

  /**
   * start the upload process
   * send request to the api to get a presigned url!
   */
  const uploadDocument = React.useCallback(() => {
    if (!file) {
      toast.error("Please add a file to the drag and drop area");
    }

    setUploading(true);

    const { name, description } = getValues();

    uploadUserDocumentReq({
      pathParams: { userId: userId.toString() },
      data: {
        level: S3Level.private,
        name,
        description
      }
    });
  }, [
    uploadUserDocumentReq,
    userId,
    file,
    getValues
  ]);

  /**
   * Resets the dropzone
   *
   */
  const removeFile = React.useCallback(() => {
    acceptedFiles.splice(0, 1);
    setFile(null);
  }, [ setFile, acceptedFiles ]);

  /**
   * Once we have a presigned url
   * upload to the authenticated s3 location
   * on completing the upload to s3
   * let the api know it worked or not
   */
  const uploadObjectToS3 = React.useCallback(async (presignedUrl: string, file: File, documentId: number) => {
    try {
      await axios.put(
        presignedUrl, file, { headers: { "Content-Type": file.type } }
      );

      setUserDocumentStatusReq({
        data: { status: DocumentStatus.uploaded },
        pathParams: { documentId: documentId.toString() }
      });
    } catch (error) {
      toast.error("Upload failed - please try again");
      removeFile();
    }
  }, [ setUserDocumentStatusReq, removeFile ]);

  // respond to the presigned url by triggering the s3 upload process
  React.useEffect(() => {
    if (uploadUserDocumentRes.errorMessage) {
      toast.error(`Failed to upload document - ${uploadUserDocumentRes.errorMessage}`);
      setUploading(false);
    }

    if (uploadUserDocumentRes.data && file) {
      uploadObjectToS3(
        uploadUserDocumentRes.data.url, file, uploadUserDocumentRes.data.document.id
      );
      uploadUserDocumentRes.data = null;
    }
  }, [
    uploadUserDocumentRes,
    file,
    uploadObjectToS3
  ]);

  // respond to the status update request and the process is finished
  React.useEffect(() => {
    if (setUserDocumentStatusRes.errorMessage) {
      toast.error(`Failed to upload document - ${setUserDocumentStatusRes.errorMessage}`);
      setUserDocumentStatusRes.errorMessage = null;
      setUploading(false);
    }

    if (setUserDocumentStatusRes.data) {
      setUserDocumentStatusRes.data = null;
      setUploading(false);
      removeFile();
      onSuccess();
      toast.success("File uploaded");
    }
  }, [
    setUserDocumentStatusRes,
    removeFile,
    onSuccess
  ]);

  if (uploading) {
    return (
      <Dropzone className="loading">
        <Text as="p">
          Uploading file...
        </Text>
        <LoadingSpinner inline />
      </Dropzone>
    );
  }

  return (
    <UploadDocumentWrapper>
      <Text as="h3">
        Add user document
      </Text>
      <Dropzone {...getRootProps()} className={`${file ? "has-file" : ""}`}>
        <input {...getInputProps()} />
        {file === null ? (
          <>
            {isDragActive ? (
              <Text>
                Drop to add file!
              </Text>
            ) : (
              <Text>
                Drag and drop a file here, or click to select
              </Text>
            )}
          </>
        ) : (
          <FormWrapper direction="row" alignSelf="center">
            <Box align="center" justify="center" className="file">
              <Icon icon={Icons.globe} />
              <Text>
                {`${file.name} (${bytesToSize(file.size)})`}
              </Text>
            </Box>
            <form onSubmit={handleSubmit(uploadDocument)}>
              <FormRow required label="File name">
                <TextInput ref={register({ ...pick(validationTemplates, [ "required", "minLength" ]) })} name="name" />
              </FormRow>

              <FormRow label="Description">
                <TextInput ref={register()} name="description" />
              </FormRow>

              <Button label="upload" type="submit" />
            </form>
            <div className="icon-wrapper" onClick={() => removeFile()}>
              <Icon icon={Icons.cross} color={theme.colors.error} />
            </div>
          </FormWrapper>
        )}

      </Dropzone>
    </UploadDocumentWrapper>
  );
};

const UploadDocumentWrapper = styled.div`
  margin-top: 2rem;

  h3 {
    margin-bottom: 2rem;
  }
`;

const FormWrapper = styled(Box)`
  ${({ theme }) => css`
    .file {
      padding: 1rem 3rem;
      border: 1px solid ${theme.colors.fadedDark};
      border-radius: ${theme.borderRadius.small};

      svg {
        margin-bottom: 1rem;
      }
    }

    form {
      margin-left: 2rem;
      text-align: left;
    }

    .icon-wrapper {
      align-self: center;
      margin-left: 2rem;
      cursor: pointer;
    }
  `}
`;

const Dropzone = styled(Box)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.small};
    text-align: center;
    padding: 3rem;
    border: ${theme.colors.fadedDark} dashed 2px;
    outline: none;
    box-shadow: none;
    &.has-file {
      border-style: solid;
    }
  `}
`;

export const bytesToSize = (bytes: number) => {
  const sizes = [
    "Bytes",
    "KB",
    "MB",
    "GB",
    "TB"
  ];

  if (bytes == 0) return "0 Byte";

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[ i ];
};
