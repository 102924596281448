import { DefaultTheme } from "styled-components";

enum BreakPoints {
  xs = "0",
  sm = "+300",
  md = "+501",
  lg = "+750",
  xl = "+960"
}

enum BorderRadius {
  small = "0.5rem",
  medium = "1rem",
  large = "1.5rem"
}

const colors = {
  oatmeal: {
    50: "#EDE9E1",
    100: "#C7B9A5"
  },
  beige: {
    50: "#FDFAF4",
    100: "#E9E4D9"
  },
  grape: {
    5: "#F4F5F7",
    10: "#E9EAEF",
    20: "#D4D6E0",
    30: "#BEC1D0",
    40: "#A9ADC1",
    50: "#9398B1",
    60: "#7D83A1",
    70: "#686F92",
    80: "#525A82",
    90: "#3D4673",
    100: "#273163",
    110: "#17214A",
    120: "#081330"
  },
  sorbet: {
    10: "#FDFDF8",
    20: "#FBFAF2",
    30: "#F9F8EB",
    40: "#F7F6E5",
    50: "#F5F3DE",
    60: "#F4F1D7",
    70: "#F2EFD1",
    80: "#F0EDCA",
    90: "#EEEAC4",
    100: "#ECE8BD",
    110: "#DEDAA7",
    120: "#CFCA89",
    130: "#B3AF6A"
  },
  burgundy: {
    10: "#F5E7EA",
    20: "#EBD0D5",
    30: "#E0B8C0",
    40: "#D6A0AB",
    50: "#CC8896",
    60: "#C27181",
    70: "#B8596C",
    80: "#AD4157",
    90: "#A32A42",
    100: "#99122D",
    120: "#7A091A",
    130: "#610410"
  },
  profitGreen: {
    100: "#3FBF83",
    200: "#084327"
  },
  amber: { 100: "#F2911F" },
  white: { 100: "#FFFFFF" },
  black: { 100: "#000000" }
};

export const theme: DefaultTheme = {
  actions: {
    error: colors.burgundy[ 100 ],
    success: colors.profitGreen[ 100 ]
  },
  breakpoints: BreakPoints,
  colors: {
    appBackground: colors.grape[ 5 ],
    cardBackground: colors.white[ 100 ],
    dark: colors.grape[ 100 ],
    fadedDarker: colors.grape[ 70 ],
    fadedDark: colors.grape[ 60 ],
    fadedReg: colors.grape[ 50 ],
    fadedMid: colors.grape[ 40 ],
    fadedLight: colors.grape[ 20 ],
    fadedLighter: colors.grape[ 10 ],
    white: colors.white[ 100 ],
    black: colors.black[ 100 ],
    light: colors.sorbet[ 100 ],
    lighter: colors.sorbet[ 50 ],
    success: colors.profitGreen[ 100 ],
    error: colors.burgundy[ 100 ],
    stagingNavBar: colors.burgundy[ 130 ],
    warning: colors.amber[ 100 ],
    darkest: colors.grape[ 110 ]
  },
  font: {
    family: {
      primary: "'Vesper Libre', sans-serif",
      secondary: "'Montserrat', sans-serif"
    },
    sizes: {
      heading1: "3.6rem",
      heading2: "2.8rem",
      heading3: "2.6rem",
      heading4: "2rem",
      heading5: "1.8rem",
      heading6: "1.4rem",
      bodyRegular: "1.6rem",
      bodySmall: "1.5rem",
      small: "1.2rem"
    },
    weights: {
      light: 400,
      regular: 400,
      medium: 600,
      bold: 700
    }
  },
  borderRadius: {
    small: BorderRadius.small,
    medium: BorderRadius.medium,
    large: BorderRadius.large
  },
  checkBox: { color: "#2e3447" },
  select: { icons: { color: "#2e3447" } },
  button: {
    border: { radius: BorderRadius.medium },
    
    padding: {
      vertical: "8px",
      horizontal: "22px" 
    },
    default: {
      font: { weight: 400 },
      background: { color: colors.grape[ 100 ] } 
    },
    secondary: {
      font: { weight: 400 },
      background: { color: colors.grape[ 100 ] } 
    },
    primary: {
      font: { weight: 400 },
      background: { color: colors.grape[ 100 ] } 
    },
    disabled: {
      font: { weight: 400 },
      background: { color: colors.grape[ 100 ] } 
    }
  },
  global: {
    active: {
      color: colors.grape[ 100 ],
      background: { color: colors.grape[ 100 ] } 
    },
    // changes here will affect more than one component at a time
    colors: {
      control: colors.grape[ 70 ],
      primary: colors.grape[ 100 ],
      header: "#2e3447",
      adminBackground: "#f7f7f7",
      primaryText: "#495664"
    },
    control: {
      border: {
        color: "#495664",
        width: "1px"
      }
    }
  }
};
