import React from "react";
import { Column, Filters } from "react-table";
import formatDate from "date-fns/format";
import { toast } from "react-toastify";
import { Box } from "grommet";
import { Storage } from "aws-amplify";

import { Table } from "src/components/shared/table";
import { useApiRequest } from "src/utils/api";
import { UserDocument, DocumentStatus } from "src/utils/api/routes/users.api";
import { UploadUserDocument } from "src/components/documents/UploadUserDocument";
import { Button } from "src/components/shared/button/Button";
import { BulkActionLayer } from "src/components/shared/bulk-action-layer";
import { Text } from "src/components/shared/text";
import { Modal } from "src/components/shared/modal";
import { FiltersOptions } from "src/utils/api/api";

type UserDocumentsTable = Pick<UserDocument, "id" | "name" | "description" | "s3Key"> & { updatedAt: string };

const columns: Array<Column<UserDocumentsTable>> = [
  {
    Header: "Name",
    accessor: "name",
    disableFilters: true
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Updated",
    accessor: "updatedAt"
  },
  {
    Header: "Download",
    accessor: "s3Key",
    // eslint-disable-next-line react/display-name
    Cell: row => {
      // download the file from s3 and trigger a download
      return (
        <div>
          <Button
            label="Download"
            onClick={() => {
              Storage.get(row.value, {
                level: "public",
                download: true,
                customPrefix: { public: "" }
              }).then(data => {
                const blob = new Blob([ (data as Record<string, any>).Body ], { type: (data as Record<string, any>).ContentType });
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(blob);
                link.download = row.row.original.name;
                link.click();
              });
            }}
          />
        </div>
      );
    }
  }
];

interface UserDocumentsPageParams {
  userId: string;
}
const defaultFilters: FiltersOptions<Partial<UserDocument>> = { status: DocumentStatus.uploaded };

export const ClientDocuments: React.FC<UserDocumentsPageParams> = props => {
  const [ data, setData ] = React.useState<UserDocumentsTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [ userDocumentsRes, userDocumentsReq ] = useApiRequest("USERS:documents");
  const [ deleteUserDocumentsRes, deleteUserDocumentsReq ] = useApiRequest("USERS:bulk-delete-documents");
  const [ selected, setSelected ] = React.useState<UserDocumentsTable[]>([]);
  const [ deleteModalOpen, setDeleteModalOpen ] = React.useState(false);

  // helper for resetting table and fetch
  const initialGetUserDocuments = React.useCallback(() => {
    if (props.userId) {
      userDocumentsReq({
        pathParams: { userId: props.userId },
        params: {
          filters: { ...defaultFilters },
          offset: 0,
          limit: 10
        }
      });
    }
  }, [ props.userId, userDocumentsReq ]);

  // get initial data
  React.useEffect(() => {
    initialGetUserDocuments();
  }, [ initialGetUserDocuments, userDocumentsReq ]);

  // table fetch paginated data
  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<UserDocumentsTable>;
  }) => {
    if (props.userId) {
    // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const filters: Partial<UserDocumentsTable> = {};

        tableFilters?.map(filter => {
          const id = filter.id as keyof UserDocumentsTable;

          filters[ id ] = filter.value;
        });
        // Set the loading state
        setLoading(true);

        userDocumentsReq({
          pathParams: { userId: props.userId },
          params: {
            filters: {
              ...defaultFilters,
              ...filters
            },
            offset: pageIndex * pageSize,
            limit: pageSize
          }
        });
      }
    }
  }, [ props, userDocumentsReq ]);

  // handle incoming documents data
  React.useEffect(() => {
    if (userDocumentsRes.data && userDocumentsRes.data.items) {
      const tableData: UserDocumentsTable[] = userDocumentsRes.data.items.map(userDocument => {
        return {
          id: userDocument.id,
          name: userDocument.name,
          description: userDocument.description,
          updatedAt: formatDate(new Date(userDocument.updatedAt), "dd MMM yyyy HH:mm"),
          s3Key: userDocument.s3Key
        };
      });

      setData(tableData);
      setPageCount(Math.ceil(userDocumentsRes.data.total / userDocumentsRes.data.limit));
      setLoading(false);
    }

    if (userDocumentsRes.errorMessage) {
      toast.error(userDocumentsRes.errorMessage);
      setLoading(false);
    }
  }, [ userDocumentsRes ]);

  // select rows by checkbox
  const handleSelectRows = React.useCallback((selected: UserDocumentsTable[]) => {
    if (selected.length > 0) {
      setSelected(selected);
    } else {
      setSelected([]);
    }
  }, []);

  // trigger bulk delete api request
  const handleBulkDelete = React.useCallback(() => {
    deleteUserDocumentsReq({ data: { ids: selected.map(sel => sel.id) } });
  }, [ deleteUserDocumentsReq, selected ]);

  // handle the response from the bulk delete endpoint
  React.useEffect(() => {
    if (deleteUserDocumentsRes.data) {
      if (!deleteUserDocumentsRes.data.errors.length) {
        toast.success("Documents deleted");
      } else if (deleteUserDocumentsRes.data.errors.length === selected.length) {
        toast.warn(`${deleteUserDocumentsRes.data.errors.length} documents failed to delete`);
      } else {
        toast.error("Failed to delete the selcted documents");
      }

      //reset the data
      deleteUserDocumentsRes.data = null;
      setSelected([]);
      initialGetUserDocuments();
    }

    if (deleteUserDocumentsRes.errorMessage) {
      toast.error("Failed to delete the selcted documents");
      deleteUserDocumentsRes.errorMessage = null;
    }
  }, [
    selected,
    deleteUserDocumentsRes,
    initialGetUserDocuments
  ]);

  return (
    <>
      <Box pad="small" direction="column" width="100%">
        <Box>
          <UploadUserDocument userId={parseInt(props.userId, 10)} onSuccess={() => initialGetUserDocuments()} />
        </Box>
        <Box>
          <Table<UserDocumentsTable>
            name="clientUserDocumentsTable"
            columns={columns}
            data={data}
            loading={loading}
            featuredFilter="name"
            fetchData={fetchData}
            pageCount={pageCount}
            usePagination
            useResizeColumns
            useHideColumns
            useFilters
            useBulkSelect
            handleSelectRows={handleSelectRows}
          />
        </Box>

      </Box>

      {selected.length > 0 && (
        <BulkActionLayer>
          <Text>
            {selected.length}
            {" "}
            selected
          </Text>
          <Box direction="row">
            <Button margin={{ left: "small" }} label="Delete" onClick={() => setDeleteModalOpen(true)} backgroundColor="error" />
          </Box>
        </BulkActionLayer>
      )}

      {deleteModalOpen && (
        <Modal
          isLoading={loading}
          onClose={() => setDeleteModalOpen(false)}
          title={`Delete ${selected.length === 1 ? "document" : "documents"}`}
          description={`Are you sure that you want to delete ${selected.length} ${selected.length === 1 ? "document" : "documents"}?`}
          actions={{
            confirm: {
              label: "Delete",
              onClick: () => {
                handleBulkDelete();
                setDeleteModalOpen(false);
              }
            },
            reject: {
              label: "Cancel",
              onClick: () => setDeleteModalOpen(false)
            }
          }}
        />
      )}
    </>

  );
};
