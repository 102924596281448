import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Box, Menu } from "grommet";

import { PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Text } from "src/components/shared/text";
import { useApiRequest } from "src/utils/api";
import { Wine } from "src/utils/api/routes/wines.api";
import { PageProps } from "src/pages/Router";
import { Vintage } from "src/utils/api/routes/vintages.api";
import { Select } from "src/components/forms/Select";
import { Card } from "src/components/shared/layout/Card";
import { Button } from "src/components/shared/button";
import { theme } from "src/utils/theme";
import { TabTitle, TabPanel } from "src/components/shared/tabs";
import { CurrentValueBox } from "src/components/shared/price-graph/CurrentValueBox";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import { Modal } from "src/components/shared/modal";
import {
  InfoPanel, InfoColumn, InfoBox
} from "src/components/shared/info";

import { VintageCasesTab } from "./ViewVintageCasesTab";
import { VintagePriceGraph } from "./VintagePriceGraph";
import { CreateVintageModal } from "./CreateVintageModal";
import { VintageRatingsTab } from "./ViewVintageRatingsTab";

interface WinePageParams {
  wineId: string;
  vintageId?: string;
}

const WinePage: React.FC<PageProps<Record<string, unknown>, WinePageParams>> = props => {
  const [ tabIndex, setTabIndex ] = React.useState(0);
  const [ wine, setWine ] = React.useState<Wine>();
  const [ loading, setLoading ] = React.useState(true);
  const [ getWineRes, getWineReq ] = useApiRequest("WINES:get");
  const [ deleteWineRes, deleteWineReq ] = useApiRequest("WINES:delete");
  const [ deleteVintageRes, deleteVintageReq ] = useApiRequest("VINTAGES:delete");
  const [ updateVintageRes, updateVintageReq ] = useApiRequest("VINTAGES:update");
  const [ selectedVintage, setVintage ] = React.useState<Vintage>();
  const [ isNewVintageModalOpen, setNewVintageModalOpen ] = React.useState(false);
  const [ deleteVintageModalOpen, setDeleteVintageModalOpen ] = React.useState(false);
  const [ deleteWineModalOpen, setDeleteWineModalOpen ] = React.useState(false);
  const wineId = React.useMemo(() => props.match?.params.wineId || "", [ props.match ]);

  // Handle the deletion of a wine
  const handleDeleteWine = React.useCallback(() => {
    deleteWineReq({ pathParams: { wineId } });
  }, [ deleteWineReq, wineId ]);

  // Handle the deletion of a vintage
  const handleDeleteVintage = React.useCallback(() => {
    if (selectedVintage?.id) {
      deleteVintageReq({ pathParams: { vintageId: selectedVintage.id.toString() } });
    }
  }, [ deleteVintageReq, selectedVintage ]);

  // Handle changing the vintage
  const handleChangeVintage = React.useCallback((vintage: Vintage) => {
    if (vintage.id.toString() !== props.match?.params.vintageId) {
      props.history.push(`/admin/wine/${vintage.wineId}/vintage/${vintage.id}`);
    }
  }, [ props.history, props.match ]);

  // Request the wine by id based off path params
  React.useEffect(() => {
    if (!wine) {
      getWineReq({ pathParams: { wineId } });
    }
  }, [
    getWineReq,
    wine,
    wineId
  ]);

  // Detect the response for get wine by id
  React.useEffect(() => {
    if (getWineRes.data) {
      setWine(getWineRes.data);
      setLoading(false);
    }

    if (getWineRes.errorMessage) {
      toast.error(getWineRes.errorMessage);
    }
  }, [
    getWineRes,
    props.history,
    props.match
  ]);

  // Detect the response for get wine by id
  React.useEffect(() => {
    if (wine) {
      const vintages = wine.vintages?.sort((a, b) => a.year - b.year).reverse();

      if (vintages) {
        let vintageToSet = vintages[ 0 ];

        if (props.match?.params.vintageId) {
          const idx = parseInt(props.match?.params.vintageId);
          const matchVintage = vintages.find(vintage => vintage.id === idx);

          if (matchVintage) {
            vintageToSet = matchVintage;
          } else {
            props.history.push(`/admin/wine/${props.match.params.wineId}`);
            toast.error(`Vintage with the ID of ${idx} cannot be found for this wine.`);
          }
        }

        setVintage(vintageToSet);
      }

      setLoading(false);
    }
  }, [
    wine,
    props.history,
    props.match
  ]);

  // Detect success or error in the delete vintage response
  React.useEffect(() => {
    if (deleteVintageRes.data) {
      toast.success("Vintage was deleted successfully.");
      props.history.push(`/admin/wine/${wineId}`);
    }

    if (deleteVintageRes.errorMessage) {
      toast.error(deleteVintageRes.errorMessage);
    }
  }, [
    deleteVintageRes,
    props.history,
    wineId
  ]);

  // Detect success or error in the delete wine response
  React.useEffect(() => {
    if (deleteWineRes.data) {
      toast.success("Wine was deleted successfully.");
      props.history.push("/admin/wines");
    }

    if (deleteWineRes.errorMessage) {
      toast.error(deleteWineRes.errorMessage);
    }
  }, [ deleteWineRes, props.history ]);

  // Refresh vintage
  const refreshVintage = React.useCallback(() => {
    if (selectedVintage?.id) {
      updateVintageReq({
        data: { id: selectedVintage.id },
        pathParams: { vintageId: selectedVintage.id.toString() }
      });
    }
  }, [ selectedVintage, updateVintageReq ]);

  // Refresh (update) vintage response
  React.useEffect(() => {
    if (updateVintageRes.data) {
      toast.success("Vintage was updated successfully.");
      // Refetch the wine, which pulls all vintage info:
      getWineReq({ pathParams: { wineId } });
    }

    if (updateVintageRes.errorMessage) {
      toast.error(`Could not update vintage: ${updateVintageRes.errorMessage}`);
    }
  }, [
    updateVintageRes,
    getWineReq,
    wineId
  ]);

  return (
    <AdminLayout>
      <PageHeader 
        backLinkText="Back to wines"
        backLink={() => props.history.push("/admin/wines")}
      />
      {loading && <LoadingSpinner />}
      {(wine && isNewVintageModalOpen) && (
        <CreateVintageModal
          lwin7={wine.lwin}
          wineId={wine.id}
          onClose={() => setNewVintageModalOpen(false)}
          onCreate={() => getWineReq({ pathParams: { wineId } })}
        />
      )}
      {wine && (
        <Box justify="between" direction="row" align="end" pad={{ bottom: "medium" }}>
          <Box>
            <Box pad={{ vertical: "medium" }}>
              <Text as="h1" bold>
                {`${wine.displayWineLine1}, ${wine.displayWineLine2}`}
              </Text>
            </Box>
            <Box direction="row" align="center">
              {wine.vintages?.length ? (
                <Select
                  options={wine.vintages.sort((a, b) => a.year - b.year).reverse() || []}
                  labelKey="year"
                  emptySearchMessage="Wine has no vintages"
                  value={selectedVintage}
                  onChange={({ option }) => handleChangeVintage(option)}
                />
              ) : (
                "This wine has no vintages. Create one?"
              )}
              <NewVintageButton label="New vintage" onClick={() => setNewVintageModalOpen(true)} />
            </Box>
          </Box>
          <Box>
            <Menu
              dropProps={{
                align: {
                  top: "bottom",
                  right: "right"
                }
              }}
              items={[
                {
                  label: "Edit wine",
                  onClick: () => props.history.push(`/admin/wine/${wineId}/edit`)

                },
                {
                  label: "Delete wine",
                  onClick: () => setDeleteWineModalOpen(true),
                  disabled: wine.vintages?.length ? true : false
                },
                {
                  label: "Refresh selected vintage",
                  onClick: () => refreshVintage(),
                  disabled: !selectedVintage
                },
                {
                  label: "Delete selected vintage",
                  onClick: () => setDeleteVintageModalOpen(true),
                  disabled: !selectedVintage
                }
              ]}
              label="Actions"
            />
          </Box>

        </Box>
      )}
      <Box direction="row">
        <Box width="100%">
          {wine && (
            <Box pad={{ vertical: "medium" }}>
              <Text as="h3">
                Wine Information
              </Text>
              <InfoPanel>
                <InfoColumn>
                  <InfoBox label="LWIN7" value={wine?.lwin} />
                  <InfoBox label="LiveEx Name" value={wine?.name} />
                  <InfoBox label="Type" value={wine?.wineColour?.name} />
                  <InfoBox label="Display Location" value={wine?.displayLocation} />
                  <InfoBox label="Region" value={wine?.region} />
                  <InfoBox label="Sub Region" value={wine?.subRegion} />
                  <InfoBox label="Village" value={wine?.village} />
                  <InfoBox label="Country" value={wine?.country} />
                  <InfoBox label="Pre Drinking Years" value={wine?.preDrinkingYears} />
                  <InfoBox label="Mature Drinking Years" value={wine?.matureDrinkingYears} />
                </InfoColumn>
              </InfoPanel>
            </Box>
          )}

          {selectedVintage && (
            <Box pad={{ vertical: "medium" }}>
              <Text as="h3">
                Vintage Information
              </Text>
              <InfoPanel>
                <InfoColumn>
                  <InfoBox label="LWIN11" value={selectedVintage?.lwin} />
                  <InfoBox label="Vintage" value={selectedVintage?.year} />
                  <InfoBox label="Drink From" value={selectedVintage?.drinkingWindowStart} />
                  <InfoBox label="Drink To" value={selectedVintage?.drinkingWindowEnd} />
                </InfoColumn>
              </InfoPanel>
            </Box>
          )}

        </Box>
      </Box>
      {selectedVintage && (
        <Card margin={{ top: "medium" }}>
          <Box
            border={{
              color: theme.colors.fadedLighter,
              side: "bottom",
              size: "2px"
            }}
            align="center"
            direction="row"
          >
            <TabTitle onClick={() => setTabIndex(0)} active={tabIndex === 0} title="Performance" />
            <TabTitle onClick={() => setTabIndex(1)} active={tabIndex === 1} title="Cases" />
            <TabTitle onClick={() => setTabIndex(2)} active={tabIndex === 2} title="Ratings" />
            <Box margin={{ left: "auto" }}>
              <CurrentValueBox currentValue={selectedVintage.currentPrice} title="Market Price" />
            </Box>
            
          </Box>
          {selectedVintage && (
            <>
              <TabPanel active={tabIndex === 0}>
                <VintagePriceGraph vintage={selectedVintage} />
              </TabPanel>
              <TabPanel active={tabIndex === 1}>
                <VintageCasesTab
                  onChangePage={id => props.history.push(`/admin/case/${id}`)}
                  vintageId={selectedVintage.id}
                />
              </TabPanel>
              <TabPanel active={tabIndex === 2}>
                <VintageRatingsTab
                  vintageId={selectedVintage.id}
                />
              </TabPanel>
            </>
          )}
        </Card>
      )}

      {deleteWineModalOpen && wine && (
        <Modal
          isLoading={loading}
          onClose={() => setDeleteWineModalOpen(false)}
          title={`Delete ${wine.name}?`}
          description={`Are you sure that you want to delete ${wine.name}?`}
          actions={{
            confirm: {
              label: "Delete",
              onClick: () => {
                handleDeleteWine();
                setDeleteWineModalOpen(false);
              }
            },
            reject: {
              label: "Cancel",
              onClick: () => setDeleteWineModalOpen(false)
            }
          }}
        />
      )}

      {deleteVintageModalOpen && selectedVintage && (
        <Modal
          isLoading={loading}
          onClose={() => setDeleteVintageModalOpen(false)}
          title={`Delete ${selectedVintage.name} vintage?`}
          description={`Are you sure that you want to delete the ${selectedVintage.name} vintage?`}
          actions={{
            confirm: {
              label: "Delete",
              onClick: () => {
                handleDeleteVintage();
                setDeleteVintageModalOpen(false);
              }
            },
            reject: {
              label: "Cancel",
              onClick: () => setDeleteVintageModalOpen(false)
            }
          }}
        />
      )}
    </AdminLayout>
  );
};

const NewVintageButton = styled(Button)`
    margin: 0 1rem;
`;

export default WinePage;
