import { Box } from "grommet";
import { debounce } from "lodash";
import React from "react";
import { toast } from "react-toastify";

import { SearchAhead, SearchAheadItem } from "src/components/shared/search-ahead/SearchAhead";
import { useApiRequest } from "src/utils/api";
import { Label } from "src/utils/api/routes/labels.api";
import { Wine } from "../../utils/api/routes/wines.api";
import { FormRow, SelectInput } from "../forms";
import { Button } from "../shared/button";
import { Text } from "../shared/text";
import { availableFormats } from "src/utils/api/routes/bottles.api";
import { formatUid } from "../../utils/formatLabelCode";

import {
  formatCaseName, formatCaseLWIN, formatBottleName, formatBottleLWIN
} from "./nameUtils";
import {
  AssignedCaseLabel, SearchAheadItemWine, AssignedBottleLabel 
} from "./LabelAssignmentForm";

interface LabelAssignmentFormProps {
  // Labels to assign
  labels: Label[];
  // Pass back the assigned bottles
  handleCreate: (caseWithBottles: AssignedCaseLabel) => void;
  // Cancel the assignment
  handleCancel: () => void;
}

/**
 * Label Assignment Form
 * When a user is ready to assign unassigned labels to a case.
 *
 * COHESIVE CASE:
 * If it is not a mixed case, then they can select:
 *  - a wine
 *  - a vintage
 *  - a format
 * We will construct the case & bottle names/lwin refs using this information.
 *
 */
export const CohesiveCaseAssignment: React.FC<LabelAssignmentFormProps> = props => {
  // Setup API request
  const [ winesRes, winesReq ] = useApiRequest("WINES:list");
  // Setup variable to store search results
  const [ wineSearchResults, setWineSearchResults ] = React.useState<Array<SearchAheadItem<SearchAheadItemWine, Wine>>>([]);
  // Setup variables to store form inputs
  const [ selectedWine, setSelectedWine ] = React.useState<Wine>();
  const [ selectedVintage, setSelectedVintage ] = React.useState<SearchAheadItem>();
  const [ selectedFormat, setSelectedFormat ] = React.useState<SearchAheadItem>();

  // Debounced search for a wine by name
  const searchForWineName = debounce((search: string) => {
    setSelectedWine(undefined);
    setSelectedVintage(undefined);
    setSelectedFormat(undefined);

    if (search) {
      winesReq({
        params: {
          offset: 0,
          limit: 25,
          filters: { name: search }
        }
      });
    }
  }, 500);

  // Listen for a response from the wine search
  React.useEffect(() => {
    if (winesRes.data?.items) {
      setWineSearchResults(winesRes.data.items.map(wine => {
        return {
          label: wine.name,
          value: {
            id: wine.id,
            displayWineLine1: wine.displayWineLine1,
            displayWineLine2: wine.displayWineLine2
          },
          data: wine
        };
      }) || []);
    }
  }, [ winesRes ]);

  // Handle selecting a wine from the wine SearchAhead
  const handleSelectWine = React.useCallback((selected?: SearchAheadItem<SearchAheadItemWine, Wine> | null) => {
    if (selected?.data) {
      setSelectedWine(selected.data);
    }
  }, []);

  // Construct case & bottle names and LWIN refs.
  const [ caseName, caseNameValid ] = formatCaseName(
    false, "", selectedVintage, selectedWine
  );

  const [ caseLWIN ] = formatCaseLWIN(
    false, props.labels.filter(l => !l.isCase).length, selectedFormat, selectedVintage, selectedWine
  );

  const [ bottleName, bottleNameValid ] = formatBottleName(selectedVintage, selectedWine);

  const [ bottleLWIN, bottleLWINValid ] = formatBottleLWIN(
    selectedFormat, selectedVintage, selectedWine
  );

  /**
   * Handle creation of AssignedCaseLabel with bottles.
   */
  const handleAssignCohesiveCase = React.useCallback(() => {
    let fieldRequired: string | null = null;
    const caseLabel = props.labels.find(l => l.isCase);

    if (!selectedWine) {
      fieldRequired = "a wine";
    } else if (!selectedVintage) {
      fieldRequired = "a vintage";
    } else if (!selectedFormat) {
      fieldRequired = "a format";
    } else if (!caseNameValid) {
      fieldRequired = "a case name";
    } else if (!bottleNameValid) {
      fieldRequired = "a bottle name";
    } else if (!bottleLWINValid) {
      fieldRequired = "a bottle lwin";
    } else if (!caseLabel) {
      fieldRequired = "a wine, vintage and format";
    }

    if (fieldRequired) {
      toast.error(`You need to select ${fieldRequired} to continue.`);
    }

    // fieldRequired means these are null - but typescript still thinks these 
    // values could be null so checking & returning here for TS to work properly.
    if (!selectedFormat || !selectedVintage || !caseLabel) {
      return;
    }

    const bottleLabels: AssignedBottleLabel[] = [];

    props.labels.filter(l => !l.isCase).map(label => {
      bottleLabels.push({
        labelId: label.id,
        // Casting type because we've already checked.
        bottleSize: selectedFormat.value as string,
        // Casting type because we've already checked.
        vintageId: selectedVintage.value as number,
        name: bottleName,
        lwin: bottleLWIN,
        verifiedCode: label.verifiedCode,
        certificationReference: formatUid(label.certificationReference)
      });
    });

    const assignedCaseLabel: AssignedCaseLabel = {
      labelId: caseLabel.id,
      bottleSize: selectedFormat.value as string,
      packSize: bottleLabels.length.toString().padStart(2, "0"),
      name: caseName,
      lwin: caseLWIN,
      bottles: bottleLabels,
      certificationReference: formatUid((caseLabel as Label).certificationReference),
      verifiedCode: (caseLabel as Label).verifiedCode
    };

    props.handleCreate(assignedCaseLabel);
  }, [
    props,
    selectedWine,
    selectedVintage,
    selectedFormat,
    caseName,
    caseNameValid,
    bottleNameValid,
    bottleLWINValid,
    caseLWIN,
    bottleName,
    bottleLWIN 
  ]);

  return (
    <Box>      
      <Box>
        <Box pad={{ top: "medium" }}>
          <SearchAhead<SearchAheadItemWine, Wine>
            name="wineSearch"
            saveState
            label="First, search for a wine"
            loading={winesRes.loading}
            items={wineSearchResults}
            renderItem={item => {
              return (
                <Box direction="row">
                  <Text bold>
                    {item.value.displayWineLine1}
                  </Text>
                  <Box pad={{ left: "small" }}>
                    <Text>
                      {item.value.displayWineLine2}
                    </Text>
                  </Box>
                </Box>
              );
            }}
            totalItems={winesRes.data?.total || 0}
            placeholder="Search name"
            onSelect={handleSelectWine}
            onSearch={searchForWineName}
          />

        </Box>
        {selectedWine && (
          <Box direction="row" pad={{ vertical: "small" }}>
            <Box margin={{ right: "small" }} fill>
              <FormRow required label="Select a vintage">
                <SelectInput<number>
                  displayType="input"
                  items={selectedWine.vintages?.sort((a, b) => b.year - a.year).map(v => ({
                    label: v.year.toString(),
                    value: v.id
                  })) || []}
                  onSelect={value => value && setSelectedVintage(value)}
                />
              </FormRow>
            </Box>
            <Box fill>
              <FormRow required label="Select a format">
                <SelectInput<string>
                  displayType="input"
                  items={availableFormats}
                  onSelect={value => value && setSelectedFormat(value)}
                />
              </FormRow>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        direction="row"
        pad={{
          top: "large",
          bottom: "small" 
        }}
      >
        <Box>
          <Button
            primary
            label="Assign"
            onClick={handleAssignCohesiveCase}
          />
        </Box>
        <Box pad={{ horizontal: "xsmall" }}>
          <Button
            secondary
            backgroundColor="fadedDark"
            label="Cancel"
            onClick={props.handleCancel}
          />
        </Box>
      </Box>
    </Box>
  );
};
