import React from "react";
import { Column } from "react-table";
import {
  Table as BasicTable, Box, TableHeader, TableRow, TableCell, TableBody 
} from "grommet";

import { Card } from "../shared/layout";
import { Text } from "../shared/text";
import { Table } from "../shared/table";
import { Modal } from "../shared/modal";
import { Button } from "../shared/button";

import { AssignedCaseLabel } from "./LabelAssignmentForm";

interface AssignedLabelsTableProps {
  assignedCases: AssignedCaseLabel[];
  removeAssignedCase: (labelId: number) => void;
  deleteCases: () => void;
  loading: boolean;
}

type LabelsTable = Pick<AssignedCaseLabel, "labelId" | "name" | "lwin" | "bottles" | "certificationReference">;

const columns: Array<Column<LabelsTable>> = [
  {
    Header: "Name",
    accessor: "name",
    disableFilters: true
  },
  {
    Header: "LWIN",
    accessor: "lwin",
    disableFilters: true
  },
  {
    Header: "Code",
    accessor: "certificationReference",
    disableFilters: true
  }
];

export const AssignedLabelsTable: React.FC<AssignedLabelsTableProps> = props => {
  const data = props.assignedCases;
  const [ caseInfoModal, setCaseInfoModal ] = React.useState<LabelsTable | null>(null);

  return (
    <Card pad="small" margin={{ bottom: "small" }}>
      <Box pad="small" direction="row" justify="between" align="center">
        <Text as="h3">
          Assigned so far
        </Text>
        <Button disabled={!data.length} onClick={() => props.deleteCases()} label="Remove all" />
      </Box>
      <Table<LabelsTable> 
        name="LabelsAssignedSoFar"
        loading={props.loading}
        data={data}
        columns={columns}
        usePagination
        useResizeColumns
        noFoundMessage="No labels assigned yet."
        onClick={selected => setCaseInfoModal(selected.original)}
      />
      {caseInfoModal && (
        <Modal
          onClose={() => setCaseInfoModal(null)}
          title={caseInfoModal.name}
          actions={{
            confirm: {
              label: "Unassign this case",
              onClick: () => {
                setCaseInfoModal(null);
                props.removeAssignedCase(caseInfoModal.labelId);
              }
            }
          }}
          description={(
            <>
              <Box pad="small">
                <Text>
                  This case contains:
                </Text>
              </Box>
              <BasicTable>
                <TableHeader>
                  <TableRow>
                    <TableCell scope="col" border="bottom">
                      Name
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      LWIN
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      Code
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {caseInfoModal.bottles.map((bottle, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {bottle.name}
                      </TableCell>
                      <TableCell>
                        {bottle.lwin}
                      </TableCell>
                      <TableCell>
                        {bottle.certificationReference}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </BasicTable>
            </>
          )}
        />
      )}
    </Card>
  );
};