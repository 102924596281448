import * as Redux from "redux";

import { User } from "src/utils/api/routes/users.api";

import { GlobalActions, GLOBAL_ACTIONS } from "./global.actions";

export interface GlobalStore {
  user: User | null;
}

const initialState: GlobalStore = { user: null };

export const globalReducer: Redux.Reducer<GlobalStore, GlobalActions> = (state: GlobalStore = initialState,
  action: GlobalActions): GlobalStore => {
  switch (action.type) {
    case GLOBAL_ACTIONS.SET_USER:
      return {
        ...state,
        user: action.user

      };

    default:
      return state;
  }
};
