import { Form, Box } from "grommet";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Button } from "src/components/shared/button";
import {
  FormRow, TextInput, FormError
} from "src/components/forms";
import useLogin from "src/utils/authentication/hooks/useLogin";
import { useAuthContext } from "src/utils/authentication/AuthContext";
import { PublicLayout } from "src/components/shared/layout/PublicLayout";
import { Text } from "src/components/shared/text";

interface LoginFormValues {
  username: string;
  password: string;
}

interface ResetPasswordFormValues {
  username: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const Login: React.FunctionComponent = () => {
  // const { history } = useRouter();

  const {
    login, loginState, confirmNewPassword
  } = useLogin();

  const { authState } = useAuthContext();

  const {
    handleSubmit: passwordResetHandleSubmit,
    register: passwordResetRegister,
    errors: passwordResetErrors,
    getValues: passwordResetGetValues,
    setValue: passwordResetSetValue,
    watch: passwordResetWatch
  } = useForm<ResetPasswordFormValues>();

  const handlePasswordReset = React.useCallback(() => {
    const values = passwordResetGetValues();

    confirmNewPassword(values.username, values.newPassword);
  }, [ passwordResetGetValues, confirmNewPassword ]);

  const {
    handleSubmit, register, errors, getValues
  } = useForm<LoginFormValues>();

  const handleLogin = React.useCallback(() => {
    const values = getValues();

    passwordResetSetValue("email", values.username);
    login(values.username, values.password);
  }, [
    getValues,
    login,
    passwordResetSetValue
  ]);

  return (
    <PublicLayout>
      {loginState.resetPassword ?
        (
          <>
            <Form onSubmit={passwordResetHandleSubmit(handlePasswordReset)}>
              <FormError message={loginState.error || authState.error} />
              <FormRow label="Email" error={passwordResetErrors.username?.message}>
                <TextInput disabled name="username" ref={passwordResetRegister({ required: "Email address is required" })} />
              </FormRow>

              <FormRow label="New Password" error={passwordResetErrors.newPassword?.message}>
                <TextInput
                  name="newPassword"
                  type="password"
                  ref={passwordResetRegister({
                    required: "Password is required",
                    pattern: {
                      value: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d+=^$*.[\]{}()?\-"!@#%&/,><':;|_~` ]{8,}$/),
                      message: "Password must be at least 8 characters, 1 upper case, 1 lowercase and 1 number"
                    }
                  })}
                />
              </FormRow>

              <FormRow label="Confirm New Password" error={passwordResetErrors.newPasswordConfirm?.message}>
                <TextInput
                  name="newPasswordConfirm"
                  type="password"
                  ref={passwordResetRegister({
                    required: "Confirm New Password is required",
                    validate: value => value === passwordResetWatch("newPassword") ? true : "Passwords must match!"
                  })}
                />
              </FormRow>

              <Button type="submit" label="Reset password" />
            </Form>
          </>
        ) :
        (
          <>
            <Form onSubmit={handleSubmit(handleLogin)}>
              <FormError message={loginState.error || authState.error} />
              <FormRow label="Email" error={errors.username?.message}>
                <TextInput name="username" ref={register({ required: "Email address is required" })} />
              </FormRow>

              <FormRow label="Password" error={errors.password?.message}>
                <TextInput name="password" type="password" ref={register({ required: "Password is required" })} />
              </FormRow>

              <Box direction="row" align="center">
                <Button type="submit" label="Login" />
                <Box pad={{ left: "small" }}>
                  <Link to="/forgot-password">
                    <Text color="dark">
                      Forgot Password?
                    </Text>
                  </Link>
                </Box>
              </Box>
            </Form>
          </>
        )}
    </PublicLayout>
  );
};

export default Login;
