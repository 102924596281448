import { Storage } from "aws-amplify";
import React from "react";
import { Column, Row } from "react-table";
import { toast } from "react-toastify";

import { Button } from "src/components/shared/button";
import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Table } from "src/components/shared/table";
import { useTableFetch } from "src/hooks/useTableFetch";
import { PageProps } from "src/pages/Router";
import { StorageFeeCalculation } from "src/utils/api/routes/storage-fees.api";
import { UserDocument } from "src/utils/api/routes/users.api";

type StorageFeesTable = Pick<StorageFeeCalculation, "id" | "createdAt" | "startDate" | "endDate" | "csvFile"> & {status: string};

const columns: Array<Column<StorageFeesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    width: 50
  },
  {
    Header: "Generated at",
    accessor: "createdAt",
    disableSortBy: true
  },
  {
    Header: "Start Date",
    accessor: "startDate"
  },
  {
    Header: "End Date",
    accessor: "endDate"
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true
  },
  {
    Header: "CSV Link",
    accessor: "csvFile",
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Cell: row => {
      if (row && row.value && row.value.s3Key) {
        // download the file from s3 and trigger a download
        return (
          <div>
            <Button
              label="Download"
              onClick={e => {
                e.stopPropagation();

                Storage.get((row.value as UserDocument).s3Key, {
                  level: "admin",
                  download: true,
                  customPrefix: { public: "" }
                }).then(data => {
                  const blob = new Blob([ (data as Record<string, any>).Body ], { type: (data as Record<string, any>).ContentType });
                  const link = document.createElement("a");

                  link.href = window.URL.createObjectURL(blob);
                  link.download = (row.value as UserDocument).name;
                  link.click();
                });
              }}
            />
          </div>
        );
      }

      return "-";
    }
  }
];

export const AllStorageFeesCalculation: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ data, setData ] = React.useState<StorageFeesTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ getStorageFeeCalculationsRes, fetch ] = useTableFetch<"STORAGE-FEE:list-storage-fee-calculations", StorageFeesTable>("STORAGE-FEE:list-storage-fee-calculations");

  React.useEffect(() => {
    if (getStorageFeeCalculationsRes.data && getStorageFeeCalculationsRes.data.items) {
      const tableData = getStorageFeeCalculationsRes.data.items.map(storageFeeCalc => {
        return {
          id: storageFeeCalc.id,
          startDate: storageFeeCalc.startDate,
          endDate: storageFeeCalc.endDate,
          createdAt: storageFeeCalc.createdAt,
          csvFile: storageFeeCalc.csvFile,
          status: storageFeeCalc.processJob ? storageFeeCalc.processJob.status : ""
        };
      });

      setData(tableData);
      setPageCount(Math.ceil(getStorageFeeCalculationsRes.data.total / getStorageFeeCalculationsRes.data.limit));
      setLoading(false);

      if (getStorageFeeCalculationsRes.errorMessage) {
        toast.error(getStorageFeeCalculationsRes.errorMessage);
      }
    }

    if (getStorageFeeCalculationsRes.errorMessage) {
      toast.error(getStorageFeeCalculationsRes.errorMessage);
    }
  }, [ getStorageFeeCalculationsRes ]);

  const handleOnClick = React.useCallback((row: Row<StorageFeesTable>) => {
    props.history.push(`/admin/storage-fee-calculation/${row.original.id}`);
  }, [ props.history ]);

  return (
    <AdminLayout>
      <PageHeader 
        title="Storage Fee Calculator" 
        backLink={() => props.history.push("/admin/dashboard")} 
        backLinkText="Back to dashboard"
        callToAction={<Button label="New" onClick={() => props.history.push("/admin/storage-fee-calculation/new")} />}
      />
      <Card>
        <Table<StorageFeesTable>
          name="StorageFeesTable"
          title="Previously generated fees"
          columns={columns}
          data={data}
          onClick={handleOnClick}
          loading={loading}
          fetchData={fetch}
          pageCount={pageCount}
          useResizeColumns
          usePagination
          useSortBy
          useHideColumns
        />
      </Card>
    </AdminLayout>
  );
};
