import React from "react";
import IcomoonReact from "icomoon-react";

import { theme } from "src/utils/theme";

import iconSet from "./selection.json";
 
export enum Icons {
  "arrow-down" = "arrow-down",
  "arrow-left" = "arrow-left",
  "arrow-right" = "arrow-right",
  "arrow-up" = "arrow-up",
  "arrowhead-down" = "arrowhead-down",
  "arrowhead-left" = "arrowhead-left",
  "arrowhead-right" = "arrowhead-right",
  "arrowhead-up" = "arrowhead-up",
  "barrel" = "barrel",
  "calendar" = "calendar",
  "chevron-down" = "chevron-down",
  "chevron-left" = "chevron-left",
  "chevron-right" = "chevron-right",
  "chevron-up" = "chevron-up",
  "critics-rating" = "critics-rating",
  "cross" = "cross",
  "droplet" = "droplet",
  "envelope" = "envelope",
  "globe" = "globe",
  "grapes" = "grapes",
  "input-code" = "input-code",
  "location-pin" = "location-pin",
  "magnifying-glass" = "magnifying-glass",
  "more-dots" = "more-dots",
  "nfc-bottle" = "nfc-bottle",
  "nfc-case" = "nfc-case",
  "profile" = "profile",
  "qr-code" = "qr-code",
  "scales" = "scales",
  "scanner-overlay" = "scanner-overlay",
  "share" = "share",
  "speech-bubble" = "speech-bubble",
  "statements" = "statements",
  "wine-bottle-filled" = "wine-bottle-filled",
  "wine-bottle-scan" = "wine-bottle-scan",
  "wine-bottles-type" = "wine-bottles-type",
  "wine-glass" = "wine-glass",
  "wines" = "wines"
}

export const Icon: React.FC<{
  color?: string;
  size?: string | number;
  icon: Icons;
  className?: string; 
}> = props => {
  const {
    color = theme.colors.dark, size = "2.5rem", icon, className = "" 
  } = props;

  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon as string}
    />
  );
};
