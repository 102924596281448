import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { find } from "lodash";
import { Box } from "grommet";

import {
  FormError, FormRow, Select, TextInput, InputTextArea
} from "src/components/forms";
import { CreateTransactionInput, TransactionMethodType } from "src/utils/api/routes/transactions.api";
import useApiRequest from "src/utils/api/useApiRequest";

import { TransactionCashInput } from "./TransactionCashInput";

export interface TransactionFormProps {
  onSubmit: (values: CreateTransactionInput) => void;
  setCashState: Dispatch<SetStateAction<{value: number; type?: TransactionMethodType}>>;
}

export const TransactionForm: React.FC<TransactionFormProps> = ({ setCashState, onSubmit }) => {
  const [ getTransactionMethodsRes, getTransactionMethodsReq ] = useApiRequest("TRANSACTIONS:listMethods");

  const {
    register, handleSubmit, errors, setValue, watch
  } = useForm<CreateTransactionInput>();

  const methodId = watch("transactionMethodId");
  const cashValue = watch("value");

  React.useEffect(() => {
    getTransactionMethodsReq({});
  }, [ getTransactionMethodsReq ]);

  const transactionMethods = React.useMemo(() => {
    if (getTransactionMethodsRes.data) {
      return getTransactionMethodsRes.data;
    }

    return [];
  }, [ getTransactionMethodsRes.data ]);

  // the selected method
  const selectedMethod = React.useMemo(() => {
    if (methodId) {
      return find(transactionMethods, method => method.id === methodId);
    }

    return null;
  }, [ transactionMethods, methodId ]);

  /** handle change */
  const changeTransactionMethod = (value: number) => {
    setValue(
      "transactionMethodId", value, true
    );
  };

  /** register the method dropdown */
  React.useEffect(() => {
    register("transactionMethodId");
  }, [ register ]);

  React.useEffect(() => {
    setCashState({
      value: cashValue,
      type: selectedMethod?.type
    });
  }, [
    cashValue,
    setCashState,
    selectedMethod
  ]);

  return (

    <form onSubmit={handleSubmit(onSubmit)} id="transaction-form">

      <FormRow required label="Transaction Type">
        <Select
          options={transactionMethods.map(m => ({
            label: m.label,
            value: m.id
          }))}
          labelKey="label"
          valueKey="value"
          name="transactionMethodId"
          onChange={e => changeTransactionMethod(e.option.value)}
        />
        {errors.transactionMethodId && <FormError message="This field is required" />}
      </FormRow>

      <>
        <Box
          direction="row"
        >
          <Box basis="1/2" pad={{ right: "medium" }}>
            <TransactionCashInput cashValue={cashValue} method={selectedMethod} formError={errors.value} ref={register({ required: true })} setValue={setValue} disabled={!selectedMethod} />
          </Box>
          <Box basis="1/2" pad={{ left: "medium" }}>
            <FormRow required label="Invoice Number">
              <TextInput name="invoice" ref={register({ required: true })} disabled={!selectedMethod} />
              {errors.invoice && <FormError message="This field is required" />}
            </FormRow>
          </Box>

        </Box>

        <FormRow required label="Note">
          <InputTextArea name="note" ref={register({ required: true })} disabled={!selectedMethod} />
          {errors.note && <FormError message="This field is required" />}
        </FormRow>

      </>

    </form>
  );
};

TransactionForm.displayName = "TransactionForm";