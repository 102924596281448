import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import React from "react";
import { toast } from "react-toastify";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { WineForm } from "src/components/wine";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { CreateWineInput } from "src/utils/api/routes/wines.api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

interface WinePageParams {
  wineId: string;
}

export const EditWineForm: React.FC<PageProps<Record<string, unknown>, WinePageParams>> = props => {
  const [ updateWineRes, updateWineReq ] = useApiRequest("WINES:update");
  const [ getWineRes, getWineReq ] = useApiRequest("WINES:get");
  const [ wineColoursRes, wineColoursReq ] = useApiRequest("WINE-COLOURS:list");
  const [ wineGrapesRes, wineGrapesReq ] = useApiRequest("WINE-GRAPES:list");
  const [ defaultValues, setDefaultValues ] = React.useState<CreateWineInput>();

  React.useEffect(() => {
    wineColoursReq({});
  }, [ wineColoursReq ]);

  React.useEffect(() => {
    wineGrapesReq({});
  }, [ wineGrapesReq ]);

  React.useEffect(() => {
    if (wineColoursRes.errorMessage) {
      toast.error(wineColoursRes.errorMessage);
    }
  }, [ wineColoursRes ]);

  React.useEffect(() => {
    if (wineGrapesRes.errorMessage) {
      toast.error(wineGrapesRes.errorMessage);
    }
  }, [ wineGrapesRes ]);

  const onSubmit = React.useCallback((wine: CreateWineInput) => {
    const valuesToUpdate = pickBy(wine, identity);
    const wineGrapesToUpdate: CreateWineInput["grapes"] | null = !wine.grapes ? null : [];

    if (wineGrapesToUpdate) {
      wine.grapes?.map(grape => {
        wineGrapesToUpdate.push({
          blendPercent: grape.blendPercent,
          grapeId: grape.grapeId
        });
      });
    }

    if (props.match?.params?.wineId) {
      updateWineReq({
        data: {
          ...valuesToUpdate,
          id: parseInt(props.match.params.wineId, 10),
          grapes: wineGrapesToUpdate
        },
        pathParams: { wineId: props.match.params.wineId }
      });
    }
  }, [ props.match, updateWineReq ]);

  React.useEffect(() => {
    if (updateWineRes.data) {
      const { name, id } = updateWineRes.data;

      toast.success(`${name} was updated successfully. Click here to view.`, {
        autoClose: false,
        onClick: () => props.history.push(`/admin/wine/${id}`)
      });
    }

    if (updateWineRes.errorMessage) {
      toast.error(updateWineRes.errorMessage);
    }
  }, [
    props.history,
    updateWineRes.data,
    updateWineRes.errorMessage
  ]);

  React.useEffect(() => {
    if (props.match?.params?.wineId) {
      getWineReq({ pathParams: { wineId: props.match.params.wineId } });
    }
  }, [ getWineReq, props.match ]);

  React.useEffect(() => {
    if (getWineRes.errorMessage) {
      toast.error(getWineRes.errorMessage);
    }

    if (getWineRes.data) {
      setDefaultValues({
        ...getWineRes.data,
        grapes: getWineRes.data.grapes?.map(grape => ({
          grapeId: grape.wineGrape.grapeId,
          blendPercent: grape.wineGrape.blendPercent,
          id: grape.id,
          name: grape.name,
          label: grape.label
        }))
      });
    }
  }, [ getWineRes ]);

  return (
    <AdminLayout>
      <PageHeader title="Edit Wine" backLink={() => props.history.goBack()} />
      <Card pad="small" maxWidth="70rem">
        {(getWineRes.loading || wineColoursRes.loading || wineGrapesRes.loading) && <LoadingSpinner />}
        {defaultValues && wineColoursRes.data && wineGrapesRes.data && (
          <WineForm isLoading={getWineRes.loading} onSubmit={onSubmit} defaultValues={defaultValues} wineColours={wineColoursRes.data} grapes={wineGrapesRes.data} />
        )}
      </Card>
    </AdminLayout>
  );
};
