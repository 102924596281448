import React from "react";
import { Column, Row } from "react-table";
import { toast } from "react-toastify";

import { SelectCaseStatusColumnFilter } from "src/components/case";
import { Table } from "src/components/shared/table";
import { useTableFetch } from "src/hooks/useTableFetch";
import { Case } from "src/utils/api/routes/cases.api";
import { formatCurrency } from "src/utils/currency";

interface VintageCasesTab {
  vintageId: number;
  onChangePage: (id: number) => void;
}

type VintageCasesTable = Pick<Case, "id" | "name" | "userId" | "lwin" | "bookPrice" | "status" | "location" | "bottleSize" | "packSize">;

const columns: Array<Column<VintageCasesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true
  },
  {
    Header: "Name",
    accessor: "name",
    disableFilters: true
  },
  {
    Header: "Bottle Size",
    accessor: "bottleSize"
  },
  {
    Header: "Pack Size",
    accessor: "packSize"
  },
  {
    Header: "LWIN",
    accessor: "lwin",
    disableFilters: true
  },
  {
    Header: "User Id",
    accessor: "userId"
  },
  {
    Header: "Book Price",
    accessor: "bookPrice",
    Cell: cell => formatCurrency(cell.value)
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectCaseStatusColumnFilter
  },
  {
    Header: "Location",
    accessor: "location"
  }
];

const hiddenColumns = [ "id" ];

export const VintageCasesTab: React.FC<VintageCasesTab> = props => {
  const [ data, setData ] = React.useState<VintageCasesTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const vintageId = React.useMemo(() => props.vintageId, [ props.vintageId ]);

  const [ listCasesByVintageRes, listCasesByVintageReq ] = useTableFetch<"VINTAGES:listCases", VintageCasesTable>(
    "VINTAGES:listCases", 
    { defaultSort: [ [ "name", "ASC" ] ] }, 
    { 
      params: {},
      pathParams: { vintageId: vintageId.toString() } 
    });

  React.useEffect(() => {
    if (listCasesByVintageRes.data && listCasesByVintageRes.data.items) {
      setData(listCasesByVintageRes.data.items);
      setPageCount(Math.ceil(listCasesByVintageRes.data.total / listCasesByVintageRes.data.limit));
      setLoading(false);
    }

    if (listCasesByVintageRes.errorMessage) {
      toast.error(listCasesByVintageRes.errorMessage);
    }
  }, [ listCasesByVintageRes.data, listCasesByVintageRes.errorMessage ]);

  const handleOnClick = React.useCallback((row: Row<VintageCasesTable>) => {
    props.onChangePage(row.original.id);
  }, [ props ]);

  return (
    <>
      <Table<VintageCasesTable>
        name="vintageCasesTable"
        title={`${listCasesByVintageRes.data?.total || 0} cases for this vintage`}
        columns={columns}
        data={data}
        onClick={handleOnClick}
        loading={loading}
        fetchData={listCasesByVintageReq}
        useResizeColumns
        pageCount={pageCount}
        defaultHiddenColumns={hiddenColumns}
        usePagination
        useSortBy
        useFilters
        useHideColumns
      />
    </>
  );
};
