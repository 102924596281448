import React from "react";
import { Column, Row } from "react-table";
import { toast } from "react-toastify";

import { Table } from "src/components/shared/table";
import { Wine } from "src/utils/api/routes/wines.api";
import { Button } from "src/components/shared/button";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { PageHeader, Card } from "src/components/shared/layout";
import { PageProps } from "src/pages/Router";
import { useTableFetch } from "src/hooks/useTableFetch";

type WinesTable = Pick<Wine, "id" | "name" | "country" | "region" | "lwin" | "displayLocation" | "displayWineLine1" | "displayWineLine2">;

const columns: Array<Column<WinesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true,
    width: 30
  },
  {
    Header: "LWIN",
    accessor: "lwin",
    width: 80
  },
  {
    Header: "Display Name",
    accessor: "displayWineLine1",
    disableFilters: true,
    Cell: ({ row }) => {
      return `${row.original.displayWineLine1}, ${row.original.displayWineLine2}`;
    }
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Country",
    accessor: "country"
  },
  {
    Header: "Region",
    accessor: "region"
  },
  {
    Header: "Display Location",
    accessor: "displayLocation",
    disableFilters: true
  }
];

const hiddenColumns = [ "id", "name" ];

const WinesTable: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ data, setData ] = React.useState<WinesTable[]>([]);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ winesRes, fetch ] = useTableFetch<"WINES:list", WinesTable>("WINES:list", { defaultSort: [ [ "displayWineLine1", "ASC" ] ] });

  React.useEffect(() => {
    if (winesRes.data && winesRes.data.items) {
      setData(winesRes.data.items);
      setPageCount(Math.ceil(winesRes.data.total / winesRes.data.limit));
    }

    if (winesRes.errorMessage) {
      toast.error(winesRes.errorMessage);
    }
  }, [ winesRes.data, winesRes.errorMessage ]);

  const handleOnClick = React.useCallback((row: Row<WinesTable>) => {
    props.history.push(`/admin/wine/${row.original.id}`);
  }, [ props.history ]);

  return (
    <AdminLayout>
      <PageHeader 
        title="Wines" 
        backLink={() => props.history.push("/admin/dashboard")} 
        backLinkText="Back to dashboard"
        callToAction={<Button label="New" onClick={() => props.history.push("/admin/wine/new")} />} 
      />
      <Card>
        <Table<WinesTable>
          name="winesTable"
          title={`${winesRes.data?.total || 0} Wines`}
          columns={columns}
          data={data}
          onClick={handleOnClick}
          loading={winesRes.loading}
          fetchData={fetch}
          pageCount={pageCount}
          defaultHiddenColumns={hiddenColumns}
          featuredFilter="name"
          useResizeColumns
          useSortBy
          usePagination
          useFilters
          useHideColumns
        />
      </Card>
    </AdminLayout>
  );
};

export default WinesTable;
