import * as React from "react";
import { Layer, Box } from "grommet";

import { Text } from "src/components/shared/text";
import { Button, ButtonProps as ButtonComponentProps } from "src/components/shared/button";

interface ModalProps {
  title: string;
  description: JSX.Element | string;
  onClose: () => void;
  actions?: {
    confirm: ButtonProps;
    reject?: ButtonProps;
  };
  isLoading?: boolean;
}

type ButtonProps = Pick<ButtonComponentProps, "label" | "onClick">;

/**
 * Modal
 */
export const Modal: React.FC<ModalProps> = props => {
  return (
    <Layer
      position="center"
      onClickOutside={props.onClose}
      onEsc={props.onClose}
    >
      <Box
        direction="row"
        align="center"
        as="header"
        justify="between"
        pad="medium"
        border="bottom"
      >
        <Text bold as="h6">
          {props.title}
        </Text>
      </Box>
      <Box pad="medium" gap="small">
        {props.description}
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{
            top: "medium",
            bottom: "small"
          }}
        >
          {props.actions && (
            <>
              <Button
                label={props.actions.confirm.label}
                onClick={props.actions.confirm.onClick}
                primary
                backgroundColor="error"
              />
              {props.actions.reject && (
                <Button
                  label={props.actions.reject.label}
                  onClick={props.actions.reject.onClick}
                  backgroundColor="fadedDarker"
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Layer>
  );
};