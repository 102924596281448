import React from "react";
import styled from "styled-components";

/**
 * Info panel
 *
 * Usage: InfoPanel > InfoColumn > InfoBox
 */
export const InfoPanel: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <InfoPanelStyled {...rest}>
      {children}
    </InfoPanelStyled>
  );
};

const InfoPanelStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;
  width: 100%;
`;
