import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import Text from "../text/Text";

import { PageConfines } from "./PageConfines";
import { ProfileMenu } from "./ProfileMenu";

export const CollectorLayout: React.FC = props => {
  const pages = [
    {
      link: "/collector/wine-portfolio",
      name: "Wine Portfolio"
    },
    {
      link: "/collector/cash-transactions",
      name: "Cash Transactions"
    }

  ];

  return (
    <Wrapper>
      <NavWrapper>
        <Header>
          <Navigation>
            <img src="/1275-logo.svg" width="90px" />
            {pages.map(page => {
              return (
                <StyledNavLink activeClassName="active" key={page.link} to={page.link}>
                  <Text as="small">
                    {page.name}
                  </Text>
                </StyledNavLink>
              );
            })}
          </Navigation>
          <ProfileMenu />
        </Header>
      </NavWrapper>
      <PageWrapper>
        {props.children}
      </PageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

`;

const NavWrapper = styled.div`
  background: ${({ theme }) => theme.colors.dark};
`;

const Header = styled(PageConfines)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 2rem;
  }

`;

const StyledNavLink = styled(NavLink)`
  padding: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.light};
  position: relative;
  :after {
    content: '';
    position: absolute;
    height: 2px;
    width: 30px;
    background: transparent;
    transition: all ease-in-out 0.2s;
    bottom: 0;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.active {
    color: ${({ theme }) => theme.colors.lighter};
    :after {
      background: ${({ theme }) => theme.colors.lighter};
    }
  }
`;

const PageWrapper = styled.main`
  position: relative;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.appBackground};
`;