import React from "react";
import formatDate from "date-fns/format";

import { User } from "src/utils/api/routes/users.api";
import { useApiRequest } from "src/utils/api";
import { AxesProps, Graph } from "src/components/shared/graph";
import { DatedGraphFooter, DatedChartLabel } from "src/components/shared/graph/DatedGraphFooter";
import { VariationPriceBox } from "src/components/shared/price-graph/VariationPriceBox";
import { getPercentageChange } from "src/utils/currency/calculatePercentChange";

interface CollectorPortfolioValueGraphProps {
  user: User;
}

type HistoricalPrice = {
  price: number;
  date: string;
};

const getAxes = (currentLabel: DatedChartLabel): AxesProps<HistoricalPrice> => {
  return {
    yAxis: {
      dataKey: "price",
      unit: " €",
      hide: true,
      interval: "preserveStartEnd",
      domain: [ "dataMin", "dataMax" ],
      allowDataOverflow: false,
      type: "number",
      tickCount: 6
    },
    xAxis: {
      dataKey: "date",
      hide: true,
      tickFormatter: v => currentLabel === "1M" || currentLabel === "6M" ? formatDate(new Date(v), "dd MMM") : formatDate(new Date(v), "dd MMM yyyy"),
      interval: "preserveStartEnd",
      tick: { fontSize: 14 }
    }
  };
};

export const CollectorPortfolioValueGraph: React.FC<CollectorPortfolioValueGraphProps> = ({ user }) => {
  const defaultStartDate = user.createdAt;
  const [ prices, setPrices ] = React.useState<HistoricalPrice[]>([]);
  const [ currentGraphStartDate, setStartGraphDate ] = React.useState<Date>(defaultStartDate);
  const [ historicalStartDate, setHistoricalStartDate ] = React.useState<Date>(defaultStartDate);
  const [ getPortfolioHistoricalResponse, getPortfolioHistoricalRequest ] = useApiRequest("PRICES:getPortfolioHistorical");
  const [ getPortfolioFirstValueResponse, getPortfolioFirstValueRequest ] = useApiRequest("PRICES:getPortfolioHistorical");

  // Get the first stored data data point.
  // This should be loaded when the component is mounted.
  React.useEffect(() => {
    getPortfolioFirstValueRequest({
      pathParams: { userId: user.id.toString() },
      params: {
        limit: 1,
        offset: 0,
        order: [ [ "valueDate", "ASC" ] ]
      }
    });
  }, [ getPortfolioFirstValueRequest, user.id ]);

  React.useEffect(() => {
    if (getPortfolioFirstValueResponse.data && getPortfolioFirstValueResponse.data.items.length) {
      const firstValue = getPortfolioFirstValueResponse.data.items[ 0 ];

      setHistoricalStartDate(new Date(firstValue.valueDate));
      setStartGraphDate(new Date(firstValue.valueDate));
    }
  }, [ getPortfolioFirstValueResponse ]);

  // Get the graph data points.
  React.useEffect(() => {
    getPortfolioHistoricalRequest({
      pathParams: { userId: user.id.toString() },
      params: { filters: { valueDate: { $gte: currentGraphStartDate } } }
    });
  }, [
    getPortfolioHistoricalRequest,
    user.id,
    currentGraphStartDate
  ]);

  React.useEffect(() => {
    if (getPortfolioHistoricalResponse.data) {
      const singlePortfolioValues = getPortfolioHistoricalResponse.data.items;

      setPrices(singlePortfolioValues.map(dataPoint => {
        return {
          date: dataPoint.valueDate.toString(),
          price: dataPoint.value
        };
      }));
    }
  }, [ getPortfolioHistoricalResponse ]);

  const [ selectedDateLabel, setSelectedDateLabel ] = React.useState<DatedChartLabel>(DatedChartLabel.all);
  let variationPercentage = 0;

  if (prices.length) {
    variationPercentage = getPercentageChange(user.bookValue, prices[ prices.length - 1 ].price);
  }

  return (
    <Graph<HistoricalPrice>
      isLoading={getPortfolioFirstValueResponse.loading || getPortfolioHistoricalResponse.loading}
      axes={getAxes(selectedDateLabel)}
      height={200}
      data={prices}
      toolTipLabelFormatter={v => formatDate(new Date(v), "MMM yyyy")}
      GraphFooter={(
        <DatedGraphFooter
          historicalStartDate={historicalStartDate}
          onSelectedLabelChange={label => setSelectedDateLabel(label)}
          onSelectedDateChange={date => setStartGraphDate(date)}
          excludeLabelOptions={[ DatedChartLabel.day ]}
        >
          <VariationPriceBox variationPercentage={variationPercentage} />
        </DatedGraphFooter>
      )}
    />
  );
};
