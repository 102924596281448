import { ApiRouteDefinition } from "../useApiRequest";
import {
  PickParams, StringResponse, PaginatedQueryRequest, PaginatedResponse, RecordTimestamps, JSONApiErrorJSON
} from "../api";

import { Case } from "./cases.api";
import { Role } from "./roles.api";

const UserRoutes = {
  /** Get User by id */
  "USERS:get": {
    path: "/users/:userId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<User, null, PickParams<"userId">>,

  /** Get User by id */
  "USERS:getMe": {
    path: "/token/me",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<User, null, null>,

  /** list user cases */
  "USERS:list": {
    path: "/users",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<User>, null, null, PaginatedQueryRequest<Partial<User>>>,

  /** Create a User */
  "USERS:create": {
    path: "/users",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
      User,
      Omit<CreateUserInput, "cognitoId" | "id">,
      null
    >,

  /** Partial update a User */
  "USERS:update": {
    path: "/users/:userId",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<
    User,
    Omit<Partial<User>, "cognitoId" | "id">,
    PickParams<"userId">
  >,

  /** delete a User */
  "USERS:delete": {
    path: "/users/:userId",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"userId">>,

  /** invite a User */
  "USERS:invite": {
    path: "/users/:userId/invite",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<User, null, PickParams<"userId">>,

  /** list user cases */
  "USERS:cases": {
    path: "/users/:userId/cases",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Case>, null, PickParams<"userId">, PaginatedQueryRequest<Partial<Case>>>,

  /** list user documents */
  "USERS:documents": {
    path: "/users/:userId/documents",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<UserDocument>, null, PickParams<"userId">, PaginatedQueryRequest<Partial<Pick<UserDocument, "name" | "level">>>>,

  "USERS:upload-user-document": {
    path: "/users/:userId/documents/upload",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<UploadDocumentResponse, CreateDocumentInput, PickParams<"userId">>,

  "USERS:upload-document": {
    path: "/users/documents/upload",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<UploadDocumentResponse, CreateDocumentInput, PickParams<"userId">>,

  "USERS:update-document-status": {
    path: "/users/documents/:documentId",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<UserDocument, Pick<UserDocument, "status">, PickParams<"documentId">>,

  "USERS:bulk-delete-documents": {
    path: "/users/documents/bulk-delete",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<BulkDeleteDocumentResponse, {ids: number[]}>

};

interface BulkDeleteDocumentResponse {
  errors: Array<{error: JSONApiErrorJSON; id: number}>;
}

interface UploadDocumentResponse {
  url: string;
  document: UserDocument;
}

export default UserRoutes;

export interface User extends RecordTimestamps {
  id: number;
  name: string;
  email: string;
  phone: string;
  status: string;
  roles: Role[];
  externalId?: string;
  cashBalance?: number;
  cognitoId?: string;
  bookValue: number;
  bookValueUpdatedAt: Date;
  portfolioValue: number;
  portfolioValueUpdatedAt: Date;
  identityId?: string | null;
  storageFeeCollectionBasis?: UserStorageFeeCollectionBasis | null;
  storageFeeRates?: UserStorageFeeRate[];
}
export interface UserStorageFeeRate {
  id: number;
  userId: number;
  percentage: number;
  rateStart: number;
  rateEnd: number | null;
}
export type CreateUserStorageFeeRate = Omit<UserStorageFeeRate, "id"| "userId" | "percentage" | "rateStart" | "rateEnd"> & {
  percentage: number | null;
  rateStart: number | null;
  rateEnd: number | null;
};

export type CreateUserInput = Pick<User, "name" | "email" | "roles" | "email" | "phone" | "externalId" | "status" | "cashBalance" | "storageFeeCollectionBasis"> & {
  storageFeeRates?: CreateUserStorageFeeRate[];
};

export type UpdateUserInput = Omit<User, "id" | "storageFeeRates"> & {
  storageFeeRates: CreateUserStorageFeeRate[] | UserStorageFeeRate[];
};

export interface CreateDocumentInput extends Pick<UserDocument, "name" | "description" | "level"> {
  pathPrefix?: string;
}

export interface UserDocument extends RecordTimestamps {
  id: number;
  userId?: number;
  ownerId: number;
  name: string;
  description?: string | null;
  s3Key: string;
  s3Bucket: string;
  status: DocumentStatus;
  level: S3Level;
  createdByUser?: User;
  user?: User;
}

export enum DocumentStatus {
  not_uploaded = "not_uploaded",
  uploaded = "uploaded",
  failed = "failed"
}

export enum UserStorageFeeCollectionBasis {
  BOOK_VALUE = "book_value",
  MARKET_VALUE = "market_value",
}

export enum S3Level {
  public = "public", // anyone can get or put to this level
  private = "private", // private files, only readable and writable by the user
  protected = "protected", // writable only by current user but readable by all
  admin = "admin", // writable and readable only by admin users
  global = "global" // writable by admins, readable by all
}
