import styled from "styled-components";
import React from "react";

import { Card } from "./Card";

export const PublicLayout: React.FC = props => {
  return (
    <Wrapper>
      <PageWrapper>
        <Card pad="medium" maxWidth="600px">
          {props.children}
        </Card>
      </PageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const PageWrapper = styled.main`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 4rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.appBackground};
`;
