import { ApiRouteDefinition } from "../useApiRequest";
import { PickParams, PaginatedResponse } from "..";

const LabelRoutes = {
  /** list all roles */
  "LABELS:list-scanner-unassigned": {
    path: "/labels/scanner/:scannerId/unassigned",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Label>, null, PickParams<"scannerId">>,
  "LABELS:remove-scanner-unassigned": {
    path: "/labels/scanner/:scannerId/unassigned",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<Label[], null, PickParams<"scannerId">>,
  "LABELS:remove-by-id": {
    path: "/labels/:labelId",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<RemoveLabelResponse, null, PickParams<"labelId">>,
  "LABELS:initialise": {
    path: "/labels/initialise",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<InitialiseLabelResponse, InitialiseLabelParams>,
  "LABELS:check": {
    path: "/labels/admin/code/:certificationReference",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Label & {caseId?: number; caseName?: string}, null, PickParams<"certificationReference">>
};

export default LabelRoutes;

interface InitialiseLabelParams {
  quantity: number;
}
interface InitialiseLabelResponse {
  message: string;
  s3Key: string;
}
interface RemoveLabelResponse {
  labelId: number;
  message: string;
  count: number;
}
export interface Label {
  id: number;
  scannerId: number;
  status: LabelStatus;
  isCase: boolean | null;
  nfcCode: string | null;
  verifiedCode: string | null; 
  certificationReference: string | null;
  errors?: Record<string, unknown>;
  entityType?: LabelEntityType | null;
  entityId?: number | null;
  batchRef?: number;
}

export enum LabelEntityType {
  BOTTLE = "bottle",
  CASE = "case"
}

export enum LabelStatus {
  valid = "valid",
  pending = "pending",
  error = "error"
}