import React from "react";
import { HeaderProps } from "react-table";

import { CaseStatus } from "../../utils/api/routes/cases.api";
import { Select } from "../forms/Select";

export function SelectCaseStatusColumnFilter<T extends Record<string, unknown>>({ 
  column: {
    filterValue, setFilter, id 
  } 
}: HeaderProps<T>): React.ReactElement {
  const options = Object.keys(CaseStatus);

  options.push("All");

  return (
    <Select
      value={filterValue}
      name={id}
      placeholder="Select status"
      onChange={({ value }) => setFilter(value)}
      options={options}
    />
  );
}