import { ApiRouteDefinition } from "../useApiRequest";
import {
  PickParams, StatusResponse, PaginatedQueryRequest, PaginatedResponse, BulkActionResponse, RecordTimestamps
} from "../api";

import { Bottle } from "./bottles.api";
import { User } from "./users.api";
import { Estate } from "./estates.api";
import { Wine } from "./wines.api";
import { Vintage } from "./vintages.api";
import { Label } from "./labels.api";

const CaseRoutes = {
  /** Get Case by id */
  "CASES:get": {
    path: "/cases/:caseId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Case, null, PickParams<"caseId">>,

  /** list cases */
  "CASES:list": {
    path: "/cases",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Case>, null, null, PaginatedQueryRequest<Partial<Case>>>,

  /** Partial update a Case */
  "CASES:update": {
    path: "/cases/:caseId",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<
    Case,
    Partial<Case>,
    PickParams<"caseId">
  >,

  /** Partially update many cases */
  "CASES:bulk-update": {
    path: "/cases/",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<
    BulkActionResponse,
    BulkEditCaseInput,
    null
  >,

  /** Partially update many cases */
  "CASES:bulk-create": {
    path: "/cases/create-from-labels",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    BulkActionResponse,
    BulkCreateCasesFromLabelsInput,
    null
  >,

  /** delete a Case */
  "CASES:delete": {
    path: "/cases/:caseId",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StatusResponse, null, PickParams<"caseId">>

};

export default CaseRoutes;

export enum CaseStatus {
  allocated = "allocated",
  in_storage = "in_storage",
  in_transit = "in_transit",
  sold = "sold",
  delivered = "delivered",
}

export const statusLabels: Record<CaseStatus, string> = {
  [ CaseStatus.allocated ]: "allocated",
  [ CaseStatus.in_storage ]: "in storage",
  [ CaseStatus.in_transit ]: "in transit",
  [ CaseStatus.delivered ]: "delivered",
  [ CaseStatus.sold ]: "sold"
};

export type WineEstate = Pick<Estate, "id" | "name" | "country" | "region">;
export type VintageWine = Pick<Wine, "id" | "name" | "lwin"> & {estate: WineEstate};
export type BottleVintage = Pick<Vintage, "id" | "name" | "lwin" | "wineId"> & {wine: VintageWine};
export type CaseBottles = Pick<Bottle, "id" | "name" | "lwin" | "qualityStandard" | "bottleSize" | "currentPrice" | "currentPriceUpdatedAt" | "status" | "label"> & {vintage: BottleVintage};

export interface Case extends RecordTimestamps {
  id: number;
  name: string;
  packSize: string;
  format: string;
  bottleSize: string;
  orderReference?: string;
  lwin: string | null;
  userId: number | null;
  bookPrice?: number;
  status: CaseStatus;
  transferredAt?: Date;
  location: string;
  user?: Pick<User, "id" | "name">;
  currentValue?: number;
  currentValueUpdatedAt?: Date;
  labelId: number;
  bottles?: CaseBottles[];
  sensorGroupId: number | null;
  label?: Label;
}

export type CreateCaseInput = Omit<Case, "id">;

export type BulkEditCaseInput = {ids: number[]} & Pick<Case, "location" | "status" | "sensorGroupId">;

export type AssignCaseToUserInput = Pick<Case, "userId" | "bookPrice">;

export type BulkCreateCasesFromLabelsInput = {cases: AssignedCaseLabel[]};

interface AssignedCaseLabel {
  labelId: number;
  orderReference: string;
  name: string;
  bottleSize: string;
  packSize: string;
  lwin: string | null;
  bottles: AssignedBottleLabel[];
}
interface AssignedBottleLabel {
  labelId: number;
  name: string;
  bottleSize: string;
  lwin: string;
  vintageId: number;
  label?: Label;
}