/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import axios, { AxiosRequestConfig } from "axios";
import { compile } from "path-to-regexp";
import { Auth } from "aws-amplify";

import { createLogger } from "src/utils/logger";

import {
  UserRoutes,
  WineRoutes,
  VintageRoutes,
  CaseRoutes,
  EstateRoutes,
  TransactionRoutes,
  RoleRoutes,
  WineColourRoutes,
  GrapeRoutes,
  CaseHistoryRoutes,
  LabelRoutes,
  PriceRoutes,
  SensorRoutes,
  BottleRoutes,
  StorageFeesRoutes
} from "./routes";

const logger = createLogger("API");

const ApiRoutes = {
  ...CaseRoutes,
  ...BottleRoutes,
  ...EstateRoutes,
  ...UserRoutes,
  ...WineRoutes,
  ...VintageRoutes,
  ...TransactionRoutes,
  ...RoleRoutes,
  ...WineColourRoutes,
  ...GrapeRoutes,
  ...CaseHistoryRoutes,
  ...LabelRoutes,
  ...SensorRoutes,
  ...PriceRoutes,
  ...StorageFeesRoutes
};

const baseUrl = process.env.REACT_APP_BASE_URL;
const defaultHeaders = { "Content-Type": "application/vnd.api+json" };
const UnknownErrorMessage = "An Unknown Error occurred";

interface ResponseState<N extends RequestName> {
  data: RoutesType[N]["_"] | null;
  loading: boolean;
  errorMessage: string | null;
}
export type UseApiRequestType<N extends RequestName> = [
  ResponseState<N>,
  (requestInfo: ConditionalRequestData<RoutesType[N]>) => void
];

const useApiRequest = <N extends RequestName>(requestName: N) => {
  const selectedRoute = ApiRoutes[ requestName ];

  // Interpolate the path parameters to the url
  const getUrlPath = React.useCallback((path: string, requestInfo: ConditionalRequestData<RoutesType[N]>) => {
    if (requestInfo.pathParams) {
      const toPath = compile(path);

      return toPath(requestInfo.pathParams);
    }

    return path;
  },
  []);

  return useApiFetch<N>(config => {
    if (!baseUrl) {
      throw new Error("No BASE_URL set in environment");
    }

    return {
      url: `${baseUrl}${getUrlPath(selectedRoute.path, config)}`,
      authenticate: selectedRoute.authenticate,
      method: selectedRoute.method,
      ...config
    };
  }, requestName);
};

type CustomAxiosConfig = AxiosRequestConfig & { authenticate: boolean };

const useApiFetch = <N extends RequestName>(
  fn: (config: ConditionalRequestData<RoutesType[N]>) => CustomAxiosConfig,
  requestName: N
): UseApiRequestType<N> => {
  const [ config, setConfig ] = React.useState<CustomAxiosConfig | null>(null);

  const [ responseState, setResponseState ] = React.useState<ResponseState<N>>({
    loading: false,
    errorMessage: null,
    data: null
  });

  const handleSuccessResponse = React.useCallback(response => {
    // success response
    logger.debug(`API:${requestName}:SUCCESS`, response.data);

    if (response.data) {
      if (typeof response.data === "string") {
        setResponseState(currentState => ({
          ...currentState,
          loading: false,
          errorMessage: null,
          data: { message: response.data }
        }));
      } else if (typeof response.data === "object") {
        const data = response.data.data || response.data;

        setResponseState(currentState => ({
          ...currentState,
          loading: false,
          errorMessage: null,
          data
        }));
      }
    } else {
      setResponseState(currentState => ({
        ...currentState,
        loading: false,
        errorMessage: null,
        data: { message: "Success!" }
      }));
    }
  },
  [ requestName ]);

  const handleErrorResponse = React.useCallback(error => {
    logger.debug(`API:${requestName}:FAILURE`, error);

    let errMessage = UnknownErrorMessage;

    // error response
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        errMessage = error.response.data.message;
      } else if (error.response.data.error && error.response.data.error.message) {
        errMessage = error.response.data.error.message;
      } else if (typeof error.response.data === "string") {
        errMessage = error.response.data;
      } else if (error.response.data.errors && error.response.data.errors[ 0 ] && error.response.data.errors[ 0 ].details) {
        errMessage = error.response.data.errors[ 0 ].details;
      }
    }

    setResponseState({
      loading: false,
      errorMessage: errMessage,
      data: null
    });
  },
  [ requestName ]);

  React.useEffect(() => {
    if (!config) {
      return;
    }

    logger.debug(`API:${requestName}:START`, config);

    setResponseState({
      loading: true,
      errorMessage: null,
      data: null
    });

    try {
      if (config.authenticate) {
        Auth.currentSession().then(authState => {
          if (!authState) {
            setResponseState({
              loading: false,
              errorMessage: "User is not authenticated",
              data: null
            });

            return;
          }

          axios({
            ...config,
            headers: {
              ...defaultHeaders,
              ...(config.headers || {}),
              Authorization: `Bearer ${authState.getIdToken().getJwtToken()}`
            }
          })
            .then(handleSuccessResponse)
            .catch(handleErrorResponse);
        }).catch(err => logger.error(err, config));
      } else {
        axios({
          ...config,
          headers: {
            ...defaultHeaders,
            ...(config.headers || {})
          }
        })
          .then(handleSuccessResponse)
          .catch(handleErrorResponse);
      }
    } catch (error) {
      logger.error(
        `API:${requestName}:FAILURE`, error, config
      );

      setResponseState({
        loading: false,
        errorMessage: "Unknown error occurred",
        data: null
      });
    }
  }, [
    config,
    handleErrorResponse,
    handleSuccessResponse,
    requestName
  ]);

  const triggerRequest = React.useCallback((conf: ConditionalRequestData<RoutesType[N]>) => setConfig(fn(conf)),
    // avoid recursive loop! - exhaustive-deps disabled for file
    []);

  return [ responseState, triggerRequest ];
};

// Api route definition gernic typing for Data and Params
export interface ApiRouteDefinition<

  Response extends Record<string, any>,
  Data = null,
  Params extends Record<string, string | number> | null = null,
  Query = null
> extends Pick<AxiosRequestConfig, "headers"> {
  path: string;
  method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH" | "PURGE";
  authenticate: boolean;
  pathParams: Params extends null ? never : Params;
  data: Data extends null ? never : Data;
  params: Query extends null ? never : Query;
  // placeholder to type the response
  _: Response;
}

// Allows pathParams,data and params to be excluded
export type ConditionalRequestData<
  Request extends ApiRouteDefinition<

    Record<string, any>,
    unknown,
    Record<string, string | number> | null,
    unknown
  >
> = (Request["data"] extends never
  ? { data?: never }
  : { data: Request["data"] }) &
  (Request["pathParams"] extends never
    ? { pathParams?: never }
    : { pathParams: Request["pathParams"] }) &
  (Request["params"] extends never
    ? { params?: never }
    : { params: Request["params"] }) & { headers?: Request["headers"] };

export type RoutesType = typeof ApiRoutes;
export type RequestName = keyof RoutesType;

export default useApiRequest;
