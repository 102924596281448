import { Column } from "react-table";

import { CaseBottles } from "src/utils/api/routes/cases.api";

export type CaseBottlesTable = Pick<CaseBottles, "id" | "name" | "bottleSize" | "qualityStandard" | "lwin" | "status"> ;

export const bottleTableColumns: Array<Column<CaseBottlesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Bottle Size",
    accessor: "bottleSize"
  },
  {
    Header: "Quality Standard",
    accessor: "qualityStandard"
  },
  {
    Header: "LWIN",
    accessor: "lwin"
  },
  {
    Header: "Status",
    accessor: "status"
  }
  
];