import { Auth } from "aws-amplify";
import * as React from "react";
import { CognitoUser } from "@aws-amplify/auth";
import createDebug from "debug";

import { AuthContext } from "../AuthContext";

const debug = createDebug("AUTH:SIGNUP");

interface State {
  error: null | string;
  loggingIn: boolean;
  resetPassword: boolean;
  cognitoUser: null | CognitoUser;
}

const useLogin = () => {
  const { checkLoggedIn } = React.useContext(AuthContext);

  const [ loginState, setLoginState ] = React.useState<State>({
    error: null,
    loggingIn: false,
    resetPassword: false,
    cognitoUser: null
  });

  /**
   * Log a user in
   *
   */
  const login = React.useCallback(async (username: string, password: string) => {
    try {
      // no spaces and lowercase usernames only
      username = username?.replace(/\s+/g, "").toLowerCase();
      password = password?.replace(/\s+/g, "");

      setLoginState(currentState => ({
        ...currentState,
        loggingIn: true,
        error: null
      }));
      const user = await Auth.signIn(username, password);

      if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return setLoginState({
          resetPassword: true,
          loggingIn: false,
          error: null,
          cognitoUser: user
        });
      }

      await checkLoggedIn();

      return true;
    } catch (error) {
      debug("SIGN IN FAILED:", error);

      setLoginState({
        ...loginState,
        loggingIn: false,
        error: `Could not log in - ${error.message ||
            "please try again later"}`
      });

      return null;
    }
  },
  [ checkLoggedIn, loginState ]);

  const confirmNewPassword = React.useCallback(async (email: string, password: string) => {
    if (loginState.cognitoUser) {
      try {
        await Auth.completeNewPassword(
          loginState.cognitoUser, password, {}
        );
        login(email, password);
      } catch (error) {
        setLoginState({
          ...loginState,
          loggingIn: false,
          error: `Could not reset password - ${error.message ||
              "please try again later"}`
        });
      }
    }
  }, [ login, loginState ]);

  return {
    login,
    loginState,
    confirmNewPassword
  };
};

export default useLogin;