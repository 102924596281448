import { Layer } from "grommet";
import React from "react";
import { toast } from "react-toastify";

import { Card } from "src/components/shared/layout";
import { VintageForm } from "src/components/vintage";
import { useApiRequest } from "src/utils/api";
import { CreateVintageInput, Vintage } from "src/utils/api/routes/vintages.api";

interface NewVintageFormProps {
  wineId: number;
  lwin7: string;
  onCreate: (newVintage: Vintage) => void;
  onClose: () => void;
}

export const CreateVintageModal: React.FC<NewVintageFormProps> = props => {
  const [ createVintageRes, createVintageReq ] = useApiRequest("VINTAGES:create");

  // On form submission
  const onSubmit = React.useCallback((vintage: CreateVintageInput) => {
    const data: CreateVintageInput = {
      wineId: props.wineId,
      lwin: `${props.lwin7}${vintage.year}`,
      year: vintage.year
    };

    createVintageReq({ data });
  }, [ createVintageReq, props ]);

  // On createVintageRes change
  React.useEffect(() => {
    if (createVintageRes.data) {
      props.onCreate(createVintageRes.data);
      props.onClose();
    }

    if (createVintageRes.errorMessage) {
      toast.error(createVintageRes.errorMessage);
    }
  }, [ createVintageRes, props ]);

  return (
    <Layer
      position="center"
      onClickOutside={props.onClose}
      onEsc={props.onClose}
    >    
      <Card pad="small" maxWidth="70rem">
        <VintageForm
          lwin7={props.lwin7}
          isLoading={createVintageRes.loading}
          onSubmit={onSubmit}
        />
      </Card>

    </Layer>
  );
};
