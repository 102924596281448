import * as React from "react";
import { SyncLoader } from "react-spinners";
import { LoaderSizeMarginProps } from "react-spinners/interfaces";
import styled, { css, DefaultTheme } from "styled-components";

interface LoadingSpinnerProps extends React.HTMLAttributes<HTMLDivElement>, Pick<LoaderSizeMarginProps, "size" | "color"> {
  inline?: boolean;
  color?: keyof DefaultTheme["colors"];
}

interface LoadingSpinnerWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  inline: boolean;
  size: number;
}

/**
 * Loading spinner
 */
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = props => {
  const {
    inline, size, color, ...rest
  } = props;

  const parsedSize = size ? (typeof size === "string" ? parseInt(size) : size) : 10;

  return (
    <LoadingSpinnerWrapper inline={inline || false} size={parsedSize} {...rest}>
      <Spinner size={parsedSize} color={color} />
    </LoadingSpinnerWrapper>
  );
};

/**
 * Handle loading spinner wrapper css depending whether "inline" is requested
 */
const handleLoadingSpinnerWrapperCss = (inline: LoadingSpinnerWrapperProps["inline"], size: number) => {
  if (inline) {
    return css`
      display: inline-block;
      text-align: center;
      padding-top: ${size / 2 / 10}rem;
      padding-bottom: ${size / 2 / 10}rem;
    `;
  }

  return css`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  `;
};

const LoadingSpinnerWrapper = styled.div<LoadingSpinnerWrapperProps>`
  ${({ inline, size }) => handleLoadingSpinnerWrapperCss(inline, size)};
`;

const Spinner = styled(SyncLoader).attrs<LoadingSpinnerProps>(({ theme, color }) => ({ color: color ? theme.colors[ color ] : theme.colors.dark }))``;
