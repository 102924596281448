import styled, { css } from "styled-components";
import React from "react";
import { Drop, Box } from "grommet";
import useRouter from "use-react-router";

import Text from "../text/Text";
import { useAuthContext } from "src/utils/authentication/AuthContext";
import { Icon, Icons } from "../icon";
import { theme } from "src/utils/theme/index";

export const ProfileMenu: React.FC = () => {
  const { logout } = useAuthContext();
  const targetRef = React.useRef<HTMLDivElement>(null);
  const [ showDrop, setShowDrop ] = React.useState(false);
  const { history } = useRouter();

  return (
    <LogoutWrapper>
      <Box align="center" justify="center">
        <Box
          pad="small"
          align="center"
          justify="start"
          ref={targetRef}
          onClick={() => setShowDrop(!showDrop)}
        >
          <Icon icon={Icons.profile} color={theme.colors.light} />
        </Box>
        {(showDrop && targetRef.current) && (
          <Drop
            onClickOutside={() => setShowDrop(false)}
            align={{
              top: "bottom",
              right: "right"
            }}
            target={targetRef.current}
          >
            <StyledMenuLink onClick={() => history.push("/collector/documents")}>
              <Text>
                Documents
              </Text>
            </StyledMenuLink>
            <StyledMenuLink onClick={logout}>
              <Text>
                Logout
              </Text>
            </StyledMenuLink>
          </Drop>
        )}
      </Box>
    </LogoutWrapper>
  );
};

const LogoutWrapper = styled.div`
  display: flex;
`;

const StyledMenuLink = styled(Box)`
  ${({ theme }) => css`
    padding: 1rem 2rem;

    &:hover {
      background: ${theme.colors.fadedLight};
    }
  `}
`;
