import { Box } from "grommet";
import debounce from "lodash/debounce";
import React from "react";

import { SearchAhead, SearchAheadItem } from "src/components/shared/search-ahead/SearchAhead";
import { Button } from "src/components/shared/button";
import { AdminLayout } from "src/components/shared/layout";
import { Text } from "src/components/shared/text";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { Wine } from "src/utils/api/routes/wines.api";

type SearchAheadItemWine = Pick<Wine, "displayWineLine1" | "displayWineLine2" | "id">;

const Dashboard: React.FC<PageProps<Record<string, unknown>>> = props => {
  // WINE SEARCH
  const [ winesRes, winesReq ] = useApiRequest("WINES:list");
  const [ wineSearchResults, setWineSearchResults ] = React.useState<Array<SearchAheadItem<SearchAheadItemWine>>>([]);

  const searchForWineName = debounce((search: string) => search && winesReq({
    params: {
      offset: 0,
      limit: 25,
      filters: { name: search }
    }
  }), 500);

  React.useEffect(() => {
    if (winesRes.data?.items) {
      setWineSearchResults(winesRes.data.items.map(wine => {
        return {
          label: wine.name,
          value: {
            id: wine.id,
            displayWineLine1: wine.displayWineLine1,
            displayWineLine2: wine.displayWineLine2
          }
        };
      }) || []);
    }
  }, [ winesRes ]);

  // CASE SEARCH
  const [ casesRes, casesReq ] = useApiRequest("CASES:list");
  const [ caseSearchResults, setCaseSearchResults ] = React.useState<SearchAheadItem[]>([]);

  const searchForCaseName = debounce((search: string) => search && casesReq({
    params: {
      offset: 0,
      limit: 25,
      filters: { name: search }
    }
  }), 500);

  React.useEffect(() => {
    if (casesRes.data?.items) {
      setCaseSearchResults(casesRes.data.items.map(aCase => {
        return {
          label: aCase.name,
          value: aCase.id
        };
      }) || []);
    }
  }, [ casesRes ]);

  return (
    <AdminLayout>
      <Box pad={{ top: "medium" }}>
        <Text as="h1">
          Welcome
        </Text>
      </Box>
      <Box pad={{ top: "medium" }}>
        <SearchAhead<SearchAheadItemWine>
          name="dashboardWineSearch"
          saveState
          label="Search for a wine"
          loading={winesRes.loading}
          items={wineSearchResults}
          renderItem={item => {
            return (
              <Box direction="row">
                <Text bold>
                  {item.value.displayWineLine1}
                </Text>
                <Box pad={{ left: "small" }}>
                  <Text>
                    {item.value.displayWineLine2}
                  </Text>
                </Box>
              </Box>
            );
          }}
          totalItems={winesRes.data?.total || 0}
          placeholder="Search name"
          onSelect={selected => selected && props.history.push(`/admin/wine/${selected.value.id}`)}
          onSearch={search => searchForWineName(search)}
        />
      </Box>
      <Box pad={{ top: "medium" }}>
        <SearchAhead
          name="dashboardCaseSearch"
          label="Search for a case"
          loading={casesRes.loading}
          totalItems={casesRes.data?.total || 0}
          items={caseSearchResults}
          placeholder="Search name"
          onSelect={selected => selected && props.history.push(`/admin/case/${selected.value}`)}
          onSearch={search => searchForCaseName(search)}
        />
      </Box>
      <Box pad={{ top: "large" }}>
        <Box pad={{ bottom: "small" }}>
          <Text as="h4">
            Setup a new collector or admin user
          </Text>
        </Box>
        <Button label="New user" onClick={() => props.history.push("/admin/user/new")} />
      </Box>
      <Box pad={{ top: "large" }}>
        <Box pad={{ bottom: "small" }}>
          <Text as="h4">
            Import labels as bottles and cases
          </Text>
        </Box>
        <Button label="Assign labels" onClick={() => props.history.push("/admin/labels")} />
      </Box>
      <Box pad={{ top: "large" }}>
        <Box pad={{ bottom: "small" }}>
          <Text as="h4">
            Calculate storage fees
          </Text>
        </Box>
        <Button label="Storage Fees" onClick={() => props.history.push("/admin/storage-fee-calculation")} />
      </Box>
    </AdminLayout>
  );
};

export default Dashboard;
