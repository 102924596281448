import { Box } from "grommet";
import React from "react";

import { Text } from "../text";
import { formatCurrency } from "src/utils/currency";

interface Props {
  currentValue?: number | string;
  title: string;
}

export const CurrentValueBox: React.FC<Props> = ({ currentValue, title }) => {
  return (
    <Box
      pad={{
        top: "small",
        horizontal: "medium" 
      }}
      align="end"
    >
      <Text color="fadedDark" as="h6" bold>
        {title}
      </Text>
      <Box pad={{ top: "5px" }}>
        <Text as="h2">
          {currentValue ? formatCurrency(parseFloat(currentValue as string)) : "-"}
        </Text>
      </Box>
    </Box>
  );
};
