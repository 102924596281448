import { HeaderProps, FilterProps } from "react-table";
import React from "react";

import { TextInput } from "../../forms/TextInput";

export const DefaultHeader: React.FC<HeaderProps<Record<string, unknown>>> = ({ column }) => (
  <>
    {column.id.startsWith("_") ? null : column.id}
  </>
);

export function DefaultColumnFilter<T extends Record<string, unknown>>({
  column: {
    id, index, Header, filterValue, setFilter, parent
  }
}: FilterProps<T>): React.ReactElement {
  const [ value, setValue ] = React.useState(filterValue || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // ensure that reset loads the new value
  React.useEffect(() => {
    setValue(filterValue || "");
  }, [ filterValue ]);

  const firstIndex = !(parent && parent.index);

  return (
    <>
      <TextInput
        name={id}
        placeholder={`Filter by ${Header}`}
        value={value}
        autoFocus={index === 0 && firstIndex}
        onChange={handleChange}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setFilter(value || undefined);
          }
        }}
        onBlur={e => {
          setFilter(e.target.value || undefined);
        }}
      />
    </>
  );
}