import React from "react";
import { useForm } from "react-hook-form";
import formatDate from "date-fns/format";

import { Button } from "src/components/shared/button";
import { FormError, FormRow } from "src/components/forms";
import { CreateStorageFeeCalculation } from "src/utils/api/routes/storage-fees.api";
import { DateInput } from "src/components/forms/DateInput";

export const greaterThanDate = (date1: Date, date2: Date) => {
  return date1.getUTCFullYear() > date2.getUTCFullYear() ||
    date1.getUTCFullYear() === date2.getUTCFullYear() && date1.getUTCMonth() > date2.getUTCMonth() ||
    date1.getUTCFullYear() === date2.getUTCFullYear() && date1.getUTCMonth() === date2.getUTCMonth() && date1.getUTCDate() > date2.getUTCDate(); 
};

interface UserFormProps {
  onSubmit: (data: CreateStorageFeeCalculation) => void;
  isLoading: boolean;
}

type CreateStorageFeeCalculationForm = Omit<CreateStorageFeeCalculation, "startDate" | "endDate"> & {
  startDate: string;
  endDate: string;
};

export const NewStorageFeeCalculationForm: React.FC<UserFormProps> = props => {
  const {
    register, handleSubmit, errors, watch, setValue, getValues
  } = useForm<CreateStorageFeeCalculationForm>();

  const onSubmit = (userInput: CreateStorageFeeCalculationForm) => {
    props.onSubmit({ ...userInput });
  };

  React.useEffect(() => {
    register("startDate", {
      pattern: {
        value: /^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])$/,
        message: "Start Date must be a valid date in the dd/mm/yyyy format"
      }
    });

    register("endDate", {
      pattern: {
        value: /^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])$/,
        message: "End Date must be a valid date in the dd/mm/yyyy format"
      },
      validate: endDate => {
        const { startDate } = getValues();
        const today = new Date();

        if (!startDate || !endDate) {
          return true;
        }

        // Compare startDate and endDate.
        if (endDate <= startDate) {
          return "Calculation end date must be strictly greater than start date"; 
        }

        const endDateDate = new Date(endDate);

        // Check endDate is today or in the past.
        if (greaterThanDate(endDateDate, today)) {
          return "Calculation end date cannot be a future date."; 
        }

        return true;
      }
    });
  }, [ register, getValues ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <FormRow required label="Start date">
        <DateInput
          name="startDate"
          format="dd/mm/yyyy"
          // @ts-ignore - TODO: wtf why is this erroring
          onChange={({ value }: {value: string}) => {
            if (value) {
              setValue("startDate", formatDate(new Date(value), "yyyy-MM-dd"));
            }
          }}
          value={watch("startDate")}
        />
        <FormError message={errors.startDate?.message} />
      </FormRow>
      
      <FormRow required label="End date">
        <DateInput
          name="endDate"
          format="dd/mm/yyyy"
          // @ts-ignore - TODO: wtf why is this erroring
          onChange={({ value }: {value: string}) => {
            if (value) {
              setValue("endDate", formatDate(new Date(value), "yyyy-MM-dd"));
            }
          }}
          value={watch("endDate")}
        />
        <FormError message={errors.endDate?.message} />
      </FormRow>
      
      <Button disabled={props.isLoading} label="Submit" type="submit" />
    </form>
  );
};
