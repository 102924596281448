import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import React from "react";
import { toast } from "react-toastify";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { UserForm } from "src/components/user";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { CreateUserInput } from "src/utils/api/routes/users.api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

export const NewUserPage: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ createUserRes, createUserReq ] = useApiRequest("USERS:create");
  const [ getRolesRes, getRolesReq ] = useApiRequest("ROLES:list");

  const onSubmit = React.useCallback((singleUser: CreateUserInput) => {
    const valuesToUpdate = pickBy(singleUser, identity) as CreateUserInput;

    createUserReq({
      data: {
        ...valuesToUpdate,
        status: "active" 
      } 
    });
  }, [ createUserReq ]);

  React.useEffect(() => {
    if (createUserRes.data) {
      toast.success(`${createUserRes.data.name} was created successfully. Click here to view this user.`, {
        autoClose: false,
        onClick: () => props.history.push(`/admin/user/${createUserRes.data?.id}`) 
      });
    }

    if (createUserRes.errorMessage) {
      toast.error(createUserRes.errorMessage);
    }
  }, [
    createUserRes,
    props.history,
    props.match
  ]);

  React.useEffect(() => {
    getRolesReq({});
  }, [ getRolesReq ]);
  
  React.useEffect(() => {
    if (getRolesRes.errorMessage) {
      toast.error(getRolesRes.errorMessage);
    }
  }, [ getRolesRes ]);

  return (
    <AdminLayout>
      <PageHeader 
        title="New User" 
        backLinkText="Back to all users" 
        backLink={() => props.history.push("/admin/users")} 
      />
      <Card pad="small" maxWidth="70rem">
        {getRolesRes.loading && <LoadingSpinner />}
        {getRolesRes.data && (
          <UserForm isLoading={createUserRes.loading} onSubmit={onSubmit} roles={getRolesRes.data} defaultValues={{ cashBalance: "0" }} />
        )}
      </Card>

    </AdminLayout>
  );
};
