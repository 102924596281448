import {
  applyMiddleware, combineReducers, createStore, Store, Middleware
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "react-router-redux";
import {
  persistStore, Persistor, persistReducer
} from "redux-persist";
import { History } from "history";

import { globalReducer, GlobalStore } from "./global/global.reducer";
import { GlobalActions } from "./global/global.actions";

export interface StoreAttributes {
  global: GlobalStore;
}
type AllActions = GlobalActions;
const middleware: Middleware[] = [];
const rootReducer = () => combineReducers({ global: globalReducer });

const persistConfig = {
  key: "root",
  storage,
  blacklist: [ "" ]
};

export default (browserHistory: History<History.PoorMansUnknown>): {
    store: Store<StoreAttributes, AllActions>;
    persistor: Persistor;
} => {
  const persistedReducer = persistReducer(persistConfig, rootReducer());

  middleware.push(routerMiddleware(browserHistory));

  const composeEnhancers = composeWithDevTools({});
  const store: Store<StoreAttributes, AllActions> = createStore(persistedReducer, composeEnhancers(applyMiddleware(... middleware)));
  const persistor = persistStore(store);

  return {
    store,
    persistor
  };
};
