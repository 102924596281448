import styled from "styled-components";
import React from "react";
import { LinkPrevious } from "grommet-icons";

import { Text } from "../text";
import { Button } from "../button";

interface PageHeaderProps {
  backLink: () => void;
  title?: string;
  backLinkText?: string;
  callToAction?: React.ReactElement;
}

export const PageHeader: React.FC<PageHeaderProps> = props => {
  return (
    <Wrapper>
      <BackLinkWrapper>
        <Button plain color="light" onClick={props.backLink} label={props.backLinkText || "Back"} iconDirection="left" icon={<LinkPrevious />} />
      </BackLinkWrapper>
      {(props.title || props.callToAction) && (
        <Header>
          <Text as="h2" bold>
            {props.title}
          </Text>
          {props.callToAction}
        </Header>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const BackLinkWrapper = styled.div`
  display: flex;
`;

const Header = styled.header`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
