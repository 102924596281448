import { GoogleApiWrapper } from "google-maps-react";
import styled from "styled-components";
import "googlemaps";

import MapContainer from "./MapContainer";

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";

/**
 * Map API wrapper
 */
const MapApiWrapper = styled(GoogleApiWrapper({ apiKey })(MapContainer))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default MapApiWrapper;