import { Box } from "grommet";
import React from "react";

import { Button } from "src/components/shared/button";
import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Text } from "src/components/shared/text";
import { PageProps } from "src/pages/Router";

import { InitialiseLabelsContainer } from "./containers/InitialiseLabelsContainer";
import { CheckLabelCodeContainer } from "./containers/CheckLabelCodeContainer";

export const LabelsPage: React.FC<PageProps<Record<string, unknown>>> = props => {
  return (
    <AdminLayout>
      <PageHeader title="Labels" backLink={() => props.history.push("/admin/dashboard")} />
      <Card direction="row" justify="between" align="center" pad="small">
        <Box pad="small">
          <Text as="h4" bold>
            Assign labels
          </Text>
          <Text as="p">
            1275 Warehouse
          </Text>
        </Box>
        <Box pad="small">
          <Button label="Start assigning" onClick={() => props.history.push("/admin/scanner/1/assign")} />
        </Box>
      </Card>
      <Box direction="row" justify="between">
        <Box
          width="100%"
          pad={{
            top: "medium",
            right: "medium" 
          }}
        >
          <InitialiseLabelsContainer />
        </Box>
        <Box width="100%" pad={{ top: "medium" }}>
          <CheckLabelCodeContainer />
        </Box>
      </Box>
    </AdminLayout>
  );
};
