import { Box } from "grommet";
import React from "react";
import differenceInSeconds from "date-fns/differenceInSeconds";

import { useInterval } from "src/hooks/useInterval";
import { Text } from "../shared/text";

export const LastResponseTimer: React.FC<{fetchLabels: () => void, lastResponse: Date; paused: boolean}> = props => {
  const [ count, setCount ] = React.useState<number>(0);

  useInterval(() => {
    const secondsElapsed = differenceInSeconds(
      new Date(), props.lastResponse
    );

    if (secondsElapsed === 10) {
      props.fetchLabels();
    }

    setCount(secondsElapsed);
  }, !props.paused ? 1000 : null);

  const fetchNow = React.useCallback(() => {
    props.fetchLabels();
    setCount(10);
  }, [ props ]);

  return (
    <Box align="center" justify="center" pad="small">
      {!props.paused && (
        <>
          <Text as="p" bold>
            {count < 10 ? `Updating in ${10 - (count || 0)}` : "Updating..."}
          </Text>
          <Box pad={{ top: "small" }} onClick={() => fetchNow()}>
            <Text color="fadedDark" as="small" small bold>
              Refresh now
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};
