import "intl-list-format";
import "intl-list-format/locale-data/en";

import formatDate from "date-fns/format";
import { Box } from "grommet";
import React from "react";

import { CaseHistory, CaseHistoryActionType } from "src/utils/api/routes/case-history.api";
import { statusLabels } from "src/utils/api/routes/cases.api";
import { Text } from "../shared/text";

import { CaseHistoryTableRow } from "./CaseHistoryTable";

interface Props {
  history: CaseHistoryTableRow;
}

export const CaseHistoryNaturalLanguageSummary: React.FC<Props> = props => {
  const renderAction = React.useCallback(() => {
    switch (props.history.action) {
      case "transferred": {
        const caseHistory = props.history as CaseHistory<CaseHistoryActionType.transferred>;
  
        return (
          <>
            <Text>
              Transferred to&nbsp;
            </Text>
            <Text bold>
              {caseHistory.actionData.toData.name}
            </Text>
          </>
        );
      }
      case "status_change": {
        const caseHistory = props.history as CaseHistory<CaseHistoryActionType.status_change>;

        return (
          <>
            <Text>
              Changed status to&nbsp;
            </Text>
            <Text bold>
              {statusLabels[ caseHistory.actionData.to ]}
            </Text>
          </>
        ); 
      }
      case "sensor_change": {
        const caseHistory = props.history as CaseHistory<CaseHistoryActionType.sensor_change>;
        const removed = caseHistory.actionData?.fromData?.name;
        const added = caseHistory.actionData?.toData?.name;
      
        return (
          <>
            <Text>
              Sensors changed -&nbsp;
            </Text>
            {removed.length > 0 && (
              <>
                <Text>
                  removed&nbsp;
                </Text>
                <Text bold>
                  {removed}
                </Text>
              </>
            )}
            {added.length > 0 && (
              <>
                {removed.length ? (
                  <Text>
                    &nbsp;and added&nbsp;
                  </Text>
                ) : (
                  <Text>
                    Added&nbsp;
                  </Text>
                )}
                <Text bold>
                  {added}
                </Text>
              </>
            )}
          </>
        ); 
      }
      case "location_change": {
        const caseHistory = props.history as CaseHistory<CaseHistoryActionType.location_change>;

        return (
          <>
            <Text>
              Changed location to&nbsp;
            </Text>
            <Text bold>
              {caseHistory.actionData.to}
            </Text>
          </>
        ); 
      }
      case "created_at": {
        return (
          <>
            <Text>
              Case created
            </Text>
          </>
        ); 
      }
    }
  }, [ props.history ]);
  
  return (
    <Box pad="small" direction="row">
      <Box align="center" justify="center" pad={{ right: "small" }}>
        <Text bold small family="secondary">
          {formatDate(new Date(props.history.date), "HH:mm")}
        </Text>
      </Box>
      {renderAction()}
    </Box>
  );
};
