import { ApiRouteDefinition } from "../useApiRequest";
import {
  PickParams, StatusResponse, PaginatedQueryRequest, PaginatedResponse, RecordTimestamps, LivExLwinView
} from "../api";

import { Vintage } from "./vintages.api";
import { WineColour } from "./wine-colours.api";

const WineRoutes = {
  /** Get Wine by id */
  "WINES:get": {
    path: "/wines/:wineId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Wine, null, PickParams<"wineId">>,

  /** list wines */
  "WINES:list": {
    path: "/wines",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Wine>, null, null, PaginatedQueryRequest<Partial<Wine>>>,

  /** Partial update a Wine */
  "WINES:update": {
    path: "/wines/:wineId",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<
    Wine,
    Partial<UpdateWineInput>,
    PickParams<"wineId">
  >,

  /** Create a Wine */
  "WINES:create": {
    path: "/wines/",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    Wine,
    CreateWineInput
  >,

  /** delete a Wine */
  "WINES:delete": {
    path: "/wines/:wineId",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StatusResponse, null, PickParams<"wineId">>,

  /** Wine LWIN lookup */
  "WINES:lwin-lookup": {
    path: "/wines/lwin-lookup",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<LivExLwinView, Pick<Wine, "lwin">>
};

export default WineRoutes;

export interface Wine extends RecordTimestamps {
  id: number;
  wineColourId: number;
  estateId?: number;
  name: string;
  lwin: string;
  country: string;
  region: string;
  subRegion?: string;
  village?: string;
  displayLocation: string;
  displayWineLine1: string;
  displayWineLine2: string;
  preDrinkingYears?: number;
  matureDrinkingYears?: number;
  wineColour?: WineColour;
  vintages?: Vintage[];
  grapes?: WineGrapeInput[];
}

export interface GrapeInput {
  grapeId: number;
  blendPercent: number;
}

export interface WineGrapeInput {
  id: number;
  name: string;
  label: string;
  wineGrape: GrapeInput;
}

export interface CreateWineInput {
  name: string;
  lwin: string;
  wineColourId: number;
  estateId?: number;
  country: string;
  region: string;
  subRegion?: string;
  village?: string;
  displayLocation: string;
  displayWineLine1: string;
  displayWineLine2: string;
  preDrinkingYears?: number;
  matureDrinkingYears?: number;
  grapes?: GrapeInput[] | null;
}

export type UpdateWineInput = CreateWineInput & {id: number};