import { Box } from "grommet";
import React from "react";
import formatDate from "date-fns/format";
import { useForm } from "react-hook-form";

import { Card } from "src/components/shared/layout";
import { SensorGroupSelect } from "src/components/label/SensorGroupSelect";
import {
  FormRow, TextInput, FormError
} from "src/components/forms";
import { Text } from "src/components/shared/text";
import { Button } from "src/components/shared/button";
import { DateInput } from "src/components/forms/DateInput";

interface SensorGroupAssignmentFormProps {
  onSubmit: (formValues: SensorGroupAssignmentFormValues) => void;
}

export interface SensorGroupAssignmentFormValues {
  orderReference: string;
  sensorGroupId?: number | null;
  sensorFromDate: string;
  sensorFromTime: string;
}

/**
 * Sensor assignment form
 *
 */
export const SensorGroupAssignmentForm: React.FC<SensorGroupAssignmentFormProps> = ({ onSubmit }) => {
  const {
    register, handleSubmit, watch, setValue, errors
  } = useForm<SensorGroupAssignmentFormValues>();

  React.useEffect(() => {
    register("sensorGroupId", {
      required: {
        value: true,
        message: "Please select a sensor group"
      }
    });

    register("sensorFromTime", {
      pattern: {
        value: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
        message: "Time must be a valid time in the HH:mm format"
      }
    });

    register("sensorFromDate", {
      pattern: {
        value: /^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])$/,
        message: "Date must be a valid date in the dd/mm/yyyy format"
      }
    });
  }, [ register ]);

  return (

    <Card pad="medium">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text as="h3">
          Sensor Information
        </Text>

        <Box direction="row" pad={{ vertical: "small" }} justify="between">
          <Box width="48%">
            <FormRow required label="Order Reference">
              <TextInput
                name="orderReference"
                value={watch("orderReference")}
                ref={register({
                  required: {
                    value: true,
                    message: "Order reference is required"
                  }
                })}
              />

              <FormError message={errors.orderReference?.message} />
            </FormRow>
          </Box>
          <Box width="48%">
            <SensorGroupSelect
              setSensorGroupId={id => setValue("sensorGroupId", id)}
              errorMessage={errors.sensorGroupId?.message}
            />
          </Box>
        </Box>

        <Box direction="row" justify="between">
          <Box width="48%">
            <FormRow required label="Sensor assigned day">            
              <DateInput
                name="sensorFromDate"
                format="dd/mm/yyyy"
                // @ts-ignore - errors because the onChange event actually gives a custom evenr but the typings is a default event
                onChange={({ value }: {value: string}) => {
                  if (value) {
                    setValue("sensorFromDate", formatDate(new Date(value), "yyyy-MM-dd"));
                  }
                }}
                value={watch("sensorFromDate")}
              />
              <FormError message={errors.sensorFromDate?.message} />
            </FormRow>
          </Box>
          <Box width="48%">
            <FormRow required label="Sensor assigned time">
              <TextInput
                name="sensorFromTime"
                type="time"
                ref={register({
                  pattern: {
                    value: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
                    message: "Time must be a valid time in the HH:mm format"
                  }
                })}
                value={watch("sensorFromTime")}
              />
              <FormError message={errors.sensorFromTime?.message} />
            </FormRow>
          </Box>
        </Box>

        <Button primary label="Finish" type="submit" />
      </form>
    </Card>
  );
};
