import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import React from "react";
import { toast } from "react-toastify";
import { debounce } from "lodash";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { CaseForm } from "src/components/case";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { Case } from "src/utils/api/routes/cases.api";
import { SearchAheadItem } from "src/components/shared/search-ahead";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

interface CasePageParams {
  caseId: string;
}

export const EditCaseForm: React.FC<PageProps<Record<string, unknown>, CasePageParams>> = props => {
  const [ updateCaseRes, updateCaseReq ] = useApiRequest("CASES:update");
  const [ getCaseRes, getCaseReq ] = useApiRequest("CASES:get");
  // USER SEARCH
  const [ usersRes, usersReq ] = useApiRequest("USERS:list");
  const [ userSearchResults, setCaseSearchResults ] = React.useState<SearchAheadItem[]>([]);

  const searchForUserName = debounce((search: string) => search && usersReq({
    params: {
      offset: 0,
      limit: 25,
      filters: { name: search } 
    } 
  }), 500);

  React.useEffect(() => {
    if (usersRes.data?.items) {
      setCaseSearchResults(usersRes.data.items.map(user => {
        return {
          label: user.name,
          value: user.id 
        };
      }) || []);
    }
  }, [ usersRes ]);

  const onSubmit = React.useCallback((singleCase: Partial<Case>) => {
    const valuesToUpdate = pickBy(singleCase, identity);

    if (props.match?.params?.caseId) {
      updateCaseReq({
        data: {
          ...valuesToUpdate,
          id: parseInt(props.match.params.caseId, 10)
        },
        pathParams: { caseId: props.match.params.caseId }
      });
    }
  }, [ props.match, updateCaseReq ]);

  React.useEffect(() => {
    if (updateCaseRes.data) {
      const { name, id } = updateCaseRes.data;

      toast.success(`${name} was updated successfully. Click here to view.`, {
        autoClose: false,
        onClick: () => props.history.push(`/admin/case/${id}`)
      });
    }

    if (updateCaseRes.errorMessage) {
      toast.error(updateCaseRes.errorMessage);
    }
  }, [
    props.history,
    updateCaseRes.data,
    updateCaseRes.errorMessage
  ]);

  React.useEffect(() => {
    if (props.match?.params?.caseId) {
      getCaseReq({ pathParams: { caseId: props.match.params.caseId } });
    }
  }, [ getCaseReq, props.match ]);

  React.useEffect(() => {
    if (getCaseRes.errorMessage) {
      toast.error(getCaseRes.errorMessage);
    }
  }, [ getCaseRes ]);

  return (
    <AdminLayout>
      <PageHeader title="Edit Case" backLink={() => props.history.goBack()} />
      <Card pad="small" maxWidth="70rem">
        {getCaseRes.loading && <LoadingSpinner />}
        {getCaseRes.data && (
          <CaseForm 
            userSearchTotal={usersRes.data?.total || 0}
            searchForUserName={searchForUserName}
            userSearchResults={userSearchResults}
            userSearchLoading={usersRes.loading}
            isLoading={updateCaseRes.loading}
            onSubmit={onSubmit}
            defaultValues={getCaseRes.data} 
          />
        )}
      </Card>

    </AdminLayout>
  );
};
