import React from "react";
import { Layer, Box } from "grommet";

import { Text } from "../shared/text";
import { BulkEditCaseInput } from "src/utils/api/routes/cases.api";

import { BulkEditCaseForm } from "./BulkEditCaseForm";

interface Props {
  onSubmit: (data: BulkEditCaseInput) => void;
  onClose: () => void;
  selectedQty: number;
  isLoading: boolean;
}

export const BulkEditModal: React.FC<Props> = props => {
  return (
    <Layer position="center" onClickOutside={props.onClose} onEsc={props.onClose}>
      <Box
        direction="row"
        align="center"
        as="header"
        justify="between"
        pad="medium"
        border="bottom"
      >
        <Text bold as="h6">
          Edit
          {props.selectedQty}
          {" "}
          cases
        </Text>
      </Box>
      <Box pad="medium" gap="small" width="medium">
        <Text as="p">
          Set the following fields for all
          {props.selectedQty}
          {" "}
          of the selected cases:
        </Text>
        <BulkEditCaseForm onSubmit={props.onSubmit} isLoading={props.isLoading} />
      </Box>

    </Layer>
  );
};