import { Box, CheckBox } from "grommet";
import React from "react";

import { Label } from "src/utils/api/routes/labels.api";
import { Wine } from "../../utils/api/routes/wines.api";
import { Card } from "../shared/layout";
import { Text } from "../shared/text";

import { CohesiveCaseAssignment } from "./CohesiveCaseAssignment";
import { MixedCaseAssignment } from "./MixedCaseAssignment";

export type SearchAheadItemWine = Pick<Wine, "displayWineLine1" | "displayWineLine2" | "id">;

interface LabelAssignmentFormProps {
  // Labels to assign
  labels: Label[];
  // Pass back the assigned bottles
  handleCreate: (caseWithBottles: AssignedCaseLabel) => void;
  // Cancel the assignment
  handleCancel: () => void;
}
export interface AssignedCaseLabel {
  labelId: number;
  name: string;
  bottleSize: string;
  packSize: string;
  lwin: string | null;
  bottles: AssignedBottleLabel[];
  verifiedCode: string | null;
  certificationReference: string | null;
}
export interface AssignedBottleLabel {
  labelId: number;
  name: string;
  bottleSize: string;
  lwin: string;
  vintageId: number;
  verifiedCode: string | null;
  certificationReference: string | null;
}

export const LabelAssignmentForm: React.FC<LabelAssignmentFormProps> = props => {
  const [ mixedCase, setMixedCase ] = React.useState(false);

  return (
    <Card pad="medium">
      <Text as="h3">
        Assign labels
      </Text>
      <Box pad={{ top: "medium" }} direction="row">
        <Text>
          Is this a mixed case?
        </Text>
        <Box pad={{ left: "small" }}>
          <CheckBox
            onClick={() => setMixedCase(!mixedCase)}
            checked={mixedCase}
          />
        </Box>
      </Box>
      {mixedCase ? (
        <MixedCaseAssignment
          handleCreate={props.handleCreate}
          handleCancel={props.handleCancel}
          labels={props.labels}
        />
      ) : (
        <CohesiveCaseAssignment
          handleCreate={props.handleCreate}
          handleCancel={props.handleCancel}
          labels={props.labels}
        />
      )}
    </Card>
  );
};
