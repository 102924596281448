import React from "react";
import styled, { css } from "styled-components";
import formatDate from "date-fns/format";
import groupBy from "lodash/groupBy";
import { Box } from "grommet";

import { Text } from "../shared/text";
import { CaseHistory } from "src/utils/api/routes/case-history.api";
import { Case, statusLabels } from "src/utils/api/routes/cases.api";

import { CaseHistoryNaturalLanguageSummary } from "./CaseHistoryNaturalLanguageSummary";

interface Props {
  data: CaseHistory[];
  currentData: Pick<Case, "location" | "status" | "createdAt">;
}

export type CaseHistoryTableRow = Pick<CaseHistory, "date" | "id" | "actionData"> & {action: CaseHistory["action"] | "created_at"};

export const CaseHistoryTable: React.FC<Props> = props => {
  const caseHistoryDates = React.useMemo(() => {
    if (props.data.length > 0) {
      const rows: CaseHistoryTableRow[] = [
        ...props.data,
        {
          id: 0,
          date: props.currentData.createdAt,
          action: "created_at",
          actionData: {}
        }
      ];

      return groupBy(rows, function (historyRow) {
        if (historyRow) {
          return formatDate(new Date(historyRow.date), "dd MMM yyyy");
        }
      });
    }
  }, [ props ]);

  return (
    <Wrapper>
      <HistoryRow>
        <DateHeader>
          <Text bold as="small" family="secondary">
            Now
          </Text>
        </DateHeader>
        <RowContents>
          <Box pad="small" direction="row">
            <Text>
              Currently
            </Text>
            <Text bold>
              &nbsp;
              {statusLabels[ props.currentData.status ]}
              &nbsp;
            </Text>
            <Text>
              at
            </Text>
            <Text bold>
              &nbsp;
              {props.currentData.location}
            </Text>
          </Box>
        </RowContents>
      </HistoryRow>
      {caseHistoryDates && Object.entries(caseHistoryDates).map(caseHistory => {
        const date = caseHistory[ 0 ];
        const histories = caseHistory[ 1 ];

        return (
          <HistoryRow key={date}>
            <DateHeader>
              <Text bold as="small" family="secondary">
                {date}
              </Text>
            </DateHeader>
            <RowContents>
              {histories.map(history => {
                return (
                  <CaseHistoryNaturalLanguageSummary key={history.id} history={history} />
                );
              })}
            </RowContents>
          </HistoryRow>
        );
      })}

    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HistoryRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DateHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    background: ${theme.colors.fadedMid};
    width: 100%;
  `};
`;

const RowContents = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  margin-left: 2rem;
  border-left: 2px solid ${theme.colors.fadedReg};
  `};

`;
