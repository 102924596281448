import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import React from "react";
import { useForm } from "react-hook-form";

import {
  FormError, FormRow, TextInput 
} from "src/components/forms";
import { Button } from "src/components/shared/button";
import { Role, ROLE_NAMES } from "src/utils/api/routes/roles.api";
import {
  CreateUserInput, CreateUserStorageFeeRate, UserStorageFeeCollectionBasis 
} from "src/utils/api/routes/users.api";
import { MultiSelectInput, MultiSelectItem } from "../forms/MultiSelectInput";
import { SelectInput, SelectItem } from "../forms/SelectInput";

import {
  CreateUserStorageFeeRateFormInput,
  UserStorageFeeRatesForm,
  validateUserStorageFees
} from "./UserStorageFeeRatesForm";

export interface CreateUserForm {
  name: string;
  email: string;
  phone: string;
  roles: Role[];
  cashBalance?: string;
  externalId: string;
  storageFeeCollectionBasis?: UserStorageFeeCollectionBasis | null;
  storageFeeRates?: CreateUserStorageFeeRateFormInput[];
}
interface UserFormProps {
  onSubmit: (data: CreateUserInput) => void;
  isLoading: boolean;
  defaultValues?: Partial<CreateUserForm>;
  roles: Role[];
  isUpdatingUser?: boolean;
}

export const UserForm: React.FC<UserFormProps> = props => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    clearError
  } = useForm<CreateUserForm>({ defaultValues: { ...props.defaultValues } });

  const onSubmit = React.useCallback((userInput: CreateUserForm) => {
    const parsedUserInput: CreateUserInput = {
      name: userInput.name,
      email: userInput.email,
      phone: userInput.phone,
      status: "active",
      roles: userInput.roles,
      externalId: userInput.externalId
    };

    if (userInput.cashBalance) {
      parsedUserInput.cashBalance = parseFloat(userInput.cashBalance);
    }

    if (!userInput.roles) {
      setError("roles", "required", "You must set at least one role for the user.");
    }

    if (userInput.roles?.find(role => role.name === ROLE_NAMES.COLLECTOR) && userInput.storageFeeRates) {
      const error = validateUserStorageFees(userInput.storageFeeRates);
      const parsedUserStorageFees: CreateUserStorageFeeRate[] = [];

      userInput.storageFeeRates.map(rate => {
        if (rate.rateStart && rate.percentage) {
          // Ensure numbers:
          parsedUserStorageFees.push({
            rateStart: parseInt(rate.rateStart, 10),
            rateEnd: rate.rateEnd ? parseInt(rate.rateEnd, 10) : null,
            percentage: parseFloat(rate.percentage)
          });
        }
      });
      parsedUserInput.storageFeeRates = parsedUserStorageFees;

      parsedUserInput.storageFeeCollectionBasis =
        userInput.storageFeeCollectionBasis;

      if (error) {
        setError("storageFeeRates", "required", error);

        return;
      } else {
        clearError("storageFeeRates");
      }
    }

    props.onSubmit(parsedUserInput);
  }, [
    clearError,
    props,
    setError 
  ]);

  React.useEffect(() => {
    register({
      name: "roles",
      required: true 
    });

    register({
      name: "storageFeeCollectionBasis",
      required: true 
    });

    register({
      name: "storageFeeRates",
      type: "custom"
    });
  }, [ register ]);

  const onUserStorageFeeRatesChange = React.useCallback((
    value: CreateUserStorageFeeRateFormInput[]
  ) => {
    setValue("storageFeeRates", value);
  }, [ setValue ]);

  const onUserStorageFeeRatesErrorChange = React.useCallback((error: string | null) => {
    if (error) {
      setError("storageFeeRates", "required", error as string);
    } else {
      clearError("storageFeeRates");
    }
  }, [ clearError, setError ]);

  const onRoleChange = React.useCallback((selected: Array<MultiSelectItem<Role>>) => {
    setValue("roles", selected.map(item => ({ ...item.value })), true);
  }, [ setValue ]);

  const onStorageFeeBasisChange = React.useCallback((selected: SelectItem<UserStorageFeeCollectionBasis>) => {
    setValue("storageFeeCollectionBasis", selected.value, true);
  }, [ setValue ]);

  const onPhoneChange = React.useCallback((value: string) => {
    const formatted = new AsYouType().input(value);
    
    setValue("phone", formatted, true);
  }, [ setValue ]);
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormRow required label="User Name">
        <TextInput name="name" ref={register({ required: true })} />
        {errors.name && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Email">
        <TextInput
          disabled={props.isUpdatingUser}
          autoComplete="email"
          inputMode="email"
          name="email"
          ref={register({ required: true })}
        />
        {errors.email && <FormError message="This field is required" />}
      </FormRow>

      <FormRow required label="Phone">
        <TextInput
          defaultValue={props.defaultValues?.phone}
          onChange={e => {
            onPhoneChange(e.target.value);
          }}
          autoComplete="phone"
          inputMode="tel"
          name="phone"
          ref={register({
            required: true,
            validate: value =>
              parsePhoneNumber(value)?.isValid() ?
                true :
                "Phone number must be valid."
          })}
        />
        {errors.phone && <FormError message={errors.phone.message} />}
      </FormRow>
      <FormRow required label="External id">
        <TextInput name="externalId" ref={register({ required: false })} />
      </FormRow>

      <FormRow required label="Roles">
        <MultiSelectInput<Role>
          items={props.roles.map(role => ({
            label: role.name,
            value: role 
          }))}
          defaultItems={props.defaultValues?.roles?.map(role => ({
            label: role.name,
            value: role 
          }))}
          onSelect={selected => selected && onRoleChange(selected)}
        />
        {errors.roles && <FormError message="This field is required" />}
      </FormRow>

      <FormRow label="Cash Balance">
        <TextInput
          type="number"
          step=".01"
          name="cashBalance"
          ref={register({ required: true })}
        />
        {errors.cashBalance && <FormError message="This field is required" />}
      </FormRow>

      {watch("roles")?.find(
        selectedRole => selectedRole.name === ROLE_NAMES.COLLECTOR
      ) && (
        <>
          <FormRow required label="Storage fee calculation basis">
            <SelectInput<UserStorageFeeCollectionBasis>
              items={[
                {
                  label: "Book value",
                  value: UserStorageFeeCollectionBasis.BOOK_VALUE
                },
                {
                  label: "Market value",
                  value: UserStorageFeeCollectionBasis.MARKET_VALUE
                }
              ]}
              defaultItem={props.defaultValues?.storageFeeCollectionBasis ? {
                label: props.defaultValues.storageFeeCollectionBasis.replace("_", " "),
                value: props.defaultValues.storageFeeCollectionBasis 
              } : undefined}
              onSelect={selected => selected && onStorageFeeBasisChange(selected)}
            />
          
            {errors.storageFeeCollectionBasis && (
              <FormError message="This field is required" />
            )}
          </FormRow>
          <FormRow required label="Storage fee rates">
            <UserStorageFeeRatesForm
              defaultValues={props.defaultValues && props.defaultValues.storageFeeRates ?
                props.defaultValues.storageFeeRates :
                []}
              onValueChange={onUserStorageFeeRatesChange}
              onErrorChange={onUserStorageFeeRatesErrorChange}
            />
            {errors.storageFeeRates && (
              <FormError message={errors.storageFeeRates.message} />
            )}
          </FormRow>
        </>
      )}

      <Button disabled={props.isLoading} label="Submit" type="submit" />
    </form>
  );
};
