import React from "react";
import { Box } from "grommet";

import { Text } from "src/components/shared/text";

export interface GraphHeaderProps {
  text: string;
}
export const GraphHeader: React.FC<GraphHeaderProps> = ({ text }) => {
  return (
    <Box pad={{ bottom: "medium" }}>
      <Text as="h4" family="primary">
        {text}
      </Text>
    </Box>
  );
};