import { Box } from "grommet";
import React from "react";
import { toast } from "react-toastify";
import formatDate from "date-fns/format";

import { Button } from "src/components/shared/button";
import {
  InfoBox, InfoColumn, InfoPanel 
} from "src/components/shared/info";
import { PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Text } from "src/components/shared/text";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { formatCurrency } from "../../../../utils/currency/formatCurrency";

interface ViewStorageFeesParams {
  userStorageFeeId: string;
}

export const ViewUserStorageFees: React.FC<PageProps<ViewStorageFeesParams, ViewStorageFeesParams>> = props => {
  const [ getUserStorageFeeRes, getUserStorageFeeReq ] = useApiRequest("STORAGE-FEE:get-user-storage-fee");

  React.useEffect(() => {
    if (props.match?.params?.userStorageFeeId) {
      getUserStorageFeeReq({ pathParams: { userStorageFeeId: props.match?.params?.userStorageFeeId } });
    }
  }, [ getUserStorageFeeReq, props.match ]);

  React.useEffect(() => {
    if (getUserStorageFeeRes.errorMessage) {
      toast.error(getUserStorageFeeRes.errorMessage);
    }
  }, [ getUserStorageFeeRes ]);

  return (
    <AdminLayout>
      <PageHeader
        title="User Storage Fee" 
        backLink={() => props.history.goBack()} 
        callToAction={<Button label="New" onClick={() => props.history.push("/admin/storage-fee-calculation/new")} />}
      />
      <Box width="100%">
        {getUserStorageFeeRes.data && (
          <Box pad={{ vertical: "medium" }}>
            <Text as="h3">
              User Storage Fee Information
            </Text>
            <InfoPanel>
              <InfoColumn>
                <InfoBox label="Total Book Value" value={formatCurrency(getUserStorageFeeRes.data.totalBookValue)} />
                <InfoBox label="Total Portfolio Value" value={formatCurrency(getUserStorageFeeRes.data.totalPortfolioValue)} />
                <InfoBox label="Storage Fee" value={formatCurrency(getUserStorageFeeRes.data.storageFee)} />
                <InfoBox label="Average Portfolio Value" value={formatCurrency(getUserStorageFeeRes.data.averagePortfolioValue)} />
                <InfoBox label="Annualised Storage Fee" value={formatCurrency(getUserStorageFeeRes.data.annualisedStorageFee)} />
                <InfoBox label="Collection Basis" value={getUserStorageFeeRes.data.calculationData?.collectionBasis} />
                <InfoBox label="Count Portfolio Values" value={getUserStorageFeeRes.data.calculationData?.countPortfolioValues} />
                {getUserStorageFeeRes.data.calculationData?.storageFeeRates.map((rate, i) => (
                  <InfoBox key={i} label={`Rate: ${rate.rateStart} - ${rate.rateEnd || "∞"}`} value={rate.percentage} />
                ))}
              </InfoColumn>
            </InfoPanel>
            {getUserStorageFeeRes.data.transaction && (
              <InfoPanel>
                <InfoColumn>
                  <InfoBox label="Transaction id" value={getUserStorageFeeRes.data.transaction.id} />
                  <InfoBox label="Transaction created at" value={formatDate(new Date(getUserStorageFeeRes.data.transaction.createdAt), "dd/MM/yyyy HH:mm:ss")} />
                  <InfoBox label="Transaction value" value={formatCurrency(getUserStorageFeeRes.data.transaction.value)} />
                  <InfoBox label="Transaction note" value={getUserStorageFeeRes.data.transaction.note} />
                  <InfoBox label="Transaction invoice" value={getUserStorageFeeRes.data.transaction.invoice} />
                </InfoColumn>
              </InfoPanel>
            )}
          </Box>
        )}
      </Box>
    </AdminLayout>
  );
};
