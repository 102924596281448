import * as React from "react";
import { RouteProps } from "react-router";
import { Route } from "react-router-dom";
import * as _ from "lodash";

import { useAuthContext } from "src/utils/authentication/AuthContext";
import { Role } from "src/utils/api/routes/roles.api";
import { PublicLayout } from "../layout/PublicLayout";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import NotAllowed from "src/pages/public/NotAllowed";

interface PrivateRouteProps extends RouteProps {
  roles?: Array<Role["name"]>; // if not defined allows all authenticated users
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles, ...rest }) => {
  const { authState } = useAuthContext();

  const userHasAccess = React.useCallback(() => {
    const { user } = authState;

    if (!user) {
      return false;
    // no roles means allow all
    } else if (!roles) {
      return true;
    } else {
      const hasRole = _.find(user.roles, role => {
        return _.includes(roles, role.name);
      });

      return !!hasRole;
    }
  }, [ authState, roles ]);

  if (authState.authenticating) {
    return (
      <PublicLayout>
        <LoadingSpinner />
      </PublicLayout>
    );
  } else if (userHasAccess()) {
    return <Route {...rest} />;
  } else {
    return <NotAllowed />;
  }
};

export default PrivateRoute;