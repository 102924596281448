/* eslint-disable no-restricted-globals */
import "react-toastify/dist/ReactToastify.css";

import { Grommet } from "grommet";
import {
  BrowserHistoryBuildOptions, createBrowserHistory, History 
} from "history";
import React from "react";
import { RouteChildrenProps } from "react-router";
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled, { ThemeProvider } from "styled-components";

import { GlobalStyles } from "src/components/shared/global-styles";
import PrivateRoute from "src/components/shared/private-route/PrivateRoute";
import { ROLE_NAMES } from "src/utils/api/routes/roles.api";
import { AuthProvider } from "src/utils/authentication/AuthContext";
import { theme } from "src/utils/theme";

import {
  CasesTable, EditCaseForm, ViewCasePage 
} from "./protected/admin/cases";
import Dashboard from "./protected/admin/Dashboard";
import {
  EditEstateForm, EstatesTable, NewEstateForm, ViewEstatePage 
} from "./protected/admin/Estates";
import { LabelAssignmentPage, LabelsPage } from "./protected/admin/labels";
import SensorMeasurementsGraph from "./protected/admin/sensors/SensorMeasurementsGraph";
import SensorTable from "./protected/admin/sensors/SensorsTable";
import {
  AllStorageFeesCalculation,
  NewStorageFeesCalculation,
  ViewStorageFeesCalculation,
  ViewUserStorageFees
} from "./protected/admin/storage-fees";
import {
  ClientTransactions, EditUserForm, NewUserPage, UsersTable, ViewUserPage 
} from "./protected/admin/users";
import { NewTransaction } from "./protected/admin/users/NewTransaction";
import {
  EditWineForm, NewWineForm, ViewWinePage, WinesTable 
} from "./protected/admin/Wines";
import { CashTransactions, WinePortfolio } from "./protected/collector";
import { CollectorDocuments } from "./protected/collector/CollectorDocuments";
import { CollectorViewCasePage } from "./protected/collector/view-case";
import ForgotPassword from "./public/ForgotPassword";
import Login from "./public/Login";
import NotFound from "./public/NotFound";
import NFCScan from "./public/NFCScan";

interface Props {
  history: <S = History.PoorMansUnknown>(options?: BrowserHistoryBuildOptions | undefined) => History<S>;
}

export type PageProps<T, U extends { [K in keyof U]?: string } = Record<string, string>, S = History.LocationState> = RouteChildrenProps<U, S> & T;

export const dashboardRoutes = [];
const history = createBrowserHistory();

const AppRouter: React.FC<Props> = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grommet theme={theme}>
        <ToastContainer />
        <GlobalStyles />
        <AuthProvider history={history}>
          <RouterContainer className="show">
            <Router history={history}>
              <Switch>
                {/* Auth */}
                <Route path="/" exact component={Login} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                {/* NFC Scan Redirect */}
                <Route path="/nfc/:nfcid" exact component={NFCScan} />
                {/* Client Wine Portfolio */}
                <PrivateRoute roles={[ ROLE_NAMES.COLLECTOR ]} path="/collector/wine-portfolio" exact component={WinePortfolio} />
                {/* Client Cash Transactions */}
                <PrivateRoute roles={[ ROLE_NAMES.COLLECTOR ]} path="/collector/cash-transactions" exact component={CashTransactions} />
                {/* Client Cases */}
                <PrivateRoute roles={[ ROLE_NAMES.COLLECTOR ]} path="/collector/case/:caseId" exact component={CollectorViewCasePage} />
                {/* Client Documents */}
                <PrivateRoute roles={[ ROLE_NAMES.COLLECTOR ]} path="/collector/documents" exact component={CollectorDocuments} />

                {/* Admin Dashboard */}
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/dashboard" exact component={Dashboard} />
                {/* Storage Fees */}
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/storage-fee-calculation" exact component={AllStorageFeesCalculation} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/storage-fee-calculation/new" exact component={NewStorageFeesCalculation} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/storage-fee-calculation/:storageFeeCalculationId" exact component={ViewStorageFeesCalculation} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/user-storage-fee/:userStorageFeeId" exact component={ViewUserStorageFees} />
                {/* Users */}
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/users" exact component={UsersTable} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/user/new" exact component={NewUserPage} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/user/:userId" exact component={ViewUserPage} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/user/:userId/edit" exact component={EditUserForm} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/user/:userId/transactions" exact component={ClientTransactions} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/user/:userId/transactions/new" exact component={NewTransaction} />

                {/* Wines & Vintages */}
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/wines" exact component={WinesTable} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/wine/new" exact component={NewWineForm} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/wine/:wineId" exact component={ViewWinePage} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/wine/:wineId/edit" exact component={EditWineForm} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/wine/:wineId/vintage/:vintageId" exact component={ViewWinePage} />
                {/* Scanner / Labels */}
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/labels" exact component={LabelsPage} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/scanner/:scannerId/assign" exact component={LabelAssignmentPage} />
                {/* Cases */}
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/cases" exact component={CasesTable} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/case/:caseId" exact component={ViewCasePage} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/case/:caseId/edit" exact component={EditCaseForm} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/estates" exact component={EstatesTable} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/estates/new" exact component={NewEstateForm} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/estates/:estateId" exact component={ViewEstatePage} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/estates/:estateId/edit" exact component={EditEstateForm} />

                {/* Sensors */}
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/sensors" exact component={SensorTable} />
                <PrivateRoute roles={[ ROLE_NAMES.ADMIN ]} path="/admin/sensors/:sensorId" exact component={SensorMeasurementsGraph} />
                {/* Fallback 4004 */}
                <Route component={NotFound} />
              </Switch>

            </Router>
          </RouterContainer>
        </AuthProvider>
      </Grommet>

    </ThemeProvider>
  );
};

const RouterContainer = styled.div`
  transition: all 1s ease-in-out;
  opacity: 0;

  &.show {
    opacity: 1;
  }
`;

export default AppRouter;