import React from "react";
import formatDate from "date-fns/format";

import { Graph, AxesProps } from "src/components/shared/graph";
import { DatedChartLabel, DatedGraphFooter } from "../graph/DatedGraphFooter";
import { GraphHeader } from "../graph/GraphHeader";
import { formatCurrencyString } from "src/utils/currency/formatCurrencyString";

import { VariationPriceBox } from "./VariationPriceBox";

interface PriceGraphProps {
  historicalStartDate: Date;
  pricePoints: HistoricalPrice[];
  onSelectedDateChange: (date: Date) => void;
  hideAxes?: boolean;
  isLoading: boolean;
}

type HistoricalPrice = {
  price: number;
  date: string;
};

const getAxes = (currentLabel: DatedChartLabel, hideAxes: boolean): AxesProps<HistoricalPrice> => {
  return {
    yAxis: {
      dataKey: "price",
      hide: hideAxes,
      interval: "preserveStartEnd",
      domain: [ 0, "dataMax" ],
      tickFormatter: v => formatCurrencyString(Math.round(v)),
      allowDataOverflow: false,
      type: "number",
      tick: {
        fontSize: 14,
        width: 800
      }
    },
    xAxis: {
      dataKey: "date",
      hide: hideAxes,
      tickFormatter: v => currentLabel === "1M" || currentLabel === "6M" ? formatDate(new Date(v), "dd MMM") : formatDate(new Date(v), "dd MMM yyyy"),
      interval: "preserveStartEnd",
      tick: { fontSize: 14 }
    }
  };
};

export const PriceGraph: React.FC<PriceGraphProps> = ({
  historicalStartDate, pricePoints, onSelectedDateChange, hideAxes = false, isLoading
}) => {
  const [ selectedDateLabel, setSelectedDateLabel ] = React.useState<DatedChartLabel>(DatedChartLabel.all);
  let variationPercentage = 0;

  if (pricePoints.length) {
    variationPercentage = 100 * (pricePoints[ pricePoints.length - 1 ].price - pricePoints[ 0 ].price) / (pricePoints[ 0 ].price);
  }

  return (
    <Graph<HistoricalPrice>
      GraphHeader={<GraphHeader text="Market Value" />}
      height={300}
      axes={getAxes(selectedDateLabel, hideAxes)}
      data={pricePoints}
      isLoading={isLoading}
      toolTipLabelFormatter={v => formatDate(new Date(v), "dd MMM yyyy HH:mm")}
      GraphFooter={(
        <DatedGraphFooter
          historicalStartDate={historicalStartDate}
          onSelectedLabelChange={label => setSelectedDateLabel(label)}
          onSelectedDateChange={date => onSelectedDateChange(date)}
          excludeLabelOptions={[ DatedChartLabel.day, DatedChartLabel.week ]}
        >
          <VariationPriceBox variationPercentage={variationPercentage} />
        </DatedGraphFooter>
      )}
    />
  );
};
