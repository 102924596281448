import React from "react";
import styled from "styled-components";

import { PageConfines } from "./PageConfines";

export const CollectorPageTop: React.FC = props => {
  return (
    <Wrapper>
      <PageConfines>
        {props.children}
      </PageConfines>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: ${({ theme }) => theme.colors.dark};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 1rem;
`;
