import React from "react";
import { toast } from "react-toastify";

import {
  FormError, FormErrorProps, FormRow 
} from "src/components/forms";
import { SearchAheadItem } from "src/components/shared/search-ahead/SearchAhead";
import { useApiRequest } from "src/utils/api";
import { SensorGroup } from "src/utils/api/routes/sensors.api";
import { SelectInput } from "../forms/SelectInput";

interface SensorGroupSelectProps {
  setSensorGroupId: (id: number | null) => void;
  errorMessage?: FormErrorProps["message"];
}

export const SensorGroupSelect: React.FC<SensorGroupSelectProps> = props => {
  const [ sensorGroupRes, sensorGroupReq ] = useApiRequest("SENSORS:list-groups");

  React.useEffect(() => {
    sensorGroupReq({ params: {} });
  }, [ sensorGroupReq ]);
  
  // Listen for a response from the sensor search
  React.useEffect(() => {
    if (sensorGroupRes.errorMessage) {
      toast.error(sensorGroupRes.errorMessage);
    }
  }, [ sensorGroupRes ]);

  // Set Previous sensor Id
  const onSelect = React.useCallback((selected?: SearchAheadItem<SensorGroup> | null) => {
    if (selected && selected.value) {
      props.setSensorGroupId(selected.value.id);
    } else {
      props.setSensorGroupId(null);
    }
  }, [ props ]);

  return (
    <>
      {sensorGroupRes.data && (
        <FormRow label="Select a sensor location">
          <SelectInput<SensorGroup>
            items={sensorGroupRes.data.map(sensorGroup => ({
              label: sensorGroup.name,
              value: sensorGroup
            })) || []}
            onSelect={onSelect}
          />
          <FormError message={props.errorMessage} />
        </FormRow>
      )}
      
    </>
  );
};
