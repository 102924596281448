import styled, { css } from "styled-components";

interface TextInputProps {
  isInvalid?: boolean;
}

/**
 * Text input field
 */
export const TextInput = styled.input.attrs<TextInputProps>(({ isInvalid }) => ({ "aria-invalid": isInvalid || false }))<TextInputProps>`
  ${({ theme, isInvalid }) => css`
    font-family: ${theme.font.family.secondary};
    padding: 1rem;
    width: 100%;
    height: 4rem;
    font-weight: ${theme.font.weights.medium};
    font-size: ${theme.font.sizes.bodySmall};
    border: 0.1rem solid ${!isInvalid ? theme.colors.fadedDarker : theme.actions.error};
    border-radius: ${theme.borderRadius.medium};
    outline: none;
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }


  `};
`;
