import React from "react";
import { toast } from "react-toastify";

import { CaseHistoryTable } from "src/components/case/CaseHistoryTable";
import { useApiRequest } from "src/utils/api";
import { Case } from "src/utils/api/routes/cases.api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

interface CollectorCaseHistoryParams {
  caseId: string;
  currentData: Pick<Case, "location" | "status" | "createdAt">;
}

export const CollectorCaseHistory: React.FC<CollectorCaseHistoryParams> = props => {
  const [ loading, setLoading ] = React.useState(false);
  const [ getCaseRes, getCaseReq ] = useApiRequest("CASE-HISTORIES:get");

  // Request the case by id based off path params
  React.useEffect(() => {
    if (props.caseId && !getCaseRes.data && !getCaseRes.loading) {
      getCaseReq({ pathParams: { caseId: props.caseId } });
    }
  }, [
    props,
    getCaseReq,
    getCaseRes
  ]);

  // Detect the response for get case by id
  React.useEffect(() => {
    if (getCaseRes.data) {
      setLoading(false);
    }

    if (getCaseRes.errorMessage) {
      toast.error(getCaseRes.errorMessage);
    }
  }, [ getCaseRes ]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {getCaseRes.data?.items && (
        <CaseHistoryTable
          data={getCaseRes.data.items}
          currentData={props.currentData}
        />
      )}
    </>
  );
};
