import { Box } from "grommet";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import React from "react";
import { Column, Row } from "react-table";
import { toast } from "react-toastify";

import { BulkEditModal, SelectCaseStatusColumnFilter } from "src/components/case";
import { BulkActionLayer } from "src/components/shared/bulk-action-layer";
import { Button } from "src/components/shared/button";
import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Table } from "src/components/shared/table";
import { Text } from "src/components/shared/text";
import { useTableFetch, FetchInfo } from "src/hooks/useTableFetch";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { BulkEditCaseInput, Case } from "src/utils/api/routes/cases.api";
import { formatCurrency } from "src/utils/currency";

type CasesTable = Pick<Case, "id" | "name" | "userId" | "lwin" | "bookPrice" | "status" | "location" | "packSize" | "bottleSize">;

const columns: Array<Column<CasesTable>> = [
  {
    Header: "Id",
    id: "id",
    accessor: "id",
    disableFilters: true,
    width: 30
  },
  {
    Header: "Name",
    accessor: "name",
    id: "name",
    width: 200
  },
  {
    Header: "Bottle Size",
    accessor: "bottleSize",
    id: "bottleSize"
  },
  {
    Header: "Pack Size",
    accessor: "packSize",
    id: "packSize"
  },
  {
    Header: "LWIN",
    accessor: "lwin",
    id: "lwin",
    disableFilters: true
  },
  {
    Header: "User Id",
    accessor: "userId",
    id: "userId"
  },
  {
    Header: "Book Price",
    accessor: "bookPrice",
    id: "bookPrice",
    Cell: cell => formatCurrency(cell.value)
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
    Filter: SelectCaseStatusColumnFilter
  },
  {
    Header: "Location",
    accessor: "location",
    id: "location"
  }

];

const hiddenColumns = [ "id", "userId" ];

const CasesTable: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ data, setData ] = React.useState<CasesTable[]>([]);
  const [ selected, setSelected ] = React.useState<CasesTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ bulkUpdateCasesRes, bulkUpdateCasesReq ] = useApiRequest("CASES:bulk-update");
 
  // Keep a ref to store table data, so that when 
  // we refetch we keep page, filters, sortBy the same.
  const fetchInfo = React.useRef<FetchInfo<CasesTable>>({
    id: 0,
    pageIndex: 1,
    pageSize: 10,
    sortBy: [ { id: "name" } ]
  });

  const [ casesRes, fetch ] = useTableFetch<"CASES:list", CasesTable>("CASES:list", {
    defaultSort: [ [ "name", "ASC" ] ],
    fetchRef: fetchInfo 
  });

  const [ editModalOpen, setEditModalOpen ] = React.useState(false); 

  React.useEffect(() => {
    if (casesRes.data && casesRes.data.items) {
      setData(casesRes.data.items);
      setPageCount(Math.ceil(casesRes.data.total / casesRes.data.limit));
      setLoading(false);
    }

    if (casesRes.errorMessage) {
      toast.error(casesRes.errorMessage);
    }
  }, [ casesRes ]);

  const handleOnClick = React.useCallback((row: Row<CasesTable>) => {
    props.history.push(`/admin/case/${row.original.id}`);
  }, [ props.history ]);

  const handleSelectRows = React.useCallback((selected: CasesTable[]) => {
    if (selected.length > 0) {
      setSelected(selected);
    } else {
      setSelected([]);
    }
  }, []);

  const handleBulkEdit = React.useCallback((data: BulkEditCaseInput) => {
    data.ids = selected.map(sel => sel.id);
    const valuesToUpdate = pickBy(data, identity) as BulkEditCaseInput;

    setLoading(true);
    bulkUpdateCasesReq({ data: valuesToUpdate });
  }, [ bulkUpdateCasesReq, selected ]);

  React.useEffect(() => {
    if (bulkUpdateCasesRes.data) {
      toast.success(bulkUpdateCasesRes.data.message);
      setEditModalOpen(false);
      setLoading(false);

      // Get last fetch information to refresh the table:
      const {
        pageIndex, tableFilters, pageSize, sortBy
      } = fetchInfo.current;

      fetch({
        pageIndex,
        pageSize,
        tableFilters,
        sortBy
      });
    }

    if (bulkUpdateCasesRes.errorMessage) {
      toast.error(bulkUpdateCasesRes.errorMessage);
      setLoading(false);
      setEditModalOpen(false);
    }
  }, [
    bulkUpdateCasesRes,
    fetch,
    pageCount
  ]);

  return (
    <AdminLayout>
      <PageHeader title="Cases" backLinkText="Back to dashboard" backLink={() => props.history.push("/admin/dashboard")} />
      <Card>
        <Table<CasesTable>
          name="casesTable"
          columns={columns}
          data={data}
          title={`${casesRes.data?.total || 0} Cases`}
          onClick={handleOnClick}
          loading={loading}
          fetchData={fetch}
          pageCount={pageCount}
          defaultHiddenColumns={hiddenColumns}
          featuredFilter="name"
          handleSelectRows={handleSelectRows}
          useResizeColumns
          usePagination
          useFilters
          useHideColumns
          useBulkSelect
          useSortBy
        />
      </Card>

      {selected.length > 0 && (
        <BulkActionLayer>
          <Text>
            {selected.length}
            {" "}
            selected
          </Text>
          <Box direction="row">
            <Button label="Edit" onClick={() => setEditModalOpen(true)} />
          </Box>
        </BulkActionLayer>
      )}

      {editModalOpen && (
        <BulkEditModal onClose={() => setEditModalOpen(false)} onSubmit={values => handleBulkEdit(values)} isLoading={loading} selectedQty={selected.length} />
      )}

    </AdminLayout>
  );
};

export default CasesTable;
