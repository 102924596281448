import { Box } from "grommet";
import React from "react";

import { Text } from "../shared/text";
import { formatCurrency } from "src/utils/currency";

interface Props {
  totalInvested?: number;
  readyToInvest?: number;
}

export const CashValueBox: React.FC<Props> = props => {
  return (
    <Box direction="row">
      {/* <Box pad={{
        vertical: "small",
        horizontal: "medium" 
      }} align="end">
        <Text color="fadedDark" as="h6" bold={true}>Total Invested</Text>
        <Text as="h2" >{formatCurrency(props.totalInvested)}</Text>
      </Box> */}
      <Box
        pad={{
          top: "small",
          horizontal: "medium" 
        }}
        align="end"
      >
        <Text color="fadedDark" as="h6" bold>
          Ready to invest
        </Text>
        <Box pad={{ top: "5px" }}>
          <Text as="h2" color={(props.readyToInvest || 0) > 0 ? "success" : "warning"}>
            {formatCurrency(props.readyToInvest)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
