import styled, { css } from "styled-components";
import { DateInput as GrommetDateInput } from "grommet";

interface DateInputProps {
  isInvalid?: boolean;
}

/**
 * Text input field
 */
export const DateInput = styled(GrommetDateInput).attrs<DateInputProps>(({ isInvalid }) => ({ "aria-invalid": isInvalid || false }))<DateInputProps>`
  ${({ theme, isInvalid }) => css`
    font-family: ${theme.font.family.secondary};
    padding: 1rem;
    width: 100%;
    height: 4rem;
    font-weight: ${theme.font.weights.medium};
    font-size: ${theme.font.sizes.bodySmall};
    border: 0.1rem solid ${!isInvalid ? theme.colors.fadedDarker : theme.actions.error};
    border-radius: ${theme.borderRadius.medium};
    outline: none;
  `};
`;
