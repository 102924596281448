import React from "react";
import { Box } from "grommet";

import { Text } from "../shared/text";
import { Icon, Icons } from "../shared/icon";

interface WineIconBoxProps {
  label?: string;
  width?: string;
  icon: Icons;
}
export const WineIconBox: React.FC<WineIconBoxProps> = props => {
  return (
    <Box direction="row" pad={{ vertical: "small" }} width={props.width}>
      <Icon icon={props.icon} />
      <Box pad={{ left: "small" }}>
        <Text>
          {props.label}
        </Text>
      </Box>
    </Box>
  );
};