import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import React from "react";
import { toast } from "react-toastify";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { UserForm } from "src/components/user";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { UpdateUserInput } from "src/utils/api/routes/users.api";
import { CreateUserForm } from "src/components/user/UserForm";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

interface UserPageParams {
  userId: string;
}

export const EditUserForm: React.FC<PageProps<Record<string, unknown>, UserPageParams>> = props => {
  const [ updateUserRes, updateUserReq ] = useApiRequest("USERS:update");
  const [ getUserRes, getUserReq ] = useApiRequest("USERS:get");
  const [ getRolesRes, getRolesReq ] = useApiRequest("ROLES:list");
  const [ preparedUserDefaults, setPreparedUserDefaults ] = React.useState<Partial<CreateUserForm>>();

  const onSubmit = React.useCallback((user: Partial<UpdateUserInput>) => {
    user.status = getUserRes.data?.status;
    const valuesToUpdate = pickBy(user, identity);

    if (props.match?.params?.userId) {
      // Email cannot be changed.
      delete valuesToUpdate.email;

      updateUserReq({
        data: valuesToUpdate,
        pathParams: { userId: props.match.params.userId }
      });
    }
  }, [
    getUserRes.data,
    props.match,
    updateUserReq
  ]);

  React.useEffect(() => {
    if (updateUserRes.data) {
      const { name, id } = updateUserRes.data;

      toast.success(`${name} was updated successfully. Click here to view.`, {
        autoClose: false,
        onClick: () => props.history.push(`/admin/user/${id}`)
      });
    }

    if (updateUserRes.errorMessage) {
      toast.error(updateUserRes.errorMessage);
    }
  }, [
    props.history,
    updateUserRes.data,
    updateUserRes.errorMessage
  ]);

  React.useEffect(() => {
    if (props.match?.params?.userId) {
      getUserReq({ pathParams: { userId: props.match.params.userId } });
    }
  }, [ getUserReq, props.match ]);

  React.useEffect(() => {
    if (getUserRes.data) {
      setPreparedUserDefaults({
        ...getUserRes.data,
        cashBalance: getUserRes.data.cashBalance ? getUserRes.data.cashBalance.toString() : "0",
        storageFeeRates: getUserRes.data.storageFeeRates?.map(rate => ({
          rateStart: rate.rateStart.toString(),
          rateEnd: rate.rateEnd?.toString() || null,
          percentage: rate.percentage.toString()
        })),
        roles: getUserRes.data.roles.map(role => {
          return {
            id: role.id,
            name: role.name,
            group: role.group
          };
        })
      });
    }

    if (getUserRes.errorMessage) {
      toast.error(getUserRes.errorMessage);
    }
  }, [ getUserRes ]);

  React.useEffect(() => {
    getRolesReq({});
  }, [ getRolesReq ]);
  
  React.useEffect(() => {
    if (getRolesRes.errorMessage) {
      toast.error(getRolesRes.errorMessage);
    }
  }, [ getRolesRes ]);

  return (
    <AdminLayout>
      <PageHeader title="Edit User" backLink={() => props.history.goBack()} />
      <Card pad="small">
        {getRolesRes.loading && <LoadingSpinner />}
        {preparedUserDefaults && getRolesRes.data && (
          <UserForm
            isUpdatingUser
            isLoading={updateUserRes.loading}
            onSubmit={onSubmit}
            defaultValues={preparedUserDefaults}
            roles={getRolesRes.data}
          />
        )}
      </Card>

    </AdminLayout>
  );
};
