import { ApiRouteDefinition } from "../useApiRequest";
import { PickParams, PaginatedResponse } from "../api";

import { Case, CaseStatus } from "./cases.api";
import { User } from "./users.api";
import { Sensor } from "./sensors.api";

const CaseHistoryRoutes = {
  /** Get Case history by case id */
  "CASE-HISTORIES:get": {
    path: "/cases/:caseId/history",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<CaseHistory>, null, PickParams<"caseId">>
};

export default CaseHistoryRoutes;

// Allow any here, we don't know which action it will be 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CaseHistory<T extends keyof CaseHistoryActionData = any> {
  id: number;
  caseId: number;
  caseData: Case;
  action: CaseHistoryActionType;
  actionData: CaseHistoryActionDataType<T>;
  date: Date;
}

export type CaseHistoryActionDataType<T extends keyof CaseHistoryActionData> = CaseHistoryActionData[T];

export enum CaseHistoryActionType {
  transferred = "transferred",
  status_change = "status_change",
  sensor_change = "sensor_change",
  location_change = "location_change",
}

export interface CaseHistoryActionData {
  transferred: {
    from: number;
    to: number;
    fromData: Pick<User, "name" | "id" >;
    toData: Pick<User, "name" | "id" >;
  };
  status_change: {
    from: CaseStatus;
    to: CaseStatus;
  };
  sensor_change: {
    from: number;
    to: number;
    fromData: Pick<Sensor, "name" | "id" >;
    toData: Pick<Sensor, "name" | "id" >;
  };
  location_change: {
    from: string;
    to: string;
  };
}
