import React from "react";
import { Layer, Box } from "grommet";

import { Text } from "../shared/text";
import { AssignCaseToUserInput } from "src/utils/api/routes/cases.api";

import { AssignCaseToUserForm } from "./AssignCaseToUserForm";

interface Props {
  onSubmit: (data: AssignCaseToUserInput) => void;
  onClose: () => void;
  isLoading: boolean;
}

export const AssignCaseModal: React.FC<Props> = props => {
  return (
    <Layer position="center" onClickOutside={props.onClose} onEsc={props.onClose}>
      <Box
        direction="row"
        align="center"
        as="header"
        justify="between"
        pad="medium" 
        border="bottom"
      >
        <Text bold as="h6">
          Assign case
        </Text>
      </Box>
      <Box pad="medium" gap="small" width="medium">
        <Text as="p">
          Assign this case to a user.
        </Text>
        <AssignCaseToUserForm onSubmit={props.onSubmit} isLoading={props.isLoading} />
      </Box>
    </Layer>
  );
};