import { ApiRouteDefinition } from "../useApiRequest";
import { Case } from "src/utils/api/routes/cases.api";
import {
  RecordTimestamps, PaginatedResponse, PaginatedQueryRequest, PickParams
} from "../api";

const SensorRoutes = {
  /** list all sensors groups */
  "SENSORS:list-groups": {
    path: "/sensors/groups/all",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<SensorGroup[], null, null, {filters?: Partial<Pick<SensorGroup, "name" | "externalReference">>}>,
  /** list all sensors */
  "SENSORS:list": {
    path: "/sensors",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Sensor>, null, null, PaginatedQueryRequest<Partial<Sensor>>>,

  /** list all sensors */
  "SENSORS:get-by-id": {
    path: "/sensors/:sensorId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Sensor, null, PickParams<"sensorId">>,

  /** list all sensors */
  "SENSORS:get-sensor-measurements": {
    path: "/sensors/:sensorId/measurements",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<SensorMeasure>, null, PickParams<"sensorId">, PaginatedQueryRequest<SensorMeasureFilters>>

};

export default SensorRoutes;

export interface SensorMeasureFilters {
  startDate: Date;
  endDate?: Date;
}
export interface Sensor extends RecordTimestamps {
  id: number;
  name: string;
  sensorGroupId: number;
  sensorMeasureUnitId: number;
  externalReference: string;
  sensorMeasurements?: SensorMeasure[]
  status: SensorStatus;
  sensorGroup?: SensorGroup;
  sensorMeasureUnit?: SensorMeasureUnit;
}

export interface SensorMeasure {
  id: number;
  sensorId: number;
  value: number;
  date: string;
}

export interface SensorMeasureUnit {
  id: number;
  name: string;
  unit: MeasurementUnit;
}

export enum MeasurementUnit {
  percentage = "%",
  degreesCelcius = "˚C",
}

export interface SensorGroup {
  id: number;
  name: string;
  externalReference: string;
  createdAt: Date;
  updatedAt: Date;
  cases?: Case[];
  sensors?: Sensor[];
}

export enum SensorStatus {
  active = "active",
  suspended = "suspended",
}