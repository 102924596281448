import { Box, Menu } from "grommet";
import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { AssignCaseModal } from "src/components/case/AssignCaseModal";
import { bottleTableColumns, CaseBottlesTable } from "src/components/case/CaseBottlesTable";
import { CurrentValueBox } from "src/components/shared/price-graph";
import { PageHeader } from "src/components/shared/layout";
import { Card } from "src/components/shared/layout/Card";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { TabPanel } from "src/components/shared/tabs/TabPanel";
import { TabTitle } from "src/components/shared/tabs/TabTitle";
import { Text } from "src/components/shared/text";
import { Table } from "src/components/shared/table";
import {
  InfoPanel, InfoColumn, InfoBox
} from "src/components/shared/info";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import {
  AssignCaseToUserInput, Case, statusLabels, CaseBottles
} from "src/utils/api/routes/cases.api";
import { theme } from "src/utils/theme";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import { formatCurrency } from "src/utils/currency/formatCurrency";

import { CaseHistoryTab } from "./CaseHistoryTab";
import { CaseValueGraph } from "./CaseValueGraph";

interface CasePageParams {
  caseId: string;
  vintageId?: string;
}

const hiddenColumns = [ "id" ];

export const ViewCasePage: React.FC<PageProps<Record<string, unknown>, CasePageParams>> = props => {
  const [ tabIndex, setTabIndex ] = React.useState(0);
  const [ singleCase, setCase ] = React.useState<Case>();
  const [ bottleTableData, setBottleTableData ] = React.useState<CaseBottles[]>([]);
  const [ loading, setLoading ] = React.useState(true);
  const [ assignCaseModal, setAssignCaseModal ] = React.useState(false);
  const [ getCaseRes, getCaseReq ] = useApiRequest("CASES:get");
  const [ updateCaseRes, updateCaseReq ] = useApiRequest("CASES:update");

  // Request the case by id based off path params
  React.useEffect(() => {
    if (!singleCase && props.match?.params?.caseId) {
      getCaseReq({ pathParams: { caseId: props.match.params.caseId } });
    }
  }, [
    props,
    getCaseReq,
    singleCase
  ]);

  // Detect the response for get case by id
  React.useEffect(() => {
    if (getCaseRes.data) {
      setCase(getCaseRes.data);

      if (getCaseRes.data.bottles) {
        const bottles = getCaseRes.data.bottles.map(bottle => ({
          ...bottle,
          verifiedCode: bottle.label ? bottle.label.verifiedCode : null
        }));

        setBottleTableData(bottles);
      }

      setLoading(false);
    }

    if (getCaseRes.errorMessage) {
      toast.error(getCaseRes.errorMessage);
    }
  }, [
    getCaseRes,
    props.history,
    props.match
  ]);

  // Handle Assignment submission form
  const handleAssignSubmit = React.useCallback((data: AssignCaseToUserInput) => {
    if (singleCase) {
      updateCaseReq({
        data: {
          ...data,
          id: singleCase.id
        },
        pathParams: { caseId: singleCase.id.toString() }
      });
    }
  }, [ singleCase, updateCaseReq ]);

  // Handle update case response
  React.useEffect(() => {
    if (updateCaseRes.data) {
      setAssignCaseModal(false);
      setCase(updateCaseRes.data);
    }

    if (updateCaseRes.errorMessage) {
      toast.error(updateCaseRes.errorMessage);
    }
  }, [ updateCaseRes ]);

  return (
    <AdminLayout>
      <PageHeader backLinkText="Back to all cases" backLink={() => props.history.push("/admin/cases")} />
      {loading && <LoadingSpinner />}
      {assignCaseModal && <AssignCaseModal isLoading={updateCaseRes.loading} onSubmit={data => handleAssignSubmit(data)} onClose={() => setAssignCaseModal(false)} />}
      {singleCase && (
        <Box justify="between" direction="row" align="end" pad={{ bottom: "medium" }}>
          <Box>
            <Box pad={{ vertical: "medium" }}>
              <Text as="h1" bold>
                {singleCase.name}
              </Text>
            </Box>
          </Box>
          <Box>
            <Menu
              dropProps={{
                align: {
                  top: "bottom",
                  right: "right"
                }
              }}
              items={[
                {
                  label: "Edit case",
                  onClick: () => props.history.push(`/admin/case/${singleCase.id}/edit`)
                },
                {
                  label: singleCase.userId ? "Re-assign case" : "Assign case to a user",
                  onClick: () => setAssignCaseModal(true)
                }
              ]}
              label="Actions"
            />
          </Box>

        </Box>
      )}
      <Box direction="row">
        <Box width="100%">
          {singleCase && (
            <InfoPanel>
              <InfoColumn>
                <InfoBox label="Pack Size" value={singleCase.packSize} />
                <InfoBox label="Bottle Size" value={singleCase.bottleSize} />
                <InfoBox label="Location" value={singleCase.location} />
                <InfoBox label="Status" value={statusLabels[ singleCase.status ]} />
                <InfoBox label="Book Price" value={formatCurrency(singleCase.bookPrice)} />
                <InfoBox
                  label="User"
                  value={(
                    <Link to={`/admin/user/${singleCase.user?.id}`}>
                      {singleCase.user?.name}
                    </Link>
                  )}
                />
              </InfoColumn>
            </InfoPanel>
          )}
        </Box>
      </Box>
      <Card margin={{ top: "medium" }}>
        <Box
          border={{
            color: theme.colors.fadedLighter,
            side: "bottom",
            size: "2px"
          }}
          align="center"
          direction="row"
        >
          <TabTitle onClick={() => setTabIndex(0)} active={tabIndex === 0} title="Bottles" />
          <TabTitle onClick={() => setTabIndex(1)} active={tabIndex === 1} title="Performance" />
          <TabTitle onClick={() => setTabIndex(2)} active={tabIndex === 2} title="Case History" />
          {singleCase && (
            <Box margin={{ left: "auto" }}>
              <CurrentValueBox currentValue={singleCase.currentValue} title="Market Value" />
            </Box>
          )}
        </Box>
        <TabPanel active={tabIndex === 0}>
          <Table<CaseBottlesTable>
            title={`${bottleTableData.length} bottles`}
            name="caseVintagesTable"
            columns={bottleTableColumns}
            data={bottleTableData}
            useResizeColumns
            loading={loading}
            pageCount={1}
            defaultHiddenColumns={hiddenColumns}
            useHideColumns
          />
        </TabPanel>
        <TabPanel active={tabIndex === 1}>
          {singleCase && <CaseValueGraph singleCase={singleCase} />}
        </TabPanel>
        <TabPanel active={tabIndex === 2}>
          {(singleCase && props.match?.params.caseId) && (
            <CaseHistoryTab
              currentData={{
                location: singleCase.location,
                createdAt: singleCase.createdAt,
                status: singleCase.status
              }}
              caseId={props.match.params.caseId}
            />
          )}
        </TabPanel>
      </Card>
    </AdminLayout>
  );
};
