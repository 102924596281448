import { Box } from "grommet";
import { Close, Checkmark } from "grommet-icons";
import React from "react";
import styled, { css } from "styled-components";

import {
  FormRow, Select, TextInput 
} from "src/components/forms";
import { Button } from "src/components/shared/button";
import { Grape } from "src/utils/api/routes/grapes.api";
import { Text } from "../shared/text";
import { GrapeInput } from "src/utils/api/routes/wines.api";

interface WineGrapeFormProps {
  grapeOptions: Grape[];
  onChange: (values: GrapeInput[]) => void;
  defaultValue?: GrapeInput[] | null;
}

interface WineGrapeRow {
  rowId: number;
  rowStatus: "editing" | "saved";
  grapeId?: number;
  blendPercent?: number;
}

type Action =
 | { type: "addGrape" }
 | { type: "editGrape"; data: WineGrapeRow }
 | { type: "saveGrape"; rowId: number }
 | { type: "removeGrape"; rowId: number };

export const WineGrapeForm: React.FC<WineGrapeFormProps> = props => {
  const initialGrapes: WineGrapeRow[] = props.defaultValue ? props.defaultValue.map((val, i) => {
    return { 
      grapeId: val.grapeId,
      blendPercent: val.blendPercent,
      rowId: i,
      rowStatus: "saved" 
    };
  }) : [
    {
      rowId: 1,
      rowStatus: "editing",
      grapeId: undefined,
      blendPercent: 100
    }
  ];

  const reducer = (grapes: WineGrapeRow[], action: Action) => {
    const nextRowId = grapes[ grapes.length - 1 ]?.rowId + 1 || 1;

    switch (action.type) {
      case "saveGrape": {
        const grapesToUpdate = grapes.map(grape => {
          if (grape.rowId == action.rowId) {
            grape = {
              ...grape,
              rowStatus: "saved" 
            };
          }

          return grape;
        });

        const savedGrapes = grapesToUpdate.filter(grape => grape.rowStatus === "saved").map(grape => {
          return {
            grapeId: grape.grapeId,
            blendPercent: grape.blendPercent
          } as GrapeInput;
        });
  
        props.onChange(savedGrapes);

        return grapesToUpdate;
      }
        
      case "editGrape":
        return grapes.map(grape => {
          if (grape.rowId == action.data.rowId) {
            grape = {
              ...action.data,
              rowStatus: "editing" 
            };
          }

          return grape;
        });
      case "addGrape":
        return [
          ...grapes,
          {
            rowId: nextRowId,
            rowStatus: "editing",
            grapeId: undefined,
            blendPercent: 50
          } as WineGrapeRow
        ]; 
      case "removeGrape": {
        const remaining = grapes.filter(grape => {
          return grape.rowId !== action.rowId;
        });

        const savedGrapes = remaining.filter(grape => grape.rowStatus === "saved").map(grape => {
          return {
            grapeId: grape.grapeId,
            blendPercent: grape.blendPercent
          } as GrapeInput;
        });

        props.onChange(savedGrapes);

        return remaining;
      }
    }
  };

  const [ grapes, dispatch ] = React.useReducer(reducer, initialGrapes);

  const totalPercentage = React.useMemo(() => {
    let total = 0;

    grapes.forEach(grape => {
      if (grape.rowStatus === "saved") {
        total = total + (grape.blendPercent || 0);
      }
    });

    return total;
  }, [ grapes ]);

  return (
    <Box pad={{ vertical: "medium" }}>
      <Text as="h4">
        Grapes
      </Text>
      {grapes.length === 0 && (
        <Box pad={{ vertical: "small" }}>
          <Text>
            No grapes
          </Text>
        </Box>
      )}
      {grapes.map(grape => (
        <GrapeRow className={grape.rowStatus} key={grape.rowId}>
          <Box pad="small">
            <FormRow label="Grape">
              <Select
                options={props.grapeOptions}
                labelKey="name"
                value={props.grapeOptions.find(grapeOption => grapeOption.id === grape.grapeId)}
                valueKey="id"
                onChange={({ value }) => {
                  dispatch({
                    type: "editGrape",
                    data: {
                      ...grape,
                      grapeId: value.id
                    } 
                  });
                }}
                name="grapes"
              />
            </FormRow>
          </Box>
          <Box pad="small">
            <FormRow label="Blend percentage">
              <TextInput 
                value={grape.blendPercent} 
                type="number" 
                step={10} 
                max={100}
                min={0}
                onChange={e => {
                  dispatch({
                    type: "editGrape",
                    data: {
                      ...grape,
                      blendPercent: parseInt(e.target.value, 10) 
                    } 
                  });
                }} 
              />
            </FormRow>
          </Box>
          <Box margin={{ left: "auto" }} direction="row">
            <Box pad="small">
              {(grape.grapeId && grape.blendPercent && grape.rowStatus === "editing") && (
                <Checkmark 
                  cursor="pointer"
                  size="2rem"
                  onClick={() => dispatch({
                    type: "saveGrape",
                    rowId: grape.rowId 
                  })} 
                />
              )}
            </Box>
            <Box pad="small">
              <Close 
                cursor="pointer"
                size="2rem"
                onClick={() => dispatch({
                  type: "removeGrape",
                  rowId: grape.rowId 
                })} 
              />
            </Box>
          </Box>
        </GrapeRow>
      ))}
  
      {totalPercentage > 100 && (
        <Warning>
          Combined blend percentages cannot exceed 100%.
        </Warning>
      )}
      <Box>
        <Button onClick={() => dispatch({ type: "addGrape" })} label="Add grape" />
      </Box>
    </Box>
  );
};

const GrapeRow = styled(Box)`
  ${({ theme }) => css`
    flex-direction: row;
    align-items: center;
    &.editing {
      padding: 1rem;
      background: ${theme.colors.fadedLight};
    }
    &.saved {
      background: ${theme.colors.fadedLight};
    }
    border-radius: 5px;
    margin-top: 2px;
  `}
`;

const Warning = styled(Box)`
  ${({ theme }) => css`
    flex-direction: row;
    align-items: center;
    color: white;
    background: ${theme.colors.warning};
    border-radius: 5px;
    margin: 6px 0;
    padding: 10px;
  `}
`;
