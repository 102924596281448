import { ApiRouteDefinition } from "../useApiRequest";
import {
  PaginatedQueryRequest,
  PaginatedResponse,
  RecordWithId,
  RecordTimestamps,
  PickParams 
} from "../api";

const SensorRoutes = {
  /** Get vintage historical prices */
  "PRICES:getVintageHistorical": {
    path: "/prices/vintages/:vintageId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<VintagePrice>, null, PickParams<"vintageId">, PaginatedQueryRequest<Partial<VintagePrice>>>,
  
  /** Get portfolio historical values */
  "PRICES:getPortfolioHistorical": {
    path: "/prices/portfolios/:userId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<PortfolioValue>, null, PickParams<"userId">, PaginatedQueryRequest<Partial<PortfolioValue>>>,
  
  /** Get case historical values */
  "PRICES:getCaseHistorical": {
    path: "/prices/cases/:caseId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<CaseValue>, null, PickParams<"caseId">, PaginatedQueryRequest<Partial<CaseValue>>>
};

export default SensorRoutes;

export interface VintagePrice extends RecordTimestamps, RecordWithId {
  id: number;
  vintageId: number;
  price: number;
  priceDate: Date;
  livexPriceDate: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface PortfolioValue extends RecordTimestamps, RecordWithId {
  id: number;
  userId: number;
  value: number;
  bookValue: number;
  valueDate: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface CaseValue extends RecordTimestamps, RecordWithId {
  id: number;
  caseId: number;
  value: number;
  bookPrice: number;
  valueDate: Date;
  createdAt: Date;
  updatedAt: Date;
}
