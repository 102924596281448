import { Box } from "grommet";
import React, { FormEvent, ReactElement } from "react";
import { TableInstance, Filters } from "react-table";

type FeaturedFilterProps<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;
  featuredFilter?: keyof T;
  setTableFilters: (data: Filters<T>) => void;
};

export function FeaturedFilter<T extends Record<string, unknown>>({
  instance, featuredFilter, setTableFilters 
}: FeaturedFilterProps<T>): ReactElement {
  const { allColumns } = instance;
  
  const onSubmit = React.useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const filterData: Filters<T> = [];
    
    allColumns.map(col => {
      filterData.push({
        id: col.id,
        value: col.filterValue
      });
    });
    setTableFilters(filterData);
  },
  [ allColumns, setTableFilters ]);

  const featuredColumn = allColumns.find(it => it.canFilter && it.id === featuredFilter);

  return (
    <Box>
      {featuredColumn && (
        <Box 
          pad="small" 
        >
          <form onSubmit={onSubmit}>
            <div>
              <div key={featuredColumn.id}>
                {featuredColumn.render("Filter")}
              </div>
            </div>
          </form>
        </Box>
      )}
    </Box>

  );
}
