import React from "react";
import pickBy from "lodash/pickBy";
import { Box } from "grommet";

import { Button } from "src/components/shared/button";

export enum DatedChartLabel {
  day = "24h",
  week = "1W",
  month = "1M",
  biannual = "6M",
  annual = "1Y",
  decade= "10Y",
  all ="ALL"
}

type ChartLabelDate = Record<DatedChartLabel, Date>;
interface DatedGraphFooterProps {
  historicalStartDate: Date;
  onSelectedDateChange: (labelDate: Date) => void;
  onSelectedLabelChange: (label: DatedChartLabel) => void;
  excludeLabelOptions?: DatedChartLabel[];
}

/**
 * Get a struct associating labels with specific dates.
 *
 * @param startDate - the graph start date.
 */
const getChartDateLabels = (startDate: Date, excludeLabelOptions?: DatedChartLabel[]) => {
  const today = new Date();
  // 1D
  const oneDayAgo = new Date(today.getTime());

  oneDayAgo.setDate(today.getDate() - 1);
  // 1W
  const oneWeekAgo = new Date(today.getTime());

  oneWeekAgo.setDate(today.getDate() - 7);
  // 1M
  const oneMonthAgo = new Date(today.getTime());

  oneMonthAgo.setUTCMonth(today.getUTCMonth() - 1);
  // 6M
  const sixMonthsAgo = new Date(today.getTime());

  sixMonthsAgo.setUTCMonth(today.getUTCMonth() - 6);
  // 1Y
  const oneYearAgo = new Date(today.getTime());

  oneYearAgo.setUTCFullYear(today.getUTCFullYear() - 1);
  // 10Y
  const tenYearsAgo = new Date(today.getTime());

  tenYearsAgo.setUTCFullYear(today.getUTCFullYear() - 10);

  const dateLabels: ChartLabelDate = {
    [ DatedChartLabel.day ]: oneDayAgo,
    [ DatedChartLabel.week ]: oneWeekAgo,
    [ DatedChartLabel.month ]: oneMonthAgo,
    [ DatedChartLabel.biannual ]: sixMonthsAgo,
    [ DatedChartLabel.annual ]: oneYearAgo,
    [ DatedChartLabel.decade ]: tenYearsAgo,
    [ DatedChartLabel.all ]: startDate
  };

  // Pick only the label that applies, depending on the graph start date
  // (we don't want to show labels with associates dates before the start date).
  return pickBy(dateLabels, (date, label) => {
    if (excludeLabelOptions && excludeLabelOptions.includes(label as DatedChartLabel)) {
      return false;
    }

    return startDate < date || label === "ALL";
  }) as ChartLabelDate;
};

export const DatedGraphFooter: React.FC<DatedGraphFooterProps> = ({
  historicalStartDate, onSelectedDateChange, onSelectedLabelChange, excludeLabelOptions, children
}) => {
  const [ selectedDateLabel, setSelectedDateLabel ] = React.useState<DatedChartLabel>(DatedChartLabel.all);
  const labelsDates = getChartDateLabels(historicalStartDate, excludeLabelOptions);

  return (
    <Box pad={{ top: "medium" }} justify="between" direction="row" align="center">
      <Box direction="row">
        {Object.keys(labelsDates).map(dateLabel => {
          return (
            <Box pad={{ right: "small" }} key={dateLabel}>
              <Button
                isSelected={selectedDateLabel === dateLabel}
                small
                backgroundColor="fadedMid"
                textColor="fadedDarker"
                label={dateLabel}
                onClick={() => {
                  setSelectedDateLabel(dateLabel as DatedChartLabel);
                  onSelectedLabelChange(dateLabel as DatedChartLabel);
                  onSelectedDateChange(labelsDates[ dateLabel as DatedChartLabel ]);
                }}
              />
            </Box>
          );
        })}
      </Box>
      {children}
    </Box>
  );
};
