import React from "react";
import { Box } from "grommet";
import styled, { css } from "styled-components";
import { Trash } from "grommet-icons";

import { Label, LabelStatus } from "src/utils/api/routes/labels.api";
import { Text } from "../shared/text";
import { formatUid } from "src/utils/formatLabelCode";
import { Icon } from "../shared/icon";
import { Icons } from "../shared/icon/Icon";

interface LabelCardProps {
  label: Label;
  canClear: boolean;
  removeLabel: (labelId: string) => void;
}
export const LabelCard: React.FC<LabelCardProps> = props => {
  const [ showAllCodes, setShowAllCodes ] = React.useState(props.label.status !== LabelStatus.valid);

  return (
    <StyledBox className={props.label.status} margin={{ top: "1rem" }}>
      <Box direction='row' pad="small" justify="between" align="center">
        <Text as="h4" bold>
          {props.label.isCase === null ? "Label" : props.label.isCase ? "Case" : "Bottle"}
        </Text>
        {props.canClear && (
          <Box onClick={() => props.removeLabel(props.label.id.toString())}>
            <Trash size="small" color="white" />
          </Box>
        )}
      </Box>
      
      <Box>
        {props.label.certificationReference && (
          <Box
            pad={{
              horizontal: "small",
              bottom: "small" 
            }}
          >
            <Text as="small" small bold>
              Code
            </Text>
            <Text bold style={{ cursor: "pointer" }} onClick={() => setShowAllCodes(!showAllCodes)}>
              {formatUid(props.label.certificationReference)}
            </Text>
          </Box>
        )}
       
        {showAllCodes && (
          <Box direction="row">
            {props.label.nfcCode && (
              <Box
                pad={{
                  horizontal: "small",
                  bottom: "small" 
                }}
              >
                <Text as="small" small bold>
                  NFC Code
                </Text>
                <Text as="small">
                  {props.label.nfcCode}
                </Text>
              </Box>
            )}
        
            {props.label.verifiedCode && (
              <Box
                pad={{
                  horizontal: "small",
                  bottom: "small" 
                }}
              >
                <Text as="small" small bold>
                  Verification Code
                </Text>
                <Text as="small">
                  {props.label.verifiedCode}
                </Text>
              </Box>
            )}
          </Box>
        )}
        {props.label.errors?.message && (
          <Box
            pad={{
              horizontal: "small",
              bottom: "small" 
            }}
          >
            <Text as="small" small bold>
              Error
            </Text>
            <Text as="small">
              {props.label.errors.message as string}
            </Text>
          </Box>
        )}
      </Box>
      <LabelIcon>
        <Icon
          size="3.5rem"
          color="rgba(0,0,0,0.3)"
          icon={props.label.isCase ? Icons[ "nfc-case" ] : Icons[ "nfc-bottle" ]}
        />
      </LabelIcon>
    </StyledBox>
  );
};

const LabelIcon = styled(Box)`
    position: absolute;
    bottom: 15px;
    right: 10px;
`;

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.fadedMid};
    border-radius: ${theme.borderRadius.small};
    padding: 1rem;

    &.error{
      background-color: ${theme.colors.error};
      color: white;
    }
    &.pending{
      background-color: ${theme.colors.fadedMid};
    }
    &.valid{
      background-color: ${theme.colors.success};
      color: white;
    }
  `}
`;