import * as React from "react";
import styled from "styled-components";

import MapApiWrapper from "./MapApiWrapper";
import { MapContainerProps } from "./MapContainer";

interface MapProps extends React.HTMLAttributes<HTMLDivElement>, MapContainerProps {}

/**
 * Map API wrapper
 */
export const Map: React.FC<MapProps> = props => {
  const { markers, ...rest } = props;

  return (
    <StyledMapWrapper {...rest}>
      <MapApiWrapper markers={markers} />
    </StyledMapWrapper>
  );
};

const StyledMapWrapper = styled.div`
  > div {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;