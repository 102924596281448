import {
  Box, CheckBox, Drop
} from "grommet";
import { Columns } from "grommet-icons";
import React, { ReactElement } from "react";
import { TableInstance } from "react-table";

import { Text } from "../text";

type ColumnHidePage<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;

};

export function ColumnHidePage<T extends Record<string, unknown>>({ instance }: ColumnHidePage<T>): ReactElement | null {
  const { allColumns, toggleHideColumn } = instance;
  const hideableColumns = allColumns.filter(column => !(column.id === "_selector"));
  const checkedCount = hideableColumns.reduce((acc, val) => acc + (val.isVisible ? 0 : 1), 0);
  const onlyOneOptionLeft = checkedCount + 1 >= hideableColumns.length;
  const targetRef = React.useRef<HTMLDivElement>(null);
  const [ showDrop, setShowDrop ] = React.useState(false);

  return (
    <Box align="center" justify="center">
      <Box
        pad="small"
        align="center"
        justify="start"
        ref={targetRef}
        onClick={() => setShowDrop(!showDrop)}
      >
        <Columns />
      </Box>
      {(showDrop && targetRef.current) && (
        <Drop
          onClickOutside={() => setShowDrop(false)}
          align={{
            top: "bottom",
            right: "right"
          }}
          target={targetRef.current}
        >
          <Box>
            <Box pad="small">
              <Text as="small" bold>
                Visible Columns
              </Text>
            </Box>

            <div>
              {hideableColumns.map(column => {
                return (
                  <Box direction="row" justify="between" key={column.id}>
                    <Box pad="small">
                      {column.render("Header")}
                    </Box>
                    <Box pad="small">
                      <CheckBox checked={column.isVisible} value={`${column.id}`} disabled={column.isVisible && onlyOneOptionLeft} onChange={() => toggleHideColumn(column.id, column.isVisible)} />
                    </Box>
                  </Box>
                );
              })}
            </div>
          </Box>
        </Drop>
      )}
    </Box>

  );
}
