import { Box, TableCell } from "grommet";
import { debounce } from "lodash";
import React from "react";
import { Checkmark, Close } from "grommet-icons";
import styled from "styled-components";

import { SearchAhead, SearchAheadItem } from "src/components/shared/search-ahead/SearchAhead";
import { useApiRequest } from "src/utils/api";
import { availableFormats } from "src/utils/api/routes/bottles.api";
import { Label } from "src/utils/api/routes/labels.api";
import { Wine } from "../../utils/api/routes/wines.api";
import { formatUid } from "../../utils/formatLabelCode";
import { SelectInput } from "../forms";
import { Text } from "../shared/text";

import { formatBottleName, formatBottleLWIN } from "./nameUtils";

type SearchAheadItemWine = Pick<Wine, "displayWineLine1" | "displayWineLine2" | "id">;

export interface AssignedBottle {
  selectedWine?: Wine
  selectedVintage?: SearchAheadItem<number>
  selectedFormat?: SearchAheadItem<string>
  bottleName?: [string, boolean];
  bottleLWIN?: [string, boolean];
}
interface MixedCaseRowProps {
  label: Label;
  onChange: (bottle: AssignedBottle) => void;
}

export const MixedCaseRow: React.FC<MixedCaseRowProps> = ({ onChange, label }) => {
  // Setup API request for wine searchAhead
  const [ winesRes, winesReq ] = useApiRequest("WINES:list");
  // Setup variable to store search results
  const [ wineSearchResults, setWineSearchResults ] = React.useState<Array<SearchAheadItem<SearchAheadItemWine, Wine>>>([]);
  // Setup variables to store form inputs
  const [ selectedWine, setSelectedWine ] = React.useState<Wine>();
  const [ selectedVintage, setSelectedVintage ] = React.useState<SearchAheadItem<number>>();
  const [ selectedFormat, setSelectedFormat ] = React.useState<SearchAheadItem<string>>();
  
  // Parse and format the bottle LWIN
  const bottleLWIN = formatBottleLWIN(
    selectedFormat, selectedVintage, selectedWine
  );

  // Parse and format the bottle name
  const bottleName = formatBottleName(selectedVintage, selectedWine);

  // Watch for changes in wine, vintage and format.
  React.useEffect(() => {
    // If all are selected, perform onChange callback
    if (selectedWine && selectedVintage && selectedFormat) {
      onChange({
        selectedWine,
        selectedVintage,
        selectedFormat,
        bottleName,
        bottleLWIN
      }); 
    }
  // Including onChange as a dependency causes infinite loop.
  // It won't change, so we can safely exclude it.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedWine,
    selectedVintage,
    selectedFormat
  ]);

  // Debounced search for a wine by name
  const searchForWineName = debounce((search: string) => {
    setSelectedWine(undefined);
    setSelectedVintage(undefined);
    setSelectedFormat(undefined);

    if (search) {
      winesReq({
        params: {
          offset: 0,
          limit: 25,
          filters: { name: search }
        }
      });
    }
  }, 500);

  // Listen for a response from the wine search
  React.useEffect(() => {
    if (winesRes.data?.items) {
      setWineSearchResults(winesRes.data.items.map(wine => {
        return {
          label: wine.name,
          value: {
            id: wine.id,
            displayWineLine1: wine.displayWineLine1,
            displayWineLine2: wine.displayWineLine2
          },
          data: wine
        };
      }) || []);
    }
  }, [ winesRes ]);

  // Handle selecting a wine from the wine SearchAhead
  const handleSelectWine = React.useCallback((selected?: SearchAheadItem<SearchAheadItemWine, Wine> | null) => {
    if (selected?.data) {
      setSelectedWine(selected.data);
    }
  }, [ ]);
  
  return (
    <>
      <TableCell scope="row">
        {label.certificationReference && (
          <Text bold>
            {formatUid(label.certificationReference)}
          </Text>
        )}
      </TableCell>
      <TableCell>
        <SearchAhead<SearchAheadItemWine, Wine>
          name="wineSearch"
          saveState
          loading={winesRes.loading}
          items={wineSearchResults}
          renderItem={item => (
            <Box direction="row">
              <Text bold>
                {item.value.displayWineLine1}
              </Text>
              <Box pad={{ left: "small" }}>
                <Text>
                  {item.value.displayWineLine2}
                </Text>
              </Box>
            </Box>
          )}
          totalItems={winesRes.data?.total || 0}
          placeholder="Search for a wine..."
          onSelect={handleSelectWine}
          onSearch={searchForWineName}
        />
      </TableCell>
      <TableCell>
        <SelectInput<number>
          maxWidth="150px"
          displayType="input"
          placeholder="Vintage"
          items={selectedWine?.vintages?.sort((a, b) => b.year - a.year).map(v => ({
            label: v.year.toString(),
            value: v.id
          })) || []}
          onSelect={value => value && setSelectedVintage(value)}
        />
      </TableCell>
      <TableCell>
        <SelectInput<string>
          maxWidth="150px"
          displayType="input"
          placeholder="Format"
          items={availableFormats}
          onSelect={value => value && setSelectedFormat(value)}
        />
      </TableCell>
      <TableCell align="center">
        {bottleName[ 1 ] && bottleLWIN[ 1 ] ? <StyledCheckMark size="15px" /> : <StyledInvalidMark size="15px" />}
      </TableCell>
    </>
  );
};

const StyledCheckMark = styled(Checkmark)`
  stroke: ${({ theme }) => theme.colors.success};
`;

const StyledInvalidMark = styled(Close)`
  stroke: ${({ theme }) => theme.colors.warning};
`;
