import React from "react";
import styled from "styled-components";

import { PublicLayout } from "src/components/shared/layout/PublicLayout";
import { useAuthContext } from "src/utils/authentication/AuthContext";
import { PageProps } from "src/pages/Router";
import { Button } from "src/components/shared/button/Button";
import { Text } from "src/components/shared/text";

const NotFound: React.FunctionComponent<PageProps<Record<string, unknown>>> = ({ history }) => {
  const { authState } = useAuthContext();
  
  return (
    <PublicLayout>
      <NotFoundWrapper>
        <Text as="p" className="not-found-text">
          404 - Page Not Found
        </Text>
        {authState.loggedIn ? <Button onClick={() => history.push(authState.redirectTo || "/")} label="Back to Dashboard" /> : <Button onClick={() => history.push("/")} label="Login" />}
      </NotFoundWrapper>
    </PublicLayout>
  );
};

const NotFoundWrapper = styled.div`
  text-align: center;

  .not-found-text {
    margin-bottom: 2rem;
  }
`;

export default NotFound;
