import React from "react";

/**
 * Format Currency
 * Given a number this function formats with a Euro sign, rounds to 2 fraction digits 
 * and makes the two fraction digits smaller (relative to significant digits).
 * Moves the negative sign before the currency symbol.
 * @param value [number]
 */
export const formatCurrency: (value?: number) => React.ReactElement = value => {
  // Separates thousands with a comma. Ensures 2 fraction digits (& rounds to nearest)
  if (value === null || value === undefined) {
    return (
      <>
        €0
      </>
    );
  }

  let number = "€";

  if (Math.sign(value) < 0) {
    // Number is negative.
    number = "- €";
    value = -value;
  }

  number += `${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  })}`;

  const formatted = number.split(".");

  return (
    <>
      {formatted[ 0 ]}
      <span style={{ fontSize: "0.75em" }}>
        .
        {formatted[ 1 ]}
      </span>
    </>
  );
};
