import { Box } from "grommet";
import React from "react";
import styled, { css } from "styled-components";
import { toast } from "react-toastify";
import { Storage, Auth } from "aws-amplify";

import { Button } from "src/components/shared/button";
import { CollectorLayout } from "src/components/shared/layout";
import { Card } from "src/components/shared/layout/Card";
import { CollectorPageTop } from "src/components/shared/layout/CollectorPageTop";
import { PageConfines } from "src/components/shared/layout/PageConfines";
import { CurrentValueBox } from "src/components/shared/price-graph";
import { Text } from "src/components/shared/text";
import { PageProps } from "src/pages/Router";
import { useAuthContext } from "src/utils/authentication";
import { useApiRequest } from "src/utils/api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import { Icon, Icons } from "src/components/shared/icon";
import { ExportData } from "src/utils/api/routes/bottles.api";

import { UserCollectionTable, CollectorPortfolioValueGraph } from "./user-collection";

export const WinePortfolio: React.FC<PageProps<Record<string, unknown>>> = props => {
  const { authState } = useAuthContext();
  const { user } = authState;
  const [ getExportRes, getExportReq ] = useApiRequest("BOTTLES:export");
  const [ loading, setLoading ] = React.useState<boolean>(false);

  // Request the case by id based off path params
  const exportCollection = React.useCallback(async () => {
    try {
      const currentUser = await Auth.currentUserCredentials();

      if (user?.id) {
        getExportReq({
          pathParams: { userId: user.id.toString() },
          params: { cognitoIdentityId: currentUser.identityId }
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Authenticated session invalid, please try logging out and in again to perform this action");
    }
  }, [ user, getExportReq ]);

  // Detect the response for get case by id
  React.useEffect(() => {
    if (getExportRes.data) {
      setLoading(false);
    }

    if (getExportRes.errorMessage) {
      toast.error(getExportRes.errorMessage);
    }
  }, [ getExportRes ]);

  const downloadFile = (key: string) => {
    const newKey = key.split("/exports/");

    Storage.get(`exports/${newKey[ 1 ]}`, { level: "private" }).then(result => {
      window.open(result as string, "blank");
    }).catch(error => {
      console.error(error);
    });
  };

  return (
    <CollectorLayout>
      <CollectorPageTop>
        <Box direction="row" justify="between">
          <Box justify="end">
            <Text color="fadedMid" as="p">
              Currently viewing
            </Text>
            <Box pad={{ vertical: "small" }}>
              <Text color="white" as="h1">
                Complete portfolio
              </Text>
            </Box>
          </Box>
          <Box width="66%" margin={{ bottom: "-20rem" }} direction="row">
            <Card>
              <CardTop>
                <div className="curve-background" />
                {user && (
                  <CurrentValueBox currentValue={user.portfolioValue} title="Portfolio Value" />
                )}
              </CardTop>
              <CardBody>
                <div className="curve-background" />
                {user && (
                  <CollectorPortfolioValueGraph user={user} />
                )}
              </CardBody>
            </Card>
          </Box>
        </Box>
      </CollectorPageTop>
      <PageConfines>
        <Box direction="column" height="20rem">
          <Box pad={{ top: "medium" }}>
            <Text as="p">
              Export your entire collection.
            </Text>
            <Box pad={{ vertical: "small" }}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <Box>
                  {getExportRes.data && getExportRes.data.s3Key ? (
                    <Box direction="row" align="center">
                      <Box pad={{ right: "small" }}>
                        <Icon size="1.5rem" icon={Icons[ "arrowhead-down" ]} />
                      </Box>
                      <Button label="Download" onClick={() => downloadFile((getExportRes.data as ExportData).s3Key)} />
                    </Box>
                  ) : (
                    <Button label="Export" primary onClick={() => exportCollection()} />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Card margin={{ top: "medium" }}>
          {user && (
            <UserCollectionTable userId={user.id} onSelectCase={caseId => props.history.push(`/collector/case/${caseId}`)} />
          )}
        </Card>
      </PageConfines>

    </CollectorLayout>
  );
};

const CardTop = styled.div`
 ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.white};
    * {
      z-index: 1;
    }
    .curve-background {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-bottom-right-radius: ${theme.borderRadius.large};
      background: ${theme.colors.fadedLighter};
      z-index: 0;
    }


  `}
`;

const CardBody = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.fadedLighter};
    * {
      z-index: 1;
    }
    .curve-background {
      border-top-left-radius: ${theme.borderRadius.large};
      background: ${theme.colors.white};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

  `}
`;
