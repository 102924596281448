import { ApiRouteDefinition } from "../useApiRequest";
import {
  PickParams, StringResponse, PaginatedQueryRequest, PaginatedResponse, LivExLwinView, LivExCriticData
} from "../api";

import { Case } from "./cases.api";
import { Wine } from "./wines.api";

const VintageRoutes = {
  /** Get Vintage by id */
  "VINTAGES:get": {
    path: "/vintages/:vintageId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Vintage, null, PickParams<"vintageId">>,
  
  /** Get vintage cases by id */
  "VINTAGES:listRatings": {
    path: "/vintages/:vintageId/ratings",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Rating>, null, PickParams<"vintageId">, PaginatedQueryRequest<Partial<Rating>>>, 

  /** Get vintage cases by id */
  "VINTAGES:listCases": {
    path: "/vintages/:vintageId/cases",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<Case>, null, PickParams<"vintageId">, PaginatedQueryRequest<Partial<Case>>>, 

  /** Partial update a Vintage */
  "VINTAGES:update": {
    path: "/vintages/:vintageId",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<
    Vintage,
    Pick<Vintage, "id">,
    PickParams<"vintageId">
  >,

  /** Partial update a Vintage */
  "VINTAGES:create": {
    path: "/vintages",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<
    Vintage,
    CreateVintageInput,
    null
  >,

  /** delete a Vintage */
  "VINTAGES:delete": {
    path: "/vintages/:vintageId",
    authenticate: true,
    method: "DELETE"
  } as ApiRouteDefinition<StringResponse, null, PickParams<"vintageId">>,

  /** Vintage LWIN lookup */
  "VINTAGES:lwin-lookup": {
    path: "/vintages/lwin-lookup",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<VintageLwinLookupResponse, Pick<Vintage, "lwin">>
};

export default VintageRoutes;

export interface Rating {
  id: number;
  reviewer: string;
  score: string;
  tastingNote: string | null;
  reviewDate: Date;
  externalReference: string | null;
  externalLink: string | null;
  externalId: string | null;
  drinkFrom: number | null;
  drinkTo: number | null;
  vintageId: number;
}

export interface Vintage {
  id: number;
  name: string;
  wineId: number;
  lwin: string;
  year: number;
  rating?: string;
  drinkingWindowStart?: number;
  drinkingWindowEnd?: number;
  currentPrice?: number | string;
  currentPriceUpdatedAt?: Date;
  pricePackSize?: string;
  priceBottleSize?: string;
  wine?: Wine;
}

export interface VintageLwinLookupResponse {
  lwinView: LivExLwinView;
  criticsData: LivExCriticData;
}

export type CreateVintageInput = Pick<Vintage, "wineId" | "lwin" | "year">;