import { ApiRouteDefinition } from "../useApiRequest";
import { RecordTimestamps } from "../api";

const GrapeRoutes = {
  /** list all roles */
  "WINE-GRAPES:list": {
    path: "/wines/grapes",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Grape[], null, null, null>
};

export default GrapeRoutes;

export interface Grape extends RecordTimestamps {
  id: number;
  name: string;
  label: string;
}
