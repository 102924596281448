import React from "react";
import styled, { css } from "styled-components";

const TableBodyWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...rest } = props;

  return (
    <TableBody {...rest}>
      {children}
    </TableBody>
  );
};

const TableBody = styled.div`
  ${({ theme }) => css`
    /* Row */
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.sizes.bodyRegular};
    > div {
      &:nth-child(even){
        background: ${theme.colors.fadedLighter};
      }
    }
  `};
`;

export default TableBodyWrapper;