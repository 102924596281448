import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import React from "react";
import { toast } from "react-toastify";

import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { WineForm, WineLwinLookupValues } from "src/components/wine";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { CreateWineInput, Wine } from "src/utils/api/routes/wines.api";
import { LoadingSpinner } from "src/components/shared/loading-spinner";

export const NewWineForm: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ lwinLookupRes, lwinLookupReq ] = useApiRequest("WINES:lwin-lookup");
  const [ wineColoursRes, wineColoursReq ] = useApiRequest("WINE-COLOURS:list");
  const [ wineGrapesRes, wineGrapesReq ] = useApiRequest("WINE-GRAPES:list");
  const [ createWineRes, createWineReq ] = useApiRequest("WINES:create");
  const [ lwinLookupLoading, setLwinLookupLoading ] = React.useState(false);
  const [ lwinLookupValues, setLwinLookupValues ] = React.useState<WineLwinLookupValues>();

  // On LWIN lookup
  const onLwinLookup = (lwin: Wine["lwin"]) => {
    setLwinLookupLoading(true);
    lwinLookupReq({ data: { lwin } });
  };

  // On form submission
  const onSubmit = (wine: CreateWineInput) => {
    // types as Partial which createWineReq doesn't like - but we've
    // already validated wine to ensure it contains required fields
    const valuesToUpdate = pickBy(wine, identity) as CreateWineInput;

    createWineReq({ data: valuesToUpdate });
  };

  // On mount
  React.useEffect(() => {
    wineColoursReq({});
    wineGrapesReq({});
  }, [ wineColoursReq, wineGrapesReq ]);

  // On wineColoursRes
  React.useEffect(() => {
    if (wineColoursRes.errorMessage) {
      toast.error(wineColoursRes.errorMessage);
    }
  }, [ wineColoursRes ]);

  // On wineGrapesRes
  React.useEffect(() => {
    if (wineGrapesRes.errorMessage) {
      toast.error(wineGrapesRes.errorMessage);
    }
  }, [ wineGrapesRes ]);

  // On lwinLookupRes
  React.useEffect(() => {
    if (lwinLookupRes.data) {
      let wineColourId = 1;

      if (lwinLookupRes.data.existing) {
        toast.warn("We already have a record of this LWIN7 in our system");
      }

      setLwinLookupLoading(false);

      // If there is a wine colour, match it to one of our colours.
      if (lwinLookupRes.data.colour && wineColoursRes.data) {
        wineColoursRes.data.map(colour => {
          if (lwinLookupRes.data?.colour && lwinLookupRes.data.colour.toLowerCase() === colour.label) {
            wineColourId = colour.id;
          }
        });
      }

      setLwinLookupValues({
        name: lwinLookupRes.data.displayName,
        country: lwinLookupRes.data.country,
        region: lwinLookupRes.data.region || undefined,
        subRegion: lwinLookupRes.data.subRegion || undefined,
        wineColourId
      });
    }

    // Error
    if (lwinLookupRes.errorMessage) {
      setLwinLookupLoading(false);
      toast.error(lwinLookupRes.errorMessage);
    }
  }, [
    lwinLookupRes.data,
    lwinLookupRes.errorMessage,
    wineColoursRes.data
  ]);

  // On createWineRes
  React.useEffect(() => {
    if (createWineRes.data) {
      toast.success(`${createWineRes.data.name} was created successfully. Click here to view.`, {
        autoClose: false,
        onClick: () => props.history.push(`/admin/wine/${createWineRes.data?.id}`)
      });
    }

    if (createWineRes.errorMessage) {
      toast.error(createWineRes.errorMessage);
    }
  }, [ createWineRes, props.history ]);

  return (
    <AdminLayout>
      <PageHeader 
        title="New Wine" 
        backLinkText="Back to wines"
        backLink={() => props.history.push("/admin/wines")}
      />
      <Card pad="small" maxWidth="70rem">
        {(wineColoursRes.loading || wineGrapesRes.loading) && <LoadingSpinner />}
        {wineColoursRes.data && wineGrapesRes.data && (
          <WineForm
            isLoading={createWineRes.loading || lwinLookupLoading}
            defaultValues={{ wineColourId: 1 }}
            onLwinLookup={onLwinLookup}
            lwinLookupValues={lwinLookupValues}
            wineColours={wineColoursRes.data}
            grapes={wineGrapesRes.data}
            onSubmit={onSubmit}
          />
        )}
      </Card>

    </AdminLayout>
  );
};
