import { Auth } from "aws-amplify";
import * as React from "react";
import createDebug from "debug";

import { AuthContext } from "../AuthContext";

import useLogin from "./useLogin";

const debug = createDebug("AUTH:SIGNUP");

interface State {
  error: null | string;
  resetting: boolean;
  action: "code" | "email";
}

const useForgotPassword = () => {
  const { login } = useLogin();
  const { authState, setAuthState } = React.useContext(AuthContext);

  const [ forgotState, setForgotState ] = React.useState<State>({
    error: null,
    resetting: false,
    action: "email"
  });

  /**
   * Send a confirmation code to the user
   *
   */
  const _getResetCode = React.useCallback(async (username: string) => {
    try {
      // no spaces and lowercase usernames only
      username = username.replace(/\s+/g, "").toLowerCase();
      await Auth.forgotPassword(username);

      return setForgotState({
        ...forgotState,
        resetting: false,
        action: "code"
      });
    } catch (error) {
      debug("SIGN IN FAILED:", error);

      setForgotState({
        ...forgotState,
        resetting: false,
        error: `Could not send verification code - ${error.message ||
            "please try again later"}`
      });

      return setAuthState({
        ...authState,
        loggedIn: false
      });
    }
  },
  [
    authState,
    forgotState,
    setAuthState
  ]);

  const requestPasswordChange = React.useCallback((username: string) => {
    setForgotState({
      ...forgotState,
      resetting: true,
      error: null
    });
    // no spaces and lowercase usernames only
    username = username.replace(/\s+/g, "").toLowerCase();
    _getResetCode(username);
  }, [ _getResetCode, forgotState ]);

  const setNewPassword = React.useCallback(async (username: string, code: string, password: string) => {
    try {
      // no spaces and lowercase usernames only
      username = username.replace(/\s+/g, "").toLowerCase();

      setForgotState({
        ...forgotState,
        resetting: true,
        error: null
      });

      await Auth.forgotPasswordSubmit(
        username, code, password
      );

      setForgotState({
        ...forgotState,
        resetting: false
      });

      return login(username, password);
    } catch (error) {
      debug("FAILED TO RESET PASSWORD:", error);

      return setForgotState({
        ...forgotState,
        resetting: false,
        error: `Could not set new password - ${error.message ||
            "please try again later"}`
      });
    }
  },
  [ forgotState, login ]);

  const setState = React.useCallback((newState: Partial<State>) => {
    setForgotState({
      ...forgotState,
      ...newState
    });
  },
  [ forgotState ]);

  return {
    requestPasswordChange,
    forgotState,
    setNewPassword,
    setForgotState: setState
  };
};

export default useForgotPassword;