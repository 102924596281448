import "googlemaps";

import {
  IProvidedProps, Map as GMap, Marker 
} from "google-maps-react";
import { Box } from "grommet";
import * as React from "react";

export interface MapContainerProps {
  markers?: google.maps.MarkerOptions[];
}

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [ { weight: 2.00 } ]
  },
  {
    featureType: "all",
    elementType: "geometry.stroke",
    stylers: [ { color: "#9c9c9c" } ]
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [ { visibility: "on" } ]
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [ { color: "#f2f2f2" } ]
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [ { color: "#ffffff" } ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [ { color: "#ffffff" } ]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [ { saturation: -100 }, { lightness: 45 } ]
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [ { color: "#eeeeee" } ]
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [ { color: "#7b7b7b" } ]
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [ { color: "#ffffff" } ]
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [ { visibility: "simplified" } ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [ { color: "#46bcec" }, { visibility: "on" } ]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [ { color: "#c8d7d4" } ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [ { color: "#070707" } ]
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [ { color: "#ffffff" } ]
  }
];

/**
 * Map container
 */
const MapContainer: React.FC<MapContainerProps & IProvidedProps> = props => {
  const {
    loaded, google, markers
  } = props;

  const [ bounds, setBounds ] = React.useState<google.maps.LatLngBounds>();
  const [ initalCenterPoint, setInitialCenterPoint ] = React.useState<google.maps.LatLngLiteral>();
  const [ centerPoint, setCenterPoint ] = React.useState<google.maps.LatLngLiteral>();

  // On mount / unmount
  React.useEffect(() => {
    return () => {
      setInitialCenterPoint(undefined);
      setCenterPoint(undefined);
      setBounds(undefined);
    };
  }, []);

  // On markers change
  React.useEffect(() => {
    if (markers) {
      const latLngBounds = new google.maps.LatLngBounds();

      // If there is only one marker, do not set bounds or a center point.
      // Only set the intial values needed
      if (markers.length === 1) {
        // Check that the position is set
        if (markers[ 0 ].position) {
          setInitialCenterPoint(latLngBounds.extend(markers[ 0 ].position).getCenter().toJSON());
        }
      } else {
        // Loop through each marker and set the bounds
        markers.map(marker => marker.position && latLngBounds.extend(marker.position));
        // Set the bounds
        setBounds(latLngBounds);
        // Set the center point
        setCenterPoint(latLngBounds.getCenter().toJSON());
      }
    }
  }, [ google.maps.LatLngBounds, markers ]);

  // On centerPoint change
  React.useEffect(() => {
    // If the centerPoint is set and the initalCenterPoint is not, set the initalCenterPoint
    if (centerPoint && !initalCenterPoint) {
      setInitialCenterPoint(centerPoint);
    }
  }, [ centerPoint, initalCenterPoint ]);

  // If the Google API hasn't loaded, show a loading message
  if (!loaded || !initalCenterPoint) {
    return (
      <Box width="100%" justify="center" align="center">
        Enter coordinates to load the map.
      </Box>
    );
  }

  return (
    <GMap
      google={google}
      styles={mapStyles}
      initialCenter={initalCenterPoint}
      zoom={6}
      center={centerPoint}
      bounds={bounds}
    >
      {markers && markers.map((marker, i) => (
        <Marker key={i} {...marker} />
      ))}
    </GMap>
  );
};

export default MapContainer;