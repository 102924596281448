import React from "react";
import { toast } from "react-toastify";
import {
  Column, Filters, Row
} from "react-table";

import { Table } from "src/components/shared/table";
import { Button } from "src/components/shared/button";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { PageHeader, Card } from "src/components/shared/layout";
import { PageProps } from "src/pages/Router";
import { useApiRequest } from "src/utils/api";
import { Estate } from "src/utils/api/routes/estates.api";
import { useTableFetch } from "src/hooks/useTableFetch";

type EstatesTable = Pick<Estate, "id" | "name" | "country" | "region" | "subRegion">;

const columns: Array<Column<EstatesTable>> = [
  {
    Header: "#",
    accessor: "id",
    width: 80,
    disableFilters: true
  },
  {
    Header: "Estate",
    accessor: "name"
  },
  {
    Header: "Country",
    accessor: "country"
  },
  {
    Header: "Region",
    accessor: "region"
  },
  {
    Header: "Sub-region",
    accessor: "subRegion"
  }
];

/**
 * Estates table
 */
const EstatesTable: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ data, setData ] = React.useState<EstatesTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ estatesRes, fetch ] = useTableFetch<"ESTATES:list", EstatesTable>("ESTATES:list", { defaultSort: [ [ "name", "ASC" ] ] });

  // Table item click handler
  const handleOnClick = React.useCallback((row: Row<EstatesTable>) => {
    props.history.push(`/admin/estates/${row.original.id}`);
  }, [ props.history ]);

  // On estatesRes
  React.useEffect(() => {
    if (estatesRes.data && estatesRes.data.items) {
      setData(estatesRes.data.items);
      setPageCount(Math.ceil(estatesRes.data.total / estatesRes.data.limit));
      setLoading(false);
    }

    if (estatesRes.errorMessage) {
      toast.error(estatesRes.errorMessage);
    }
  }, [ estatesRes ]);

  return (
    <AdminLayout>
      <PageHeader 
        title="List of estates" 
        backLinkText="Back to dashboard"
        backLink={() => props.history.push("/admin/dashboard")} 
        callToAction={<Button label="New" onClick={() => props.history.push("/admin/estates/new")} />}
      />
      <Card>
        <Table<EstatesTable>
          title={`${estatesRes.data?.total || 0} estates`}
          name="EstatesTable"
          columns={columns}
          initalSortBy={[
            {
              id: "id",
              desc: false
            }
          ]}
          data={data}
          onClick={handleOnClick}
          loading={loading}
          fetchData={fetch}
          useResizeColumns
          pageCount={pageCount}
          featuredFilter="name"
          usePagination
          useFilters
          useSortBy
          useHideColumns
        />
      </Card>
    </AdminLayout>
  );
};

export default EstatesTable;