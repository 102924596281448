import { ApiRouteDefinition } from "../useApiRequest";

const RoleRoutes = {
  /** list all roles */
  "ROLES:list": {
    path: "/users/roles",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Role[], null, null, null>
};

export default RoleRoutes;

export interface Role {
  id: number;
  name: ROLE_NAMES;
  group: string;
}

export enum ROLE_NAMES {
  ADMIN = "admin",
  COLLECTOR = "collector"
}
