import { ApiRouteDefinition } from "../useApiRequest";
import {
  PickParams, PaginatedResponse, PaginatedQueryRequest 
} from "..";

import {
  UserDocument, UserStorageFeeCollectionBasis, UserStorageFeeRate, User
} from "./users.api";
import { Transaction } from "./transactions.api";

const StorageFeeRoutes = {
  /** Create storage fee calulation */
  "STORAGE-FEE:create-storage-fee-calculation": {
    path: "/storage-fee-calculations",
    authenticate: true,
    method: "POST"
  } as ApiRouteDefinition<StorageFeeCalculation, CreateStorageFeeCalculation, null, null>,

  /** Get storage fee calculations */
  "STORAGE-FEE:list-storage-fee-calculations": {
    path: "/storage-fee-calculations",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<StorageFeeCalculation>, null, null, PaginatedQueryRequest<StorageFeeCalculation>>,

  /** Get storage fee calculation by id */
  "STORAGE-FEE:get-storage-fee-calculation": {
    path: "/storage-fee-calculations/:storageFeeCalculationId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<StorageFeeCalculation, null, PickParams<"storageFeeCalculationId">, null>,

  /** Get user storage fees by storageFeeCalculationId  */
  "STORAGE-FEE:get-storage-fees-by-storage-fee-calculation": {
    path: "/user-storage-fees/storage-fee-calculation/:storageFeeCalculationId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<PaginatedResponse<UserStorageFee>, null, PickParams<"storageFeeCalculationId">, PaginatedQueryRequest<StorageFeeCalculation>>,

  /** Get user storage fees by id */
  "STORAGE-FEE:get-user-storage-fee": {
    path: "/user-storage-fees/:userStorageFeeId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<UserStorageFee, null, PickParams<"userStorageFeeId">, null>,

  /** Bulk action user storage fees */
  "STORAGE-FEE:bulk-action-user-storage-fees": {
    path: "/user-storage-fees/bulk-action",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<StorageFeeBulkActionResponse, StorageFeeBulkActionRequest, null, null>

};

export default StorageFeeRoutes;

export enum ROLE_NAMES {
  ADMIN = "admin",
  COLLECTOR = "collector"
}

export interface CreateStorageFeeCalculation {
  startDate: string;
  endDate: string;
}

export interface StorageFeeCalculation {
  id: number;
  facilitatorId: number;
  startDate: Date;
  endDate: Date;
  processJobId?: number | null;
  processJob?: ProcessJob;
  csvFileId?: number | null;
  csvFile?: UserDocument;
  createdAt: Date;
  updatedAt: Date;
}

export type ProcessJobName = string;
export type ProcessJobData = Record<string, any>;
export type ProcessJobResultData = Record<string, any>;
export type ProcessErrorData = any;

export interface ProcessJob {
  id: number;
  name: ProcessJobName;
  startedAt?: Date | null;
  finishedAt?: Date | null;
  status: ProcessJobStatus;
  statusMessage?: string | null;
  triggeredByUserId: number;
  jobId?: string | null;
  jobData?: ProcessJobData | null;
  jobResultData?: ProcessJobResultData | null;
  errorData?: ProcessErrorData | null;
  priority: number;
}

export enum ProcessJobStatus {
  SUCCESS = "success",
  ERROR = "error",
  QUEUED = "queued",
  IN_PROGRESS = "in_progress"
}

export interface UserStorageFee {
  id: number;
  storageFeeCalculationId: number;
  userId: number;
  storageFee: number;
  totalPortfolioValue: number;
  totalBookValue: number;
  averagePortfolioValue: number;
  calculationData: UserStorageFeeCalculationData | null;
  annualisedStorageFee: number;
  transactionId: number | null;
  transaction?: Transaction;
  status: UserStorageFeeStatus;
  createdAt: Date;
  updatedAt: Date;
  storageFeeCalculation?: StorageFeeCalculation;
  user?: User;
}

export interface UserStorageFeeCalculationData {
  storageFeeRates: UserStorageFeeRate[];
  countPortfolioValues: number;
  collectionBasis: UserStorageFeeCollectionBasis;
} 

export enum UserStorageFeeStatus {
  TRANSACTIONED_SUCCESS = "transactioned_success",
  CALCULATED_SUCCESS = "calculated_success",
}

export interface StorageFeeBulkActionRequest {
  ids: number[];
  action: UserStorageFeeBulkAction;
}

export enum UserStorageFeeBulkAction {
  "CREATE_TRANSACTIONS" = "create_transactions"
}

export interface StorageFeeBulkActionResponse {
  total: 0;
  notActioned: 0;
  actioned: 0;
}