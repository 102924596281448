import { Column } from "react-table";

import { Case } from "src/utils/api/routes/cases.api";
import { formatCurrency } from "../../utils/currency/formatCurrency";

export type UserCasesTable = Pick<Case, "id" | "name" | "lwin" | "bookPrice" | "status" | "location" | "packSize" | "bottleSize">;

export const userCasesColumns: Array<Column<UserCasesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true,
    width: 40
  },
  {
    Header: "Name",
    accessor: "name",
    width: 200
  },
  {
    Header: "Bottle Size",
    accessor: "bottleSize"
  },
  {
    Header: "Pack Size",
    accessor: "packSize"
  },
  {
    Header: "LWIN",
    accessor: "lwin",
    disableFilters: true
  },
  {
    Header: "Book Price",
    accessor: "bookPrice",
    Cell: cell => formatCurrency(cell.value)
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Location",
    accessor: "location"
  }
];
