import styled, { css } from "styled-components";
import React from "react";
import { NavLink } from "react-router-dom";
import { Logout } from "grommet-icons";

import Text from "../text/Text";
import { Button } from "../button/Button";
import { useAuthContext } from "src/utils/authentication/AuthContext";

export const AdminLayout: React.FC = props => {
  const { logout } = useAuthContext();

  const pages = [
    {
      link: "/admin/dashboard",
      name: "Home"
    },
    {
      link: "/admin/wines",
      name: "Wines"
    },
    {
      link: "/admin/users",
      name: "Users"
    },
    {
      link: "/admin/estates",
      name: "Estates"
    },
    {
      link: "/admin/cases",
      name: "Cases"
    },
    {
      link: "/admin/labels",
      name: "Labels"
    },
    {
      link: "/admin/storage-fee-calculation",
      name: "Storage fees"
    }, 
    {
      link: "/admin/sensors",
      name: "Sensors"
    }
  ];

  return (
    <Wrapper>
      <NavWrapper environment={process.env.REACT_APP_ENV}>
        <Header>
          <img src="/1275-logo.svg" width="90px" />
          {process.env.REACT_APP_ENV === "staging" && (
            <Text>
              Staging
            </Text>
          )}
        </Header>
        <Navigation>
          {pages.map(page => {
            return (
              <StyledNavLink activeClassName="active" key={page.link} to={page.link}>
                <Text as="small">
                  {page.name}
                </Text>
              </StyledNavLink>
            );
          })}
        </Navigation>
        <LogoutWrapper>
          <Button plain label="" icon={<Logout color="#9398B1" />} onClick={logout} />
        </LogoutWrapper>
      </NavWrapper>
      <PageWrapper>
        {props.children}
      </PageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const LogoutWrapper = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 2rem;
`;

const NavWrapper = styled.div<{environment?: string}>`
  display: flex;
  flex-direction: column;
  ${({ environment, theme }) => css`
    background: ${environment === "staging" ? theme.colors.stagingNavBar : theme.colors.dark};
  `}
  width: 14rem;
  align-items: center;
  text-align: center;

  @media (min-width: 1024px) {
    width: 18rem;
  }

  @media (min-width: 1920px) {
    width: 25rem;
  }
`;

const Header = styled.header`
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: ${({ theme }) => theme.colors.light};  
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledNavLink = styled(NavLink)`
  padding: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.light};
  &.active {
    color: ${({ theme }) => theme.colors.lighter};
  }
`;

const PageWrapper = styled.main`
  position: relative;
  width: calc(100vw - 14rem);
  min-height: 100vh;
  padding: 2.2rem 4%;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.appBackground};

  @media (min-width: 768px) {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }

  @media (min-width: 1024px) {
    width: calc(100vw - 18rem);
  }

  @media (min-width: 1920px) {
    width: calc(100vw - 25rem);
  }
`;