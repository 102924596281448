// shared types for the api

export interface AvailableParams {
  userId: string;
  wineId: string;
  vintageId: string;
  bottleId: string;
  caseId: string;
  labelId: string;
  estateId: string;
  scannerId: string;
  documentId: string;
  storageFeeCalculationId: string;
  userStorageFeeId: string;
  sensorId: string;
  certificationReference: string;
}

export type PickParams<P extends keyof AvailableParams> = Pick<
  AvailableParams,
  P
>;

export interface StringResponse {
  message: string;
}
export interface StatusResponse {
  status: number;
}

export interface PaginatedResponse<T> {
  total: number;
  items: T[];
  offset: number;
  limit: number;
}

export interface BulkActionResponse {
  message: string;
  rowsAffected: number;
}

type OrderBy<TAttributes> = Array<[keyof TAttributes, "ASC" | "DESC"]>;
export interface PaginatedQueryRequest<TAttributes> {
  filters?: FiltersOptions<TAttributes>;
  offset?: number;
  limit?: number;
  order?: OrderBy<TAttributes>;
}

export interface RecordWithId {
  id: number;
}

export interface RecordTimestamps {
  createdAt: Date;
  updatedAt: Date;
}

export enum LivExWineColor {
  RED = "red",
  WHITE = "white",
  ROSE = "rose"
}

export enum LivExWineStatus {
  LIVE = "live",
  DELETED = "deleted",
  COMBINED = "combined"
}

export enum LivExVintageConfiguration {
  SEQUENTIAL = "sequential",
  NON_SEQUENTIAL = "nonSequential",
  SINGLE_VINTAGE_ONLY = "singleVintageOnly"
}

/**
 * Types that can be used in filters.
 *
 * These types have been mainly infered from Sequelize types
 * WhereOptions => FiltersOptions, WhereAttributeHash => FiltersAttributeHash, etc
 */
export type FiltersOptions<TAttributes = unknown> =
  | FiltersAttributeHash<TAttributes>
  | AndOperator<TAttributes>
  | OrOperator<TAttributes>;

export type FiltersAttributeHash<TAttributes = unknown> = {
  [field in keyof TAttributes]: FiltersValue<TAttributes> | FiltersOptions<TAttributes>;
};

export type FiltersValue<TAttributes = unknown> =
  | string // literal value
  | number // literal value
  | boolean // literal value
  | Date // literal value
  | Buffer // literal value
  | null
  | FiltersOperators
  | FiltersAttributeHash<unknown> // for JSON columns
  | OrOperator<TAttributes>
  | AndOperator<TAttributes>;

export interface OrOperator<TAttributes = unknown> {
  $or: FiltersOptions<TAttributes> | Array<FiltersOptions<TAttributes>> | FiltersValue<TAttributes> | Array<FiltersValue<TAttributes>>;
}

export interface AndOperator<TAttributes = unknown> {
  $and: FiltersOptions<TAttributes> | Array<FiltersOptions<TAttributes>> | FiltersValue<TAttributes> | Array<FiltersValue<TAttributes>>;
}

export interface FiltersOperators {
  $any: Array<string | number>;
  $gte: number | string | Date;
  $lt: number | string | Date;
  $lte: number | string | Date;
  $ne: null | string | number | FiltersOperators;
  $not: null | boolean | string | number | FiltersOperators;
  $between: Rangable;
  $in: Array<string | number>;
  $notIn: Array<string | number>;
  $like: string | AnyOperator | AllOperator;
  $notLike: string | AnyOperator | AllOperator;
  $iLike: string | AnyOperator | AllOperator;
  $overlap: Rangable;
  $contains: Array<string | number> | Rangable;
  $contained: Array<string | number> | Rangable;
  $gt: number | string | Date;
  $notILike: string | AnyOperator | AllOperator;
  $notBetween: Rangable;
  $startsWith: string;
  $endsWith: string;
  $substring: string;
  $regexp: string;
  $notRegexp: string;
  $iRegexp: string;
  $notIRegexp: string;
  $strictLeft: Rangable;
  $strictRight: Rangable;
  $noExtendLeft: Rangable;
  $noExtendRight: Rangable;
}

export interface AnyOperator {
  $any: Array<string | number>;
}

/** Undocumented? */
export interface AllOperator {
  $all: Array<string | number | Date>;
}

export type Rangable = [number, number] | [Date, Date];

export interface LivExLwinView {
  // Our own record of whether the LWIN exists in our system or not:
  existing: boolean;
  // LWIN7 or LWIN11 code depending on the type of the query
  lwin: number;
  // Title of producer or owner of wine
  producerTitle: string;
  // Producer or owner of wine
  producerName: string;
  // Name of wine (brand and/or grape and/or technical term)
  wine: string;
  // Country of origin
  country: string;
  // Region of origin (where applicable, specific to local laws)
  region: string | null;
  // Sub-region of origin (where applicable, specific to local laws)
  subRegion: string | null;
  // Site within sub-region (where applicable, specific to local laws)
  site: string | null;
  // Parcel within site (where applicable, specific to local laws)
  parcel: string | null;
  // Colour of LWIN product.
  colour: LivExWineColor | null;
  // LWIN beverage type
  type: string;
  // Subcategory of LWIN type
  subType: string;
  // Officially assigned status (specific to local laws)
  designation: string;
  // Officially declared quality level (where applicable, specific to local laws)
  classification: string | null;
  // Vintage pattern of the wine (e.g. single vintage only, production ended)
  vintageConfiguration: LivExVintageConfiguration | null;
  // List of existing vintages.
  vintageValues: number[];
  //  One out of 8 display name concatenation formulas. Assigned by LWIN
  // administrator
  displayNameType: string;
  // The name of the wine
  displayName: string;
  // The status
  status: LivExWineStatus;
  // The new leader LWIN for the LWIN that has been combined
  combineReference: number | null;
  // datetime / epoch
  dateCreated: string;
  // datetime / epoch
  lastUpdateDate: string;
  // The first vintage of the LWIN7. Not returned for LWIN11.
  firstVintage?: number | null;
  // The final vintage of the LWIN7. Not returned for LWIN11.
  finalVintage?: number | null;
  // Parent wine LWIN7. This should be used to map associated wines to
  // one another. This is a separate relationship to LWIN merge references
  // Not returned for LWIN11
  childOf?: number | null;
}

export interface LivExCriticData {
  // lwin LWIN11 code of product
  lwin: string;
  publicationData: LivExPublicationData[];
}

export interface LivExPublicationData {
  // The name of the publication. e.g. "Vinous"
  publication: string;
  publicationReview: LivExPublicationReview[];
}

export interface LivExPublicationReview {
  // Epoch time if JSON
  reviewDate: string;
  // The name of the reviewer. e.g. "Neal Martin"
  reviewer: string;
  // The score as published, e.g. "93-96", "17++"
  scoreRaw: string;
  // The lower range of the score. If rawScore is not a range the actual value
  // will be stated. e.g. "93"
  scoreFrom: string;
  // The upper range of the score. If rawScore is not a range the actual value
  // will be stated. e.g. "96"
  scoreTo: string;
  // The median value of the score if a range. If rawScore is not a range the
  // actual value will be stated. e.g. "94.5"
  scoreMedian: string; 
  // The lower range of the drinking window published (if available). e.g. "2012"
  drinkFrom: string | null;
  // The upper range of the drinking window published (if available). e.g. "2020"
  drinkTo: string | null;
  // The reviewer's review 
  tastingNote: string | null;
  // The name of the article in which the review featured
  externalReference: string | null;
  // HTTP link to the review on the publication's website
  externalLink: string | null;
  // Third party reference id for the score and tasting note
  externalId: string | null;
}

export interface JSONApiErrorJSON {
  id?: string; // a unique identifier for this particular occurrence of the problem.
  title: string; // a short, human-readable summary of the problem that SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization.
  details: string; // a human-readable explanation specific to this occurrence of the problem. Like title, this field’s value can be localized.
  status: number; //the HTTP status code applicable to this problem, expressed as a string value.
  code: ERROR_CODE_ENUM; // an application-specific error code, expressed as a string value.
  /**  an object containing references to the source of the error, optionally including any of the following members: */
  source?: {
    /* a JSON Pointer [RFC6901] to the value in the request document that caused the error [e.g. "/data" for a primary data object, or "/data/attributes/title" for a specific attribute]. This MUST point to a value in the request document that exists; if it doesn’t, the client SHOULD simply ignore the pointer. **/
    pointer?: string;
    parameter?: string; // a string indicating which URI query parameter caused the error.
    stack?: string;
  };
}

export enum ERROR_CODE_ENUM {
  API_ERROR = "API_ERROR",
  API_CONNECTION_ERROR = "API_CONNECTION_ERROR",
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  INVALID_DATA_ERROR = "INVALID_DATA_ERROR",
  DATABASE_ERROR = "DATABASE_ERROR",
  NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
  THIRD_PARTY_ERROR = "THIRD_PARTY_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR"
}