import { Box } from "grommet";
import React, { ReactElement } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps
} from "recharts";
import styled, { css } from "styled-components";

import { theme } from "src/utils/theme";
import { Text } from "../text";
import { LoadingSpinner } from "../loading-spinner";

import {
  ToolTipHeader, ToolTipValue, ToolTipWrapper 
} from "./ToolTip";

export interface GraphProps<T extends Record<string, unknown>> {
  height: number;
  GraphFooter?: JSX.Element | string;
  GraphHeader?: JSX.Element | string;
  axes: AxesProps<T>;
  data: T[];
  isLoading: boolean;
  toolTipLabelFormatter?: TooltipProps["labelFormatter"];
}

export interface AxesProps<T> {
  yAxis: YAxisProps & {dataKey: keyof T};
  xAxis: XAxisProps & {dataKey: keyof T};
}

const CustomToolTip: React.FC<TooltipProps & {unit?: string | number}> = props => {
  const {
    label, labelFormatter, payload, unit 
  } = props;

  return (
    <ToolTipWrapper>
      {label && (
        <ToolTipHeader>
          {labelFormatter ? labelFormatter(label) : label}
        </ToolTipHeader>
      )}
      <ToolTipValue>
        {payload?.map(data => data.value)}
        {unit}
      </ToolTipValue>
    </ToolTipWrapper>
  );
};

export function Graph<T extends Record<string, unknown>>(props: GraphProps<T>): ReactElement {
  const { xAxis, yAxis } = props.axes;

  return (
    <StyledGraphWrapper width="100%" pad="medium">
      {props.GraphHeader}
      {!props.data || !props.data.length && (
        <NoDataAvailableWrapper>
          {props.isLoading ? <LoadingSpinner /> : (
            <Text as="p">
              No data available
            </Text>
          )}
        </NoDataAvailableWrapper>
      )}
      <ResponsiveContainer height={props.height} width="100%">
        <LineChart data={props.data}>
          <XAxis {...xAxis} tickMargin={10} />
          <YAxis {...yAxis} tickMargin={10} />

          <Tooltip 
            content={(
              <CustomToolTip 
                unit={yAxis.unit} 
                labelFormatter={props.toolTipLabelFormatter} 
              />
            )}
          />
          <CartesianGrid 
            vertical={false} 
            stroke={theme.colors.fadedMid} 
          />
          <Line 
            type="monotone" 
            dataKey={yAxis.dataKey} 
            stroke="black" 
            strokeWidth={2} 
          />
        </LineChart>
     
      </ResponsiveContainer>
      {props.GraphFooter}
    </StyledGraphWrapper>
  );
}

const StyledGraphWrapper = styled(Box)`
  ${({ theme }) => css`
    tspan {
      font-family: ${theme.font.family.secondary};
      font-size: ${theme.font.sizes.small};
    }
  `}
  position: relative;
`;

const NoDataAvailableWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  padding: 5px;
  background: white;
  z-index: 99;
  left: 0;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;