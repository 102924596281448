import React from "react";
import { Box } from "grommet";
import styled, { css } from "styled-components";

import { Card } from "../shared/layout/Card";
import { Text } from "../shared/text";
import { Label } from "src/utils/api/routes/labels.api";

import { LabelAssignmentHeader, LabelCard } from ".";

import { LastResponseTimer } from "./LastResponseTimer";

interface LabelContainerProps {
  lastResponse?: Date;
  labels: Label[];
  labelsTotal: number;
  unassignedCaseLabelCount: number;
  unassignedBottleLabelCount: number;
  assignmentActive: boolean;
  setAssignmentActive: (active: boolean) => void;
  removeAllLabels: () => void;
  removeSingleLabel: (labelId: string) => void;
  scanError?: string;
  fetchLabels: () => void;
}

export const LabelContainer: React.FC<LabelContainerProps> = props => {
  const { 
    labels,
    unassignedCaseLabelCount,
    unassignedBottleLabelCount,
    assignmentActive,
    setAssignmentActive,
    labelsTotal,
    removeAllLabels,
    scanError,
    removeSingleLabel,
    fetchLabels
  } = props;
  
  return (
    <Card>
      {(labels && labelsTotal > 0) && (
        <LabelAssignmentHeader 
          onAssign={() => setAssignmentActive(true)}
          onClear={() => removeAllLabels()}
          canAssign={!scanError && !assignmentActive && unassignedCaseLabelCount > 0}
          canClear={!assignmentActive}
          labelCount={labelsTotal}
        />
      )}
      <Box pad="small">
        {scanError && (
          <ScanErrorContainer pad="small">
            {scanError}
          </ScanErrorContainer>
        )}
        <Box>
        
          {labels.sort((a, b) => {
            if (a.status < b.status) {
              return -1; 
            }

            if (a.status > b.status) {
              return 1; 
            }

            return 0;
          }).map(label => {
            return (
              <LabelCard canClear={!assignmentActive} removeLabel={removeSingleLabel} key={label.id} label={label} />
            );
          })}
        </Box>
        
        {(!unassignedCaseLabelCount && !unassignedBottleLabelCount) && (
          <Box align="center" justify="center" pad="large">
            <Text as="h4" bold>
              No labels detected.
            </Text>
          </Box>
        )}
        <Box align="center" justify="center" pad="large">
          {props.lastResponse && (
            <LastResponseTimer paused={assignmentActive} fetchLabels={fetchLabels} lastResponse={props.lastResponse} />
          )}           
        </Box>
      </Box>
    </Card>
  );
};

const ScanErrorContainer = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.colors.warning};
    color: white;
    border-radius: ${theme.borderRadius.small};
  `}
`;