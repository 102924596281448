import React from "react";
import { toast } from "react-toastify";
import {
  Column, Filters, Row 
} from "react-table";
import formatDate from "date-fns/format";
import { Box } from "grommet";

import { useApiRequest } from "src/utils/api";
import { Table } from "src/components/shared/table";
import { Text } from "src/components/shared/text";
import { Rating } from "src/utils/api/routes/vintages.api";
import { Modal } from "src/components/shared/modal";

interface VintageRatingsTab {
  vintageId: number;
}

type VintageRatingsTable = Pick<Rating, "id" | "reviewer" | "reviewDate" | "score" | "tastingNote">;

const columns: Array<Column<VintageRatingsTable>> = [
  {
    Header: "Id",
    accessor: "id"
  },
  {
    Header: "Reviewer",
    accessor: "reviewer"
  },
  {
    Header: "Tasting Note",
    accessor: "tastingNote"
  },
  {
    Header: "Score",
    accessor: "score"
  },
  {
    Header: "Review Date",
    accessor: "reviewDate",
    Cell: item => formatDate(new Date(item.value), "dd MMM yyyy")
  }
];

const hiddenColumns = [ "id", "tastingNote" ];

export const VintageRatingsTab: React.FC<VintageRatingsTab> = props => {
  const [ listRatingsByVintageRes, listRatingsByVintageReq ] = useApiRequest("VINTAGES:listRatings");
  const [ data, setData ] = React.useState<VintageRatingsTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const vintageId = React.useMemo(() => props.vintageId || null, [ props.vintageId ]);
  const [ currentRating, setCurrentRating ] = React.useState<VintageRatingsTable | null>(null);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<VintageRatingsTable>;
  }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current && vintageId) {
      const filters: Partial<VintageRatingsTable> = {};

      tableFilters?.map(filter => {
        const id = filter.id as keyof VintageRatingsTable;

        filters[ id ] = filter.value;
      });
      // Set the loading state
      setLoading(true);

      listRatingsByVintageReq({
        pathParams: { vintageId: vintageId.toString() },
        params: {
          filters,
          offset: pageIndex * pageSize,
          limit: pageSize
        }
      });
    }
  }, [ listRatingsByVintageReq, vintageId ]);

  React.useEffect(() => {
    if (listRatingsByVintageRes.data && listRatingsByVintageRes.data.items) {
      setData(listRatingsByVintageRes.data.items);
      setPageCount(Math.ceil(listRatingsByVintageRes.data.total / listRatingsByVintageRes.data.limit));
      setLoading(false);
    }

    if (listRatingsByVintageRes.errorMessage) {
      toast.error(listRatingsByVintageRes.errorMessage);
    }
  }, [ listRatingsByVintageRes ]);

  const handleOnClick = React.useCallback((row: Row<VintageRatingsTable>) => {
    setCurrentRating(row.original);
  }, [ setCurrentRating ]);
  
  return (
    <>
      <Table<VintageRatingsTable>
        name="vintageRatingsTable"
        title={`${listRatingsByVintageRes.data?.total || 0} ratings for this vintage`}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        onClick={handleOnClick}
        pageCount={pageCount}
        defaultHiddenColumns={hiddenColumns}
        usePagination
        useResizeColumns
        useFilters={false}
        useHideColumns
      />
      {currentRating && (
        <Modal
          isLoading={loading}
          onClose={() => setCurrentRating(null)}
          title={`${currentRating.reviewer} - ${currentRating.score}`}
          description={(
            <Box>
              <Text bold>
                Tasting Note
              </Text>
              <Box pad={{ top: "small" }}>
                <Text>
                  {currentRating.tastingNote || "-"}
                </Text>
              </Box>
            </Box>
          )}
          
        />
      )}
    </>
  );
};
