import React from "react";
import styled from "styled-components";

interface PanelProps {
  active: boolean;
}
export const TabPanel: React.FC<PanelProps> = props => {
  return props.active ? (
    <Panel>
      {props.children}
    </Panel>
  ) : null;
};

const Panel = styled.div`
  display: flex;
`;