import React from "react";
import styled, { css } from "styled-components";

export const BulkActionLayer: React.FC = props => {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    margin: auto;
    display: flex;
    bottom: 5rem;
    left: 5rem;
    right: 5rem;
    background: ${theme.colors.darkest};
    color: ${theme.colors.fadedLight};
    box-shadow: 0px 0px 8px -3px ${theme.colors.darkest};
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    border-radius: ${theme.borderRadius.medium};
    z-index: 9;
    left: calc(14rem + 5rem);

    @media (min-width: 1024px) {
      left: calc(18rem + 5rem);
    }

    @media (min-width: 1920px) {
      left: calc(25rem + 5rem);
    }
    
  `};
`;
