import React from "react";
import { Box } from "grommet";

import { theme } from "src/utils/theme";
import { Text } from "../text";

export const ToolTipWrapper: React.FC = props => {
  return (
    <Box direction="column" style={{ borderRadius: theme.borderRadius.small }} overflow="hidden">
      {props.children}
    </Box>
  );
};
export const ToolTipHeader: React.FC = props => {
  return (
    <Box pad="small" background="black" align="center" justify="center">
      <Text as="small" bold uppercase color="fadedLight">
        {props.children}
      </Text>
    </Box>
  );
};
export const ToolTipValue: React.FC = props => {
  return (
    <Box pad="small" background={theme.colors.darkest} color="white" align="center" justify="center">
      <Text as="h4">
        {props.children}
      </Text>
    </Box>
  );
};