import React from "react";

import { Vintage } from "src/utils/api/routes/vintages.api";
import { PriceGraph } from "src/components/shared/price-graph";
import { useApiRequest } from "src/utils/api";

interface VintagePriceGraphProps {
  vintage: Vintage;
}

interface HistoricalPrice {
  price: number;
  date: string;
}

export const VintagePriceGraph: React.FC<VintagePriceGraphProps> = ({ vintage }) => {
  const defaultStartDate = new Date(
    vintage.year + 1, 0, 1
  );

  const [ prices, setPrices ] = React.useState<HistoricalPrice[]>([]);
  const [ currentGraphStartDate, setStartGraphDate ] = React.useState<Date>(defaultStartDate);
  const [ historicalStartDate, setHistoricalStartDate ] = React.useState<Date>(defaultStartDate);
  const [ getVintageHistoricalResponse, getVintageHistoricalRequest ] = useApiRequest("PRICES:getVintageHistorical");
  const [ getVintageFirstValueResponse, getVintageFirstValueRequest ] = useApiRequest("PRICES:getVintageHistorical");

  // Get the first stored data data point.
  // This should be loaded when the component is mounted.
  React.useEffect(() => {
    getVintageFirstValueRequest({
      pathParams: { vintageId: vintage.id.toString() },
      params: {
        limit: 1,
        offset: 0,
        order: [ [ "priceDate", "ASC" ] ]
      }
    });
  }, [ getVintageFirstValueRequest, vintage.id ]);

  React.useEffect(() => {
    if (getVintageFirstValueResponse.data && getVintageFirstValueResponse.data.items.length) {
      const firstValue = getVintageFirstValueResponse.data.items[ 0 ];

      setHistoricalStartDate(new Date(firstValue.priceDate));
      setStartGraphDate(new Date(firstValue.priceDate));
    }
  }, [ getVintageFirstValueResponse ]);

  // Get the graph data points.
  React.useEffect(() => {
    getVintageHistoricalRequest({
      pathParams: { vintageId: vintage.id.toString() },
      params: { filters: { priceDate: { $gte: currentGraphStartDate } } }
    });
  }, [
    getVintageHistoricalRequest,
    vintage.id,
    currentGraphStartDate
  ]);

  React.useEffect(() => {
    if (getVintageHistoricalResponse.data) {
      const vintagePrices = getVintageHistoricalResponse.data.items;

      setPrices(vintagePrices.map(dataPoint => {
        return { 
          date: dataPoint.priceDate.toString(),
          price: dataPoint.price
        };
      }));
    }
  }, [ getVintageHistoricalResponse ]);

  return (
    <PriceGraph
      isLoading={getVintageFirstValueResponse.loading || getVintageHistoricalResponse.loading}
      onSelectedDateChange={date => setStartGraphDate(date)}
      pricePoints={prices}
      historicalStartDate={historicalStartDate}
    />
  );
};
