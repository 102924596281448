import React from "react";
import { Column, Filters } from "react-table";
import formatDate from "date-fns/format";
import { toast } from "react-toastify";

import { useApiRequest } from "src/utils/api";
import { Table } from "src/components/shared/table";
import { Transaction } from "src/utils/api/routes/transactions.api";
import { formatCurrency } from "src/utils/currency/formatCurrency";

type TransactionsTable = Pick<Transaction, "id" | "invoice" | "note"> & { value: React.ReactElement; createdAt: string};

const columns: Array<Column<TransactionsTable>> = [
  {
    Header: "Transaction",
    accessor: "createdAt",
    disableFilters: true
  },
  {
    Header: "Invoice",
    accessor: "invoice"
  },
  {
    Header: "Notes",
    accessor: "note"
  },
  {
    Header: "Cash",
    accessor: "value",
    disableFilters: true
  }
];

interface TransactionsPageParams {
  userId: number;
}

export const CollectorTransactions: React.FC<TransactionsPageParams> = props => {
  const [ data, setData ] = React.useState<TransactionsTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [ userTransactionsRes, userTransactionsReq ] = useApiRequest("TRANSACTIONS:listByUser");

  React.useEffect(() => {
    if (props.userId) {
      userTransactionsReq({
        pathParams: { userId: props.userId.toString() },
        params: {
          filters: {},
          offset: 0,
          limit: 10
        }
      });
    }
  }, [ props, userTransactionsReq ]);

  const fetchData = React.useCallback(({
    pageSize, pageIndex, tableFilters
  }: {
    pageSize: number;
    pageIndex: number;
    tableFilters?: Filters<TransactionsTable>;
  }) => {
    if (props.userId) {
    // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const filters: Partial<TransactionsTable> = {};

        tableFilters?.map(filter => {
          const id = filter.id as keyof TransactionsTable;

          filters[ id ] = filter.value;
        });
        // Set the loading state
        setLoading(true);

        userTransactionsReq({
          pathParams: { userId: props.userId.toString() },
          params: {
            filters,
            offset: pageIndex * pageSize,
            limit: pageSize
          }
        });
      }
    }
  }, [ props, userTransactionsReq ]);

  React.useEffect(() => {
    if (userTransactionsRes.data && userTransactionsRes.data.items) {
      const tableData: TransactionsTable[] = userTransactionsRes.data.items.map(transaction => {
        return {
          id: transaction.id,
          invoice: transaction.invoice,
          note: transaction.note,
          value: formatCurrency(transaction.value),
          createdAt: formatDate(new Date(transaction.createdAt), "dd MMM yyyy HH:mm")
        };
      });

      setData(tableData);
      setPageCount(Math.ceil(userTransactionsRes.data.total / userTransactionsRes.data.limit));
      setLoading(false);
    }

    if (userTransactionsRes.errorMessage) {
      toast.error(userTransactionsRes.errorMessage);
    }
  }, [ userTransactionsRes ]);

  return (
    <Table<TransactionsTable>
      name="collectorTransactionsTable"
      columns={columns}
      data={data}
      loading={loading}
      featuredFilter="invoice"
      fetchData={fetchData}
      pageCount={pageCount}
      usePagination
      useResizeColumns
      useHideColumns
      useFilters
    />
  );
};
