/* eslint-disable react/display-name */
import React from "react";
import {
  CellProps, HeaderProps, Hooks
} from "react-table";

import { CheckBoxInput } from "../../forms/CheckBoxInput";

function bulkSelection<T extends Record<string, unknown>>(hooks: Hooks<T>): void {
  hooks.allColumns.push(columns => [
    // Create a column for selection
    {
      id: "_selector",
      disableResizing: true,
      disableGroupBy: true,
      minWidth: 45,
      width: 45,
      maxWidth: 45,
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<T>) => (
        <CheckBoxInput {...getToggleAllRowsSelectedProps()} />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }: CellProps<T>) => <CheckBoxInput {...row.getToggleRowSelectedProps()} />
    },
    ...columns
  ]);

  hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
    // fix the parent group of the selection button to not be resizable
    const selectionGroupHeader = headerGroups[ 0 ].headers[ 0 ];

    selectionGroupHeader.canResize = false;
  });
}

export default bulkSelection;