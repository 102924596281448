import { ApiRouteDefinition } from "../useApiRequest";
import { PickParams } from "../api";
import { SearchAheadItem } from "src/components/shared/search-ahead";

import { Case } from "./cases.api";
import { Vintage } from "./vintages.api";
import { Label } from "./labels.api";

const BottleRoutes = {
  /** Get Bottle by id */
  "BOTTLES:get": {
    path: "/bottles/:bottleId",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<Bottle, null, PickParams<"bottleId">>,

  /** Partial update a Bottle */
  "BOTTLES:update": {
    path: "/bottles/:bottleId",
    authenticate: true,
    method: "PATCH"
  } as ApiRouteDefinition<
    Bottle,
    Partial<Bottle>,
    PickParams<"bottleId">
  >,
  /** Export a users bottles */
  "BOTTLES:export": {
    path: "/bottles/user/:userId/export",
    authenticate: true,
    method: "GET"
  } as ApiRouteDefinition<
    ExportData,
    null,
    PickParams<"userId">,
    { cognitoIdentityId: string }
  >
};

export interface ExportData {
    s3Key: string;
}

export default BottleRoutes;

export enum BottleStatus {
  in_case = "in_case",
  damaged = "damaged",
  lost = "lost",
}

export interface Bottle {
  id: number;
  name: string;
  bottleSize: string;
  lwin: string;
  vintageId: number;
  caseId: number;
  case?: Case;
  qualityStandard: string;
  status: BottleStatus;
  currentPrice?: number;
  currentPriceUpdatedAt?: Date;
  vintage?: Vintage;
  labelId: number;
  label?: Label;
}

export const availableFormats: Array<SearchAheadItem<string>> = [
  {
    value: "00500",
    label: "50cl"
  },
  {
    value: "00750",
    label: "75cl"
  },
  {
    value: "01000",
    label: "1L"
  },
  {
    value: "01500",
    label: "1.5L"
  },
  {
    value: "03000",
    label: "3L"
  },
  {
    value: "06000",
    label: "6L"
  }
];

export type CreateBottleInput = Omit<Bottle, "id">;