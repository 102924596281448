import React from "react";

const NFCScan: React.FC = () => {
  // redirect to brochure site
  React.useEffect(() => {
    window.location.href = "https://www.1275.ch";
  }, []);

  // TODO: add splash page and universal link to open native app
  return null;
};

export default NFCScan;
