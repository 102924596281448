import React from "react";
import { Column, Row } from "react-table";
import { toast } from "react-toastify";

import { Button } from "src/components/shared/button";
import { Card, PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Table } from "src/components/shared/table";
import { useTableFetch } from "src/hooks/useTableFetch";
import { PageProps } from "src/pages/Router";
import { User } from "src/utils/api/routes/users.api";
import { SelectRoleColumnFilter } from "src/components/user/SelectRoleColumnFilter";

type UsersTable = Pick<User, "id" | "name" | "email" | "phone" | "status" | "externalId" | "roles">;

const columns: Array<Column<UsersTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Phone",
    accessor: "phone"
  },
  {
    Header: "Role",
    accessor: "roles",
    disableSortBy: true,
    Cell: ({ row }) => {
      return row.original.roles.map(r => r.group).join(", ");
    },
    Filter: SelectRoleColumnFilter
  },
  {
    Header: "External Id",
    accessor: "externalId"
  },
  {
    Header: "Status",
    accessor: "status",
    disableFilters: true
  } 
];

const UsersTable: React.FC<PageProps<Record<string, unknown>>> = props => {
  const [ data, setData ] = React.useState<UsersTable[]>([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ usersRes, fetch ] = useTableFetch<"USERS:list", UsersTable>("USERS:list", { defaultSort: [ [ "name", "ASC" ] ] });

  React.useEffect(() => {
    if (usersRes.data && usersRes.data.items) {
      const tableData = usersRes.data.items.map(user => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          phone: user.phone,
          externalId: user.externalId,
          status: user.status,
          roles: user.roles
        };
      });

      setData(tableData);
      setPageCount(Math.ceil(usersRes.data.total / usersRes.data.limit));
      setLoading(false);
    }

    if (usersRes.errorMessage) {
      toast.error(usersRes.errorMessage);
    }
  }, [ usersRes ]);

  const handleOnClick = React.useCallback((row: Row<UsersTable>) => {
    props.history.push(`/admin/user/${row.original.id}`);
  }, [ props.history ]);

  return (
    <AdminLayout>
      <PageHeader 
        title="Users" 
        backLinkText="Back to dashboard"
        backLink={() => props.history.push("/admin/dashboard")} 
        callToAction={<Button label="New" onClick={() => props.history.push("/admin/user/new")} />} 
      />
      <Card>
        <Table<UsersTable>
          name="UsersTable"
          defaultHiddenColumns={[ "id" ]}
          title={`${usersRes.data?.total || 0} Users`}
          columns={columns}
          data={data}
          onClick={handleOnClick}
          loading={loading}
          useResizeColumns
          fetchData={fetch}
          pageCount={pageCount}
          usePagination
          featuredFilter="roles"
          useFilters
          useSortBy
          useHideColumns
        />
      </Card>
    </AdminLayout>
  );
};

export default UsersTable;
