import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  Column, Filters, Row
} from "react-table";
import { Menu, Box } from "grommet";
import { format } from "date-fns";

import { PageHeader } from "src/components/shared/layout";
import { AdminLayout } from "src/components/shared/layout/AdminLayout";
import { Text } from "src/components/shared/text";
import { useApiRequest } from "src/utils/api";
import { Estate } from "src/utils/api/routes/estates.api";
import { PageProps } from "src/pages/Router";
import {
  InfoPanel, InfoColumn, InfoBox, InfoLayoutColumnLeft, InfoLayoutColumnRight, InfoLayoutWrapper
} from "src/components/shared/info";
import { Card } from "src/components/shared/layout/Card";
import { Map } from "src/components/shared/map";
import { Wine } from "src/utils/api/routes/wines.api";
import { Table } from "src/components/shared/table";
import { LoadingSpinner } from "src/components/shared/loading-spinner";
import { Modal } from "src/components/shared/modal";
import { useTableFetch } from "src/hooks/useTableFetch";

interface ViewEstatePageParams {
  estateId: string;
}

type WinesTable = Pick<Wine, "id" | "estateId" | "lwin" | "name" | "region" | "subRegion" | "updatedAt">;

const columns: Array<Column<WinesTable>> = [
  {
    Header: "Id",
    accessor: "id",
    disableFilters: true
  },
  {
    Header: "LWIN",
    accessor: "lwin",
    disableFilters: true
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Region",
    accessor: "region"
  },
  {
    Header: "Sub-region",
    accessor: "subRegion"
  },
  {
    Header: "Last updated",
    accessor: "updatedAt",
    disableFilters: true,
    Cell: row => format(new Date(row.value), "i LLLL yyyy H:mm")
  }
];

const hiddenColumns = [ "id" ];

/**
 * Estate page
 */
const ViewEstatePage: React.FC<PageProps<Record<string, unknown>, ViewEstatePageParams>> = props => {
  const [ loading, setLoading ] = React.useState(false);
  const [ estate, setEstate ] = React.useState<Estate>();
  const [ getEstateRes, getEstateReq ] = useApiRequest("ESTATES:get");
  const [ deleteEstateRes, deleteEstateReq ] = useApiRequest("ESTATES:delete");
  const [ winesLoading, setWinesLoading ] = React.useState(false);
  const [ wines, setWines ] = React.useState<WinesTable[]>([]);
  const [ pageCount, setPageCount ] = React.useState(0);
  const [ deleteModalOpen, setDeleteModalOpen ] = React.useState(false);
  const estateId = React.useMemo(() => props.match?.params.estateId || "", [ props.match ]);

  const [ winesRes, fetch ] = useTableFetch<"WINES:list", WinesTable>("WINES:list", {
    persistFilters: { estateId: parseInt(estateId, 10) },
    defaultSort: [ [ "name", "ASC" ] ] 
  });

  // Table item click handler
  const handleOnClick = React.useCallback((row: Row<WinesTable>) => {
    props.history.push(`/admin/wine/${row.original.id}`);
  }, [ props.history ]);

  // On mount / unmount
  React.useEffect(() => {
    return () => {
      setEstate(undefined);
    };
  }, []);

  // Request the estate by id based off path params
  React.useEffect(() => {
    if (!estate && estateId) {
      getEstateReq({ pathParams: { estateId } });
      setLoading(true);
    }
  }, [
    estate,
    estateId,
    getEstateReq
  ]);

  // Detect the response for get estate by id
  React.useEffect(() => {
    if (getEstateRes.data) {
      setEstate(getEstateRes.data);
      setLoading(false);
    }

    if (getEstateRes.errorMessage) {
      toast.error(getEstateRes.errorMessage);
    }
  }, [ getEstateRes ]);

  // On winesRes change
  React.useEffect(() => {
    if (winesRes.data && winesRes.data.items) {
      setWines(winesRes.data.items);
      setPageCount(Math.ceil(winesRes.data.total / winesRes.data.limit));
      setWinesLoading(false);
    }

    if (winesRes.errorMessage) {
      toast.error(winesRes.errorMessage);
    }
  }, [ winesRes ]);

  // On deleteEstateRes change
  React.useEffect(() => {
    if (deleteEstateRes.data) {
      toast.success("The estate was successfully deleted.");
      props.history.push("/admin/estates");
    }

    if (deleteEstateRes.errorMessage) {
      toast.error(deleteEstateRes.errorMessage);
    }
  }, [ deleteEstateRes, props.history ]);

  return (
    <AdminLayout>
      <PageHeader backLink={() => props.history.push("/admin/estates")} />
      {loading && <LoadingSpinner />}

      {!loading && estate && (
        <Box>
          <InfoLayoutWrapper>
            <InfoLayoutColumnLeft>
              <Text as="h1">
                {estate.name}
              </Text>

              <InfoPanelStyled>
                <InfoColumn>
                  <InfoBox label="Lat" value={estate.latitude} />
                  <InfoBox label="Lon" value={estate.longitude} />
                  <InfoBox label="Country" value={estate.country} />
                  <InfoBox label="Region" value={estate.region} />
                  <InfoBox label="Sub region" value={estate.subRegion} />
                </InfoColumn>
              </InfoPanelStyled>
            </InfoLayoutColumnLeft>

            <InfoLayoutColumnRight>
              <Box pad={{ bottom: "small" }}>

                <StyledMenu
                  label="Actions"
                  dropProps={{
                    align: {
                      top: "bottom",
                      right: "right"
                    }
                  }}
                  items={[
                    {
                      label: "Edit estate",
                      onClick: () => props.history.push(`/admin/estates/${estateId}/edit`)

                    },
                    {
                      label: "Delete estate",
                      onClick: () => setDeleteModalOpen(true)
                    }
                  ]}
                />
              </Box>

              <Card>
                <StyledMap
                  markers={[
                    {
                      title: estate.name,
                      position: {
                        lat: estate.latitude,
                        lng: estate.longitude
                      }
                    }
                  ]}
                />
              </Card>
            </InfoLayoutColumnRight>
          </InfoLayoutWrapper>

          <Card margin={{ top: "large" }}>
            <Table<WinesTable>
              name="winesTable"
              title={`${winesRes.data?.total || 0} wines from this estate`}
              columns={columns}
              initalSortBy={[
                {
                  id: "lwin",
                  desc: false
                }
              ]}
              data={wines}
              onClick={handleOnClick}
              loading={winesLoading}
              fetchData={fetch}
              useResizeColumns
              pageCount={pageCount}
              defaultHiddenColumns={hiddenColumns}
              useSortBy
              usePagination
              useFilters
              useHideColumns
            />
          </Card>

          {deleteModalOpen && (
            <Modal
              isLoading={loading}
              onClose={() => setDeleteModalOpen(false)}
              title={`Delete ${estate.name}?`}
              description={`Are you sure that you want to delete ${estate.name}?`}
              actions={{
                confirm: {
                  label: "Delete",
                  onClick: () => {
                    deleteEstateReq({ pathParams: { estateId } });
                    setDeleteModalOpen(false);
                  }
                },
                reject: {
                  label: "Cancel",
                  onClick: () => setDeleteModalOpen(false)
                }
              }}
            />
          )}
        </Box>
      )}
    </AdminLayout>
  );
};

export default ViewEstatePage;

const InfoPanelStyled = styled(InfoPanel)`
  padding-top: 4.8rem;

  @media (min-width: 1380px) {
    padding-top: 8.8rem;
  }
`;

const StyledMenu = styled(Menu)`
  align-self: flex-end;
`;

const StyledMap = styled(Map)`
  position: relative;
  padding-top: 56.25%;
  width: 100%;

  @media (min-width: 768px) {
    padding-top: 114%;
  }

  @media (min-width: 960px) {
    padding-top: 56.25%;
  }

  @media (min-width: 1380px) {
    padding-top: 25rem;
  }
`;